(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.reports')
    .config(reportsRoutesConfig);

  function reportsRoutesConfig($stateProvider) {

    $stateProvider.state('admin.reports', {
      url: '/reports',
      abstract: true,
      template: '<div ui-view="active"></div>'
    }).state('admin.reports.list', {
      url: '',
      views: {
        'active': {
          controller: 'AdminReportsListCtrl as vm',
          templateUrl: 'dashboard/admin/reports/list/reports-list.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Reports',
        parent: 'admin.dashboard'
      }
    });
  }
}());
