/*globals moment:false */
(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoWebexSessionBadge', eoWebexSessionBadge);

  function eoWebexSessionBadge($rootScope, $state, $interval, WebexSessionsService) {
    return {
      replace: true,
      templateUrl: 'components/eo-webex-session-badge/eo-webex-session-badge.html',
      scope: { },
      controller: eoWebexSessionBadgeCtrl,
      controllerAs: 'badge',
      bindToController: true
    };

    function eoWebexSessionBadgeCtrl($element) {
      var fetchTimer, badge = this, counter;

      // fetch new unread notifications each minute
      fetchTimer = $interval(getSessionsCount, 60000);

      badge.createNewWebexSession = function() {
        if ($state.includes('instructor.**')) {
          $state.go('instructor.webexSessions.new');
        } else if($state.includes('successcoach.**')){
             $state.go('successcoach.webexSessions.new');
        }
      };

      badge.redirectToViewWebexSessions = function() {
        if ($state.includes('student.**')) {
          $state.go('student.webexSessions.list', {}, {reload: true});
        } else if ($state.includes('instructor.**')) {
          $state.go('instructor.webexSessions.list', {}, {reload: true});
        } else if ($state.includes('successcoach.**')) {
          $state.go('successcoach.webexSessions.list', {}, {reload: true});
        }
      };

      function getSessionsCount() {
        return WebexSessionsService.canRead().then(function(hasPermission) {
          if (!hasPermission) {
            return false;
          }

          WebexSessionsService.getSessionsCount().then(function(response) {
            badge.unreadSessionsCount = response.data;
            // fetch sessions preview
            getUserWebexSessionsForPreview();
            counter =  badge.unreadSessionsCount;
          });

          WebexSessionsService.canCreateSession().then(function(response) {
            badge.canSchedule = response;
          });
        });
      }

      function getUserWebexSessionsForPreview() {
        return WebexSessionsService.canRead().then(function(hasPermission) {
          if (!hasPermission) {
            return false;
          }

          return WebexSessionsService.getUnreadSessionsForPreview().then(function(response) {
            badge.unreadSessions = [];
            var link;

            _.each(response[0].data, function(value) {
              link = value.instructor_url || value.student_url;

              badge.unreadSessions.push(
                {
                  id: value.id,
                  subject: value.name,
                  created_at: moment(value.scheduled_date, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').format('MMM D, h:mm a'),
                  event: link ? 'webex_meeting_ready' : 'webex_meeting'
                }
              );
            });
          });
        });
      }

      $rootScope.$on('badge-counter:refresh', function() {
        getSessionsCount();
      });

      $element.on('$destroy', function() {
        $interval.cancel(fetchTimer);
      });

      getSessionsCount();
    }
  }
}());
