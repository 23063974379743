(function() {
  'use strict';

  angular
    .module('tcm.common')
    .factory('StudentSearchActivities', StudentSearchActivities);

  function StudentSearchActivities($stateParams, UserService, UserActivities,
    SearchService) {

    var search = new SearchService();

    function getActivities(queryString, state) {
      queryString = queryString || {};
      // set search query params only the search case
      if (state === 'search') {
        search.addParams(queryString);
      }
      return UserService.loadProfile().then(function(profile) {
        return UserActivities.getActivitiesByUser(profile.id, $stateParams.id, queryString);
      });
    }

    return {
      searchData: search.data,
      getActivities: getActivities
    };
  }
}());
