/*globals moment:false */
(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoStudentList', eoStudentList);

  function eoStudentList($state, $stateParams, $location, Student,
    StudentActivity, UserService, TokenService, TableFeaturesService,
    Institution, SpinnerService, $uibModal, Upload, DashboardRouting, $rootScope, localStorageService) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-student-list/eo-student-list.html',
      scope: {
        cohortName: '=',
        courseName: '=',
        courseId: '=',
        institutionId: '=',
        isGradePercentage: '='
      },
      controllerAs: 'vm',
      bindToController: true,
      controller: eoStudentListCtrl
    };

    function eoStudentListCtrl() {
      var vm = this, tableFeature, spinner;

      vm.activeTab = {};
      // set cohort id as 'all' to view all cohorts or as 'false' value to get an id of a particular cohort
      vm.viewAll = $stateParams.id === 'all' ? 'all' : '';
      vm.isfilterCollapsed = ($stateParams.user_first_name_cont || $stateParams.user_last_name_cont) ? false : true;
      vm.cohortId =  $stateParams.id === 'all' ? 'all' : parseInt($stateParams.id, 10);
      vm.state = $stateParams.state;
      spinner = new SpinnerService();

      tableFeature = new TableFeaturesService({
        sorting: {column: 'last_name'},
        pagination: {perPage: 25},
        search: {
          'user_first_name_cont': $stateParams.user_first_name_cont || '',
          'user_last_name_cont': $stateParams.user_last_name_cont || ''
        },
        loadData: function(queryParams) {
          return getResourceData(queryParams);
        }
      });

      vm.table = {
        searchData: tableFeature.searchParams,
        sortingData: tableFeature.sortingParams,
        paginationData: tableFeature.paginationParams
      };

      vm.getTabData = function() {
        if ($stateParams.dashboard === 'dashboard') {
          return;
        }

        setQueryParams();

        return spinner.activateController([tableFeature.getPageData().then(function(response) {
          vm.data = response[0].data;
        })]);
      };

      function getResourceData(queryParams) {
        if (!TokenService.hasAccessToken() && $location.search().refresh_token) {
          return UserService.refreshToken($location.search().refresh_token).then(function(result) {
            TokenService.setToken(result.data);
            return getResourceByState(queryParams);
          });
        } else {
          return getResourceByState(queryParams);
        }
      }

      function getResourceByState(queryParams) {
        if (vm.state === 'to-grade') {
          return StudentActivity.getList(queryParams);
        }

        return Student.getList(queryParams);
      }

      function setActiveTab() {
        var activeTab = 'all';

        if (vm.state === 'at-risk') {
          activeTab = 'risk';
        } else if (vm.state === 'to-grade') {
          activeTab = 'grade';
        }

        $rootScope.$broadcast('roleChanged', {role: 'instructor'});
        localStorageService.set('role', 'instructor');

        vm.activeTab[activeTab] = true;
      }

      function setQueryParams() {
        if (vm.cohortId !== 'all') {
          tableFeature.searchParams.cohort_id_eq = vm.cohortId;
        }

        if (vm.state === 'at-risk') {
          tableFeature.searchParams.at_risk_true = 1;
        } else if (vm.state === 'to-grade') {
          // change default sorting to 'completed_at' for To-grade tab
          if (tableFeature.sortingParams.column === 'last_name') {
            tableFeature.sortingParams.column = 'completed_at';
          }
          tableFeature.searchParams.state_eq = 'awaiting_instructor_grading';
        }

        tableFeature.resetEmptySearchData();
      }

      vm.goToSearchState = function() {
        $state.go('instructor.cohorts.objectives.studentlist.search', vm.extractParamsToFilterBy());
      };

      vm.reset = function() {
        tableFeature.resetSearch();
        setQueryParams();
      };

      vm.extractParamsToFilterBy = function() {
        var searchData = {};

        _.each(vm.table.searchData, function(item, key) {
          if (key.indexOf('name_cont') !== -1) {
            searchData[key] = vm.table.searchData[key];
          }
        });

        return searchData;
      };

      vm.export = function() {
        spinner.activateController([
          Institution.one(vm.institutionId).one('cohorts', vm.cohortId).one('csv').get().then(function(response) {
            if (typeof Blob === 'function') {
              var library_csv, today;

              library_csv = new Blob([response.data], {type: 'text/csv;charset=UTF-8'});
              today = moment().format('MMDDYYYY');
              saveAs(library_csv, today + '_' + vm.cohortName + '_' + vm.cohortId + '.csv');
            }
          })
        ]);
      };

      vm.update = function() {
        $uibModal.open({
          templateUrl: 'components/eo-student-list/modals/update-cohort-modal.html',
          controllerAs: 'modal',
          controller: function($scope) {
            var modal = this, fileObj;
            modal.model = {};

            modal.fields = [
              {
                key: 'file',
                type: 'file-upload',
                templateOptions: {
                  required: true,
                  label: 'CSV File',
                  fileExtension: '.csv'
                },
                expressionProperties: {
                  'templateOptions.fileOptions.fileExtension': '".csv"'
                },
                /* @ngInject */
                controller: function($scope) {
                  $scope.$watch('file', function(newValue) {
                    var file;

                    delete modal.savedSuccesfuly;
                    delete modal.flash;

                    if (newValue) {
                      // FIXME. Creating a copy of the file object after processing it to avoid exceptions caused due
                      // to illegal types. Using $.extend since its the only one that seemed to extend the
                      // native File object as expected
                      // For formly validation
                      file = $.extend({}, newValue);
                      file.lastModifiedDate = file.lastModifiedDate.toString();
                      modal.model.file = file;

                      // File object to upload
                      fileObj = newValue;
                    }
                  });
                }
              }
            ];

            modal.save = function() {
              delete modal.savedSuccesfuly;
              delete modal.flash;

              if (modal.updateCohortForm.$valid) {
                spinner.activateController([
                  Upload.upload({
                    url: Institution.one(vm.institutionId).one('cohorts', vm.cohortId).one('csv').getRestangularUrl(),
                    file: fileObj,
                    method: 'POST'
                  })
                    .then(
                      function(response) {
                        vm.getTabData();
                        vm.flash = response.data.message;
                        vm.savedSuccesfuly = true;

                        $scope.$dismiss();
                      },
                      function(response) {
                        modal.flash = response.data.error;
                      }
                    )
                ]);
              }
            };
          }
        });
      };

      vm.masqueradeAsStudent = function($event, student) {
        $event.stopPropagation();

        $uibModal.open({
          templateUrl: 'components/eo-student-list/modals/masquerade-as-student-modal.html',
          controllerAs: 'modal',
          controller: function($scope) {
            var modal = this;

            modal.studentFullName = student.user_first_name + ' ' + student.user_last_name;

            modal.submit = function() {
              UserService.signInAsStudent(student.user_id, vm.cohortId).then(function() {
                $scope.$close();
                $scope.$emit('badge-counter:refresh');
                DashboardRouting.execStudentDashboardRouting();
              });
            };
          }
        });
      };

      vm.toggleState = function() {
        $state.go(vm.roleModel + '.cohorts.objectives.studentlist', {id: 'all', state: 'all', dashboard: 'dashboard'});
      };

      $rootScope.$on('roleChanged', function(event, data) {
        vm.roleModel = data.role;
      });

      setActiveTab();
      vm.getTabData();
    }
  }
}());
