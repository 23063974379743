(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.competencies')
    .controller('AdminCompetenciesManageObjectivesCtrl', AdminCompetenciesManageObjectivesCtrl);

  function AdminCompetenciesManageObjectivesCtrl($stateParams, SpinnerService,
    Institution, UserService) {

    var vm = this, spinner, contextId, competencyId;

    spinner = new SpinnerService();
    contextId = parseInt($stateParams.contextId, 10);
    competencyId = parseInt($stateParams.id, 10);

    var getCompetency = function() {
      Institution.one(contextId).one('competencies', competencyId).get().then(function(response) {
        vm.competency = response.data;
      });
    };

    function checkManagePermission() {
      return UserService.canAccess(['MANAGE_OBJECTIVES'], ['competencies']).then(function(hasPermission) {
        vm.managePermission = hasPermission;
      });
    }

    spinner.activateController([
      getCompetency(),
      checkManagePermission()
    ]);
  }
}());
