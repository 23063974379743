(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoAssignedCourseObjectives', eoAssignedCourseObjectives);

  function eoAssignedCourseObjectives($rootScope, $timeout, $stateParams,
    Institution, TableFeaturesService, SpinnerService
  ) {
    return {
      templateUrl: 'components/eo-assigned-course-objectives/eo-assigned-course-objectives.html',
      scope: {
        courseState: '=',
        flashMessage: '=?',
        managePermission: '=?'
      },
      controller: eoAssignedCourseObjectivesCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoAssignedCourseObjectivesCtrl($scope) {
      var vm = this, spinner, tableFeatures, contextId, courseId;

      contextId = parseInt($stateParams.contextId, 10);
      courseId = parseInt($stateParams.id, 10);
      vm.checkedObjectives = [];

      spinner = new SpinnerService();

      $timeout(function() {
        $scope.$emit('eoAssignedCourseObjectives:handshake');
      }, 0);

      $rootScope.$on('eoAvailableCourseObjectives:handshake', function() {
        vm.manageMode = true;
      });

      $rootScope.$on('eoAssignedCourseObjectives:reload', function() {
        vm.loadAssignedCourseObjectives();
      });

      tableFeatures = new TableFeaturesService({
        sorting: false,
        pagination: {
          perPage: 10,
          scrollTop: false
        },
        loadData: function(queryParams) {
          return Institution.one(contextId).one('curriculums', courseId).getList('objectives', queryParams);
        }
      });

      vm.table = {
        paginationData: tableFeatures.paginationParams
      };

      vm.updateWeights = function() {
        var weights = {
          objective_weights: _.chain(vm.assignedObjectives).map(function(objective) {
            return {
              id: objective.id,
              weight: (objective.curriculum_weight !== null) ? objective.curriculum_weight : 1
            };
          }).value()
        };

        spinner.activateController([
          Institution.one(contextId).one('curriculums', courseId).one('objectives/weights').customPUT(weights)
            .then(function() {
              vm.flashMessage = '';
            }, function() {
              vm.flashMessage = 'Updating objective\'s weight has failed';
            })
        ]);
      };

      vm.loadAssignedCourseObjectives = function() {
        vm.checkedObjectives.length = 0;
        tableFeatures.getPageData().then(function(response) {
          vm.assignedObjectives = response[0].data;
        });
      };

      vm.checkObjective = function(objective) {
        if (objective.checked) {
          vm.checkedObjectives.push(objective.id);
        } else {
          _.pull(vm.checkedObjectives, objective.id);
        }
      };

      vm.setCheckedState = function(state) {
        vm.checkedObjectives.length = 0;
        _.each(vm.assignedObjectives, function(objective) {
          objective.checked = state;
          if (state) {
            vm.checkedObjectives.push(objective.id);
          } else {
            vm.checkedObjectives.length = 0;
          }
        });
      };

      vm.moveObjective = function(objective, direction, $event) {
        var position, delta;

        $event.stopPropagation();
        $event.preventDefault();

        delta = (direction === 'up') ?  -1 : 1;
        position = objective.position + delta;

        if (position >= 0 && position <= vm.table.paginationData.totalItems) {
          spinner.activateController([
            Institution.one(contextId).one('curriculums', courseId).one('objectives', objective.id).put({
              position: position
            }).then(function() {
              vm.flashMessage = '';
              vm.loadAssignedCourseObjectives();
            }, function() {
              vm.flashMessage = 'Updating objective\'s position has failed';
            })
          ]);
        }
      };

      vm.removeSelectedObjectives = function() {
        Institution.one(contextId).one('curriculums', courseId).customDELETE('objectives', {'lo_ids[]': vm.checkedObjectives}).then(function() {
          vm.loadAssignedCourseObjectives();
          vm.checkedObjectives.length = 0;
          $scope.$emit('eoAvailableCourseObjectives:reload');
        });
      };

      vm.loadAssignedCourseObjectives();
    }
  }
}());
