(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.courses')
    .controller('AdminCoursesListCtrl', AdminCoursesListCtrl);

  function AdminCoursesListCtrl($scope, $stateParams, $location, Institution,
    TableFeaturesService) {

    $scope.flash = {error: null};

    var tableFeatures = new TableFeaturesService({
      sorting: {column: 'name'},
      search: {
        'name_cont': $stateParams.name_cont,
        'code_cont': $stateParams.code_cont
      },
      loadData: function(queryParams) {
        return Institution.one($stateParams.contextId).getList('curriculums', queryParams);
      }
    });

    $scope.userList = {
      searchData: tableFeatures.searchParams,
      sortingData: tableFeatures.sortingParams,
      paginationData: tableFeatures.paginationParams
    };

    $scope.loadPage = function() {
      tableFeatures.getPageData().then(function(response) {
        $scope.curriculums = response[0].data;
      });
    };

    $scope.search = function() {
      tableFeatures.resetEmptySearchData();
      $location.search($scope.userList.searchData);
      tableFeatures.getNewData().then(function(response) {
        $scope.curriculums = response[0].data;
      });
    };

    $scope.reset = function() {
      tableFeatures.resetSearch();
      $scope.search();
    };

    $scope.disableClearOption = function() {
      var disable = true;

      _.each($scope.userList.searchData, function(n, key) {
        if ($scope.userList.searchData[key] !== void 0 && $scope.userList.searchData[key].length > 0) {
          disable = false;
        }
      });

      return disable;
    };

    $scope.loadPage();
  }
}());
