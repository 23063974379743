(function() {
  'use strict';

  angular
    .module('tcm.error')
    .config(errorRoutesConfig);

  function errorRoutesConfig($stateProvider) {
    $stateProvider
      .state('error', {
        url: '/error',
        views: {
          'main': {
            controller: 'ErrorCtrl',
            templateUrl: 'error/error.html'
          }
        }
      });
  }
}());
