(function() {
  'use strict';

  angular
    .module('tcm.dashboard.student.cohorts')
    .directive('eoStudentActivitiesLaunch', StudentActivitiesLaunch);

  function StudentActivitiesLaunch() {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-learning-activity-card/eo-learning-activity-card-launch.html',
      controller: StudentActivitiesLaunchCtrl,
      controllerAs: 'vm'
    };
  }

  function StudentActivitiesLaunchCtrl($rootScope, $scope, $stateParams, $state,
    $uibModal, $element, UserActivities, UserService, LocationTrackingService, ExternalService, SpinnerService) {
    var vm = this, spinner;
    spinner = new SpinnerService();
    vm.state = $stateParams.state;
    vm.activityId = parseInt($stateParams.activityId, 10);
    vm.cohortId = parseInt($stateParams.id, 10);
    vm.objectiveId = parseInt($stateParams.objectiveId, 10);
    vm.sortingColumn = $stateParams.sort || 'position';
    vm.sortingDirection = $stateParams.direction;

    vm.launchActivity = function(activity) {
      var queryParams = {
        cohort_id: vm.cohortId,
        role: 'Student'
      };

      queryParams.learning_activity_id = activity ? activity.id : vm.activity.id;

      if (vm.state === 'all') {
        queryParams.learning_objective_id = vm.objectiveId;
      }

      if (vm.currentAttempt && vm.currentAttempt.state !== 'in_progress') {
        queryParams.attempt_number = vm.currentAttempt.attempt_number;
      }

      checkIfStudentCanCreateNewAttempt(vm.activity);

      ExternalService.launchContentForActivity(activity || vm.activity, queryParams).then(function(result) {
        vm.contentLaunched = result.contentLaunched;
        vm.masqueraded = result.custom_launch_read_only;
      });
    };

    vm.redirectToActivity = function(activityId) {
      if (vm.activity.activity_type === 'assessment') {
        // update assessment attempts state
        requestActivity().then(function(response) {
          // verify if the last attempt state is in progress in attempts and attempts with comments arrays, if the activity is an assessment then show the popup
          var activity, attempInProgress, attemptWithCommentsInProgress;

          activity = response[0].data;
          attempInProgress = activity.user_attempts[0] && activity.user_attempts[0].state === 'in_progress'; //FIFO
          attemptWithCommentsInProgress = _.last(activity.attempts_with_comments) && _.last(activity.user_attempts).state === 'in_progress'; //LAFO

          if (attempInProgress || attemptWithCommentsInProgress) {
            var $modalInstance = $uibModal.open({
              animation: vm.animationsEnabled,
              templateUrl: 'components/eo-learning-activity-card/modals/activity-confirmation-modal.html',
              controllerAs: 'modal',
              controller: function() {
                var modal = this;

                modal.title = 'Submit Activity Progress';
                modal.heading = 'You have not yet submitted your work. Are you sure you want to leave this activity?';
                modal.confirmation = 'Leaving the activity may submit it automatically';

                modal.ok = function() {
                  redirect();
                  $modalInstance.close();
                };
              }
            });
          } else {
            redirect();
          }
        });
      } else {
        redirect();
      }

      function redirect() {
        if (vm.state === 'search')  {
          $state.go('student.cohorts.all.activitySearchLaunch', {state: vm.state, activityId: activityId});
        } else {
          $state.go('student.cohorts.activityLaunch', {state: vm.state, objectiveId: vm.objectiveId, activityId: activityId});
        }
      }
    };

    function checkIfStudentCanCreateNewAttempt(activity) {
      vm.allowCreateNewAttempt = true;

      if (activity && activity.user_attempts && activity.user_attempts.length) {
        _.each(activity.user_attempts, function(value) {
          if (value.state === 'in_progress') {
            vm.allowCreateNewAttempt = false;
            return vm.allowCreateNewAttempt;
          }
        });
      }
    }

    function loadActivity() {
      if (vm.state === 'search') {
        return UserService.loadProfile().then(function(profile) {
          var queryParams = {};

          if ($stateParams.search) {
            queryParams['q[learning_activity_description_cont]'] = $stateParams.search;
          }

          return spinner.activateController([
            UserActivities.getActivityByUser(profile.id, vm.cohortId, vm.activityId, queryParams).then(function(response) {
              vm.activity = response.data;
              processAttemptState(vm.activity);

              //make sure to select last attempt
              if (vm.activity.user_attempts && vm.activity.user_attempts.length) {
                vm.currentAttempt = vm.activity.user_attempts[0];
              }

              checkIfStudentCanCreateNewAttempt(vm.activity);
              if (vm.activity.content_type !== 'LtiProvider') {
                vm.launchActivity(vm.activity);
              }

              return vm.activity;
            })
          ]);
        });
      } else {
        return UserService.loadProfile().then(function() {
          return spinner.activateController([
            UserActivities.getActivityByObjective (vm.objectiveId, vm.activityId).then(function(response) {
              vm.activity = response.data;
              processAttemptState(vm.activity);

              if (vm.activity.content_type !== 'LtiProvider') {

                //make sure to select last attempt
                if (vm.activity.user_attempts && vm.activity.user_attempts.length) {
                  vm.currentAttempt = vm.activity.user_attempts[0];
                }

                checkIfStudentCanCreateNewAttempt(vm.activity);
                vm.launchActivity(vm.activity);
              }

              return vm.activity;
            })
          ]);
        });
      }
    }

    // TO-DO: Reuse this function to replace current loadActivity and clean the duplicated code
    function requestActivity() {
      if (vm.state === 'search') {
        return UserService.loadProfile().then(function(profile) {
          var queryParams = {};

          if ($stateParams.search) {
            queryParams['q[learning_activity_description_cont]'] = $stateParams.search;
          }

          return spinner.activateController([UserActivities.getActivityByUser(profile.id, vm.cohortId, vm.activityId, queryParams)]);
        });

      } else {
        return UserService.loadProfile().then(function() {
          return spinner.activateController([UserActivities.getActivityByObjective (vm.objectiveId, vm.activityId)]);
        });
      }
    }

    function processAttemptState(activity) {
      if (activity.user_attempts !== undefined && !activity.gradable) {
        activity.user_attempts.forEach(function(attempt) {
          if (attempt.state === 'mastered') {
            attempt.readable_state = 'Approved';
          } else if (attempt.state === 'failed') {
            attempt.readable_state = 'Disapproved';
          } else if (attempt.state === 'awaiting_instructor_grading') {
            attempt.readable_state = 'Awaiting Instructor Approval';
          }
        });
      }
    }

    function hideHeaderComponents() {
      $rootScope.$broadcast('activityLaunched');
    }

    function trackIframeEvents() {
      LocationTrackingService.trackIframeMessages('CDP-TCM:Mouse-move', function() {
        $element.mousemove();
      });
    }

    loadActivity();
    hideHeaderComponents();
    trackIframeEvents();
  }
}());
