(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('SessionStorageService', SessionStorageService);

  function SessionStorageService($window) {

    return {
      get: function(value) {
        try {
          return JSON.parse($window.sessionStorage.getItem(value));
        } catch (e) {
          return $window.sessionStorage.getItem(value);
        }
      },

      set: function(key, value) {
        $window.sessionStorage.setItem(key, value);
      },

      clearItem: function(key) {
        $window.sessionStorage.removeItem(key);
      },

      clearAll: function() {
        $window.sessionStorage.clear();
      }
    };
  }
}());
