(function() {
  'use strict';

  angular
    .module('tcm.common')
    .directive('eoExportProgram', eoExportProgram);

  function eoExportProgram() {
    return {
      restrict: 'E',
      templateUrl: 'common/directives/eo-export-program/eo-export-program.html',
      scope: {
        'program': '='
      },
      controller: eoExportProgramCtrl
    };

    function eoExportProgramCtrl($scope, $stateParams, SpinnerService, $uibModal, Institution) {
      var spinner, exportProgram;

      spinner = new SpinnerService();

      $scope.export = function() {
        var modalInstance = $uibModal.open({
          templateUrl: 'common/directives/eo-export-program/eo-export-program-pop.html',
          controller: function($scope) {
            $scope.data = {};

            $scope.ok = function() {
              $scope.$close();
            };
          }
        });

        // Handle cancel on modal and patch errors
        modalInstance.result.then(function() {exportProgram(true);})
        .catch(function() {exportProgram();});

        return;
      };

      exportProgram = function(includeLO) {
        var queryParams = {};

        if (includeLO) {
          queryParams.include_lo = true;
        }

        spinner.activateController([
          Institution.one($stateParams.contextId).one('programs', $scope.program.id).one('competencies','csv').get(queryParams).then(function(response) {
            if (typeof Blob === 'function') {
              var library_csv = new Blob([response.data], {type: 'text/csv;charset=UTF-8'});
              saveAs(library_csv, $scope.program.name + '_' + $scope.program.id + '.csv');
            }
          })
        ]);
      };
    }
  }
}());
