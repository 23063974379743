(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('SearchService', SearchService);

  function SearchService($location) {

    function Search(data) {
      this.data = data || {};
    }

    function keyValues(parent_key, obj, acc) {
      acc = acc || {};
      return _.reduce(obj, function(acc2, value, key) {
        var new_key = parent_key + '[' + key + ']';
        if (_.isObject(value)) {
          return keyValues(new_key, value, acc2);
        } else if (_.isArray(value)) {
          _.forOwn(value, function(value1, key1) {
            var new_key1 = new_key + '[' + key1 + ']';
            acc2[new_key1] = value1;
          });
        } else {
          acc2[new_key] = value;
          return acc2;
        }
      }, acc);
    }

    Search.prototype.addParams = function(queryString) {
      queryString = queryString || {};
      return keyValues('q', this.data, queryString);
    };

    Search.prototype.setSearchQueryParamsByState = function(params) {
      var searchData = {
        g: params,
        m: 'or'
      };
      this.reset();
      _.extend(this.data, searchData);
    };

    Search.prototype.reset = function() {
      for (var prop in this.data) {
        delete this.data[prop];
        $location.search(prop, null);
      }
    };

    Search.prototype.resetEmpty = function() {
      for (var prop in this.data) {
        if (this.data[prop] === '') {
          delete this.data[prop];
          $location.search(prop, null);
        }
      }
    };

    return Search;
  }
}());
