(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoCourseStructureTreeActivity', eoCourseStructureTreeActivity);

  function eoCourseStructureTreeActivity($state, $stateParams, $uibModal,
     ExternalService, UserActivities, UserService, Institution, SpinnerService) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-course-structure-tree/eo-course-structure-tree-activity.html',
      scope: {},
      controller: eoCourseStructureTreeActivityCtrl,
      controllerAs: 'vm'
    };

    function eoCourseStructureTreeActivityCtrl($rootScope) {
      var vm = this, spinner, institutionId, activityId, activities = [], courseId, course, 
      objectiveId, objectives, modules, moduleIndex = 0, $uibModalInstance;

      vm.contentLaunched = false;
      vm.activity = {};
      vm.editRubricMode = false;

      spinner = new SpinnerService();

      vm.launchActivity = function() {
        ExternalService.previewContentForActivity(vm.activity).then(function(result) {
          vm.contentLaunched = result;
        });
      };

      vm.redirectToActivity = function(activityId, newObjectiveId) {
        objectiveId = newObjectiveId || objectiveId;

        if ($state.includes('admin.**')) {
          $state.go('admin.courses.activity', {activityId: activityId, objectiveId: objectiveId, curriculumId: courseId});  
        } else {
          var role;

          if ($state.includes('instructor.**')) {
            role = 'instructor';
          } else if ($state.includes('successcoach.**')) {
            role = 'successcoach';
          }

          if (role) {
            $state.go(role + '.cohorts.structure.activity', {activityId: activityId, objectiveId: objectiveId});  
          }
          
          return;
        }


      };

      vm.rubricEdit = function() {
        if (vm.editRubricMode && vm.isEditRubricsTableValid) {
          $uibModalInstance = $uibModal.open({
            animation: vm.animationsEnabled,
            templateUrl: 'components/eo-course-structure-tree/modals/confirm-rubrics-modal.html',
            controllerAs: 'modal',
            controller: function CancelModalCtrl() {
              var modal = this;

              modal.save = function() {

                if (vm.model && vm.model.id) {
                  Institution.one(vm.model.owner_institution_id).one('rubrics', vm.model.id).patch(vm.model).then(function() {
                    loadPage();
                    $uibModalInstance.close();
                    vm.editRubricMode = !vm.editRubricMode;
                  }).catch(function(errorResponse) {
                    modal.rubricMessage = errorResponse.data.error;
                  });
                }
              };

              modal.cancel = function() {
                $uibModalInstance.close();
              };
            }
          });
        }

        if (!vm.editRubricMode) {
          vm.editRubricMode = !vm.editRubricMode;
        }
      };

      vm.rubricDiscard = function() {
        loadPage();
        vm.editRubricMode = !vm.editRubricMode;
      };

      function loadPage() {
        institutionId = parseInt($stateParams.contextId, 10);
        activityId = parseInt($stateParams.activityId, 10);
        courseId = parseInt($stateParams.curriculumId, 10);
        objectiveId = parseInt($stateParams.objectiveId, 10);

        spinner.activateController([
          UserActivities.getActivity(activityId).then(function(response) {
            vm.activity = response.data;
            getRubric();

            if (vm.activity.content_type !== 'LtiProvider') {
              vm.launchActivity();
            }
          }),
          UserService.loadProfile().then(function(profile) {
            institutionId = institutionId || profile.institution_id;
            loadCourse(courseId);
          })
        ]);
      }

      function loadCourse(courseId) {
        Institution.one(institutionId).one('curriculums', courseId).get().then(function(response) {
          course = response.data;

          Institution.one(institutionId).one('curriculums', courseId).getList('children').then(function(response) {
            modules = response.data;
          });

          loadObjectives(course);
        });
      }

      function loadObjectives(course) {
        var currentActIndex;

        Institution.one(institutionId).one('curriculums', course.id).getList('objectives').then(function(response) {
          objectives = response.data;
          activities = _.flatten(_.map(objectives, function(objective) {
            return _.map(objective.activities, function(activity) {return {id: activity.id, objective_id: objective.id};});
          }));

          currentActIndex = _.findIndex(activities, {id: activityId, objective_id: objectiveId});

          if (currentActIndex > -1) {
            // check if there is a next activity
            if (activities[currentActIndex + 1] && activities[currentActIndex + 1].id) {
              vm.nextActivity = activities[currentActIndex + 1];
            }

            // check if there is a previous activity
            if (activities[currentActIndex - 1] && activities[currentActIndex - 1].id) {
              vm.previousActivity = activities[currentActIndex - 1];
            }
          } else {
            loadModules(modules, currentActIndex);
          }
        });
      }

      function loadModules(modules, currentActIndex) {
        if (!modules || modules.length < 1 || !modules[moduleIndex] || currentActIndex > -1) {
          return;
        }

        Institution.one(institutionId).one('curriculums', modules[moduleIndex].id).get().then(function(response) {
          course = response.data;

          loadObjectives(course);

          moduleIndex ++;
        });
      }

      function getRubric() {
        if (!_.isEmpty(vm.activity.rubric) && vm.activity.rubric.id) {
          vm.isEditRubricsTableValid = true;
          Institution.one(vm.activity.rubric.owner_institution_id).one('rubrics', vm.activity.rubric.id).get().then(function(response) {
            vm.model = response.data;
            vm.model.delete_criteria_ids = [];
            vm.model.delete_rules_ids = [];
          });
        }
      }

      function hideHeaderComponents() {
        $rootScope.$broadcast('activityLaunched');
      }

      loadPage();
      hideHeaderComponents();
    }
  }
}());
