(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoObjectivesList', eoObjectivesList);

  function eoObjectivesList($location, $stateParams, $rootScope,
      gettext, UserObjectives, UserService, TableFeaturesService) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-objectives-list/eo-objectives-list.html',
      scope: {},
      controller: eoObjectivesListCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoObjectivesListCtrl() {
      var vm = this, cohortId, tableFeatures;

      vm.state = $stateParams.state;
      cohortId = parseInt($stateParams.id, 10);

      tableFeatures = new TableFeaturesService({
        sorting: {column: 'position'},
        pagination: {
          perPage: 10,
          scrollTop: false
        },
        loadData: function(queryParams) {
          return UserObjectives.getObjectivesByCohort(cohortId, queryParams);
        }
      });

      vm.table = {
        searchData: tableFeatures.searchParams,
        sortingData: tableFeatures.sortingParams,
        paginationData: tableFeatures.paginationParams
      };

      function setActiveTab(tab) {
        vm.activeTab = {};

        if (tab === 'in-progress') {
          tab = 'in_progress';
        }

        vm.activeTab[tab] = true;
      }

      function loadObjectives() {
        // Logging in through LTI (do not perform any logic if student login first time)
        vm.getTabData();
      }

      vm.getTabData = function() {
        tableFeatures.setSearchQueryParamsByState(vm.state, 'user_objective_state_eq');
        tableFeatures.getPageData().then(function(results) {
          vm.objectives = results[0].data;
          setActiveTab(vm.state);

          $rootScope.$broadcast('user-objectives-cache:invalidate');
        });
      };

      //TODO: Candidate for a custom filter
      vm.status = function(objective) {
        if (objective.user_at_risk) {
          return gettext('At Risk');
        } else {
          return objective.user_state_readable;
        }
      };

      setActiveTab(vm.state);
      loadObjectives();
    }
  }
}());
