/*globals moment:false */
(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.reports')
    .controller('AdminReportsListCtrl', AdminReportsListCtrl);

  function AdminReportsListCtrl(SpinnerService, Institution, $stateParams, $uibModal) {

    var vm = this,  spinner, contextId;
    spinner = new SpinnerService();
    contextId = parseInt($stateParams.contextId, 10);

    vm.export = function() {
      var modalInstance = $uibModal.open({
        templateUrl: 'dashboard/admin/reports/modals/student-progress-reports.html',
        controllerAs: 'vm',
        controller: function($scope) {
          var vm = this;
          vm.model = {};
          vm.model.to = new Date().toISOString().slice(0, 10);
          vm.model.from = moment().subtract(14, 'days').toDate().toISOString().slice(0, 10);
          vm.fields = [
            {

              key: 'from',
              type: 'ui-date',
              templateOptions: {
                required: true,
                label: 'Start Date'
              },
              expressionProperties: {
                'templateOptions.dateOptions.dateFormat': '"yy-mm-dd"'
              }
            },
            {

              key: 'to',
              type: 'ui-date',
              templateOptions: {
                required: true,
                label: 'End Date'
              },
              expressionProperties: {
                'templateOptions.dateOptions.dateFormat': '"yy-mm-dd"',
                'templateOptions.dateOptions.minDate': function() {
                  return vm.model.from;
                }
              }
            }
          ];

          vm.cancel = function() {
            $scope.$dismiss('close');
          };

          vm.save = function() {
            if (vm.studentProgressReportsForm.$valid) {
              $scope.$close(vm.model);
            }
          };
        }
      });
      modalInstance.result.then(function(data) {

        spinner.activateController([
          Institution.one(contextId).one('reports').one('student_progress.csv').get(data).then(function(response) {
            if (typeof Blob === 'function') {
              var reports_csv = new Blob([response.data], {type: 'text/csv;charset=UTF-8'});
              saveAs(reports_csv, 'student course progress.csv');
            }
          })
        ]);
      });
    };

    //Empty spinner request to avoid looping
    spinner.activateController([]);
  }
}());
