/*globals moment:false */

(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.cohorts')
    .controller('AdminCohortsListCtrl', AdminCohortsListCtrl);

  function AdminCohortsListCtrl(SpinnerService, $stateParams, $location, TableFeaturesService,
    Institution, UserService) {

    var vm = this, contextId, tableFeatures, spinner;

    spinner = new SpinnerService();


    contextId = parseInt($stateParams.contextId, 10);

    vm.isfilterCollapsed = true;
    vm.operators = [
      {key: '>', value: 'gt'},
      {key: '<', value: 'lt'},
      {key: '=', value: 'eq'}
    ];
    vm.filters = {
      start: {
        operator: vm.operators[1],
        input: moment().add(1, 'day').format('YYYY-MM-DD').toString()
      },
      end: {
        operator: vm.operators[0],
        input: moment().add(1, 'day').format('YYYY-MM-DD').toString()
      },
      uiDateFormat: {
        dateFormat: 'yy-mm-dd'
      },
      clearDisabled: false
    };

    tableFeatures = new TableFeaturesService({
      sorting: {
        column: 'name'
      },
      search: searchParams(),
      loadData: function(queryParams) {
        return Institution.one(contextId).getList('cohorts', queryParams);
      }
    });

    vm.table = {
      searchData: tableFeatures.searchParams,
      sortingData: tableFeatures.sortingParams,
      paginationData: tableFeatures.paginationParams
    };

    vm.search = function() {
      tableFeatures.resetSearch();
      getFilters();
      checkSearchButton();
      tableFeatures.resetEmptySearchData();
      $location.search(vm.table.searchData);
      tableFeatures.getNewData().then(function(response) {
        vm.cohorts = response[0].data;
      });
    };

    vm.loadCohortsList = function() {
      getFiltersFromParams();
      tableFeatures.getPageData().then(function(response) {
        vm.cohorts = response[0].data;
        checkPermissionToView();
      });
    };

    vm.reset = function() {
      vm.resetFilters();
      tableFeatures.resetSearch();
      vm.search();
    };

    vm.resetFilters = function() {
      _.forEach(vm.operators, function(value) {
        if (vm.table.searchData['starts_on_' + value.value]) {
          delete vm.table.searchData['starts_on_' + value.value];
        }

        if (vm.table.searchData['ends_on_' + value.value]) {
          delete vm.table.searchData['ends_on_' + value.value];
        }
      });

      if (vm.table.searchData.name_cont) {
        delete vm.table.searchData.name_cont;
      }

      vm.filters.name = '';
      vm.filters.start.input = '';
      vm.filters.end.input = '';
    };

    vm.export = function(cohort) {
      var queryParams = {cohort_id: cohort.id, from: cohort.starts_on, to: cohort.ends_on};
      spinner.activateController([
        Institution.one(contextId).one('reports').one('student_progress.csv').get(queryParams).then(function(response) {
          if (typeof Blob === 'function') {
            var reports_csv = new Blob([response.data], {type: 'text/csv;charset=UTF-8'});
            saveAs(reports_csv, cohort.name + '_reports.csv');
          }
        })
      ]);
    };

    function getFiltersFromParams() {
      var flagFound = false;

      _.forEach(vm.operators, function(value) {
        if ($stateParams['starts_on_' + value.value]) {
          vm.table.searchData['starts_on_' + value.value] = $stateParams['starts_on_' + value.value];
          vm.filters.start.operator = value;
          vm.filters.start.input = $stateParams['starts_on_' + value.value];
          flagFound = true;
        }

        if ($stateParams['ends_on_' + value.value]) {
          vm.table.searchData['ends_on_' + value.value] = $stateParams['ends_on_' + value.value];
          vm.filters.end.operator = value;
          vm.filters.end.input = $stateParams['ends_on_' + value.value];
          flagFound = true;
        }
      });

      if ($stateParams.name_cont) {
        vm.table.searchData.name_cont = $stateParams.name_cont;
        vm.filters.name = $stateParams.name_cont;
        flagFound = true;
      }

      if (!flagFound) {
        getFilters();
      }
    }

    function getFilters() {
      vm.table.searchData.name_cont = vm.filters.name;
      vm.table.searchData['starts_on_' + vm.filters.start.operator.value] = vm.filters.start.input;
      vm.table.searchData['ends_on_' + vm.filters.end.operator.value] = vm.filters.end.input;
    }

    function searchParams() {
      var params = {name_cont: vm.filters.name};
      params['starts_on_' + vm.filters.start.operator.value] = $stateParams['starts_on_' + vm.filters.start.operator.value];
      params['ends_on_' + vm.filters.end.operator.value] = $stateParams['ends_on_' + vm.filters.end.operator.value];

      return params;
    }

    function checkPermissionToView() {
      UserService.canAccess(['UPDATE'], ['cohorts']).then(function(response) {
        vm.userHasPermissionToView = response;
      });
    }

    function checkSearchButton() {
      vm.filters.clearDisabled = true;

      if (vm.filters.name || vm.filters.start.input || vm.filters.end.input) {
        vm.filters.clearDisabled = false;
      }

      return vm.filters.clearDisabled;
    }

    vm.loadCohortsList();
  }
}());
