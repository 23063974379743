(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoCreateRubricTable', eoCreateRubricTable);

  function eoCreateRubricTable($document) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-create-rubric-table/eo-create-rubric-table.html',
      scope: {
        rubric: '=',
        defaultRules: '=',
        isValid: '=?'
      },
      controller: eoCreateRubricTableCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoCreateRubricTableCtrl($scope) {
      var vm = this, editRowIndex, editColumnIndex, formRef;

      vm.isValid = true;

      vm.toggleEdit = function(rowIndex, columnIndex, $event) {
        // prevent 'keypress' event to toggle edit mode (e.g. space key)
        if ($event.type !== 'keypress') {
          if (rowIndex !== editRowIndex) {
            if (isEoRubricTableValid()) {
              editRowIndex = rowIndex;
              editColumnIndex = columnIndex;
              vm.isValid = false;
            }
          } else {
            if (isEoRubricTableValid()) {
              resetEditMode();
            }
          }
        }
      };

      vm.calculateCriteriaMaxPoints = function(criteria) {
        criteria.maximum_points = _(criteria.rules).map('points').max();
        return criteria.maximum_points;
      };

      vm.calculateRubricMaxPoints = function() {
        vm.rubric.points = _(vm.rubric.criteria).map('maximum_points').sum();
        return vm.rubric.points;
      };

      vm.isEditMode = function(rowIndex, columnIndex) {
        return rowIndex === editRowIndex && columnIndex === editColumnIndex;
      };

      vm.addCriteria = function() {
        var rules = [];
        // add default rules only to the first criteria
        if (vm.rubric.criteria.length === 0) {
          rules = angular.copy(vm.defaultRules, []);
        } else {
          for (var i = 0; i < vm.rubric.criteria[0].rules.length; i++) {
            rules.push({title: 'New Rule', points: 0, position: i + 1});
          }
        }

        vm.rubric.criteria.push({
          title: 'New Criteria',
          rules: rules
        });
      };

      vm.removeCriteria = function(index) {
        if (vm.rubric.criteria[index].id) {
          vm.rubric.delete_criteria_ids.push(vm.rubric.criteria[index].id);
        }
        vm.rubric.criteria.splice(index, 1);
      };

      vm.addRule = function(rowIndex, columnIndex, $event) {
        $event.stopPropagation();
        // rules positions must be updated before inserting new rule
        updateRulePosition(columnIndex, true);

        vm.rubric.criteria.forEach(function(criteria) {
          criteria.rules.splice(columnIndex, 0, {
            title: 'New Rule',
            points: 0,
            position: columnIndex + 1
          });
        });

        editRowIndex = rowIndex;
        editColumnIndex = columnIndex + 1;
      };

      vm.removeRule = function(index) {
        // rules positions must be updated before removing the rule
        updateRulePosition(index);

        vm.rubric.criteria.forEach(function(criteria) {
          if (criteria.rules[index].id) {
            vm.rubric.delete_rules_ids.push(criteria.rules[index].id);
          }
          criteria.rules.splice(index, 1);
        });
        if (isEoRubricTableValid()) {
          resetEditMode();
        }
      };

      function isEoRubricTableValid() {
        if (vm.criteriaForm) {
          vm.isValid = vm.criteriaForm.$valid;
          return vm.criteriaForm.$valid;
        }

        if (vm.ruleForm) {
          vm.isValid = vm.ruleForm.$valid;
          return vm.ruleForm.$valid;
        }

        return true;
      }

      function updateRulePosition(startPosition, isIncrementUpdate) {
        vm.rubric.criteria.forEach(function(criteria) {
          criteria.rules.forEach(function(rule) {
            if (rule.position > startPosition) {
              rule.position += isIncrementUpdate ? 1 : -1;
            }
          });
        });
      }

      function resetEditMode() {
        editRowIndex = null;
        editColumnIndex = null;
        formRef = null;
      }

      $document.on('click', function(event) {
        if (event.target.tagName !== 'TD' && (event.target.parentNode && event.target.parentNode.tagName !== 'BUTTON')) {
          if (isEoRubricTableValid()) {
            $scope.$apply(resetEditMode);
          }
        }
      });

      /**
       *  When user quickly switches between criteria/rules fields we lose reference
       *  to the form as we use ng-if to toggle edit mode. These watchers restore
       *  references so that all form validations work properly
       */
      $scope.$watch('vm.criteriaForm', function(form) {
        if (form) {
          formRef = form;
        } else if (form === void 0 && formRef) {
          vm.criteriaForm = formRef;
        }
      });

      $scope.$watch('vm.ruleForm', function(form) {
        if (form) {
          formRef = form;
        } else if (form === void 0 && formRef) {
          vm.ruleForm = formRef;
        }
      });
    }
  }
}());
