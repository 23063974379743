(function() {
  'use strict';

  angular
    .module('tcm.common')
    .directive('eoVmFlash', eoVmFlash);

  function eoVmFlash() {
    /**
     * This directive is a bit more flexible copy of eo-flash directive which is able
     * to work with 'controllerAs' syntax.
     * It should be eventually replaced with proper and solid solution.
     */
    return {
      restrict: 'E',
      transclude: true,
      scope: {
        message: '=?'
      },
      template: function(elem, attr) {
        attr.type = attr.type || 'danger';
        return '<div role="alert" class="flash alert alert-' + attr.type + '" ng-show="message">{{message}}</div>';
      }
    };
  }
}());
