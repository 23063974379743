/*globals moment:false */

(function() {
  'use strict';

  angular
    .module('tcm.common')
    .filter('durationFormat', DurationFormat);

  function DurationFormat() {
    return function(input, format) {
      return moment.duration(input, format || 'seconds').format('d[d] h[h] mm[m] ss[s]');
    };
  }
}());
