(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('AssignableRole', AssignableRoleResource);

  function AssignableRoleResource(ApiRestangular) {
    /**
     *  AssignableRole service is used to retrieve list of assignable roles by the user.
     */
    return ApiRestangular.withConfig(function(RestangularConfigurer) {
      RestangularConfigurer.setFullResponse(true);
      RestangularConfigurer.setDefaultHeaders({'Content-Type': 'application/json'});
    }).service('roles');
  }
}());
