(function() {
  'use strict';

  angular
    .module('tcm.core')
    .config(localStorageServiceProviderConfig);

  function localStorageServiceProviderConfig(localStorageServiceProvider) {
    localStorageServiceProvider.setPrefix('eo');
  }
}());
