(function() {
  'use strict';

  angular
    .module('tcm.common')
    .directive('eoEnabledWithAccess', eoEnabledWithAccess);

  function eoEnabledWithAccess(UserService) {
    /**
     * eoEnabledWithAccess directive disables element by setting 'disabled' attribute
     * when user by default does not have 'CREATE' (can be customized) right to a given
     * resource which should be provided with directive.
     *
     * ## Usage
     *
     * <button eo-enabled-with-access='users'>Create User</button>
     * This button would be disabled for all users who does not have 'CREATE' right for a 'users'
     * resource
     *
     * In case when we need to check a few resources and rights to them resources should be
     * divided by pipe as rights which are mapped to them.
     *
     * ## Usage
     *
     * <button eo-enabled-with-access='users|cohorts' permission='CREATE|READ'>Create User</button>
     * This button would be disabled for all users who does not have 'CREATE' right for a 'users'
     * resource and 'READ' right for a 'cohorts' resource.
     *
     * # OR case
     *
     * If there is a case when element should be disabled according to scope value but
     * regardless user's permissions as ng-disabled does. eoEnabledWithAccess has an attribute
     * 'or-disabled' which binds scope value and indicates disable state if it is true
     * so that you should not use ng-disabled.
     *
     * ## Usage
     *
     * <button eo-enabled-with-access='users or-disabled='value'>Create User</button>
     * This button would be disabled for all users who does not have 'CREATE' right for a 'users'
     * resource or if scope.value is evaluated as true.
     *
     * # AND case
     *
     * If there is a case when element should be disabled according to scope value and
     * user's permissions as ng-disabled does. eoEnabledWithAccess has an attribute
     * 'and-disabled' which binds scope value and indicates disable state if it is true
     * so that you should not use ng-disabled.
     *
     * ## Usage
     *
     * <button eo-enabled-with-access='users' and-disabled='value'>Create User</button>
     * This button would be disabled for all users who does not have 'CREATE' right for a 'users'
     * resource and if scope.value is evaluated as true.
     *
     * # Custom permission
     *
     * If there is a case when element should be disabled according to a custom
     * user's permission ('CREATE' by default) as ng-disabled does. eoEnabledWithAccess has
     * an attribute 'permission' which will replace the default value.
     *
     * ## Usage
     *
     * <button eo-enabled-with-access='users' permission='UPDATE'>Create User</button>
     * This button would be disabled for all users who does not have 'UPDATE' right
     * for a 'users' resource.
     *
     */

    return {
      scope: {
        orDisabled: '=',
        andDisabled: '='
      },
      link: function(scope, element, attrs) {

        scope.$watch('orDisabled', validatePermission);
        scope.$watch('andDisabled', validatePermission);

        function validatePermission() {
          var resources, permissions;
          resources = attrs.eoEnabledWithAccess.split('|');
          permissions = (attrs.permission) ? attrs.permission.split('|') : ['CREATE'];

          UserService.canAccess(permissions, resources).then(function(hasPermission) {
            if (!hasPermission) {
              element.attr('disabled', 'disabled');
            }
            if (angular.isDefined(scope.orDisabled)) {
              if (scope.orDisabled || !hasPermission) {
                element.attr('disabled', 'disabled');
              } else {
                element.removeAttr('disabled');
              }
            }
            if (angular.isDefined(scope.andDisabled)) {
              if (scope.andDisabled && !hasPermission) {
                element.attr('disabled', 'disabled');
              } else {
                element.removeAttr('disabled');
              }
            }
          });
        }
      }
    };
  }
}());
