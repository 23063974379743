(function() {
  'use strict';

  angular
    .module('tcm.common')
    .filter('excludeItemByDate', ExcludeItemByDate);

  function ExcludeItemByDate() {

    /**
     * excludeItemByDate filter aims to filter data set to exclude
     * items which have 'start date' files in the future or 'end date' files
     * in the past or both cases.
     *
     * This filter expects four arguments to be provided:
     * - input data set - array of data to be filtered;
     * - dateFields - it can be a string value in case of one direction filtering
     *   or an object which should contain 'futureDateField' and
     *   'expiredDateField' properties with respective field names
     * - criteria - an argument with three possible values:
     *   'future' - to filter ony future items;
     *   'expired' - to filter only expired items;
     *   'both' - to filter on both criteria;
     * - limitDate - JavaScript date object which will be a limitation point
     *   for filtering. In a standard case it would be a current day (e.g. new Date())
     *   this value should be set on a controller
     * - offset - offset value for limitDate specified in number of days.
     *   The default value is 0 days.
     *
     * # Use case: exclude future (analogous for expired) items
     *
     *  // in a controller: $scope.currentDate = new Date();
     *  ng-repeat='item in items | excludeItemByDate:"starts_on":"future":currentDate'
     *
     *  # Use case: exclude both future and expired items
     *
     *  // in a controller: $scope.currentDate = new Date();
     *  ng-repeat='item in items | excludeItemByDate:"starts_on":{futureDateField:'starts_on', expiredDateField:'ends_on'}:currentDate'
     */

    return function(inputArray, dateFields, criteria, limitDate, offset) {
      if (inputArray === void 0) { return; }

      if (offset === void 0 && criteria !== 'both') { offset = 0; }
      if (offset === void 0 && criteria === 'both') { offset = {}; }

      if (typeof offset === 'object') {
        offset.futureDateOffset = offset.futureDateOffset || 0;
        offset.expiredDateOffset = offset.expiredDateOffset || 0;
      }

      switch (criteria) {
        case 'future':
          applyLimitDateOffset(limitDate, offset);
          return inputArray.filter(excludeFutureItems);
        case 'expired':
          applyLimitDateOffset(limitDate, offset);
          return inputArray.filter(excludeExpiredItems);
        case 'both':
          var futureLimitDate = limitDate, expiredLimitDate = limitDate;
          applyLimitDateOffset(futureLimitDate, offset.futureDateOffset);
          applyLimitDateOffset(expiredLimitDate, offset.expiredDateOffset);
          return inputArray.filter(excludeFutureAndExpiredItems);
        default:
          return inputArray;
      }

      function excludeFutureItems(item) {
        return new Date(item[dateFields]) <= limitDate;
      }

      function excludeExpiredItems(item) {
        return new Date(item[dateFields]) >= limitDate;
      }

      function excludeFutureAndExpiredItems(item) {
        if (typeof dateFields !== 'object') {
          throw new Error('excludeItemByDate filter: dateFields argument has to be an object with \'futureDateField\' and \'expiredDateField\' properties');
        }

        if (typeof offset !== 'object') {
          throw new Error('excludeItemByDate filter: offset argument has to be an object with \'futureDateOffset\' and \'expiredDateOffset\' properties');
        }

        return new Date(item[dateFields.futureDateField]) <= futureLimitDate && new Date(item[dateFields.expiredDateField]) >= expiredLimitDate;
      }

      function applyLimitDateOffset(limitDate, daysOffset) {
        limitDate.setDate(limitDate.getDate() + daysOffset);
      }
    };
  }
}());
