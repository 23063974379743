(function() {
  'use strict';

  angular
    .module('tcm.auth')
    .constant('Grants', {
      'Administrator Manager': ['isAdmin'],
      'Administrator': ['isAdmin'],
      'Program Manager': ['isAdmin'],
      'Curriculum Developer': ['isAdmin'],
      'Lead Instructor': [],
      'Instructor': ['isInstructor', 'showDashboard'],
      'Success Coach': ['isSuccessCoach', 'showDashboard'],
      'Academic Administrator': [],
      'Student': ['isStudent', 'showDashboard'],
      'Account Manager': ['isAdmin'],
      'Support': ['isAdmin'],
      'Academic Registrar': ['isAdmin']
    });
}());
