(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.courses')
    .controller('AdminCoursesManageCoursesCtrl', AdminCoursesManageCoursesCtrl);

  function AdminCoursesManageCoursesCtrl($stateParams, SpinnerService,
    Institution, UserService) {

    var vm = this, spinner, contextId, courseId;

    spinner = new SpinnerService();
    contextId = parseInt($stateParams.contextId, 10);
    courseId = parseInt($stateParams.id, 10);

    function loadCourse() {
      return Institution.one(contextId).one('curriculums', courseId).get().then(function(response) {
        vm.course = response.data;
      });
    }

    function checkManagePermission() {
      return UserService.canAccess(['MANAGE_COURSES'], ['curriculums']).then(function(hasPermission) {
        vm.managePermission = hasPermission;
      });
    }

    spinner.activateController([
      loadCourse(),
      checkManagePermission()
    ]);
  }
}());
