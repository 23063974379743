(function() {
  'use strict';

  angular
    .module('tcm.release')
    .controller('ReleaseCtrl', ReleaseCtrl);

  function ReleaseCtrl($scope, Release) {
    var vm = this;

    function loadRelease() {
      Release.one().get().then(function(response) {
        vm.version = _.findKey(response.data);
        vm.release = response.data[vm.version];
      });
    }

    vm.getObjectKey = function(object) {
      return _.findKey(object);
    };

    loadRelease();
  }
}());
