(function() {
  'use strict';

  angular
    .module('tcm.messages')
    .controller('MessagesCreateCtrl', MessagesCreateCtrl);

  function MessagesCreateCtrl($state, MessagesService) {
    var vm = this;

    vm.model = {};
    vm.model.cohort_id = {};

    vm.filterOptions = [
      {
        value: 'all_instructors',
        label: 'All instructors'
      },
      {
        value: 'all_students',
        label: 'All students'
      },
      {
        value: 'general_message',
        label: 'All users'
      },
      {
        value: 'by_user',
        label: 'Selected recipients'
      }
    ];

    vm.getUserRole = function() {
      if ($state.includes('student.**')) {
        return 'Student';
      } else if ($state.includes('instructor.**')) {
        return 'Instructor';
      } else if ($state.includes('admin.**')) {
        return 'Administrator';
      } else if ($state.includes('successcoach.**')) {
        return 'Success Coach';
      }
      return null;
    };

    vm.fields = [
      {
        key: 'filter',
        type: 'ui-radio-buttons',
        templateOptions: {
          required: true,
          label: 'Send to',
          labelProp: 'label',
          valueProp: 'value',
          options: []
        },
        /* @ngInject */
        controller: function($scope) {
          var userRole = vm.getUserRole();

          if (userRole === 'Student') {
            vm.filterOptions[3].label = 'Selected instructors';
            $scope.to.options = [vm.filterOptions[0], vm.filterOptions[3]];
            vm.model.filter = vm.filterOptions[0].value;
          } else {
            $scope.to.options = vm.filterOptions;
            vm.model.filter = vm.filterOptions[1].value;
          }
        }
      },
      {
        key: 'cohort_id',
        type: 'ui-select',
        templateOptions: {
          required: true,
          label: 'Cohort',
          options: [],
          labelProp: 'name',
          valueProp: 'id'
        },
        /* @ngInject */
        controller: function($scope, UserCohorts) {
          var userRole = vm.getUserRole();
          UserCohorts.getGroupedCohorts(userRole).then(function(response) {
            $scope.to.options = response.data.current;

            if (!_.isEmpty($scope.to.options)) {
              vm.model.cohort_id = $scope.to.options[0].id;
            }
          });
        },
        validators: {
          required: function($viewValue, $modelValue) {
            return !_.isEmpty($modelValue) || $modelValue > 0;
          }
        }
      },
      {
        key: 'user_ids',
        type: 'ui-select-multiple',
        templateOptions: {
          label: setUiSelectLabel(),
          placeholder: 'Type or select the user name',
          labelProp: 'name',
          valueProp: 'id',
          options: []
        },
        expressionProperties: {
          'templateOptions.disabled': '!model.cohort_id || model.filter !== "by_user"',
          'templateOptions.notEmpty': function($viewValue, $modelValue, scope) {
            if (_.isEmpty($modelValue)) {
              scope.fc.$setValidity('required', false);
            }
          },
          'templateOptions.isFilter': function($viewValue, $modelValue, scope) {
            if (scope.model.filter && scope.model.filter !== 'by_user') {
              scope.fc.$setValidity('required', true);
            }
          }
        },
        validators: {
          required: function($viewValue, $modelValue, scope) {
            // Manual validation because templateOptions.required is not working for ui-select-multiple
            return !_.isEmpty($modelValue) || scope.model.filter !== 'by_user';
          }
        },
        /* @ngInject */
        controller: function($scope, UserCohorts) {
          var userRole = vm.getUserRole(), queryParams = {};

          $scope.to = $scope.to || {};

          // Watcher to change values of users if cohort changes
          $scope.$watch('model.cohort_id', function(cohortId) {
            if (cohortId > 0) {
              delete $scope.model.user_ids;

              if (userRole === 'Student') {
                queryParams['q[role_eq]'] = 'Instructor';
              }

              UserCohorts.getCohortEnrollments(cohortId, queryParams).then(function(response) {
                $scope.to.options = [];

                // create a new array with users from cohort
                _.each(response.data, function(value) {
                  $scope.to.options.push(
                    {
                      name: value.user.first_name + ' ' + value.user.last_name,
                      id: value.user.id
                    }
                  );
                });
              });
            }
          });
        }
      },
      {
        key: 'subject',
        type: 'input',
        templateOptions: {
          required: true,
          label: 'Subject'
        }
      },
      {
        key: 'body',
        type: 'ta-editor',
        templateOptions: {
          required: true,
          label: 'Body'
        }
      }
    ];

    vm.send = function() {
      if (vm.createMessageForm.$valid) {
        if (vm.model.filter !== 'by_user') { delete(vm.model.user_ids); }

        MessagesService.createMessage(vm.model).then(function() {
          var role = vm.getUserRole().toLowerCase();
          $state.go(role + '.messages.list');
        });
      }
    };

    function setUiSelectLabel() {
      return $state.includes('student.**') ? 'Instructors' : 'Recipients';
    }
  }
}());
