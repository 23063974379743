(function() {
  'use strict';

  angular
    .module('tcm.notes')
    .controller('NotesListCtrl', NotesListCtrl);

  function NotesListCtrl($stateParams, $state, User, TableFeaturesService) {

    var vm = this, userId, tableFeature;

    userId = parseInt($stateParams.userId, 10);

    tableFeature = new TableFeaturesService({
      sorting: false,
      search: false,
      pagination: {perPage: 12},
      loadData: function(queryParams) {
        return User.one(userId).getList('notes', queryParams);
      }
    });

    vm.table = {
      paginationData: tableFeature.paginationParams
    };

    vm.redirectToCreateNote = function() {
      var state = ($state.is('admin.users.notes.list')) ? 'admin.users.notes.new' : 'instructor.users.notes.new';
      $state.go(state);
    };

    vm.getNotes = function() {
      tableFeature.getPageData().then(function(response) {
        vm.notes = response[0].data;
      });
    };

    function getUser() {
      User.one(userId).get().then(function(response) {
        vm.user = response.data;
      });
    }

    getUser();
    vm.getNotes();
  }
}());
