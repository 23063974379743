(function() {
  'use strict';

  angular
    .module('tcm.common')
    .filter('limitToWithEllipsis', LimitToWithEllipsis);

  function LimitToWithEllipsis($filter) {
    /**
     * limitToWithEllipsis filter extends standard 'limitTo' filter and adds ellipsis
     * when input length >= limit
     */
    var standardLimitTo = $filter('limitTo');

    return function(input, limit) {
      if (input) {
        var ellipsis = input.length > limit ? '...' : '';
        return standardLimitTo(input, limit) + ellipsis;
      }
      return standardLimitTo(input, limit);
    };
  }
}());
