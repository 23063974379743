(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('UserCohorts', UserCohorts);

  function UserCohorts(User, UserService, Institution, PaginationService, EnvConfig,
    excludeItemByDateFilter) {
    var pagination;

    pagination = new PaginationService({ perPage: 100 });

    function getCohorts(role) {
      var queryString = {};

      if (role) {
        queryString.role = role;
      }

      pagination.addParams(queryString);

      return UserService.loadProfile().then(function(profile) {
        return User.one(profile.id).getList('cohorts', queryString);
      });
    }

    function getGroupedCohorts(role, queryString) {
      queryString = queryString || {};

      if (role) {
        queryString.user_role = role;
      }

      return UserService.loadProfile().then(function(profile) {
        return User.one(profile.id).one('cohorts/grouped').get(queryString);
      });
    }

    function getFirstCohort(limitDate, role) {
      return getCohorts(role).then(function(response) {
        return _.head(excludeItemByDateFilter(response.data, 'user_started_on', 'future', limitDate));
      });
    }

    function getCohort(id) {
      var indexes, index;
      return getCohorts().then(function(response) {
        indexes = _.map(response.data, 'id');
        index = _.indexOf(indexes, id);
        return response.data[index];
      });
    }

    function getCohortEnrollments(cohortId, queryParams) {
      return UserService.loadProfile().then(function(profile) {
        return Institution.one(profile.institution_id).one('cohorts', cohortId).one('enrollments').get(queryParams);
      });
    }

    function getUrlTemplate() {
      return UserService.loadProfile().then(function(profile) {
        return _.template(EnvConfig.main_url + '/api/v1/institutions/' + profile.institution_id + '/{{type}}/{{id}}/enrollments/' + profile.id + '/{{graph}}');
      });
    }

    function resetCohortUser(contextId, cohortId, userId) {
      return Institution.one(contextId).one('cohorts', cohortId).one('users', userId).one('reset_state').patch();
    }

    function getChartsUrls(cohort) {
      return getUrlTemplate().then(function(template) {
        if (_.isEmpty(cohort)) { return {}; }
        return {
          burnupUrl: template({
            type: 'cohorts',
            id: cohort.id,
            graph: 'objective_burnup'
          }),
          donutUrl: template({
            type: 'cohorts',
            id: cohort.id,
            graph: 'objective_donut'
          })
        };
      });
    }

    return {
      getCohorts: getCohorts,
      getFirstCohort: getFirstCohort,
      getCohort: getCohort,
      getCohortEnrollments: getCohortEnrollments,
      getChartsUrls: getChartsUrls,
      getGroupedCohorts: getGroupedCohorts,
      resetCohortUser: resetCohortUser
    };
  }
}());
