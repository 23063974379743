(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoMessageControls', eoMessageControls);

  function eoMessageControls(MessagesService) {
    return {
      replace: true,
      transclude: true,
      templateUrl: 'components/eo-message-controls/eo-message-controls.html',
      scope: {
        message: '=?',
        showReply: '=?'
      },
      link: eoMessageControlsLinkFn,
      controller: eoMessageControlsCtrl,
      controllerAs: 'controls',
      bindToController: true
    };

    function eoMessageControlsLinkFn(scope, element) {

      // prevent click on checkbox to trigger button click
      element.find('input').on('click', function(e) {
        e.stopPropagation();
      });
    }

    function eoMessageControlsCtrl($scope, $element, $state) {
      var controls = this, messageElements = [], selectedMessageElements = [],
      selectedMsgsId = [], selectedMsgsIdtoMarkRead = [];

      controls.isDetailView = $state.includes('**.messages.detail');

      controls.allSelected = false;

      //list operation
      controls.setMessageElement = function(messageElement) {
        messageElements.push(messageElement);
      };

      controls.unsetMessageElement = function(messageElement) {
        var index = _.indexOf(messageElements, messageElement);
        messageElements.splice(index, 1);
      };

      controls.selectMessage = function(messageElement) {
        selectedMessageElements.push(messageElement);

        controls.selectedAll = true;
      };

      controls.unselectMessage = function(messageElement) {
        var index = _.indexOf(selectedMessageElements, messageElement);
        selectedMessageElements.splice(index, 1);
        if (selectedMessageElements.length === 0) { controls.selectedAll = false; }
      };

      controls.selectAllMessages = function() {
        return controls.selectedAll ? unselectAll() : selectAll();
      };

      controls.reply = function() {
        if (!controls.showReply) {
          controls.showReply = true;
        }
      };

      controls.markRead = function() {
        if (!controls.isDetailView) {
          selectedMessageElements.forEach(function(msgElm) {
            if (msgElm.isolateScope().isRead) {
              selectedMsgsId.push(msgElm.isolateScope().message.id);
              msgElm.isolateScope().isRead = false;
            } else {
              selectedMsgsIdtoMarkRead.push(msgElm.isolateScope().message.id);
              msgElm.isolateScope().isRead = true;
            }
          });
        } else {
          if (controls.message.read_at) {
            selectedMsgsId.push(controls.message.id);
          } else {
            selectedMsgsIdtoMarkRead.push(controls.message.id);
          }
        }

        if (selectedMsgsIdtoMarkRead.length) {
          MessagesService.markRead(selectedMsgsIdtoMarkRead).then(function() {
            $scope.$emit('eo-message:mark_read', selectedMsgsIdtoMarkRead.length);
            successMessageAction();
          });
        }

        if (selectedMsgsId.length) {
          MessagesService.markUnread(selectedMsgsId).then(function() {
            $scope.$emit('eo-message:mark_unread', selectedMsgsId.length);
            successMessageAction();
          });
        }
      };

      controls.delete = function() {
        if (!controls.isDetailView) {
          selectedMessageElements.forEach(function(msgElm) {
            selectedMsgsId.push(msgElm.isolateScope().message.id);
            
            if (!msgElm.isolateScope().isRead) {
              selectedMsgsIdtoMarkRead.push(msgElm.isolateScope().message.id); 
            }
          });
        } else {
          selectedMsgsId.push(controls.message.id);

          if (!controls.message.read_at) {
            selectedMsgsIdtoMarkRead.push(controls.message.id);
          } 
        }
        MessagesService.deleteMessages(selectedMsgsId).then(function() {
          $scope.$emit('MessagesListCtrl:reload');
          $scope.$emit('eo-message:mark_read', selectedMsgsIdtoMarkRead.length);
          successMessageAction();
        }); 
      };

      function selectAll() {
        messageElements.forEach(function(messageElement) {
          if (messageElement.isolateScope()) {
            messageElement.isolateScope().selected = true;
          }
        });
        controls.selectedAll = true;
      }

      function unselectAll() {
        messageElements.forEach(function(messageElement) {
          if (messageElement.isolateScope()) {
            messageElement.isolateScope().selected = false;
          }
        });
        controls.selectedAll = false;
        selectedMessageElements.length = 0;
      }

      function goToMessagesList() {
        if ($state.includes('student.**')) {
          $state.go('student.messages.list');
        } else if ($state.includes('instructor.**')) {
          $state.go('instructor.messages.list');
        } else if ($state.includes('successcoach.**')) {
          $state.go('successcoach.messages.list');
        }
      }

      function resetControls() {
        selectedMessageElements.length = 0;
        selectedMsgsId.length = 0;
        selectedMsgsIdtoMarkRead.length = 0;
        controls.selectedAll = false;
        unselectAll();
      }

      function successMessageAction() {
        if (controls.isDetailView) {
          goToMessagesList();
        } else {
          resetControls(); 
        }
      }
    }
  }
})();
