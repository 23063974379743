(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin')
    .config(adminRoutesConfig);

  function adminRoutesConfig($stateProvider) {

    $stateProvider.state('admin', {
      url: '/institutions/:contextId/admin',
      abstract: true,
      views: {
        'main': {
          template: '<ui-view/>'
        }
      },
      resolve: {
        auth: function(SecurityManagerService, $state) {
          SecurityManagerService.authorize(['isAdmin']).then(function(hasPermission) {
            if (!hasPermission) {
              $state.go('dashboard');
            }
          });
        },
        institutionContext: function($stateParams, $state, UserService) {
          if (_.isEmpty($stateParams.contextId)) {
            UserService.loadProfile().then(function(profile) {
              $state.go('admin.dashboard', {contextId: profile.institution_id});
            });
          }
        }
      },
      ncyBreadcrumb: {
        skip: true
      }
    }).state('admin.profile', {
      url: '/profile',
      abstract: true,
      template: '<div ui-view="active"></div>'
    }).state('admin.profile.show', {
      url: '',
      views: {
        'active': {
          controller: 'ProfileDetailsCtrl as vm',
          templateUrl: 'auth/profile/detail/profile-detail.html'
        }
      }
    }).state('admin.profile.edit', {
      url: '/edit',
      views: {
        'active': {
          controller: 'ProfileEditCtrl as vm',
          templateUrl: 'auth/profile/edit/profile-edit.html'
        }
      }
    }).state('admin.dashboard', {
      url: '',
      controller: 'AdminDashboardCtrl',
      templateUrl: 'dashboard/admin/dashboard-admin.html',
      ncyBreadcrumb: {
        label: 'Dashboard'
      }
    });
  }
}());
