(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.users')
    .controller('AdminUsersProgressCtrl', AdminUsersProgressCtrl);

  function AdminUsersProgressCtrl($stateParams, $location,
    EnvConfig, SpinnerService, User, Institution, SortingService) {
    var vm = this, spinner, sorting;

    spinner = new  SpinnerService();
    sorting = new SortingService({
      column: $location.search().sort || 'user_name_i',
      direction: $location.search().direction || 'asc'
    });

    vm.userId = parseInt($stateParams.userId, 10);
    vm.cohortId = parseInt($stateParams.cohortId, 10);

    vm.isUserProgress = $stateParams.state === 'users';

    function getStudentAsUser() {
      var queryParams = {};
      sorting.addParams(queryParams);

      return User.one(vm.userId).get().then(function(response) {
        vm.user = response.data; // breadcrumb label
        return Institution.one(response.data.institution_id).one('cohorts', vm.cohortId).one('enrollments', vm.userId).get(queryParams).then(function(response) {
          vm.user = response.data;
          vm.user.first_name = vm.user.user_first_name; //breadcrumb label
          vm.user.last_name = vm.user.user_last_name; //breadcrumb label
          getCohort(vm.user.user_institution_id);
          setChartsUrls(vm.user.user_institution_id);
        });
      });
    }

    function setChartsUrls(institutionId) {
      var burnupTemplate = _.template(EnvConfig.main_url + '/api/v1/institutions/' + institutionId + '/cohorts/{{cohortId}}/enrollments/{{id}}/objective_burnup');
      vm.burnupUrl = burnupTemplate({
        cohortId: vm.cohortId,
        id: vm.userId
      });
    }

    function loadStudentPrograms() {
      return User.one(vm.userId).getList('programs').then(function(response) {
        vm.programs = response.data;
      });
    }

    function getCohort(institutionId) {
      var queryParams = {'role': 'Student'};
      if (vm.isUserProgress) {
        return User.one(vm.userId).one('cohorts', vm.cohortId).get(queryParams).then(function(response) {
          vm.cohort = response.data;
        });
      }else {
        return Institution.one(institutionId).one('cohorts', vm.cohortId).get().then(function(response) {
          vm.cohort = response.data;
        });
      }
    }

    spinner.activateController([
      getStudentAsUser(),
      loadStudentPrograms()
    ]);
  }
}());
