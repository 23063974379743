(function() {
  'use strict';

  angular
    .module('tcm.help')
    .config(helpRoutesConfig);

  function helpRoutesConfig($stateProvider) {

    $stateProvider.state('help', {
      url: '/help',
      views: {
        'main': {
          controller: 'HelpCtrl',
          templateUrl: 'help/help.html'
        }
      }
    });
  }
}());
