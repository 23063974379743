angular
  .module('tcm.components')
  .directive('eoNote', eoNote);

function eoNote($state, User) {
  'use strict';

  return {
    scope: {
      note: '='
    },
    restrict: 'E',
    templateUrl: 'components/eo-note/eo-note.html',
    link: function(scope, element) {
      scope.showInformation = function() {
        element.find('.eo-note').toggleClass('note-info-open');
      };

      scope.redirectToEditNote = function() {
        var state = ($state.includes('admin')) ? 'admin.users.notes.edit' : 'instructor.users.notes.edit';
        $state.go(state, {noteId: scope.note.id});
      };

      scope.publish = function() {
        User.one(scope.note.student_id).one('notes', scope.note.id).one('publish').patch().then(function(response) {
          scope.note = response.data;
        });
      };
    }
  };
}
