(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.institutions')
    .controller('AdminInstitutionsCreateCtrl', AdminInstitutionsCreateCtrl);

  function AdminInstitutionsCreateCtrl($scope, $state, $stateParams,
    SpinnerService, Institution, ValidationService, UserService) {

    var vm = this, spinner, institutionId;

    spinner = new SpinnerService();
    institutionId = parseInt($stateParams.id, 10);
    vm.model = {};

    vm.fields = [
      {
        key: 'institution_name',
        type: 'input',
        templateOptions: {
          required: true,
          maxlength: 100,
          type: 'text',
          label: 'Name'
        },
        validators: {
          alphaNumeric: ValidationService.validateAlphaNumeric()
        }
      },
      {
        key: 'institution_type',
        type: 'ui-select',
        templateOptions: {
          className: 'col-xs-6',
          required: true,
          label: 'Institution Type',
          options: [],
          valueProp: 'name'
        },
        /* @ngInject */
        controller: function($scope, InstitutionType) {
          InstitutionType.getList().then(function(response) {
            $scope.to.options = response.data;
          });
        }
      },
      {
        className: 'row',
        fieldGroup: [
          {
            key: 'demo_institution',
            type: 'checkbox',
            className: 'col-xs-6',
            templateOptions: {
              label: 'Demo Institution'
            },
            hideExpression: function() {
              UserService.canAccess(['MANAGE_DEMO_INSTITUTION'], ['institutions']).then(function(hasPermission) {
                vm.demoPermission = hasPermission;
              });

              return !vm.demoPermission;
            }
          },
          {
            key: 'webex',
            type: 'checkbox',
            className: 'col-xs-6',
            templateOptions: {
              label: 'Webex features'
            }
          }
        ]
      },
      {
        className: 'row',
        hideExpression: function() {
          return !vm.model.webex;
        },
        fieldGroup: [
          {
            key: 'webex_id',
            type: 'input',
            className: 'col-xs-6',
            templateOptions: {
              type: 'text',
              label: 'Webex Id'
            },
            expressionProperties: {
              'templateOptions.required': function() {
                return vm.model.webex;
              }
            }
          },
          {
            key: 'webex_site_id',
            type: 'input',
            className: 'col-xs-6',
            templateOptions: {
              type: 'number',
              label: 'Webex Site Id'
            },
            expressionProperties: {
              'templateOptions.required': function() {
                return vm.model.webex;
              }
            }
          },
          {
            key: 'webex_site_name',
            type: 'input',
            className: 'col-xs-6',
            templateOptions: {
              type: 'text',
              label: 'Webex Site Name'
            },
            expressionProperties: {
              'templateOptions.required': function() {
                return vm.model.webex;
              }
            }
          },
          {
            key: 'webex_partner_id',
            type: 'input',
            className: 'col-xs-6',
            templateOptions: {
              type: 'text',
              label: 'Webex Partner Id'
            }
          }
        ]
      },
      {
        className: 'row',
        fieldGroup: [
          {
            className: 'col-xs-6',
            key: 'help_desk_email',
            type: 'input',
            templateOptions: {
              maxlength: 50,
              type: 'email',
              label: 'Helpdesk email'
            },
            validators: {
              emailAddress: ValidationService.validateEmail()
            }
          },
          {
            className: 'col-xs-6',
            key: 'help_desk_phone_number',
            type: 'input',
            templateOptions: {
              type: 'text',
              label: 'Helpdesk Phone'
            },
            validators: {
              phone: ValidationService.validatePhoneNumber()
            }
          }
        ]
      },
      {
        key: 'eocustomer',
        type: 'input',
        templateOptions: {
          required: true,
          minlength: 5,
          maxlength: 5,
          type: 'text',
          label: 'EO Customer'
        },
        validators: {
          alphaNumeric: ValidationService.validateAlphaNumeric()
        },
        asyncValidators: {
          uniqueEoCustomer: {
            expression: ValidationService.validateUniqueEoCustomer(institutionId)
          }
        }
      },
      {
        key: 'address1',
        type: 'input',
        templateOptions: {
          maxlength: 100,
          type: 'text',
          label: 'Address Line 1'
        },
        validators: {
          address: ValidationService.validateAddress()
        }
      },
      {
        key: 'address2',
        type: 'input',
        templateOptions: {
          maxlength: 100,
          type: 'text',
          label: 'Address Line 2'
        },
        validators: {
          address: ValidationService.validateAddress()
        }
      },
      {
        className: 'row',
        fieldGroup: [
          {
            className: 'col-xs-6',
            key: 'city',
            type: 'input',
            templateOptions: {
              maxlength: 50,
              type: 'text',
              label: 'City'
            },
            validators: {
              address: ValidationService.validateAddress()
            }
          },
          {
            className: 'col-xs-6',
            key: 'zip_postal_code',
            type: 'input',
            templateOptions: {
              type: 'text',
              label: 'Zip Code'
            },
            validators: {
              zip: ValidationService.validateZip()
            }
          }
        ]
      },
      {
        className: 'row',
        fieldGroup: [
          {
            className: 'col-xs-6',
            key: 'state_province',
            type: 'ui-select',
            templateOptions: {
              label: 'State',
              options: []
            },
            /* @ngInject */
            controller: function($scope, State) {
              State.getList().then(function(response) {
                $scope.to.options = response.data;
              });
            }
          },
          {
            className: 'col-xs-6',
            key: 'contact_phone',
            type: 'input',
            templateOptions: {
              type: 'text',
              label: 'Contact Phone'
            },
            validators: {
              phone: ValidationService.validatePhoneNumber()
            }
          }
        ]
      },
      {
        key: 'contact_name',
        type: 'input',
        templateOptions: {
          maxlength: 100,
          type: 'text',
          label: 'Contact Name'
        },
        validators: {
          lettersHyphensSpaces: ValidationService.validateLettersHyphensSpaces()
        }
      },
      {
        key: 'contact_email',
        type: 'input',
        templateOptions: {
          type: 'email',
          label: 'Contact Email'
        },
        validators: {
          emailAddress: ValidationService.validateEmail()
        }
      },
      {
        key: 'time_zone',
        type: 'ui-select',
        defaultValue: 'Eastern Time (US & Canada)',
        templateOptions: {
          label: 'Time Zone',
          options: [
            {name: 'Hawaii'},
            {name: 'Alaska'},
            {name: 'Pacific Time (US & Canada)'},
            {name: 'Arizona'},
            {name: 'Mountain Time (US & Canada)'},
            {name: 'Central Time (US & Canada)'},
            {name: 'Eastern Time (US & Canada)'},
            {name: 'Indiana (East)'}
          ],
          required: true,
          valueProp: 'name'
        }
      }
    ];

    vm.save = function() {
      vm.flash = '';
      if (vm.createEditForm.$valid) {
        if (vm.model && vm.model.id) {
          vm.model.eocustomer = vm.model.eocustomer.toUpperCase();

          if (!vm.model.webex) {
            delete vm.model.webex_id;
            delete vm.model.webex_site_id;
            delete vm.model.webex_site_name;
            delete vm.model.webex_partner_id;
          }

          vm.model.patch(vm.model).then(function(response) {
            $state.go('admin.institutions.show', {id: response.data.id});
          }, function() {
            vm.flash = 'An error ocurred while updating the institution';
          });
        } else {
          vm.model.parent_id = institutionId;
          vm.model.eocustomer = vm.model.eocustomer.toUpperCase();
          Institution.post(vm.model).then(function(response) {
            $scope.$emit('institution:add');
            $state.go('admin.institutions.show', {id: response.data.id});
          }, function() {
            vm.flash = 'An error ocurred while creating the institution';
          });
        }
      }
    };

    function loadParentInstitution() {
      if (!vm.isEditMode) {
        return Institution.one(institutionId).get().then(function(response) {
          vm.model.parent_institution_name = response.data.institution_name;
        });
      }
    }

    function loadInstitutionToEdit() {
      vm.isEditMode = false;
      if (institutionId && $state.is('admin.institutions.edit')) {
        vm.isEditMode = true;
        return Institution.one(institutionId).get().then(function(response) {
          vm.model = response.data;
          vm.institution = vm.model; // breadcrumb label
        });
      }
    }

    spinner.activateController([
      loadParentInstitution(),
      loadInstitutionToEdit()
    ]);
  }
}());
