(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.objectives')
    .controller('AdminObjectivesManageActivitiesCtrl', AdminObjectivesManageActivitiesCtrl);

  function AdminObjectivesManageActivitiesCtrl($stateParams, Objective,
    SpinnerService, UserService) {

    var vm = this, spinner, objectiveId;

    vm.flash = null;
    spinner = new SpinnerService();
    objectiveId = parseInt($stateParams.id, 10);

    vm.contextId = parseInt($stateParams.contextId, 10);

    function loadObjective() {
      return Objective.one(objectiveId).get().then(function(response) {
        vm.objective = response.data;
      });
    }

    function checkManagePermission() {
      return UserService.canAccess(['MANAGE_ACTIVITIES'], ['objectives']).then(function(hasPermission) {
        vm.managePermission = hasPermission;
      });
    }

    spinner.activateController([
      loadObjective(),
      checkManagePermission()
    ]);
  }
}());
