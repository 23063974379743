(function() {
  'use strict';

  angular
    .module('tcm.auth')
    .service('TokenService', TokenService);

  function TokenService(localStorageService) {

    this.setToken = function(token) {
      var tokens = JSON.parse(localStorageService.get('oauth'));
      if (this.hasAccessToken()) {
        tokens.unshift(token);
        // make sure we do not store more than 2 tokens
        if (tokens.length > 2) {
          tokens.pop();
        }
        localStorageService.set('oauth', JSON.stringify(tokens));
      } else {
        localStorageService.set('oauth', JSON.stringify([token]));
      }
    };

    this.hasAccessToken = function() {
      if (JSON.parse(localStorageService.get('oauth')) === null) { return false; }
      return JSON.parse(localStorageService.get('oauth')).length > 0;
    };

    this.removeToken = function() {
      var tokens = JSON.parse(localStorageService.get('oauth'));
      if (this.hasAccessToken()) {
        tokens.shift();
        localStorageService.set('oauth', JSON.stringify(tokens));
      }
    };

    this.removeAllTokens = function() {
      localStorageService.remove('oauth');
    };

    this.getAccessToken = function() {
      return this.hasAccessToken() ? JSON.parse(localStorageService.get('oauth'))[0].access_token : null;
    };

    this.getRefreshToken = function() {
      return this.hasAccessToken() ? JSON.parse(localStorageService.get('oauth'))[0].refresh_token : null;
    };

    this.getExpiresIn = function() {
      return this.hasAccessToken() ? JSON.parse(localStorageService.get('oauth'))[0].expires_in : null;
    };

    this.getTokenInstructorId = function() {
      return this.hasAccessToken() ? JSON.parse(localStorageService.get('oauth'))[1].resource_owner_id : null;
    };
  }
}());
