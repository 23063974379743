(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoCourseManageObjectives', eoCourseManageObjectives);

  function eoCourseManageObjectives(Institution, $uibModal, $stateParams, SpinnerService, $state, UserService) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-course-manage-objectives/eo-course-manage-objectives.html',
      scope: {},
      controller: eoCourseMangeObjectiveCtrl,
      controllerAs: 'vm'
    };

    function eoCourseMangeObjectiveCtrl() {
      var vm = this, spinner, courseId, contextId, curriculumId;
      vm.flash = null;
      contextId = parseInt($stateParams.contextId, 10);
      courseId = parseInt($stateParams.id, 10);
      spinner = new SpinnerService();

      function loadPage() {
        if ($state.includes('instructor.**')) {
          courseId = parseInt($stateParams.id, 10);
          curriculumId = parseInt($stateParams.id, 10);
          return UserService.loadProfile().then(function(profile) {
            contextId = profile.institution_id;
            return getActivities();
          });
        } else {
          return getActivities();
        }
      }

      function getActivities() {
        return Institution.one(contextId).one('cohorts', courseId).one('graded_activities').get().then(function(response) {
          vm.activities = response.data;
          defineDefaultValueToGrade();
        });
      }

      function defineDefaultValueToGrade() {
        _.each(vm.activities, function(value) {
          if (value.grade_percentage === undefined) {
            value.grade_percentage = 0;
          }
        });
      }

      function prepareBodyData() {
        var activitiesToUpdate = [], totalGradeValue = 0;
        _.each(vm.activities, function(value) {
          totalGradeValue = totalGradeValue + value.grade_percentage;
          activitiesToUpdate.push({learning_activity_id: value.learning_activity_id, percentage: value.grade_percentage});
        });
        return {activities: activitiesToUpdate, totalGradeValue: totalGradeValue};
      }

      vm.gradeActivity = function() {
        var bodyData = prepareBodyData();
        if (bodyData.activities.length > 0 && bodyData.totalGradeValue !== 100) {
          $uibModal.open({
            templateUrl: 'components/eo-course-manage-objectives/modals/grade-activity-modal.html',
            controllerAs: 'modal',
            controller: function() {
              var modal = this;
              modal.totalGradeValue = bodyData.totalGradeValue;
              modal.submit = function() {
                updateGradeValues(bodyData);
              };
            }
          });
        } else {
          updateGradeValues(bodyData);
        }
      };

      function updateGradeValues(bodyData) {
        if (bodyData.activities.length > 0) {
          return Institution.one(contextId).one('cohorts', courseId).one('graded_activities').patch({activities: bodyData.activities}).then(
      function() {
        vm.error = false;
        redirectToViewPage();
      },

      function() {
        vm.errorsMsg = 'There was a problem saving your changes. Please try again';
        vm.error = true;
      });

        } else {
          redirectToViewPage();
        }
      }

      function redirectToViewPage() {
        if ($state.includes('instructor.**')) {
          $state.go('instructor.cohorts.objectives.studentlist', {id: curriculumId, state: 'all'});
        } else {
          $state.go('admin.courses.show', {id: courseId});
        }
      }

      spinner.activateController([
        loadPage()
      ]);
    }
  }
}());
