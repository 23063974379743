(function() {
  'use strict';

  angular
    .module('tcm.common')
    .directive('eoSpinner', eoSpinner);

  function eoSpinner() {
    return {
      scope: { },
      link: function(scope) {
        // TODO Why does this have to be true?  Useful for feedback during form submits, which arent
        // on initial load.
        scope.dataLoading = true;

        scope.$on('eo_spinner_loading', function(e) {
          e.preventDefault();
          scope.dataLoading = true;
        });

        scope.$on('eo_spinner_loaded', function(e) {
          e.preventDefault();
          scope.dataLoading = false;
        });
      },
      template: '<div class="app-loading" ng-show="dataLoading"><div class="spinner"></div></div>'
    };
  }
}());
