(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('UserActivities', UserActivities);

  function UserActivities($q, $http, ExternalService, User, UserService, Activity) {
    var cachedActivities = {};

    function getActivitiesByObjective(objectiveId) {
      if (cachedActivities[objectiveId]) {
        return $q.when(cachedActivities[objectiveId]);
      }
      return UserService.loadProfile().then(function(profile) {
        return User.one(profile.id).one('objectives', objectiveId).getList('activities').then(function(response) {
          cachedActivities[objectiveId] = response;
          return response;
        });
      });
    }

    function getActivityByObjective(objectiveId, activityId) {
      return UserService.loadProfile().then(function(profile) {
        return User.one(profile.id).one('objectives', objectiveId).one('activities', activityId).get();
      });
    }

    function getActivity(id) {
      return Activity.one(id).get();
    }

    function getActivitiesByUser(userId, cohortId, queryParams) {
      queryParams = queryParams || {};
      return User.one(userId).one('cohorts', cohortId).getList('activities', queryParams);
    }

    function getActivityByUser(userId, cohortId, activityId, queryParams) {
      queryParams = queryParams || {};
      return User.one(userId).one('cohorts', cohortId).one('activities', activityId).get(queryParams);
    }

    function sendScore(userId, activityId, params) {
      return User.one(userId).one('activities', activityId).post('scores', params);
    }

    function updateActivityState(userId, activityId, params) {
      return User.one(userId).one('activities', activityId).patch(params);
    }

    function deleteActivityComment(userId, activityId, feedbackId, params) {

      return User.one(userId).one('activities', activityId).one('comments', feedbackId).customDELETE('', params);
    }

    function updateCDPActivityState(userId, activityId, params) {
      return $http({
        method: 'POST',
        url: ExternalService.getLaunchUrl() + 'api/eol/UpdateActivityAttemptAsInvalid',
        data: params,
        transformRequest: function(obj) {
          var str = [];
          for (var p in obj) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
          }
          return str.join('&');
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
    }

    function submitActivityAttemptComment(userId, activityId, params) {
      return User.one(userId).one('activities', activityId).post('comments', params);
    }
    function updateActivityAttemptComment(userId, activityId, feedbackId, params) {
      return User.one(userId).one('activities', activityId).one('comments', feedbackId).patch(params);
    }

    function flushCache() {
      cachedActivities = {};
    }

    return {
      getActivitiesByObjective: getActivitiesByObjective,
      getActivityByObjective: getActivityByObjective,
      getActivity: getActivity,
      getActivitiesByUser: getActivitiesByUser,
      sendScore: sendScore,
      getActivityByUser: getActivityByUser,
      updateActivityState: updateActivityState,
      updateCDPActivityState: updateCDPActivityState,
      submitActivityAttemptComment: submitActivityAttemptComment,
      flushCache: flushCache,
      updateActivityAttemptComment: updateActivityAttemptComment,
      deleteActivityComment: deleteActivityComment
    };
  }
}());
