(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.courses')
    .controller('AdminCoursesManageObjectivesCtrl', AdminCoursesManageObjectivesCtrl);

  function AdminCoursesManageObjectivesCtrl($state, $stateParams, SpinnerService,
    Institution, UserService, $uibModal) {

    var vm = this, spinner, contextId, courseId, endPoint;

    vm.flash = null;
    contextId = parseInt($stateParams.contextId, 10);
    courseId = parseInt($stateParams.id, 10);
    spinner = new SpinnerService();

    function getCourse() {
      return Institution.one(contextId).one('curriculums', courseId).get().then(function(response) {
        vm.course = response.data;
      });
    }

    function checkManagePermission() {
      return UserService.canAccess(['MANAGE_OBJECTIVES'], ['curriculums']).then(function(hasPermission) {
        vm.managePermission = hasPermission;
      });
    }

    function getActivities() {
      return Institution.one(contextId).one(endPoint, courseId).one('graded_activities').get().then(function(response) {
        vm.activities = response.data;
        defineDefaultValueToGrade();
      });
    }

    function defineDefaultValueToGrade() {
      _.each(vm.activities, function(value) {
        if (value.grade_percentage === undefined) {
          value.grade_percentage = 0;
        }
      });
    }

    function prepareBodyData() {
      var activitiesToUpdate = [], totalGradeValue = 0;
      _.each(vm.activities, function(value) {
        totalGradeValue = totalGradeValue + value.grade_percentage;
        activitiesToUpdate.push({learning_activity_id: value.learning_activity_id, percentage: value.grade_percentage});
      });
      return {activities: activitiesToUpdate, totalGradeValue: totalGradeValue};
    }

    vm.gradeActivity = function() {
      var bodyData = prepareBodyData();
      if (bodyData.activities.length > 0 && bodyData.totalGradeValue !== 100) {
        $uibModal.open({
          templateUrl: 'components/eo-course-manage-objectives/modals/grade-activity-modal.html',
          controllerAs: 'modal',
          controller: function() {
            var modal = this;
            modal.totalGradeValue = bodyData.totalGradeValue;
            modal.submit = function() {
              updateGradeValues(bodyData);
            };
          }
        });
      } else {
        updateGradeValues(bodyData);
      }
    };

    function updateGradeValues(bodyData) {
      if (bodyData.activities.length > 0) {
        return Institution.one(contextId).one(endPoint, courseId).one('graded_activities').patch({activities: bodyData.activities}).then(
          function() {
            vm.error = false;
            redirectToViewPage();
          }, function() {
            vm.errorsMsg = 'There was a problem saving your changes. Please try again';
            vm.error = true;
          }
        );
      } else {
        redirectToViewPage();
      }
    }

    function loadCohortDetails() {
      spinner.activateController([
        Institution.one(contextId).one('cohorts', courseId).get().then(function(response) {
          vm.cohort = response.data;
        })
      ]);
    }

    function redirectToViewPage() {
      if ($state.includes('admin.courses.**')) {
        $state.go('admin.courses.show', {id: courseId});
      } else {
        $state.go('admin.cohorts.show', {id: courseId});
      }
    }

    function loadPage() {
      if ($state.includes('admin.courses.**')) {
        getCourse();
        endPoint = 'curriculums';
      } else {
        loadCohortDetails();
        endPoint = 'cohorts';
      }
      spinner.activateController([
        checkManagePermission(),
        getActivities()
      ]);
    }

    loadPage();
  }
}());
