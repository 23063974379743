(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.activities')
    .config(activityRoutesConfig);

  function activityRoutesConfig($stateProvider) {

    $stateProvider.state('admin.activities', {
      url: '/activities?identifier_cont&description_cont&activity_type_eq&published_eq',
      abstract: true,
      template: '<div ui-view="active"></div>'
    }).state('admin.activities.list', {
      url: '',
      reloadOnSearch: false,
      views: {
        'active': {
          controller: 'AdminActivitiesListCtrl as vm',
          templateUrl: 'dashboard/admin/activities/list/activities-list.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Activities',
        parent: 'admin.dashboard'
      }
    }).state('admin.activities.new', {
      url: '/new',
      views: {
        'active': {
          controller: 'AdminActivitiesCreateCtrl as vm',
          templateUrl: 'dashboard/admin/activities/create/activities-create.html'
        }
      },
      ncyBreadcrumb: {
        label: 'New',
        parent: 'admin.activities.list'
      }
    }).state('admin.activities.show', {
      url: '/:id',
      views: {
        'active': {
          controller: 'AdminActivitiesDetailCtrl as vm',
          templateUrl: 'dashboard/admin/activities/detail/activities-detail.html'
        }
      },
      ncyBreadcrumb: {
        label: '{{vm.activity.description}}',
        parent: 'admin.activities.list'
      }
    }).state('admin.activities.rubric', {
      url: '/:id/rubric',
      views: {
        'active': {
          controller: 'AdminActivitiesDetailCtrl as vm',
          templateUrl: 'dashboard/admin/activities/detail/activities-detail-rubric.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Rubric',
        parent: 'admin.activities.show'
      }
    }).state('admin.activities.edit', {
      url: '/:id/edit',
      views: {
        'active': {
          controller: 'AdminActivitiesCreateCtrl as vm',
          templateUrl: 'dashboard/admin/activities/create/activities-create.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Edit',
        parent: 'admin.activities.show'
      }
    });
  }
}());
