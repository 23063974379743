(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoInstitutionSettingsPanel', eoInstitutionSettingsPanel);

  function eoInstitutionSettingsPanel() {
    return {
      templateUrl: 'components/eo-institution-settings-panel/eo-institution-settings-panel.html',
      scope: {
        settings: '=',
        editSettings: '&'
      },
      controller: eoInstitutionSettingsPanelCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoInstitutionSettingsPanelCtrl($attrs) {
      var vm = this;

      vm.title = $attrs.title;
    }
  }
}());
