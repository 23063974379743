(function() {
  'use strict';

  angular
    .module('tcm.auth')
    .service('authInterceptor', authInterceptor);

  function authInterceptor($rootScope, $injector, $q, localStorageService, EnvConfig, TokenService) {
    return {
      request: function(config) {
        config.headers = config.headers || {};
        if (config.url.indexOf(EnvConfig.main_url) > -1) {
          if (TokenService.hasAccessToken()) {
            config.headers.Authorization = 'Bearer ' + TokenService.getAccessToken();
          }
        }
        return config;
      },

      responseError: function(error) {
        if (error.status === 401) {
          var $state = $injector.get('$state');
          TokenService.removeAllTokens();
          localStorageService.remove('isMasqueradeMode');

          if (!$state.includes('change_password')) {
            $state.go('signin');
          }

          $rootScope.$emit('auth:unauthorized');
        }
        return $q.reject(error);
      }
    };
  }
}());
