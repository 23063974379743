(function() {
  'use strict';

  angular
    .module('tcm.core')
    .config(['$compileProvider', compileProviderConfig]);

  function compileProviderConfig($compileProvider) {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|chrome-extension):/);
    // Angular before v1.2 uses $compileProvider.urlSanitizationWhitelist(...)
  }
}());
