(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.competencies', [
      'tcm.core',
      'tcm.common',
      'tcm.components'
    ]);
}());
