/*globals moment:false */
(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('LocationTrackingService', LocationTrackingService);

  /**
   * LocationTrackingService services sends requests to track user's time spent
   * on a particular page (state).
   *
   * The service sends the following keys:
   *  - route_entered - url path of the page that user visits at this moment.
   *  - route_exited - url path of the previous page.
   *  - key_entered - unique random value to create a start record on the backend
   *  - key_exited - unique random value to end a record on the backend and count time
   *
   *  Example:
   *
   *  Initially the services sends key_enter and route_enter values:
   *  {
   *    key_enter: 12345
   *    route_enter: /first/path
   *  }
   *
   *  When user navigates to an another page the service will send all of the 4 keys:
   *  {
   *    key_enter: 54321
   *    route_enter: /second/path
   *    key_exited: 12345
   *    route_exited: /first/path
   *  }
   *
   *  So that, backend creates a record as a time difference between
   *  time when key_enter was sent and then key_exit having equal value to key_enter
   *  was send (as a pair keys).
   *  key_enter and key_exit are the only values that matter for time calculations.
   */

  function LocationTrackingService($location, $window,
     PageViews) {
    var service = this, activityId, entry = {};

    $window.onbeforeunload = function() {
      if (entry.route_entered) {
        service.onExit();
      }
    };

    this.stateChanged = function() {
      if (entry.route_entered) {
        entry.key_exited = entry.key_entered;
        entry.route_exited = entry.route_entered;
      }

      // Use timestamp as a unique random value
      // this is not the time value for calculations
      entry.key_entered = new Date().getTime();
      entry.route_entered = $location.url();
      return trackState();
    };

    this.onExit = function() {
      entry.key_exited = entry.key_entered;
      entry.route_exited = entry.route_entered;
      delete entry.key_entered;
      delete entry.route_entered;

      // if activity wasn't closed in a proper way (e.g close tab)
      // anyways add activity_id on exit to count spent time
      if (activityId) {
        entry.activity_id = activityId;
      }

      return trackState();
    };

    this.trackActivityTimeOnEnter = function(id) {
      activityId = id;
      service.stateChanged();
    };

    this.trackActivityTimeOnExit = function() {
      // send activity id only on exit as it tells backend to add time
      // to the activity total time
      entry.activity_id = activityId;
      service.stateChanged().then(function() {
        activityId = null;
        delete entry.activity_id;
      });
    };

    this.trackActivityTimeOnNextPrev = function(id) {
      entry.activity_id = activityId;
      service.stateChanged().then(function() {
        activityId = id;
      });
    };

    this.trackActivityListener = function(id) {
      var entered = true, time;

      $window.onblur = function() {
        if (entered) {
          time = moment().subtract(entry.key_entered).seconds();
          console.log('Activity exit ' + id + ' segs:' + time);
          service.onExit();
          entered = false;
        }
      };

      $window.onfocus = function() {
        if (!entered) {
          console.log('Activity enter ' + id);
          service.stateChanged().then(function() {
            entered = true;
          });
        }
      };

    };

    this.trackIframeMessages = function(validMessage, callback) {
      if (!$window.addEventListener) {
        // ie8 cases
        $window.attachEvent('onmessage', iframeMessageHandler);
      } else {
        $window.addEventListener('message', iframeMessageHandler, false);
      }

      function iframeMessageHandler(message) {
        if (message.type === 'message' && message.data === validMessage) {
          return callback();
        }
      }

      return $window; 
    };

    function trackState() {
      return PageViews.post(entry);
    }
  }
}());
