(function() {
  'use strict';

  /* Attach to input time elements and switch their formatting to be HH:MM
  */
  angular
    .module('tcm.common')
    .directive('eoTimeFormatter', eoTimeFormatter);

  function eoTimeFormatter() {
    return {
      require: '?ngModel',
      link: function(scope, elem, attr, ngModel) {
        if (!ngModel) {return;}
        if (attr.type !== 'time') {return;}

        ngModel.$formatters.unshift(function(value) {
          return value.replace(/:00\.000$/, '');
        });
      }
    };
  }
}());
