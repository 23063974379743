(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoAvailableCompetencies', eoAvailableCompetencies);

  function eoAvailableCompetencies($rootScope, $timeout, $stateParams, Institution,
    TableFeaturesService
  ) {
    return {
      templateUrl: 'components/eo-available-competencies/eo-available-competencies.html',
      scope: {
        managePermission: '=?'
      },
      controller: eoAvailableCompetenciesCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoAvailableCompetenciesCtrl($scope) {
      var vm = this, tableFeatures, contextId, programId;

      contextId = parseInt($stateParams.contextId, 10);
      programId = parseInt($stateParams.id, 10);
      vm.checkedCompetencies = [];
      vm.isFilterCollapsed = true;

      $timeout(function() {
        $scope.$emit('eoAvailableCompetencies:handshake');
      }, 0);

      $rootScope.$on('eoAssignedCompetencies:handshake', function() {
        vm.manageMode = true;
      });

      $rootScope.$on('eoAvailableCompetencies:reload', function() {
        vm.loadAvailableCompetencies();
      });

      tableFeatures = new TableFeaturesService({
        sorting: false,
        search: { published_eq: true },
        pagination: {
          perPage: 10,
          scrollTop: false
        },
        loadData: function(queryParams) {
          queryParams.program_id = programId;
          return Institution.one(contextId).one('competencies', 'leaves').get(queryParams);
        }
      });

      vm.table = {
        searchData: tableFeatures.searchParams,
        paginationData: tableFeatures.paginationParams
      };

      vm.loadAvailableCompetencies = function() {
        vm.checkedCompetencies.length = 0;
        tableFeatures.getPageData().then(function(response) {
          vm.availableCompetencies = response[0].data;
        });
      };

      vm.search = function() {
        tableFeatures.getNewData().then(function(response) {
          vm.availableCompetencies = response[0].data;
        });
      };

      vm.checkCompetency = function(competency) {
        if (competency.checked) {
          vm.checkedCompetencies.push(competency.id);
        } else {
          _.pull(vm.checkedCompetencies, competency.id);
        }
      };

      vm.setCheckedState = function(state) {
        vm.checkedCompetencies.length = 0;
        _.each(vm.availableCompetencies, function(competency) {
          competency.checked = state;
          if (state) {
            vm.checkedCompetencies.push(competency.id);
          } else {
            vm.checkedCompetencies.length = 0;
          }
        });
      };

      vm.addSelectedCompetencies = function() {
        Institution.one(contextId).one('programs', programId).post('competencies', {'competency_ids': vm.checkedCompetencies}).then(function() {
          vm.loadAvailableCompetencies();
          vm.checkedCompetencies.length = 0;
          $scope.$emit('eoAssignedCompetencies:reload');
        });
      };

      vm.loadAvailableCompetencies();
    }
  }
}());
