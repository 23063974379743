(function() {
  'use strict';

  angular
    .module('tcm.notes')
    .controller('NotesCreateCtrl', NotesCreateCtrl);

  function NotesCreateCtrl($stateParams, $state, User,
    SpinnerService, ViewState) {

    var vm = this, spinner, userId, noteId;

    spinner = new SpinnerService();
    userId = parseInt($stateParams.userId, 10);
    noteId = parseInt($stateParams.noteId, 10);

    vm.fields = [
      {
        key: 'body',
        type: 'textarea',
        templateOptions: {
          required: true,
          label: 'Text'
        }
      }
    ];

    vm.goToListState = function() {
      var stateArray;

      stateArray = ViewState.current.name.split('.');
      stateArray.pop();

      $state.go(stateArray.join('.') + '.list');
    };

    vm.save = function(mode) {
      if (vm.createEditNoteForm.$valid) {
        if (mode === 'publish') {
          vm.model.published = true;
        }

        if (vm.model && vm.model.id) {
          vm.model.patch({body: vm.model.body, published: vm.model.published}).then(vm.goToListState);
        } else {
          User.one(userId).post('notes', vm.model).then(vm.goToListState);
        }
      }
    };

    function loadNoteToEdit() {
      if (noteId) {
        vm.isEditMode = true;
        User.one(userId).one('notes', noteId).get().then(function(response) {
          vm.model = response.data;
        });
      }
    }

    function loadStudent() {
      return User.one(userId).get().then(function(response) {
        vm.student = response.data;
        vm.user = vm.student; // breadcrumb label
      });
    }

    spinner.activateController([
      loadNoteToEdit(),
      loadStudent()
    ]);
  }
}());
