(function() {
  'use strict';

  angular
    .module('tcm.common')
    .constant('EO_DEFAULT_INFO', eoDefaultInfo());

  function eoDefaultInfo() {
    return {
      name: 'Meteor Learning',
      email: 'tcmsupport@meteorlearning.com',
      phone: '800-501-2578'
    };
  }
}());
