(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('Role', RoleResource);

  function RoleResource(ApiRestangular) {
    /**
     *  Role service is used to retrieve list of roles.
     */
    return ApiRestangular.withConfig(function(RestangularConfigurer) {
      RestangularConfigurer.setFullResponse(true);
      RestangularConfigurer.setDefaultHeaders({'Content-Type': 'application/json'});
    }).service('roles_to_filters');
  }
}());
