/*globals moment:false */

(function() {
  'use strict';

  angular
    .module('tcm.webexSessions')
    .controller('WebexSessionsCreateCtrl', WebexSessionsCreateCtrl);

  function WebexSessionsCreateCtrl($state, SpinnerService, WebexSessionsService) {
    var vm = this, spinner, minTimeDiff = 10;

    spinner = new SpinnerService();

    vm.timezone = new Date().toString().match(/\(([A-Za-z\s].*)\)/)[1];
    vm.model = {};
    vm.model.cohort_id = {};

    vm.filterOptions = [
      {
        value: 'webex_all_students',
        label: 'All students'
      },
      {
        value: 'webex_specific_users',
        label: 'Selected students'
      }
    ];

    vm.getUserRole = function() {
      if ($state.includes('student.**')) {
        return 'Student';
      } else if ($state.includes('instructor.**')) {
        return 'Instructor';
      } else if ($state.includes('admin.**')) {
        return 'Administrator';
      } else if ($state.includes('successcoach.**')) {
        return 'Success Coach';
      }
      return null;
    };

    vm.fields = [
      {
        key: 'name',
        className: 'col-md-12',
        type: 'input',
        templateOptions: {
          required: true,
          label: 'Topic'
        }
      },
      {
        key: 'date',
        className: 'col-md-4',
        type: 'ui-date',
        templateOptions: {
          required: true,
          label: 'Date'
        },
        expressionProperties: {
          'templateOptions.dateOptions.minDate': function() {
            return moment.utc().format('MM/DD/YYYY');
          }
        }
      },
      {
        key: 'start_time',
        className: 'col-md-3 col-md-offset-1',
        type: 'input',
        ngModelAttrs: {
          eoTimeFormatter: {
            bound: 'eo-time-formatter',
            attribute: 'eo-time-formatter'
          }
        },
        templateOptions: {
          type: 'time',
          required: true,
          label: 'Start time (' + vm.timezone + ')',
          eoTimeFormatter: vm.model
        },
        validators: {
          validTime: function($viewValue, $modelValue) {
            // update the end time adding 1 hour after start_time
            vm.model.end_time = moment.utc($modelValue).add(1, 'hour').seconds(0).milliseconds(0).toDate();
            // validate if the selected date is not before today's date or if it is today
            return !moment($modelValue, 'YYYY-MM-DD HH:mm:ss').isBefore(moment()) || moment($modelValue).diff(moment.utc(), 'minutes') >= 0;
          }
        },
        /* @ngInject */
        controller: function($scope) {
          // Watcher to change values of start_time and end_time if date changes
          $scope.$watch('model.date', function(newDate) {
            vm.model.start_time = moment.utc(moment(newDate).format('YYYY-MM-DD') + ' ' + moment(vm.model.start_time).format('HH:mm:ssZ'), 'YYYY-MM-DD HH:mm:ssZ').seconds(0).milliseconds(0).toDate();
            vm.model.end_time = moment.utc(moment(newDate).format('YYYY-MM-DD') + ' ' + moment(vm.model.end_time).format('HH:mm:ssZ'), 'YYYY-MM-DD HH:mm:ssZ').seconds(0).milliseconds(0).toDate();
          });
        }
      },
      {
        key: 'end_time',
        className: 'col-md-3 col-md-offset-1',
        type: 'input',
        ngModelAttrs: {
          eoTimeFormatter: {
            bound: 'eo-time-formatter',
            attribute: 'eo-time-formatter'
          }
        },
        templateOptions: {
          type: 'time',
          required: true,
          label: 'End time (' + vm.timezone + ')',
          eoTimeFormatter: vm.model
        },
        validators: {
          validTime: function($viewValue, $modelValue) {
            return moment($modelValue).diff(moment(vm.model.start_time), 'minutes') >= minTimeDiff;
          }
        }
      },
      {
        key: 'cohort_id',
        className: 'col-md-12',
        type: 'ui-select',
        templateOptions: {
          required: true,
          label: 'Course',
          options: [],
          labelProp: 'name',
          valueProp: 'id'
        },
        /* @ngInject */
        controller: function($scope, UserCohorts) {
          var userRole = vm.getUserRole();

          spinner.activateController([
            UserCohorts.getGroupedCohorts(userRole).then(function(response) {
              $scope.to.options = response.data.current;

              if (!_.isEmpty($scope.to.options)) {
                vm.model.cohort_id = $scope.to.options[0].id;
              }
            })
          ]);
        },
        validators: {
          required: function($viewValue, $modelValue) {
            return !_.isEmpty($modelValue) || $modelValue > 0;
          }
        }
      },
      {
        key: 'filter',
        className: 'col-md-6',
        type: 'ui-radio-buttons',
        templateOptions: {
          required: true,
          label: 'Send to',
          labelProp: 'label',
          valueProp: 'value',
          options: vm.filterOptions
        }
      },
      {
        key: 'user_ids',
        className: 'col-md-6',
        type: 'ui-select-multiple',
        templateOptions: {
          label: 'Students',
          placeholder: 'Type or select the user name',
          labelProp: 'name',
          valueProp: 'id',
          options: []
        },
        expressionProperties: {
          'templateOptions.disabled': '!model.cohort_id || model.filter !== "webex_specific_users"',
          'templateOptions.notEmpty': function($viewValue, $modelValue, scope) {
            if (_.isEmpty($modelValue)) {
              scope.fc.$setValidity('required', false);
            }
          },
          'templateOptions.isFilter': function($viewValue, $modelValue, scope) {
            if (scope.model.filter && scope.model.filter !== 'webex_specific_users') {
              scope.fc.$setValidity('required', true);
            }
          }
        },
        hideExpression: function() {
          return vm.model.filter !== 'webex_specific_users';
        },
        validators: {
          required: function($viewValue, $modelValue) {
            // Manual validation because templateOptions.required is not working for ui-select-multiple
            return !_.isEmpty($modelValue) || vm.model.filter === 'all_students';
          }
        },
        /* @ngInject */
        controller: function($scope, UserCohorts) {
          var queryParams = {};

          $scope.to = $scope.to || {};

          // Watcher to change values of users if cohort changes
          $scope.$watch('model.cohort_id', function(cohortId) {
            if (cohortId) {
              delete $scope.model.user_ids;

              //include only students
              queryParams['q[role_eq]'] = 'Student';

              spinner.activateController([
                UserCohorts.getCohortEnrollments(cohortId, queryParams).then(function(response) {
                  $scope.to.options = [];
                  // create a new array with users from cohort
                  _.each(response.data, function(value) {
                    $scope.to.options.push({id: value.user.id, name: value.user.first_name + ' ' + value.user.last_name});
                  });
                })
              ]);
            }
          });
        }
      }
    ];

    vm.schedule = function() {
      if (vm.createWebexSessionForm.$valid) {
        datesFormat();

        if (vm.model.duration >= minTimeDiff) {
          if (!vm.isEditMode) {
            spinner.activateController([
              WebexSessionsService.createNewSession(vm.model).then(function() {
                vm.flash = undefined;

                redirectToViewWebexSessions();
              }).catch(function(response) {
                vm.flash = response.data.error;
                window.scrollTo(0, 0);
              })
            ]);
          }
        } else {
          return false;
        }
      }
    };

    function redirectToViewWebexSessions() {
      if ($state.includes('instructor.**')) {
        $state.go('instructor.webexSessions.list', {}, {reload: true});
      } else if ($state.includes('student.**')) {
        $state.go('student.webexSessions.list', {}, {reload: true});
      } else if ($state.includes('admin.**')) {
        $state.go('admin.user.webexSessions.list', {}, {reload: true});
      } else if ($state.includes('successcoach.**')) {
        $state.go('successcoach.webexSessions.list', {}, {reload: true});
      }
    }

    function datesFormat() {
      // diff of times in minutes
      vm.model.duration = moment(vm.model.end_time).diff(moment(vm.model.start_time), 'minutes');
      // adding start time to the scheduled date
      vm.model.scheduled_date = moment(vm.model.date).format('DD/MM/YYYY') + ' ' + moment(vm.model.start_time).format('HH:mm:ss Z');
    }

    function initForm() {
      // preselect all student options
      vm.model.filter = vm.filterOptions[0].value;
      // set current date
      vm.model.date = moment.utc().format('YYYY-MM-DD HH:mm:ss');
      // set 2 offset hours
      vm.model.start_time = moment.utc().add(2, 'hour').seconds(0).milliseconds(0).toDate();
      // set 3 offset hours
      vm.model.end_time = moment.utc().add(3, 'hour').seconds(0).milliseconds(0).toDate();
    }

    initForm();
  }
}());
