(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoCompetencyCompletion', eoCompetencyCompletion);

  function eoCompetencyCompletion($http, $filter, gettextCatalog) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-competency-completion/eo-competency-completion.html',
      scope: {
        url: '='
      },
      controller: eoCompetencyCompletionCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoCompetencyCompletionCtrl($scope) {
      var vm = this;

      vm.chartConfig = {
        options: {
          chart: {
            type: 'column',
            style: {
              fontFamily: '"Source Sans Pro", "Helvetica Neue", Helvetica, sans-serif'
            }
          },
          tooltip: {
            formatter: function() {
              var losInLis = _(this.point.lo)
              .map(function(lo) {
                return '<li>' + $filter('capitalize')(lo) + '</li>';
              })
              .value()
              .join('');

              var totalText = '<strong>Competency: ' + $filter('capitalize')(this.point.description) + '</strong><br>' +
                '<strong>' + stringForLOType(this.point.type) + '</strong>' +
                '<ul>' + losInLis + '</ul>';

              var words = totalText.split(/[\s]+/);
              var numWordsPerLine = 7;
              var wordsInLine = 1;
              var str = [];

              for (var word in words) {
                if (words[word].match(/<\/?li\>/)) {
                  wordsInLine = 0;
                } else if (word > 0 && wordsInLine % numWordsPerLine === 0) {
                  str.push('<br>');
                }

                wordsInLine++;

                str.push(words[word]);
              }

              return str.join(' ');
            },
            useHTML: true,
            hideDelay: 700
          },
          plotOptions: {
            series: {
              stacking: 'normal'
            }
          },
          scrollbar: {
            enabled: true
          }
        },
        xAxis: {
          categories: [],
          max: 10,
          labels: {
            enabled: false
          },
          title: {
            text: gettextCatalog.getString('Competencies')
          }
        },
        yAxis: {
          allowDecimals: false,
          title: {
            text: gettextCatalog.getString('Learning Objectives')
          }
        },
        series: [],
        title: {
          text: null
        },
        credits: {
          enabled: false
        },
        loading: false
      };

      var mapData = function(type, item) {
        return {
          y: item[type].length,
          name: item.name,
          description: item.description,
          lo: item[type],
          type: type
        };
      };

      var stringForLOType = function(type) {
        if (type === 'mastered') {
          return gettextCatalog.getString('Mastered');
        } else if (type === 'notmastered') {
          return gettextCatalog.getString('Not Mastered');
        } else if (type === 'inprogress') {
          return gettextCatalog.getString('In Progress');
        } else if (type === 'available') {
          return gettextCatalog.getString('Not Started');
        } else if (type === 'notavailable') {
          return gettextCatalog.getString('Not Enrolled');
        }
      };

      function competencySize(c) {
        return c.available.length +
               c.inprogress.length +
               c.mastered.length +
               c.notmastered.length +
               c.notavailable.length;
      }

      function notavailablePercent(c) {
        return c.notavailable.length / competencySize(c);
      }

      function sortByNotavailable(a, b) {
        var comparePercent = notavailablePercent(a) - notavailablePercent(b);
        if (comparePercent !== 0) {
          return comparePercent;
        } else {
          return a.notavailable.length - b.notavailable.length;
        }
      }

      $scope.$watch('vm.url', function(newVal) {
        if (!newVal) {
          return;
        }

        vm.chartConfig.loading = true;

        $http.get(newVal).then(function(result) {
          if (!result.data.length) {
            return;
          }

          vm.chartConfig.series = [];

          result.data.sort(sortByNotavailable);

          var notavailable = _.map(result.data, _.partial(mapData, 'notavailable'));
          vm.chartConfig.series.push({
            name: stringForLOType('notavailable'),
            data: notavailable,
            color: '#d0d2d9'
          });

          var mastered = _.map(result.data, _.partial(mapData, 'mastered'));
          vm.chartConfig.series.push({
            name: stringForLOType('mastered'),
            data: mastered,
            color: '#2e8b57'
          });

          var inprogress = _.map(result.data, _.partial(mapData, 'inprogress'));
          vm.chartConfig.series.push({
            name: stringForLOType('inprogress'),
            data: inprogress,
            color: '#cccc00'
          });

          var notmastered = _.map(result.data, _.partial(mapData, 'notmastered'));
          vm.chartConfig.series.push({
            name: stringForLOType('notmastered'),
            data: notmastered,
            color: '#cd5c5c'
          });

          var available = _.map(result.data, _.partial(mapData, 'available'));
          vm.chartConfig.series.push({
            name: stringForLOType('available'),
            data: available,
            color: '#afb4c2'
          });

          vm.chartConfig.yAxis.max = _(result.data)
          .map(competencySize)
          .max();
        })
        .finally(function() {
          vm.chartConfig.loading = false;
        });
      });
    }
  }
}());
