(function() {
  'use strict';

  angular
    .module('tcm.common')
    .directive('eoShowWithoutAccess', eoShowWithoutAccess);

  function eoShowWithoutAccess(UserService) {
    /**
     * This directive is similar to the eoShowWithAccess directive, however it lets you
     * handle cases where you need conditional logic based on when a user does not
     * have a specific permission.  Use case would be to show plain text instead
     * of a form element that is disabled.
     */

    return {
      transclude: 'element',
      scope: true,
      link: function(scope, element, attrs, ctr, transclude) {
        var resources, permissions;
        resources = attrs.eoShowWithoutAccess.split('|');
        permissions = (attrs.permission) ? attrs.permission.split('|') : ['CREATE'];

        UserService.canAccess(permissions, resources).then(function(hasPermission) {
          if (!hasPermission) {
            transclude(scope, function(clone) {
              element.after(clone);
            });
          }
        });
      }
    };
  }
}());
