(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.activities')
    .controller('AdminActivitiesDetailCtrl', AdminActivitiesDetailCtrl);

  function AdminActivitiesDetailCtrl($stateParams, Activity,
    Institution, ExternalService, SpinnerService, Objective) {

    var vm = this, spinner, activityId, scoreLabels;

    spinner = new SpinnerService();
    activityId = parseInt($stateParams.id, 10);
    vm.contentLaunched = false;

    scoreLabels = [
      {value: 'highest', label: 'Highest'},
      {value: 'lowest', label: 'Lowest'},
      {value: 'average', label: 'Average of all attempts'},
      {value: 'latest', label: 'Last Attempt'}
    ];

    function loadActivityDetails() {
      return Activity.one(activityId).get().then(function(response) {
        vm.activity = response.data;
        vm.rubric = vm.activity.rubric;
        vm.activityScoreType = _.find(scoreLabels, {'value': vm.activity.type_of_score}).label;
        return loadInstitutionName(vm.activity.owner_institution_id);
      });
    }

    function loadInstitutionName(institutionId) {
      return Institution.one(institutionId).get().then(function(response) {
        vm.institutionName = response.data.institution_name;

      });
    }
    function loadCourses() {
      var queryParam = {learning_activity_id: activityId};
      return Objective.getList(queryParam).then(function(response) {
        vm.learningObjectives = response.data;
      });
    }

    vm.launchContent = function(data) {
      if (vm.preventDoubleLaunch) { return; }

      vm.preventDoubleLaunch = true;

      ExternalService.previewContentForActivity(data).then(function(result) {
        vm.contentLaunched = result;
      });
    };

    spinner.activateController([
      loadActivityDetails(),
      loadCourses()
    ]);
  }
}());
