(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoProgramEnrollment', eoProgramEnrollment);

  function eoProgramEnrollment($state) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-program-enrollment/eo-program-enrollment.html',
      scope: {
        programs: '=',
        userId: '='
      },
      controller: eoProgramEnrollmentCtrl
    };

    function eoProgramEnrollmentCtrl($scope) {
      $scope.viewProgress = function(pid) {
        var params = {userId: $scope.userId, id: pid},
          state = 'admin.users.competencies';

        if ($state.is('instructor.cohorts.objectives.gradebook')) {
          state = 'instructor.users.competencies';
        }else if ($state.is('successcoach.cohorts.objectives.progress.details')) {
          state = 'successcoach.users.competencies';
        }

        $state.go(state, params);
      };
    }
  }
}());
