(function() {
  'use strict';

  angular
    .module('tcm.dashboard.student.cohorts')
    .controller('StudentSearchActivitiesCtrl', StudentSearchActivitiesCtrl);

  function StudentSearchActivitiesCtrl($stateParams, $scope, UserService, User,
    StudentSearchActivities, TableFeaturesService, SpinnerService, localStorageService) {
    var vm = this, tableFeatures, spinner, cohortId;

    cohortId = parseInt($stateParams.id, 10);
    spinner = new SpinnerService();

    vm.state = $stateParams.state || 'search';
    vm.search = $stateParams.search || null;
    vm.displayCards = localStorageService.get('displayCards') !== null ? JSON.parse(localStorageService.get('displayCards')) : true;

    tableFeatures = new TableFeaturesService({
      pagination: {perPage: 1000},
      sorting: {column: 'cohort_order'},
      search: false,
      loadData: function(queryParams) {
        if (vm.search) {
          queryParams['q[learning_activity_description_cont]'] = vm.search;
        } else {
          StudentSearchActivities.searchData.learning_activity_description_cont = undefined;
        }

        return StudentSearchActivities.getActivities(queryParams, vm.state);
      }
    });

    vm.listData = {paginationData: tableFeatures.paginationParams};

    vm.changeDisplay = function(visible) {
      vm.displayCards = visible;
      localStorageService.set('displayCards', visible);
    };


    vm.getActivities = function () {
      if (!vm.search) {
        tableFeatures.resetSearch();
      }

      return tableFeatures.getPageData().then(function(response) {
        vm.activities = response[0].data;
      });
    };

    function loadCohort() {
      return UserService.loadProfile().then(function(profile) {
        return User.one(profile.id).one('cohorts', cohortId).get().then(function(response) {
          vm.cohort = response.data;
        });
      });
    }

    $scope.$on('activities-refresh:search', function() {
      vm.getActivities();
      loadCohort();
    });

    spinner.activateController([
      vm.getActivities(),
      loadCohort()
    ]);
  }
}());
