(function() {
  'use strict';

  angular
    .module('tcm.common')
    .directive('eoBack', eoBack);

  function eoBack($window, ViewState) {
    /**
     *  eo-back directive simulates 'back' button in a browsers.
     *  It basically gets a user back in the history or redirect to correct state.
     */
    return {
      replace: true,
      scope: {},
      transclude: true,
      link: function(scope, element) {
        var timesBack = -1;

        $window.sessionStorage.setItem('previous', ViewState.previous.name || $window.sessionStorage.previous);

        if (!ViewState.previous.name) {
          ViewState.previous.name = $window.sessionStorage.previous;
        }

        element.bind('click', function() {
          if (ViewState.current.name.indexOf('.show') >= 0) {
            // Avoids redirecting to an edit or create page.
            if (ViewState.previous.name.indexOf('.edit') >= 0 || ViewState.previous.name.indexOf('.new') >= 0 || ViewState.previous.name.indexOf('.manage') >= 0) {
              timesBack = -2;
            }
          } else if (ViewState.current.name.indexOf('.notes') >= 0) {
            if (ViewState.previous.name.indexOf('.edit') >= 0 || ViewState.previous.name.indexOf('.new') >= 0) {
              timesBack = -3;
            }
          }
          $window.history.go(timesBack);
        });
      },
      template: '<button type="reset" class="btn" ng-transclude></button>'
    };
  }
}());
