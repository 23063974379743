(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoItemsSlider', eoItemsSlider);

  function eoItemsSlider() {
    return {
      template: function(element, attrs) {
        attrs.$eoItemsSliderTemplate = element.html();
        return '' +
          '<eo-items-slider-nav-wrapper> ' +
            '<eo-item-slider-data></eo-item-slider-data> ' +
            '<ul class="pager"> ' +
              '<li ' +
                'role="button" ' +
                'aria-label="Previous Page" ' +
                'aria-disabled="{{ !$ctrl.canPageBack()}}" ' +
                'ng-class="{\'eo-disabled\': !$ctrl.canPageBack()}" ' +
                'ng-click="$ctrl.previousPage()" ' +
                'class="previous"> ' +
                '<a href> ' +
                  '<span class="glyphicon glyphicon-menu-left"></span> ' +
                '</a> ' +
              '</li> ' +
              '<li ' +
                'role="button" ' +
                'aria-label="Next Page" ' +
                'aria-disabled="{{ !$ctrl.canPageForward()}}" ' +
                'ng-class="{\'eo-disabled\': !$ctrl.canPageForward()}" ' +
                'ng-click="$ctrl.nextPage()" ' +
                'class="next"> ' +
                '<a href> ' +
                  '<span class="glyphicon glyphicon-menu-right"></span> ' +
                '</a> ' +
              '</li> ' +
            '</ul> ' +
          '</eo-items-slider-nav-wrapper> ' +
          '<eo-items-slider-content-wrapper> ' +
            '<eo-item-slider-content ' +
              'ng-repeat="item in $ctrl.items" ' +
              'ng-class="{ ' +
              '\'eo-active\': item.isActive(), ' +
              '\'eo-left\': item.isLeft(), ' +
              '\'eo-right\': item.isRight() ' +
              '}"> ' +
            '<div eo-item-slider-template="::item.template" ' +
            'eo-scope="::item.parent"></div> ' +
            '</eo-item-slider-content> ' +
          '</eo-items-slider-content-wrapper>';
      },
      scope: {
        selectedIndex: '=?'
      },
      controllerAs: '$ctrl',
      bindToController: true,
      controller: eoItemsSliderCtrl
    };

    function eoItemsSliderCtrl($scope, $element, $attrs, $compile, $animateCss) {
      var $ctrl = this, elements = getElements();

      $ctrl.items = [];
      $ctrl.selectedIndex = $ctrl.selectedIndex || 0;

      $scope.$watch('$ctrl.selectedIndex', function() {
        animateContentHeight();
      });

      $ctrl.getItemElementIndex = getItemElementIndex;
      $ctrl.insertItemElement = insertItemElement;
      $ctrl.select = select;
      $ctrl.previousPage = previousPage;
      $ctrl.nextPage = nextPage;
      $ctrl.canPageBack = canPageBack;
      $ctrl.canPageForward = canPageForward;

      function animateContentHeight() {
        var itemContent = elements.contents[$ctrl.selectedIndex],
          contentHeight = itemContent ? itemContent.offsetHeight : 0,
          navHeight    = elements.nav.offsetHeight,
          newHeight = contentHeight + navHeight,
          currentHeight = $element.prop('clientHeight');

        if (newHeight === currentHeight) { return; }

        var fromHeight = {height: currentHeight + 'px'},
          toHeight = {height: (newHeight) + 'px'};

        $animateCss($element, {
          from: fromHeight,
          to: toHeight,
          easing: 'cubic-bezier(0.35, 0, 0.25, 1)',
          duration: 0.5
        }).start();
      }

      function compileTemplate() {
        var template = $attrs.$eoItemsSliderTemplate,
          element = angular.element(elements.data);
        element.html(template);
        $compile(element.contents())($scope.$parent);
        delete $attrs.$eoItemsSliderTemplate;
      }

      function select(index) {
        $ctrl.selectedIndex = index;
      }

      function nextPage() {
        if ($ctrl.canPageForward()) {
          $ctrl.selectedIndex += 1;
        }
      }

      function previousPage() {
        if ($ctrl.canPageBack()) {
          $ctrl.selectedIndex -= 1;
        }
      }
      function canPageBack() {
        return $ctrl.selectedIndex > 0;
      }

      function canPageForward() {
        return $ctrl.selectedIndex < $ctrl.items.length - 1;
      }

      function insertItemElement(itemData, index) {
        var proto = {
          getIndex: function() { return $ctrl.items.indexOf(item); },
          isActive: function() { return this.getIndex() === $ctrl.selectedIndex; },
          isLeft: function() { return this.getIndex() < $ctrl.selectedIndex; },
          isRight: function() { return this.getIndex() > $ctrl.selectedIndex; }
        },
            item = angular.extend(proto, itemData);

        $ctrl.items.splice(index, 0, item);
        return item;
      }

      function getItemElementIndex(itemEl) {
        var items = $element[0].getElementsByTagName('eo-item-slider');
        return Array.prototype.indexOf.call(items, itemEl[0]);
      }

      function getElements() {
        var elements = {};

        elements.nav = $element[0].getElementsByTagName('eo-items-slider-nav-wrapper')[0];
        elements.data = $element[0].getElementsByTagName('eo-item-slider-data')[0];
        elements.contents = $element[0].getElementsByTagName('eo-item-slider-content');
        return elements;
      }

      compileTemplate();
    }
  }
}());
