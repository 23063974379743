(function() {
  'use strict';

  angular
    .module('restangular.api', ['ng2Web'])
    .factory('ApiRestangular', ApiRestangularResource);

  function ApiRestangularResource(Restangular, EnvConfig) {
    return Restangular.withConfig(function(RestangularConfigurer) {
      RestangularConfigurer.setBaseUrl(EnvConfig.main_url + '/api/v1');
      RestangularConfigurer.setDefaultHeaders({
        'Content-Type': 'application/json'
      });
    });
  }
}());
