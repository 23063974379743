(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('Institution', InstitutionResource);

  function InstitutionResource(ApiRestangular) {
    return ApiRestangular.withConfig(function(RestangularConfigurer) {
      RestangularConfigurer.setFullResponse(true);
      RestangularConfigurer.setDefaultHeaders({'Content-Type': 'application/json'});
    }).service('institutions');
  }
}());
