/*globals moment:false */
(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoWebexSession', eoWebexSession);

  function eoWebexSession($stateParams, WebexSessionsService, UserService,
    SpinnerService, $uibModal
  ) {
    return {
      replace: true,
      templateUrl: 'components/eo-webex-session/eo-webex-session.html',
      scope: {
        session: '='
      },
      link: eoWebexSessionLinkFn
    };

    function eoWebexSessionLinkFn(scope) {
      var sessionId = parseInt($stateParams.id, 10);

      scope.showSession = function() {
        if (scope.isActive) {
          scope.isActive = false;
        } else {
          scope.isActive = true;
        }

        return scope.isActive;
      };

      scope.goToMeeting = function() {
        window.open(scope.session.link, '_blank');
      };

      scope.cancel = function(id) {
        var spinner = new SpinnerService(),
          $uibModalCloseInstance;

        $uibModalCloseInstance = $uibModal.open({
          animation: true,
          templateUrl: 'components/eo-webex-session/modals/confirm-cancel-session-modal.html',
          controllerAs: 'modal',
          controller: function CancelModalCtrl() {
            var modal = this;

            modal.on = function() {
              spinner.activateController([
                WebexSessionsService.cancelSession(id).then(function(response) {
                  $uibModalCloseInstance.close();

                  scope.$emit('eo-webex-session:delete', scope.session, response);
                }).catch(function() {
                  var message = 'There was a problem in cancelling your session. ' +
                    'Please contact support at tcmsupport@meteorlearning.com';

                  $uibModalCloseInstance.close();
                  scope.$emit('eo-webex-session:cancel-error', message);
                })
              ]);
            };
          }
        });
      };

      function controllerInit() {
        scope.isActive = sessionId === scope.session.id;
        scope.session.link = scope.session.instructor_url || scope.session.student_url;
        scope.session.time = {};
        scope.session.time.start = moment(scope.session.scheduled_date, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').format('h:mm a');
        scope.session.time.end = moment(scope.session.scheduled_date, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').add(scope.session.duration, 'minutes').format('h:mm a');
      }

      controllerInit();
    }
  }
}());
