(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('PaginationService', PaginationService);

  function PaginationService($rootScope) {

    var Pagination = function(options) {
      options = options || {};

      this.data = {
        currentPage: options.currentPage || 0,
        perPage: options.perPage || 25,
        scrollTop: options.scrollTop
      };
    };

    Pagination.prototype.addParams = function(queryParams) {
      if (this.data.currentPage) {
        queryParams.page = this.data.currentPage;
      }
      queryParams.per_page = this.data.perPage;
      return queryParams;
    };

    Pagination.prototype.reset = function() {
      delete this.data.currentPage;
      delete this.data.totalItems;
    };

    /**
     * Create a function that parses the response from the server, extracting the
     * information from the headers
     * @return {Promise} a resolved promise with the original response, to allow
     * chaining
     */
    Pagination.prototype.parse = function() {
      var pagination = this;
      return function(response) {
        pagination.data.currentPage = response.headers('X-Page');
        pagination.data.totalItems = response.headers('X-Total');
        pagination.data.perPage = response.headers('X-Per-Page');
        pagination.data.totalPages = Math.ceil(pagination.data.totalItems / pagination.data.perPage);

        if (pagination.data.scrollTop !== false) {
          // trigger an even for eo-scroll-top directive
          $rootScope.$broadcast('paginationService:scrollTop');
        }

        return response;
      };
    };

    return Pagination;
  }
}());
