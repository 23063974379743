(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('UserTodoList', UserTodoList);

  function UserTodoList(UserService, Restangular, EnvConfig) {
    var resource =  Restangular.withConfig(function(RestangularConfigurer) {
      RestangularConfigurer.setBaseUrl(EnvConfig.main_url + '/api/ui');
      RestangularConfigurer.setFullResponse(true);
    });

    function getActivitiesByCohortId(cohort) {
      return UserService.loadProfile().then(function(profile) {
        return resource.one('users', profile.id).one('cohorts', cohort).getList('activities/todolist');
      });
    }
    return {
      getActivitiesByCohortId: getActivitiesByCohortId
    };
  }
}());
