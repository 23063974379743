(function() {
  'use strict';

  angular
    .module('tcm.common')
    .directive('eoWhiteLabelBranding', eoWhiteLabelBranding);

  function eoWhiteLabelBranding() {
    /**
     * White Label Branding directive applies institution branding styles
     *
     *  NOTE: it requires a folder being created for an institution such as:
     *  /assets/custom/inst-x, where x = institution name being lowercase
     *  and snake case (e.g educate-online).
     *
     *  The folder has to contain custom css file named institution-whitelabel-styles.css,
     *  as well as required assets (logo, background, etc)
     */
    return {
      replace: true,
      scope: {
        institutionWhiteLabel: '='
      },
      template: '<link rel="stylesheet" ng-href="/assets/custom/inst-{{institutionWhiteLabel}}/institution-whitelabel-styles.css"/>',
      link: function(scope, element) {
        var defaultStyles = 'meteor-learning';

        scope.institutionWhiteLabel = scope.institutionWhiteLabel || defaultStyles;

        scope.$on('eo-white-label-branding:default', function() {
          scope.institutionWhiteLabel = defaultStyles;
        });

        angular.element('head').append(element);
      }
    };
  }
}());
