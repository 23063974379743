/*globals moment:false */
(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.cohorts')
    .controller('AdminCohortsCreateCtrl', AdminCohortsCreateCtrl);

  function AdminCohortsCreateCtrl($scope, $state, $stateParams, SpinnerService,
    Institution, $uibModal) {
    var vm = this, courseId, spinner, contextId, cohortId, originalStartDate, originalEndDate;

    spinner = new SpinnerService();
    contextId = parseInt($stateParams.contextId, 10);
    cohortId = parseInt($stateParams.id, 10);
    courseId = parseInt($stateParams.courseId, 10);
    vm.model = {};

    $scope.$watch('vm.model.starts_on', function(startsOn) {
      if (startsOn) {
        // on cohort create case, min date for ends_on should be starts_on
        vm.model.minEndsOnDate = startsOn;

        if (vm.model.isOngoingCohort) {
          // if cohort is ongoing, min date for ends_on should be today's date
          vm.model.minEndsOnDate = moment().format('YYYY-MM-DD');
        }
      }
    });

    vm.fields = [
      {
        key: 'name',
        type: 'input',
        templateOptions: {
          required: true,
          type: 'text',
          label: 'Name',
          maxlength: 100
        }
      },
      {
        key: 'curriculum_id',
        type: 'ui-select',
        templateOptions: {
          required: true,
          label: 'Course',
          options: [],
          placeholder: 'Select a course',
          valueProp: 'id',
          labelProp: 'name'
        },
        expressionProperties: {
          'templateOptions.disabled': 'model.enableCohort'
        },
        /* @ngInject */
        controller: function($scope, TableFeaturesService) {
          if (!courseId) {
            var tableFeatures = new TableFeaturesService({
            pagination: {perPage: 1000},
            search: {published_eq: true},
            sorting: {column: 'name'},
            loadData: function(queryParams) {
              return Institution.one(contextId).getList('curriculums', queryParams);
            }
          });

            tableFeatures.getPageData().then(function(response) {
              $scope.to.options = response[0].data;
            });
          } else {
            Institution.one(contextId).one('curriculums', courseId).get().then(function(response) {
              vm.course = response.data;
              $scope.to.options = [vm.course];
              vm.model.curriculum_id = vm.course.id;
            });
          }
        }
      },
      {
        key: 'starts_on',
        type: 'ui-date',
        templateOptions: {
          required: true,
          label: 'Starts On'
        },
        expressionProperties: {
          'templateOptions.disabled': 'model.isOngoingCohort',
          'templateOptions.dateOptions.dateFormat': '"yy-mm-dd"'
        }
      },
      {
        key: 'ends_on',
        type: 'ui-date',
        templateOptions: {
          label: 'Ends On'
        },
        expressionProperties: {
          'templateOptions.disabled': '!model.starts_on',
          'templateOptions.dateOptions.dateFormat': '"yy-mm-dd"',
          'templateOptions.dateOptions.minDate': 'model.minEndsOnDate',
          'templateOptions.required': '!model.rolling'
        }
      },
      {
        key: 'rolling',
        type: 'checkbox',
        templateOptions: {
          label: 'Mark as a Rolling Cohort'
        },
        expressionProperties: {
          'templateOptions.disabled': 'model.isOngoingCohort'
        }
      },
      {
        key: 'display_grade',
        type: 'checkbox',
        templateOptions: {
          label: 'Display grade for this cohort'
        }
      },
      {
        key: 'use_activity_grade_percentage',
        type: 'checkbox',
        templateOptions: {
          label: 'Use Activity Grade Percentage for Course Score'
        }
      },
      {
        key: 'reset_on_enrollment',
        type: 'checkbox',
        templateOptions: {
          label: 'Reset Users on Re-enrollment'
        }
      },
      {
        key: 'description',
        type: 'textarea',
        templateOptions: {
          label: 'Description'
        },
        expressionProperties: {
          'templateOptions.disabled': 'model.isOngoingCohort'
        }
      }
    ];

    vm.save = function() {
      if (vm.createCohortForm.$valid) {
        if (vm.model && vm.model.id) {
          var message = {}, callbackYes, callbackCancel;

          vm.modalShowed = {start: false, end: false};

          if (!vm.model.rolling && originalStartDate && originalStartDate !== vm.model.starts_on) {
            message.main = 'Do you want to change the start date of already enrolled users?';
            message.confirmation = '';

            callbackYes = function() {
              vm.modalShowed.start = true;
            };

            callbackCancel = function() {
              vm.model.starts_on = originalStartDate;
              vm.modalShowed.start = false;
              vm.modalShowed.end = false;
            };

            openModal(message, callbackYes, callbackCancel);
          } else {
            vm.modalShowed.start = true;
            readyForUpdate();
          }

          if (originalEndDate && originalEndDate !== vm.model.ends_on) {
            message.main = 'Changing the cohort end date will change the end date for all enrolled users.';
            message.confirmation = 'Do you want to continue?';

            callbackYes = function() {
              vm.modalShowed.end = true;
            };

            callbackCancel = function() {
              // restore the default value and reset the flags
              vm.model.ends_on = originalEndDate;
              vm.modalShowed.start = false;
              vm.modalShowed.end = false;
            };

            openModal(message, callbackYes, callbackCancel);
          } else {
            vm.modalShowed.end = true;
            readyForUpdate();
          }
        } else {
          Institution.one(contextId).post('cohorts', vm.model).then(function(response) {
            if (courseId) {
              $state.go('admin.courses.cohorts', {id: courseId});
            } else {
              $state.go('admin.cohorts.show', {id: response.data.id});
            }
          });
        }
      }
    };

    function readyForUpdate() {
      if (vm.modalShowed.start && vm.modalShowed.end) {
        updateCohort();
      }
    }

    function openModal(message, callbackYes, callbackCancel) {
      $uibModal.open({
        templateUrl: 'dashboard/admin/cohorts/modals/confirm-end-date-update.html',
        controllerAs: 'modal',
        controller: function CohortEditModalCtrl() {
          var modal = this;

          modal.message = message;
        }
      }).result.then(function(isConfirmed) {
        if (isConfirmed) {
          callbackYes();
        } else {
          callbackCancel();
        }

        readyForUpdate();
      });
    }

    function updateCohort(params) {
      vm.model.patch(params).then(function(response) {
        $state.go('admin.cohorts.show', {id: response.data.id});
      });
    }

    function loadCohortToEdit() {
      vm.isEditMode = false;
      if (cohortId) {
        vm.isEditMode = true;
        return Institution.one(contextId).one('cohorts', cohortId).get().then(function(response) {
          vm.model = response.data;
          vm.cohort = response.data; // breadcrumb label

          // save original start date for enrollmens updating
          originalStartDate = vm.model.starts_on;

          // save original end date for rollback case
          if (!vm.model.rolling) {
            originalEndDate = vm.model.ends_on;
          }
          vm.model.isOngoingCohort = vm.isOngoingCohort(response.data);

          if (vm.model.isOngoingCohort) {
            // if cohort is ongoing, min date for ends_on should be today's date
            vm.model.minEndsOnDate = moment().format('YYYY-MM-DD');
          } else {
            // if it's a future cohort, min date for ends_on should be starts_on
            vm.model.minEndsOnDate = vm.model.starts_on;
          }
          activateCourseDropdown();
        });
      } else {
        return Institution.one(contextId).get().then(function(response) {
          vm.model.display_grade = response.data.display_grades;
          activateCourseDropdown();
        });
      }
    }

    function activateCourseDropdown() {
      if (courseId) {
        vm.model.enableCohort = 'true';
      } else {
        vm.model.enableCohort = vm.model.has_active_enrollments;
      }
    }

    vm.isOngoingCohort = function(cohort) {
      if (cohort.ends_on) {
        // add one day to end date as isBetween does not include today in the range
        return moment().isBetween(cohort.starts_on, moment(cohort.ends_on).add(1, 'days'), 'day');
      } else {
        return moment().isAfter(cohort.starts_on, 'day');
      }
    };

    spinner.activateController([
      loadCohortToEdit()
    ]);
  }
}());
