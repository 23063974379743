(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('Program', ProgramResource);

  function ProgramResource(ApiRestangular) {
    return ApiRestangular.withConfig(function(RestangularConfigurer) {
      RestangularConfigurer.setFullResponse(true);
      RestangularConfigurer.setDefaultHeaders({'Content-Type': 'application/json'});
    }).service('programs');
  }
}());
