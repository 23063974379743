(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('TableFeaturesService', TableFeaturesService);

  function TableFeaturesService(SortingService, SearchService,
    PaginationService, SpinnerService
  ) {

    /**
     * TableFeatures service encapsulates common features for tables.
     * It contains functionality of the following services:
     *  - Sorting service
     *  - Search service
     *  - Pagination service
     *  - Spinner service
     *
     * TableFeatures service requires config object being passed when it is instantiated, which should
     * contain configuration at least for SortingService. If config object does not explicitly contain
     * 'false' value for a service the TableFeatures service will instantiate this service with default
     * parameters.
     *
     * TableFeatures service also requires loadData method (as a part of config object) being implemented
     * in order to provide a way of how to make request to a server to get data. This method should
     * contain queryParams as an argument and return a promise and it assumed to be used with Restangular.
     *
     *
     * # Usage
     *
     * var tableFeatures = new TableFeaturesService({
     *   sorting:{column:'name},
     *   loadData: function(queryParams){
     *     RestangularSampleResource.getList(queryParams);
     *   }});
     *
     * This example creates an instance of the TableFeatures service which will contain sorting (by column 'name'),
     * searching and pagination with default parameters.
     * */
    var TableFeatures = function(config) {
      config = config || {};
      configureSorting(config, this);
      configureSearch(config, this);
      configurePagination(config, this);
      configureSpinner(config, this);

      this.loadData = config.loadData || angular.noop;
    };

    var configureSorting = function(config, self) {
      if (config.sorting === false) {
        return;
      }
      self._sorting = new SortingService(config.sorting);
      self.sortingParams = self._sorting.data;
    };

    var configureSearch = function(config, self) {
      if (config.search === false) {
        return;
      }
      self._search = (config.search) ? new SearchService(config.search) :  new SearchService();
      self.searchParams = self._search.data;

      self.setSearchQueryParamsByState = function(state, stateKey) {
        stateKey = stateKey || 'state_eq';
        var queryParams = prepareQueryParams(stateKey, ['in_progress', 'failed']);
        self._search.reset();
        if (state === 'in-progress' || state === 'in_progress') {
          self._search.setSearchQueryParamsByState(queryParams);
        } else if (state !== 'all') {
          self._search.data[stateKey] = state;
        }
      };
    };

    function prepareQueryParams(stateKey, states) {
      var params = [];
      for (var i = 0; i < states.length; i++) {
        var queryObj = {};
        queryObj[stateKey] = states[i];
        params.push(queryObj);
      }
      return params;
    }

    var configurePagination = function(config, self) {
      if (config.pagination === false) {
        return;
      }
      self._pagination = (config.pagination) ? new PaginationService(config.pagination) : new PaginationService();
      self.paginationParams = self._pagination.data;
    };

    var configureSpinner = function(config, self) {
      if (config.spinner === false) {
        return;
      }
      self._spinner = new SpinnerService();
    };

    TableFeatures.prototype.getQueryParams = function() {
      var queryParams = {};
      if (this._sorting) {
        this._sorting.addParams(queryParams);
      }
      if (this._pagination) {
        this._pagination.addParams(queryParams);
      }
      if (this._search) {
        this._search.addParams(queryParams);
      }

      return queryParams;
    };

    TableFeatures.prototype.getPageData = function() {
      return requestData(this);
    };

    TableFeatures.prototype.getNewData = function() {
      if (this._pagination) {
        this._pagination.reset();
      }
      return requestData(this);
    };

    TableFeatures.prototype.resetSearch = function() {
      if (this._search) {
        this._search.reset();
      }
    };

    TableFeatures.prototype.resetEmptySearchData = function() {
      if (this._search) {
        this._search.resetEmpty();
      }
    };

    var requestData = function(self) {
      if (self._spinner) {
        if (self._pagination) {
          return self._spinner.activateController([
            self.loadData(self.getQueryParams()).then(self._pagination.parse())
          ]);
        } else {
          return self._spinner.activateController([
            self.loadData(self.getQueryParams())
          ]);
        }
      } else {
        if (self._pagination) {
          return self.loadData(self.getQueryParams()).then(self._pagination.parse());
        } else {
          return self.loadData(self.getQueryParams());
        }
      }
    };

    return TableFeatures;
  }
}());
