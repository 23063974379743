(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.programs')
    .controller('AdminProgramsDetailCtrl', AdminProgramsDetailCtrl);

  function AdminProgramsDetailCtrl($stateParams, Institution,
    SpinnerService, TableFeaturesService, UserService, User, $uibModal) {

    var vm = this, tableFeature, spinner, contextId, programId;

    spinner = new SpinnerService();
    contextId = parseInt($stateParams.contextId, 10);
    programId = parseInt($stateParams.id, 10);

    tableFeature = new TableFeaturesService({
      search: {active_true: '1'},
      sorting: {column: 'username'},
      loadData: function(queryParams) {
        return Institution.one(contextId).one('programs', programId).getList('enrollments', queryParams);
      }
    });

    vm.table = {
      searchData: tableFeature.searchParams,
      sortingData: tableFeature.sortingParams,
      paginationData: tableFeature.paginationParams
    };

    vm.search = function() {
      tableFeature.getNewData().then(function(response) {
        vm.enrollments = response[0].data;
      });
    };

    vm.loadEnrollments = function() {
      tableFeature.getPageData().then(function(response) {
        vm.enrollments = response[0].data;
      });
    };

    function loadProgram() {
      Institution.one(contextId).one('programs', programId).get().then(function(response) {
        vm.program = response.data;
      });
    }

    function checkUserRights() {
      UserService.canAccess(['MANAGE_PROGRAM_ENROLLMENT'], ['users']).then(function(hasPermission) {
        if (hasPermission) {
          vm.showEnrollments = true;
          vm.loadEnrollments();
        } else {
          vm.showEnrollments = false;
        }
      });
    }

    vm.updateEnrollmentDate = function(enrollment) {
      Institution
        .one(contextId)
        .one('programs', programId)
        .one('enrollments', enrollment.user_id)
        .patch({
          enrollment_date: enrollment.enrollment_date
        }).then(function() {
          vm.flash = '';
        }, function() {
          vm.flash = 'There was a problem saving the data. Please try again later.';
        });
    };

    vm.disenrollFromProgram = function(student) {
      var studentIndex = _.indexOf(vm.enrollments, student);

      $uibModal.open({
        templateUrl: 'dashboard/admin/programs/detail/confirm-unenroll.html',
        controllerAs: 'modal',
        controller: function($scope) {
          var modal = this;
          modal.fullName = student.user_first_name + ' ' + student.user_last_name;

          modal.ok = function() {
            $scope.$close();
          };
        }
      }).result.then(function() {
        User.one(student.user_id).one('programs', vm.program.id).remove().then(function() {
          vm.enrollments.splice(studentIndex, 1);
        });
      });
    };

    spinner.activateController([
      loadProgram(),
      checkUserRights()
    ]);
  }
}());
