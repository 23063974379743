(function() {
  'use strict';

  angular
    .module('tcm.error')
    .controller('ErrorCtrl', ErrorCtrl);

  function ErrorCtrl($scope, $location) {
    $scope.lti_errormsg = $location.search().lti_errormsg;
  }
}());
