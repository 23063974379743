(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoUsersList', eoUsersList);

  function eoUsersList($rootScope, $state, $stateParams, $location, UserService, TableFeaturesService,
    Institution, SpinnerService) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-users-list/eo-users-list.html',
      scope: {
        cohortName: '=',
        courseName: '=',
        courseId: '=',
        institutionId: '='
      },
      controllerAs: 'vm',
      bindToController: true,
      controller: eoStudentListCtrl
    };

    function eoStudentListCtrl() {
      var vm = this, tableFeature, spinner;
      vm.allUsers =  'All Students';
      vm.roleModel = 'successcoach';

      vm.isfilterCollapsed = ($stateParams.first_name_cont || $stateParams.last_name_cont || $stateParams.username_cont || $stateParams.email_cont) ? false : true;
      vm.state = $stateParams.state;
      spinner = new SpinnerService();

      tableFeature = new TableFeaturesService({
        sorting: {column: 'last_name'},
        search: {
          'last_name_cont': $stateParams.last_name_cont,
          'first_name_cont': $stateParams.first_name_cont,
          'username_cont': $stateParams.username_cont,
          'email_cont': $stateParams.email_cont
        },
        loadData: function(queryParams) {
          return UserService. loadProfile().then(function(profile) {
            return Institution. one(profile.institution_id).one('students').get(queryParams);
          });
        }
      });

      vm.table = {
        sortingData: tableFeature.sortingParams,
        paginationData: tableFeature.paginationParams,
        searchData: tableFeature.searchParams
      };

      vm.getTabData = function() {
        if ($stateParams.dashboard === 'dashboard') {
          $location.url($location.path());
        }

        tableFeature.resetEmptySearchData();
        tableFeature.getPageData().then(function(response) {
          vm.data = response[0].data;
        });
      };

      vm.goToSearchState = function() {
        tableFeature.resetEmptySearchData();
        $location.search(vm.table.searchData);
        tableFeature.getNewData().then(function(response) {
          vm.data = response[0].data;
        });
      };

      vm.reset = function() {
        tableFeature.resetSearch();
        tableFeature.resetEmptySearchData();
        tableFeature.getNewData().then(function(response) {
          vm.data = response[0].data;
        });
      };

      vm.extractParamsToFilterBy = function() {
        var searchData = {};

        _.each(vm.table.searchData, function(item, key) {
          searchData[key] = vm.table.searchData[key];
        });

        return searchData;
      };

      vm.toggleState = function() {
        $state.go(vm.roleModel + '.cohorts.objectives.studentlist', {id: 'all', state: 'all', dashboard: 'dashboard'});
      };

      $rootScope.$on('roleChanged', function(event, data) {
        vm.roleModel = data.role;
      });

      vm.getTabData();
    }
  }
}());
