(function() {
  'use strict';

  angular
    .module('tcm.core')
    .config(formlyConfigProviderConfig);

  function formlyConfigProviderConfig(formlyConfigProvider) {
    formlyConfigProvider.setWrapper({
      name: 'validation',
      types: ['ui-select', 'ui-select-multiple', 'ui-date', 'ui-timepicker', 'file-upload', 'ta-editor', 'ui-radio-buttons', 'zxcvbn'],
      templateUrl: 'error-messages.html',
      overwriteOk: true
    });

    formlyConfigProvider.setWrapper({
      name: 'control-with-validation',
      types: ['ui-select', 'ui-select-multiple', 'ui-date', 'ui-timepicker', 'file-upload', 'ta-editor', 'ui-radio-buttons', 'zxcvbn'],
      templateUrl: 'core/templates/angular-formly-field-types/control-with-validation-wrapper.html',
      overwriteOk: true
    });

    formlyConfigProvider.setType({
      name: 'ui-select',
      templateUrl: 'core/templates/angular-formly-field-types/ui-select.html'
    });

    formlyConfigProvider.setType({
      name: 'ui-select-multiple',
      templateUrl: 'core/templates/angular-formly-field-types/ui-select-multiple.html'
    });

    formlyConfigProvider.setType({
      name: 'ui-date',
      templateUrl: 'core/templates/angular-formly-field-types/ui-date.html'
    });

    formlyConfigProvider.setType({
      name: 'ui-timepicker',
      templateUrl: 'core/templates/angular-formly-field-types/ui-timepicker.html'
    });

    formlyConfigProvider.setType({
      name: 'file-upload',
      templateUrl: 'core/templates/angular-formly-field-types/file-upload.html'
    });

    formlyConfigProvider.setType({
      name: 'ta-editor',
      templateUrl: 'core/templates/angular-formly-field-types/ta-editor.html'
    });

    formlyConfigProvider.setType({
      name: 'ui-radio-buttons',
      templateUrl: 'core/templates/angular-formly-field-types/ui-radio-buttons.html'
    });

    formlyConfigProvider.setType({
      name: 'zxcvbn',
      templateUrl: 'core/templates/angular-formly-field-types/zxcvbn.html',
      /* @ngInject */
      controller: function($scope) {
        $scope.fullData = {};
      }
    });
  }
}());
