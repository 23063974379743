(function() {
  'use strict';

  angular
    .module('tcm.dashboard.student.cohorts')
    .controller('StudentCohortsCtrl', StudentCohortsCtrl);

  function StudentCohortsCtrl($stateParams, UserCohorts, SpinnerService) {
    var vm = this, spinner;

    spinner = new SpinnerService();
    vm.cohortId = parseInt($stateParams.id, 10);

    function getCohort() {
      return UserCohorts.getCohort(vm.cohortId).then(function(cohort) {
        vm.cohort = cohort;
        getChartsUrls(vm.cohort);
      });
    }

    function getChartsUrls(cohort) {
      return UserCohorts.getChartsUrls(cohort).then(function(chartUrls) {
        if (chartUrls !== void 0) {
          vm.burnupUrl = chartUrls.burnupUrl;
          vm.donutUrl = chartUrls.donutUrl;
        }
      });
    }

    spinner.activateController([
      getCohort()
    ]);
  }
}());
