(function() {
  'use strict';

  angular
    .module('tcm.core')
    .config(httpProviderConfig);

  function httpProviderConfig($httpProvider) {
    $httpProvider.defaults.headers.common.Accept = 'application/json';
    $httpProvider.interceptors.push('authInterceptor');
  }
}());
