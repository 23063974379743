(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.institutions')
    .config(institutionsRoutesConfig);

  function institutionsRoutesConfig($stateProvider) {

    $stateProvider.state('admin.institutions', {
      url: '/institutions?name_cont',
      abstract: true,
      template: '<div ui-view="active"></div>'
    }).state('admin.institutions.list', {
      url: '',
      reloadOnSearch: false,
      views: {
        'active': {
          controller: 'AdminInstitutionsListCtrl',
          templateUrl: 'dashboard/admin/institutions/list/institutions-list.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Institutions',
        parent: 'admin.dashboard'
      }
    }).state('admin.institutions.new', {
      url: '/:id/new',
      views: {
        'active': {
          controller: 'AdminInstitutionsCreateCtrl as vm',
          templateUrl: 'dashboard/admin/institutions/create/institutions-create.html'
        }
      },
      ncyBreadcrumb: {
        label: 'New',
        parent: 'admin.institutions.list'
      }
    }).state('admin.institutions.edit', {
      url: '/:id/edit',
      views: {
        'active': {
          controller: 'AdminInstitutionsCreateCtrl as vm',
          templateUrl: 'dashboard/admin/institutions/create/institutions-create.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Edit',
        parent: 'admin.institutions.show'
      }
    }).state('admin.institutions.show', {
      url: '/:id',
      views: {
        'active': {
          controller: 'AdminInstitutionsDetailCtrl as vm',
          templateUrl: 'dashboard/admin/institutions/detail/institutions-detail.html'
        }
      },
      ncyBreadcrumb: {
        label: '{{vm.institution.institution_name}}',
        parent: 'admin.institutions.list'
      }
    }).state('admin.institutions.settings', {
      url: '/:id/settings',
      views: {
        'active': {
          controller: 'AdminInstitutionsSettingsCtrl as vm',
          templateUrl: 'dashboard/admin/institutions/settings/institutions-settings.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Settings',
        parent: 'admin.institutions.show'
      }
    });
  }
}());
