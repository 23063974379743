(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.users')
    .config(usersRoutesConfig);

  function usersRoutesConfig($stateProvider) {

    $stateProvider.state('admin.users', {
      url: '/users?first_name_cont&last_name_cont&username_cont&role_id&institution_id?active_eq',
      abstract: true,
      template: '<div ui-view="active"></div>',
      ncyBreadcrumb: {skip: true}
    }).state('admin.users.list', {
      url: '',
      reloadOnSearch: false,
      views: {
        'active': {
          controller: 'AdminUsersListCtrl as vm',
          templateUrl: 'dashboard/admin/users/list/users-list.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Users',
        parent: 'admin.dashboard'
      }
    }).state('admin.users.new', {
      url: '/new',
      views: {
        'active': {
          controller: 'AdminUsersCreateCtrl as vm',
          templateUrl: 'dashboard/admin/users/create/users-create.html'
        }
      },
      ncyBreadcrumb: {
        label: 'New',
        parent: 'admin.users.list'
      }
    }).state('admin.users.show', {
      url: '/:userId',
      views: {
        'active': {
          controller: 'AdminUsersDetailCtrl as vm',
          templateUrl: 'dashboard/admin/users/detail/users-detail.html'
        }
      },
      ncyBreadcrumb: {
        label: '{{vm.user.first_name}} {{vm.user.last_name}}',
        parent: 'admin.users.list'
      }
    }).state('admin.users.edit', {
      url: '/:userId/edit',
      views: {
        'active': {
          controller: 'AdminUsersCreateCtrl as vm',
          templateUrl: 'dashboard/admin/users/create/users-create.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Edit',
        parent: 'admin.users.show'
      }
    }).state('admin.users.cohorts', {
      url: '/:userId/cohorts',
      views: {
        'active': {
          controller: 'AdminUsersCohortsCtrl as vm',
          templateUrl: 'dashboard/admin/users/cohorts/users-cohorts.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Cohorts',
        parent: 'admin.users.show'
      }
    }).state('admin.users.competencies', {
      url: '/:userId/report/:id',
      views: {
        'active': {
          controller: 'CompetenciesReportCtrl as vm',
          templateUrl: 'reports/competencies/competencies.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Competencies',
        parent: 'admin.users.show'
      }
    }).state('admin.users.progress', {
      url: '/:userId/gradebook/:cohortId/:state?page&sort&direction&gb_page&gb_sort&gb_direction&search&weight',
      abstract: true,
      views: {
        'active': {
          template: '<div ui-view="page"><div/>'
        }
      },
      ncyBreadcrumb: {skip: true}
    }).state('admin.users.progress.details', {
      url: '',
      views: {
        'page': {
          controller: 'AdminUsersProgressCtrl as vm',
          templateUrl: 'dashboard/admin/users/progress/users-progress.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Progress',
        parent: 'admin.users.show'
      }
    }).state('admin.users.progress.activity', {
      url: '/activity/:activityId',
      views: {
        'page': {
          controller: 'AdminUsersProgressActivityCtrl as vm',
          templateUrl: 'dashboard/admin/users/progress/activity/progress-activity.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Activity',
        parent: 'admin.users.progress.details'
      }
    }).state('admin.users.notes', {
      url: '/:userId/notes',
      abstract: true,
      ncyBreadcrumb: {skip: true},
      views: {
        'active': {
          template: '<div ui-view="page"><div/>'
        }
      }
    }).state('admin.users.notes.list', {
      url: '',
      views: {
        'page': {
          controller: 'NotesListCtrl as vm',
          templateUrl: 'notes/list/notes-list.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Notes',
        parent: 'admin.users.show'
      }
    }).state('admin.users.notes.new', {
      url: '/new',
      views: {
        'page': {
          controller: 'NotesCreateCtrl as vm',
          templateUrl: 'notes/create/notes-create.html'
        }
      },
      ncyBreadcrumb: {
        label: 'New',
        parent: 'admin.users.notes.list'
      }
    }).state('admin.users.notes.edit', {
      url: '/:noteId/edit',
      views: {
        'page': {
          controller: 'NotesCreateCtrl as vm',
          templateUrl: 'notes/create/notes-create.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Edit',
        parent: 'admin.users.notes.list'
      }
    }).state('admin.users.webexSessions', {
      url: '/:userId/webexsessions',
      abstract: true,
      ncyBreadcrumb: {skip: true},
      views: {
        'active': {
          template: '<div ui-view="page"><div/>'
        }
      }
    }).state('admin.users.webexSessions.new', {
      url: '/new',
      views: {
        'page': {
          controller: 'WebexSessionsCreateCtrl as vm',
          templateUrl: 'webex-sessions/create/webex-sessions-create.html'
        }
      }
    });
  }
}());
