(function() {
  'use strict';

  angular
    .module('tcm.common')
    .directive('eoSupportPop', eoSupportPop);

  function eoSupportPop(ZenboxService) {
    ZenboxService.init();

    return {
      restrict: 'A',
      link: function(scope, element) {
        element.bind('click', ZenboxService.launch);
      }
    };
  }
}());
