(function() {
  'use strict';

  angular
    .module('tcm.common')
    .directive('eoActivationToggle', eoActivationToggle);

  function eoActivationToggle() {
    return {
      restrict: 'E',
      templateUrl: 'common/directives/eo-activation-toggle/eo-activation-toggle.html',
      scope: {
        'obj': '=',
        'resource': '@'
      },
      controller: eoActivationToggleCtrl
    };

    function eoActivationToggleCtrl($scope, $element, $attrs, $uibModal, ApiRestangular, UserService) {
      var resources = $attrs.resource.split('|');

      UserService.canAccess(['MANAGE_ACTIVATION'], resources).then(function(hasPermission) {
        if (!hasPermission) {
          var buttons = $element.find('button');
          angular.element(buttons[0]).attr('disabled', 'disabled');
          angular.element(buttons[1]).attr('disabled', 'disabled');
        }
      });

      $scope.service = ApiRestangular.service($scope.resource);

      $scope.$watch('obj.active', function(newValue, oldValue) {
        $scope.oldValue = oldValue;
        return newValue;
      });

      function revertActivation() {
        $scope.obj.active = $scope.oldValue;
      }

      $scope.toggleActivation = function() {
        if (!$scope.obj.active) {
          var modalInstance = $uibModal.open({
            templateUrl: 'common/directives/eo-activation-toggle/eo-activation-toggle-pop.html',
            controller: function($scope) {
              $scope.data = {};

              $scope.ok = function() {
                $scope.$close();
              };
            }
          });

          // Handle cancel on modal and patch errors
          modalInstance.result.then(function() {
            $scope.service.one($scope.obj.id).one('deactivate').patch()
              .catch(revertActivation);
          })
          .catch(revertActivation);

        } else {
          $scope.service.one($scope.obj.id).one('activate').patch();
        }
        return $scope.obj.active;
      };
    }
  }
}());
