(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('Cohort', CohortResource);

  function CohortResource(ApiRestangular) {
    return ApiRestangular.withConfig(function(RestangularConfigurer) {
      RestangularConfigurer.setFullResponse(true);
      RestangularConfigurer.setDefaultHeaders({'Content-Type': 'application/json'});
    }).service('cohorts');
  }
}());
