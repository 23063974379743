(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoInstitutionLibraries', eoInstitutionLibraries);

  function eoInstitutionLibraries(Library, TableFeaturesService,
    Institution, HasManyService, $stateParams, SpinnerService
  ) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-institution-libraries/eo-institution-libraries.html',
      scope: {},
      controller: eoInstitutionLibrariesCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoInstitutionLibrariesCtrl() {
      var vm = this, spinner, institutionId, librariesTable, libraries;

      spinner = new SpinnerService();
      institutionId = parseInt($stateParams.id, 10);
      vm.activeTab = {
        assigned: true
      };

      librariesTable = new TableFeaturesService({
        search: false,
        sorting: {column: 'name'},
        pagination: {
          perPage: 10,
          scrollTop: false
        },
        loadData: function(queryParams) {
          if (vm.activeTab.available) {
            return Library.getList(queryParams);
          } else {
            return Institution.one(institutionId).all('libraries').getList(queryParams);
          }
        }
      });

      vm.librariesTable = {
        paginationData: librariesTable.paginationParams,
        sortingData: librariesTable.sortingParams
      };

      libraries = new HasManyService({
        onAdd: function(element) {
          spinner.activateController([
            Institution.one(institutionId).one('libraries', element.id).put()
          ]);
        },
        onRemove: function(element) {
          spinner.activateController([
            Institution.one(institutionId).one('libraries', element.id).remove()
          ]);
        }
      });

      vm.availableLibraries = libraries.data.available;
      vm.assignedLibraries = libraries.data.selected;

      vm.getTabData = function(tab) {
        setActiveTab(tab);
        librariesTable.getNewData().then(function(response) {
          if (vm.activeTab.assigned) {
            libraries.resetSelected(response[0].data);
          } else {
            libraries.resetAvailable(filterAssignedLibraries(response[0].data, libraries.data.selected));
          }
        });
      };

      vm.loadLibraries = function() {
        librariesTable.getPageData().then(function(response) {
          if (vm.activeTab.assigned) {
            libraries.resetSelected(response[0].data);
          } else {
            libraries.resetAvailable(filterAssignedLibraries(response[0].data, libraries.data.selected));
          }
        });
      };

      vm.linkLibrary = function(library) {
        var index = _.indexOf(vm.availableLibraries, library);
        libraries.add(library);
        vm.availableLibraries.splice(index, 1);
      };

      vm.unlinkLibrary = function(library) {
        libraries.remove(library);
      };

      function setActiveTab(tab) {
        if (tab) {
          vm.activeTab = {};
          vm.activeTab[tab] = true;
        }
      }

      function filterAssignedLibraries(available, selected) {
        return _.filter(available, function(availableItem) {
          return _.every(selected, function(selectedItem) {
            return selectedItem.id !== availableItem.id;
          });
        });
      }

      vm.loadLibraries();
    }
  }
}());
