(function() {
  'use strict';

  angular
    .module('tcm.auth.password')
    .controller('PasswordForgotCtrl', PasswordForgotCtrl);

  function PasswordForgotCtrl($scope, UserService) {
    $scope.resetPassword = function() {
      UserService.resetPassword($scope.username)
        .then(function() {
          $scope.success = true;
        })
        .catch(function(response) {
          $scope.errors = response.data.errors;
        });
    };

    $scope.hasErrors = function(field) {
      return $scope.errors && $scope.errors[field];
    };
  }
}());
