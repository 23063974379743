(function() {
  'use strict';

  angular
    .module('tcm.core')
    .config(tagsInputConfigProviderConfig);

  function tagsInputConfigProviderConfig(tagsInputConfigProvider) {
    tagsInputConfigProvider
      .setDefaults('tagsInput', {
        placeholder: 'New tag',
        maxLength: 50
      })
      .setTextAutosizeThreshold(15);
  }
}());
