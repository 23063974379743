(function() {
  'use strict';

  angular
    .module('tcm.auth', [
      'tcm.core',
      'tcm.common',
      'tcm.EnvConfig',
      'tcm.auth.password'
    ]);
}());
