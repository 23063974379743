/* globals Zenbox:false */
(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('ZenboxService', ZenboxService);

  function ZenboxService() {

    return {
      init: function() {
        if (angular.isDefined(Zenbox)) {
          Zenbox.init({
            dropboxID:   '20190344',
            url:         'https://isgengage.zendesk.com',
            hide_tab:    true
          });
        }
      },

      launch: function(event) {
        if (angular.isDefined(Zenbox)) {
          Zenbox.show(event);
        }
      }
    };
  }
}());
