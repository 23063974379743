(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoLearningActivityCardsList', eoLearningActivityCardsList);

  function eoLearningActivityCardsList() {
    return {
      restrict: 'E',
      scope: { },
      controller: eoLearningActivityCardsListCtrl,
      controllerAs: '$ctrl'
    };

    function eoLearningActivityCardsListCtrl($scope, $stateParams, $interval, $element) {
      var $ctrl = this, cardElements = [], isSearchList, intervalPromise;

      isSearchList = $stateParams.state === 'search';

      $ctrl.setCardElement = function(cardElement) {
        cardElements.push(cardElement);
      };

      $ctrl.unsetCardElement = function(cardElement) {
        var index = _.indexOf(cardElements, cardElement);
        cardElements.splice(index, 1);
      };

      $ctrl.updateCardState = function(updatedActivity) {
        var index = _.findIndex(cardElements, function(cardEl) {
          return cardEl.isolateScope().card.activity.id === updatedActivity.id;
        });

        if (index > -1) {
          cardElements[index].isolateScope().card.activity = updatedActivity;
        }
      };

      function refreshPage() {
        intervalPromise = $interval(function() {

          if (isSearchList) {
            $scope.$emit('activities-refresh:search');
          } else {
            $scope.$emit('activities-refresh:objective');
          }
        }, 300000);
      }

      //refresh page for every 5 minutes
      refreshPage();
      $element.on('$destroy', function() {
        $interval.cancel(intervalPromise);
        delete $stateParams.search;
      });
    }
  }
}());
