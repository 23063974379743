(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('Objective', ObjectiveResource);

  function ObjectiveResource(ApiRestangular) {
    return ApiRestangular.withConfig(function(RestangularConfigurer) {
      RestangularConfigurer.setFullResponse(true);
    }).service('objectives');
  }
}());
