(function() {
  'use strict';

  angular
    .module('tcm.auth')
    .controller('ProfileDetailsCtrl', ProfileDetailsCtrl);

  function ProfileDetailsCtrl(UserService, User, PaginationService,
    SpinnerService, DashboardRouting, $state) {

    var vm = this, spinner, pagination;

    spinner = new SpinnerService();
    pagination = new PaginationService({perPage: 100});

    function loadProfile() {
      return UserService.loadProfile().then(function(profile) {
        vm.profile = profile;
        vm.hasStudentDashboard = UserService.hasRole('Student');
        vm.hasInstructorDashboard = UserService.hasRole('Instructor');
        vm.hasSuccessCoachDashboard = UserService.hasRole('Success Coach');

        loadUserCoursesEnrollment(profile.id);
        loadUserProgramsEnrollment(profile.id);
      });
    }

    function loadUserCoursesEnrollment(userId) {
      var queryParams = {};
      pagination.addParams(queryParams);

      if (UserService.hasRole('Student') || UserService.hasRole('Instructor')) {
        return User.one(userId).getList('cohorts', queryParams).then(function(response) {
          vm.courses = response.data;
        });
      }
    }

    function loadUserProgramsEnrollment(userId) {
      var queryParams = {};
      pagination.addParams(queryParams);

      if (UserService.hasRole('Student') || UserService.hasRole('Instructor')) {
        return User.one(userId).getList('programs', queryParams).then(function(response) {
          vm.programs = response.data;
        });
      }
    }

    vm.redirectToUserEditProfile = function() {
      if ($state.includes('admin.**')) {
        $state.go('admin.profile.edit');
      } else if ($state.includes('student.**')) {
        $state.go('student.profile.edit');
      } else if ($state.includes('successcoach.**')) {
        $state.go('successcoach.profile.edit');
      } else {
        $state.go('instructor.profile.edit');
      }
    };

    vm.redirectToDashboard = function() {
      DashboardRouting.execStudentDashboardRouting();
    };

    spinner.activateController([
      loadProfile()
    ]);
  }
}());
