(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('UserService', UserService);

  function UserService($rootScope, $http, EnvConfig, TokenService, Profile,
    $timeout, $location, localStorageService, LocationTrackingService) {

    var profile = null;
    var profilePromise = null;
    var refreshTimer = null;
    var permissionResources;
    var scheduleRefresh;
    var cancelRefresh;
    var doRefresh;

    permissionResources = function(resources) {
      return _.map(resources, function(r) {
        if (r === 'activities' || r === 'objectives') {
          return 'learning_' + r;
        } else if (r === 'libraries') {
          return 'competency_libraries';
        } else {
          return r;
        }
      });
    };

    scheduleRefresh = function(expiresInSeconds) {
      // Get a new token once half the expiration time has elapsed
      // 500 = expires_in (seconds) converted to milliseconds (x 1000), divided by 2
      refreshTimer = $timeout(function() {
        doRefresh();
      }, expiresInSeconds * 500);
      return refreshTimer;
    };

    cancelRefresh = function() {
      // Cancel the token refresh
      if (refreshTimer) {
        $timeout.cancel(refreshTimer);
        refreshTimer = null;
      }
    };

    doRefresh = _.throttle(function(refreshToken) {
      // POST refresh token to the Auth Server, and place the new token into the TokenService.
      return $http.post(EnvConfig.main_url + '/oauth/token', {
        grant_type: 'refresh_token',
        refresh_token: refreshToken || TokenService.getRefreshToken()
      })
        .success(function refreshSuccess(result) {
          TokenService.setToken(result);
          // Schedule a subsequent refresh for the new token
          scheduleRefresh(result.expires_in);
        });
    }, 2000, {trailing: false});

    function getUserProfile() {
      return Profile.one().get().then(function(response) {
        profile = response.data;
        return profile;
      }, function() {
        profilePromise = null;
        return null;
      });
    }

    function getSearchData(searchData, status) {
      if (status === 'all') {
        searchData.state_eq = null;
        searchData.m = null;
        searchData.g = null;
      } else if (status === 'in-progress') {
        searchData.state_eq = null;
        searchData.m = 'or';
        searchData.g = [{
          state_eq: 'in_progress'
        }, {
          state_eq: 'failed'
        }];
      } else {
        searchData.m = null;
        searchData.g = null;
        searchData.state_eq = status;
      }

      return searchData;
    }

    function loadProfile() {
      if (!profilePromise) {
        // Logging in through LTI
        if (!TokenService.hasAccessToken() && $location.search().refresh_token) {
          profilePromise = doRefresh($location.search().refresh_token).then(function(result) {
            TokenService.setToken(result.data);
            return getUserProfile();
          });
        } else {
          profilePromise = getUserProfile();
        }
      }
      return profilePromise;
    }

    function loadNewProfile() {
      profile = null;
      profilePromise = null;
      return loadProfile().then(function(profile) {
        if (profile) {
          $rootScope.$broadcast('update-user-name', profile);
        }
      });
    }

    function clearUserProfile() {
      profile = null;
      profilePromise = null;
    }

    function removeLocalStorage() {
      localStorageService.remove('displayCards');
      localStorageService.remove('role');
    }

    return {
      signin: function(username, password) {
        return $http.post(EnvConfig.main_url + '/oauth/token', {
          grant_type: 'password',
          username: username,
          password: password
        })
          .success(function signinSuccess(result) {
            localStorageService.remove('isMasqueradeMode');
            // when  a user uses 2 tabs and then sign out from one tab, another
            // tab stores profile reference (see DE3367)
            clearUserProfile();
            TokenService.setToken(result);
            scheduleRefresh(result.expires_in);
          });
      },

      signout: function() {
        removeLocalStorage();
        return LocationTrackingService.onExit().then(function() {
          return $http.post(EnvConfig.main_url + '/oauth/revoke', {
            token: TokenService.getAccessToken()
          }).then(function signOutSuccess() {
            TokenService.removeToken();
            clearUserProfile();
            cancelRefresh();
          });
        });
      },

      signInAsStudent: function(studentId, cohortId, userId, role) {
        return $http.post(EnvConfig.main_url + '/api/v1/masquerade', {
          student_id: studentId
        }).then(function(result) {
          TokenService.setToken(result.data);
          loadNewProfile();
          scheduleRefresh(result.data.expires_in);
          localStorageService.set('isMasqueradeMode', true);
          if (cohortId) {
            localStorageService.set('sourceCohort', cohortId);
          }
          if (userId) {
            localStorageService.set('sourceUser', userId);
          }
          if (role) {
            localStorageService.set('masqueradeRole', role);
          }
        });
      },

      signOutAsStudent: function() {
        return $http.post(EnvConfig.main_url + '/api/v1/masquerade/revoke', {
          instructor_id: TokenService.getTokenInstructorId()
        }).then(function(result) {
          TokenService.removeToken();
          loadNewProfile();
          scheduleRefresh(result.data.expires_in);
          $rootScope.$broadcast('cache:invalidate');
          localStorageService.remove('isMasqueradeMode');
          localStorageService.remove('sourceCohort');
          localStorageService.remove('sourceUser');
        });
      },

      isMasqueradeMode: function() {
        return localStorageService.get('isMasqueradeMode');
      },

      changePassword: function(password, passwordConfirmation, resetPasswordToken) {
        return $http.put(EnvConfig.main_url + '/api/v1/password', {
          password: password,
          password_confirmation: passwordConfirmation,
          reset_password_token: resetPasswordToken
        }).then(function(response) {
          // Success returns OAuth token... place in the token service
          TokenService.setToken(response.data);
          scheduleRefresh(response.data.expires_in);
        });
      },

      refreshToken: doRefresh,

      resetPassword: function(username) {
        return $http.post(EnvConfig.main_url + '/api/v1/password', {
          username: username
        });
      },

      signedIn: function() {
        return TokenService.hasAccessToken();
      },

      can: function(operation, resource, identifier) {
        if (profile) {
          return _.some(profile.rights, function(nextRight) {
            // TODO Take identifier into account
            return nextRight.resource === resource && nextRight.operation === operation;
          });
        } else {
          var self = this;
          return this.loadProfile().then(function() {
            return self.can(operation, resource, identifier);
          });
        }
      },

      canAccess: function(operations, resources) {
        return this.loadProfile().then(function(profile) {
          return _.every(permissionResources(resources), function(resource) {
            // fetch an array of rights for a particular resource
            var rights = _.filter(profile.rights, {'resource': resource});
            if (rights) {
              return _.some(rights, function(right) {
                return _.indexOf(operations, right.operation) > -1;
              });
            }
            return false;
          });
        });
      },

      getRoles: function() {
        return (profile && profile.roles) ? profile.roles : [];
      },

      hasRole: function(role) {
        if (profile && profile.roles) {
          return _.some(profile.roles, function(profileRole) {
            return profileRole.title === role;
          });
        } else {
          return false;
        }
      },

      saveProfile: function(data) {
        return $http({
          method: 'PATCH',
          url: EnvConfig.main_url + '/api/v1/profile',
          data: data
        }).then(function(response) {
          profile = response.data;
          return profile;
        });
      },

      loadProfile: loadProfile,
      getSearchData: getSearchData,
      loadNewProfile: loadNewProfile
    };
  }
}());
