(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('MessagesService', MessagesService);

  function MessagesService($state, TableFeaturesService, Message, Notification, UserService) {
    var service = this, tableFeatures, tableFeaturesForPreview;

    tableFeatures = new TableFeaturesService({
      search: false,
      sorting: false,
      pagination: {
        scrollTop: false,
        perPage: 15
      },
      loadData: function(queryParams) {
        return Message.getList(queryParams);
      }
    });

    tableFeaturesForPreview = new TableFeaturesService({
      pagination: false,
      sorting: false,
      loadData: function(queryParams) {
        queryParams.only_unread = true;
        queryParams.limit = 3;
        return Message.getList(queryParams);
      }
    });

    service.paginationData = tableFeatures.paginationParams;

    service.createMessage = function(queryParams) {
      return Message.post(queryParams);
    };

    service.getUserMessages = function() {
      return tableFeatures.getPageData();
    };

    service.getUnreadMessagesCount = function() {
      return Message.one('unread_count').get();
    };

    service.getUnreadMessagesForPreview = function(queryParams) {
      return tableFeaturesForPreview.getPageData(queryParams);
    };

    service.getMessage = function(messageId) {
      return Notification.one(messageId).get();
    };

    service.markRead = function(ids) {
      return Notification.one('mark_read').patch({ids: ids });
    };

    service.markUnread = function(ids) {
      return Notification.one('mark_unread').patch({ids: ids });
    };

    service.deleteMessages = function(ids) {
      return Notification.one().customDELETE('', {'notification_ids[]': ids});
    };

    service.resetPagination = function() {
      tableFeatures._pagination.reset();
    };

    service.canRead = function() {
      return UserService.canAccess(['READ'], ['announcements']);
    };

    service.canCompose = function() {
      return ($state.includes('instructor.**') || $state.includes('student.**')) && !UserService.isMasqueradeMode();
    };
  }
}());
