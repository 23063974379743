/*globals moment:false */
(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.users')
    .controller('AdminUsersListCtrl', AdminUsersListCtrl);

  function AdminUsersListCtrl($stateParams, $location, Institution,
    SpinnerService, SortingService, Role, User, TableFeaturesService, SearchService, $state, $uibModal) {

    var vm = this, spinner, sorting, cohortId, contextId;

    spinner = new SpinnerService();
    sorting = new SortingService({column: 'name'});
    cohortId = $stateParams.id;
    contextId = $stateParams.contextId;

    vm.startDate = {dateFormat: 'yy-mm-dd'};
    vm.endDate = {dateFormat: 'yy-mm-dd'};

    var tableFeatures = new TableFeaturesService({
      sorting: {column: 'name_i'},
      search: {
        'first_name_cont': $stateParams.first_name_cont,
        'last_name_cont': $stateParams.last_name_cont,
        'username_cont': $stateParams.username_cont,
        'email_cont': $stateParams.email_cont,
        'role_id': parseInt($stateParams.role_id, 10) || undefined,
        'institution_id': parseInt($stateParams.contextId, 10) || undefined,
        'owner_institution_id_eq': $stateParams.contextId,
        'active': $stateParams.active || undefined,
        'active_eq': $stateParams.active_eq || undefined
      },
      loadData: function(queryParams) {
        return User.getList(queryParams);
      }
    });

    vm.isfilterCollapsed = true;

    vm.userList = {
      searchData: tableFeatures.searchParams,
      sortingData: tableFeatures.sortingParams,
      paginationData: tableFeatures.paginationParams
    };

    vm.loadPage = function() {
      return tableFeatures.getPageData().then(function(response) {
        vm.users = response[0].data;
      });
    };

    vm.search = function() {
      tableFeatures.resetEmptySearchData();
      $location.search(vm.userList.searchData);
      tableFeatures.getNewData().then(function(response) {
        vm.users = response[0].data;
      });
    };

    vm.reset = function() {
      tableFeatures.resetSearch();
      vm.userList.searchData.owner_institution_id_eq = parseInt($stateParams.contextId, 10);
      vm.userList.searchData.institution_id = parseInt($stateParams.contextId, 10);
      vm.search();
    };

    vm.disableClearOption = function() {
      var disable = true;

      _.each(vm.userList.searchData, function(n, key) {
        if ((key !== 'owner_institution_id_eq' && key !== 'institution_id') && (!_.isEmpty(vm.userList.searchData[key]) || _.isNumber(vm.userList.searchData[key]))) {
          disable = false;
        }
      });

      return disable;
    };

    function loadInstitutions() {
      var queryParams = {};
      sorting.addParams(queryParams);
      Institution.getList(queryParams).then(function(response) {
        vm.institutions = response.data;
      });
    }

    function isFutureCohort(startsOn) {
      return startsOn !== new Date().toISOString().slice(0, 10) && moment(startsOn).isAfter(new Date(), 'day');
    }

    function loadCohort() {
      if ($state.is('admin.cohorts.enroll')) {
        vm.isCohortView = true;
        spinner.activateController([
          Institution.one(contextId).one('cohorts', cohortId).get().then(function(response) {
            vm.cohort = response.data;
            vm.startDate.minDate = response.data.starts_on;
            vm.startDate.maxDate = response.data.ends_on;

            vm.endDate.minDate = vm.startDate.maxDate;
            vm.userList.searchData.active_eq = true;
            vm.search();
          })
        ]);
      }
    }

    function loadRoles() {
      Role.getList().then(function(response) {
        vm.roles = response.data;
      });
    }

    vm.enrollToCohort = function(userId) {
      var modalInstance = $uibModal.open({
        templateUrl: 'dashboard/admin/users/modals/enroll-cohort.html',
        resolve: {
          cohorts: function() {
            var search, queryString;
            queryString = {};
            search = new SearchService({
              published_eq: true,
              ends_on_gteq: new Date().toISOString().slice(0, 10)
            });
            search.addParams(queryString);
            return [vm.cohort];
          }
        },
        controllerAs: 'vm',
        controller: function($scope, cohorts) {
          var vm = this;

          vm.cohorts = cohorts;
          vm.model = {};
          vm.model.id = vm.cohorts[0].id;

          var cohort = vm.cohorts[0];
          vm.model.starts_on = cohort.starts_on;
          vm.model.ends_on = cohort.ends_on;
          vm.model.name = cohort.name;
          vm.model.rolling = cohort.rolling;
                    // rolling cohort case
          if (cohort.rolling) {
            vm.model.user_started_on = isFutureCohort(vm.model.starts_on) ? vm.model.starts_on : new Date().toISOString().slice(0, 10);
                    // standard cohort case
          } else {
            vm.model.user_started_on = vm.model.starts_on;
          }
          vm.fields = [
            {
              key: 'id',
              type: 'ui-select',
              templateOptions: {
                required: true,
                label: 'Cohorts',
                options: vm.cohorts,
                placeholder: 'Select a cohort',
                valueProp: 'id',
                labelProp: 'name',
                onChange: function($viewValue) {
                  delete vm.model.user_started_on;

                  if ($viewValue !== void 0) {
                    var cohort = _.find(vm.cohorts, {id: $viewValue});
                    vm.model.starts_on = cohort.starts_on;
                    vm.model.ends_on = cohort.ends_on;
                    vm.model.name = cohort.name;
                    vm.model.rolling = cohort.rolling;
                    // rolling cohort case
                    if (cohort.rolling) {
                      vm.model.user_started_on = isFutureCohort(vm.model.starts_on) ? vm.model.starts_on : new Date().toISOString().slice(0, 10);
                    // standard cohort case
                    } else {
                      vm.model.user_started_on = vm.model.starts_on;
                    }
                  }
                }
              },
              expressionProperties: {
                'templateOptions.disabled': 'true'
              }
            },
            {
              key: 'user_role',
              type: 'radio',
              defaultValue: 'Student',
              templateOptions: {
                label: 'Select role within the cohort',
                options: [
                  {name: 'Student', value: 'Student'},
                  {name: 'Instructor', value: 'Instructor'}
                ]
              }
            },
            {
              key: 'drc_accommodation',
              type: 'ui-select',
              defaultValue: '100',
              templateOptions: {
                label: 'DRC',
                options: [
                  {id: 0, value: 0},
                  {id: 100, value: 100},
                  {id: 150, value: 150},
                  {id: 200, value: 200}
                ],
                placeholder: 'Select a value',
                valueProp: 'id',
                labelProp: 'value'
              },
              hideExpression: 'model.user_role === "Instructor"'
            },
            {
              key: 'user_started_on',
              type: 'ui-date',
              templateOptions: {
                required: true,
                label: 'Select enrollment date'
              },
              expressionProperties: {
                'templateOptions.disabled': function() {
                  return !vm.model.rolling;
                },
                'templateOptions.dateOptions.dateFormat': '"yy-mm-dd"',
                'templateOptions.dateOptions.minDate': function($viewValue, $modelValue, $scope) {
                  if (vm.model.rolling) {
                    return isFutureCohort($scope.model.starts_on) ? $scope.model.starts_on : new Date().toISOString().slice(0, 10);
                  } else {
                    return $scope.model.starts_on;
                  }
                },
                'templateOptions.dateOptions.maxDate': 'model.ends_on'
              }
            }
          ];

          vm.cancel = function() {
            $scope.$dismiss('close');
          };

          vm.save = function() {
            if (vm.enrollInCohortForm.$valid) {
              $scope.$close(vm.model);
            }
          };
        }
      });

      modalInstance.result.then(function(cohort) {
        var queryString = {
          started_on: cohort.user_started_on
        };

        if (cohort.user_role === 'Student') {
          queryString.drc_accommodation = cohort.drc_accommodation;
        }
        queryString.allow_re_enroll = false;

        spinner.activateController([
          Institution.one(contextId).one('cohorts', cohort.id).one('enrollments', userId).one(cohort.user_role).put(queryString).then(function(response) {
            if (response.data.already_enrolled) {
              vm.flash = 'User is already enrolled as a ' + response.data.role + ' . Please unenroll and try again if you need to change the role of the user';
            } else {
              $state.go('admin.cohorts.show', {id: cohort.id});
            }
          })
        ]);
      });
    };

    spinner.activateController([
      loadInstitutions(),
      loadRoles(),
      vm.loadPage(),
      loadCohort()
    ]);
  }
}());
