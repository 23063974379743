(function() {
  'use strict';

  angular
    .module('tcm.notifications')
    .controller('NotificationsListCtrl', NotificationsListCtrl);

  function NotificationsListCtrl($scope, NotificationsService, $location,
    $rootScope) {
    var vm = this, notificationsPage = parseInt($location.search().page, 10);

    vm.notificationsTable = NotificationsService.paginationData;

    vm.getUserNotifications = function() {
      NotificationsService.getUserNotifications(notificationsPage).then(function(response) {
        vm.notifications = response[0].data;
      });
    };

    vm.getNewUserNotifications = function() {
      NotificationsService.getNewUserNotifications().then(function(response) {
        vm.notifications = response[0].data;
      });
    };

    $scope.$on('UserNotificationsCtrl:reload', function() {
      NotificationsService.invalidateCache();
      vm.getUserNotifications();
    });

    vm.getUserNotifications();
    // invalidate cached objectives
    $rootScope.$broadcast('user-objectives-cache:invalidate');
  }
}());
