/*jshint loopfunc:true*/
(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoRubricForGradeTable', eoRubricForGradeTable);

  function eoRubricForGradeTable() {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-rubric-for-grade-table/eo-rubric-for-grade-table.html',
      scope: {
        rubric: '=',
        selectedRules: '=',
        isValid: '=?',
        isReadOnly: '@',
        onSelectRule: '&?',
        onRuleUpdate: '&?',
        totalComments: '='
      },
      controller: eoRubricForGradeTableCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoRubricForGradeTableCtrl($scope, $state) {
      var vm = this;

      vm.isReadOnly = $scope.$eval(vm.isReadOnly);
      vm.successcoach = $state.includes('successcoach.**');

      $scope.$watch('vm.selectedRules', function(selectedRules) {
        // deselect previously selected rules
        clearPreviouslySelectedRules();

        if (selectedRules && selectedRules.length) {
          highlightSelectedRules(selectedRules);
          vm.isValid = true;
        } else {
          vm.isValid = false;
        }
      });

      vm.calculateRubricMaxPoints = function() {
        if (vm.rubric !== undefined) {
          vm.rubric.points = _(vm.rubric.criteria).map('rule_points').sum();
          return vm.rubric.points || 0;
        }
      };

      vm.selectRule = function(rule, criteria) {
        if (!vm.isReadOnly && !$state.includes('successcoach.**')) {
          criteria.rules.forEach(function(rule) { delete rule.selected; });

          rule.selected = true;
          criteria.hasSelectedRule = true;
          criteria.rule_points = rule.points;
          criteria.commentsPlaceHolder = '';

          vm.onSelectRule({rule: rule});
        }
      };

      vm.onPointChange = function(criteria) {
        vm.isValid = criteria.criteriaPointsForm.$valid ? true : false;
        vm.onRuleUpdate({criteria: criteria});
      };

      function clearPreviouslySelectedRules() {
        for (var i = 0; vm.rubric !== undefined && i <  vm.rubric.criteria.length; i++) {
          vm.rubric.criteria[i].rule_points = 0;
          delete vm.rubric.criteria[i].rule_comment;
          delete vm.rubric.criteria[i].hasSelectedRule;
          vm.rubric.criteria[i].commentsPlaceHolder = 'Please select the Ratings to enter feedback';
          vm.rubric.criteria[i].rules.forEach(function(rule) { delete rule.selected; });
        }
      }

      function highlightSelectedRules(selectedRules) {
        for (var i = 0; i < selectedRules.length; i++) {

          var criteriaIndex = _.findIndex(vm.rubric.criteria, function(criteria) {
            return criteria.id === selectedRules[i].rubric_criterium_id;
          });

          var ruleIndex = _.findIndex(vm.rubric.criteria[criteriaIndex].rules, function(rule) {
            return rule.id === selectedRules[i].rubric_rule_id;
          });

          if (ruleIndex > -1) {
            vm.rubric.criteria[criteriaIndex].rules[ruleIndex].selected = true;
            vm.rubric.criteria[criteriaIndex].hasSelectedRule = true;
            vm.rubric.criteria[criteriaIndex].rule_points = selectedRules[i].points;
            vm.rubric.criteria[criteriaIndex].rule_comment = selectedRules[i].comment;
            vm.rubric.criteria[criteriaIndex].commentsPlaceHolder = '';
            if (!vm.isReadOnly) {
              var rule = angular.copy(vm.rubric.criteria[criteriaIndex].rules[ruleIndex], {});
              rule.points  = selectedRules[i].points;
              vm.onSelectRule({rule: rule});
            }
          }
        }
      }
    }
  }
}());
