/* globals zxcvbn:false */

(function() {
  'use strict';

  angular
    .module('tcm.auth.password')
    .controller('PasswordChangeCtrl', PasswordChangeCtrl);

  function PasswordChangeCtrl($scope, $state, $location, UserService,
    SpinnerService, ValidationService) {

    var vm = this, spinner;

    spinner = new SpinnerService();

    if ($location.search().reset_password_token) {
      vm.resetPasswordToken = $location.search().reset_password_token;
    } else {
      $state.go('signin');
    }

    vm.model = {};

    vm.fields = [
      {
        key: 'password',
        type: 'zxcvbn',
        modelOptions: {
          allowInvalid: true
        },
        templateOptions: {
          placeholder: 'Password',
          maxlength: '32',
          minlength: '8',
          required: true,
          type: 'password',
          onKeyup: function(viewValue, modelValue, scope) {
            if (!viewValue) {
              scope.fullData = {};
            }
          }
        },
        validators: {
          password: function(viewValue, modelValue, scope) {
            if (viewValue) {
              scope.fullData = zxcvbn(viewValue);
            }

            return ValidationService.validatePassword();
          }
        }
      },
      {
        key: 'passwordConfirmation',
        type: 'input',
        templateOptions: {
          placeholder: 'Confirm Password',
          required: true,
          type: 'password'
        },
        validators: {
          passwordMatch: ValidationService.validatePasswordMatch()
        }
      }
    ];

    vm.save = function() {
      if (vm.changePasswordForm.$valid) {
        spinner.activateController([
          UserService.changePassword(vm.model.password, vm.model.passwordConfirmation, vm.resetPasswordToken).then(function() {
            UserService.loadNewProfile().then(function() {
              $scope.$emit('login');
            });
          }, function(response) {
            var errors = JSON.parse(response.data.errors);
            formatErrorMessages(errors);
          })
        ]);
      }
    };

    vm.hasErrors = function(field) {
      return vm.errors && vm.errors[field];
    };

    // TODO: Backend should send error messages in a consistent format to avoid such operations
    function formatErrorMessages(errors) {
      _.each(errors, function(messages, errorType) {
        errors[errorType] = _.map(messages, function(msg) {
          switch (errorType) {
            case 'password':
              return {message: 'Password ' + msg};
            case 'password_confirmation':
              return {message: 'Password Confirmation ' + msg};
          }
        });
      });
      vm.errors = errors;
    }

    spinner.activateController([
      UserService.signout()
    ]);
  }
}());
