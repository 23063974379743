(function() {
  'use strict';

  angular
    .module('tcm.core')
    .config(urlRouterProviderConfig);

  function urlRouterProviderConfig($urlRouterProvider) {
    $urlRouterProvider.otherwise('/signin');
  }
}());
