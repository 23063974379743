(function() {
  'use strict';

  angular
    .module('tcm.dashboard.instructor.cohorts')
    .controller('InstructorCohortsCtrl', InstructorCohortsCtrl);

  function InstructorCohortsCtrl($stateParams, $state, SpinnerService,
    UserService, UserCohorts, User, SessionStorageService, $location, SortingService) {
    var vm = this, spinner, cohortId, sorting;

    spinner = new SpinnerService();
    sorting = new SortingService({column: 'cohort_users.started_on', direction: 'desc'});
    cohortId =  $stateParams.id === 'all' ? 'all' : parseInt($stateParams.id, 10);

    vm.courseId = 'all';
    vm.courseName = 'All Courses';

    function getCurrentCohort() {
      var isDashboard = $stateParams.dashboard === 'dashboard';

      if (isDashboard) {
        // remove the dashboard param from $stateParams and url
        delete $stateParams.dashboard;
        $location.url($location.path());

        loadCohorts(isDashboard);
      }

      if (cohortId !== 'all') {
        UserService.loadProfile().then(function(profile) {
          User.one(profile.id).one('cohorts', cohortId).get().then(function(response) {
            vm.courseId = response.data.curriculum_id;
            vm.institutionId = response.data.institution_id;
            vm.courseName = response.data.curriculum_name;
            vm.cohortName = response.data.name;
            vm.isGradePercentage = response.data.use_activity_grade_percentage;
            loadCohorts();
          });
        });
      }
    }

    function loadCohorts(isDashboard) {
      var queryParams = {};

      sorting.addParams(queryParams);

      return UserCohorts.getGroupedCohorts('Instructor', queryParams).then(function(response) {
        vm.cohorts = response.data;

        SessionStorageService.set('eo-cohorts', JSON.stringify(vm.cohorts));

        //select the proper cohort from start
        if (isDashboard) {
          selectCohort();
        }
      });
    }

    function selectCohort() {
      if (!vm.cohorts) {
        return;
      }

      if (vm.cohorts.expired && vm.cohorts.expired.length) {
        cohortId = vm.cohorts.expired[0].id;
      }

      if (vm.cohorts.future && vm.cohorts.future.length) {
        cohortId = vm.cohorts.future[0].id;
      }

      if (vm.cohorts.current && vm.cohorts.current.length) {
        cohortId = vm.cohorts.current[0].id;
      }

      $state.go('instructor.cohorts.objectives.studentlist', {id: cohortId});
    }

    spinner.activateController([
      getCurrentCohort()
    ]);
  }
}());
