(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoAvailableActivities', eoAvailableActivities);

  function eoAvailableActivities($rootScope, $timeout, $stateParams, Objective,
    Activity, TableFeaturesService
  ) {
    return {
      templateUrl: 'components/eo-available-activities/eo-available-activities.html',
      scope: {
        managePermission: '=?',
        contextId: '=?'
      },
      controller: eoAvailableActivitiesCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoAvailableActivitiesCtrl($scope) {
      var vm = this, tableFeatures, objectiveId;

      objectiveId = parseInt($stateParams.id, 10);
      vm.checkedActivities = [];
      vm.isFilterCollapsed = true;

      $timeout(function() {
        $scope.$emit('eoAvailableActivities:handshake');
      }, 0);

      $rootScope.$on('eoAssignedActivities:handshake', function() {
        vm.manageMode = true;
      });

      $rootScope.$on('eoAvailableActivities:reload', function() {
        vm.loadAvailableActivities();
      });

      tableFeatures = new TableFeaturesService({
        sorting: false,
        search: { 
          published_eq: true,
          owner_institution_id_eq: vm.contextId
        },
        pagination: {
          perPage: 10,
          scrollTop: false
        },
        loadData: function(queryParams) {
          queryParams.objective_id = objectiveId;
          return Activity.getList(queryParams);
        }
      });

      vm.table = {
        searchData: tableFeatures.searchParams,
        paginationData: tableFeatures.paginationParams
      };

      vm.loadAvailableActivities = function() {
        vm.checkedActivities.length = 0;
        tableFeatures.getPageData().then(function(response) {
          vm.availableActivities = response[0].data;
        });
      };

      vm.search = function() {
        tableFeatures.getNewData().then(function(response) {
          vm.availableActivities = response[0].data;
        });
      };

      vm.checkActivity = function(activity) {
        if (activity.checked) {
          vm.checkedActivities.push(activity.id);
        } else {
          _.pull(vm.checkedActivities, activity.id);
        }
      };

      vm.setCheckedState = function(state) {
        vm.checkedActivities.length = 0;
        _.each(vm.availableActivities, function(activity) {
          activity.checked = state;
          if (state) {
            vm.checkedActivities.push(activity.id);
          } else {
            vm.checkedActivities.length = 0;
          }
        });
      };

      vm.addSelectedObjectives = function() {
        Objective.one(objectiveId).post('activities', {'la_ids': vm.checkedActivities}).then(function() {
          vm.loadAvailableActivities();
          vm.checkedActivities.length = 0;
          $scope.$emit('eoAssignedActivities:reload');
        });
      };

      vm.loadAvailableActivities();
    }
  }
}());
