(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.programs')
    .config(programsRoutesConfig);

  function programsRoutesConfig($stateProvider) {

    $stateProvider.state('admin.programs', {
      url: '/programs?name_cont',
      abstract: true,
      template: '<div ui-view="active"></div>'
    }).state('admin.programs.list', {
      url: '',
      reloadOnSearch: false,
      views: {
        'active': {
          controller: 'AdminProgramsListCtrl',
          templateUrl: 'dashboard/admin/programs/list/programs-list.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Programs',
        parent: 'admin.dashboard'
      }
    }).state('admin.programs.new', {
      url: '/new',
      views: {
        'active': {
          controller: 'AdminProgramsCreateCtrl as vm',
          templateUrl: 'dashboard/admin/programs/create/programs-create.html'
        }
      },
      ncyBreadcrumb: {
        label: 'New',
        parent: 'admin.programs.list'
      }
    }).state('admin.programs.edit', {
      url: '/:id/edit',
      views: {
        'active': {
          controller: 'AdminProgramsCreateCtrl as vm',
          templateUrl: 'dashboard/admin/programs/create/programs-create.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Edit',
        parent: 'admin.programs.show'
      }
    }).state('admin.programs.show', {
      url: '/:id',
      views: {
        'active': {
          controller: 'AdminProgramsDetailCtrl as vm',
          templateUrl: 'dashboard/admin/programs/detail/programs-detail.html'
        }
      },
      ncyBreadcrumb: {
        label: '{{vm.program.name}}',
        parent: 'admin.programs.list'
      }
    }).state('admin.programs.manage', {
      url: '/:id/manage',
      views: {
        'active': {
          controller: 'AdminProgramsManageCompetenciesCtrl as vm',
          templateUrl: 'dashboard/admin/programs/manage-competencies/programs-manage-competencies.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Manage',
        parent: 'admin.programs.show'
      }
    });
  }
}());
