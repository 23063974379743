(function() {
  'use strict';

  angular
    .module('tcm.auth')
    .controller('SigninCtrl', SigninCtrl);

  function SigninCtrl($scope, $state, UserService, TokenService, SpinnerService) {
    var spinner = new SpinnerService();

    $scope.signIn = function() {
      spinner.activateController([
        UserService.signin($scope.username, $scope.password)
      ]).then(function() {
        $scope.$emit('login');
      }).catch(function() {
        $scope.flash = 'Sign in failed';
      });
    };

    function checkIfUserHasValidToken() {
      if (TokenService.hasAccessToken()) {
        $state.go('dashboard');
      }
    }

    checkIfUserHasValidToken();

    spinner.activateController([]);
  }
}());
