(function() {
  'use strict';

  angular
    .module('tcm.common')
    .directive('eoShowWithAccess', eoShowWithAccess);

  function eoShowWithAccess(UserService) {
    /**
     * eoShowWithAccess directive removes element as ng-if does when user does not
     * have 'CREATE' (can be customized) right to a given resource which should be
     * provided with directive.
     *
     * ## Usage
     *
     * <button eo-show-with-access='users'>Create User</button>
     * This button would be removed for all users who does not have 'CREATE' right
     * for a 'users' resource
     *
     * In case when we need to check a few resources and rights to them resources should be
     * divided by pipe as rights which are mapped to them.
     *
     * ## Usage
     *
     * <button eo-show-with-access='users|cohorts' permission='CREATE|READ'>Create User</button>
     * This button would be removed for all users who does not have 'CREATE' right for a 'users'
     * resource and 'READ' right for a 'cohorts' resource.
     *
     * # Custom permission
     *
     * If there is a case when element should be removed according to a custom
     * user's permission ('CREATE' by default). eoShowWithAccess has
     * an attribute 'permission' which will replace the default value.
     *
     * ## Usage
     *
     * <button eo-show-with-access='users' permission='UPDATE'>Create User</button>
     * This button would be disabled for all users who does not have 'UPDATE' right
     * for a 'users' resource.
     */

    return {
      transclude: 'element',
      scope: true,
      link: function(scope, element, attrs, ctr, transclude) {
        var resources, permissions;
        resources = attrs.eoShowWithAccess.split('|');
        permissions = (attrs.permission) ? attrs.permission.split('|') : ['CREATE'];

        UserService.canAccess(permissions, resources).then(function(hasPermission) {
          if (hasPermission) {
            transclude(scope, function(clone) {
              element.after(clone);
            });
          }
        });
      }
    };
  }
}());
