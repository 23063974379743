(function() {
  'use strict';

  angular
    .module('tcm.dashboard.student')
    .config(studentRoutesConfig);

  function studentRoutesConfig($stateProvider) {
    $stateProvider
      .state('student', {
        url: '/student',
        abstract: true,
        ncyBreadcrumb: {skip: true},
        views: {
          'main': {
            template: '<div ui-view="active"><div/>'
          }
        },
        resolve: {
          auth: function(SecurityManagerService, $state) {
            SecurityManagerService.authorize(['isStudent']).then(function(hasPermission) {
              if (!hasPermission) {
                $state.go('dashboard');
              }
            });
          }
        }
      }).state('student.notifications', {
        url: '/notifications',
        views: {
          'active': {
            controller: 'NotificationsListCtrl as vm',
            templateUrl: 'notifications/list/notifications-list.html'
          }
        }
      }).state('student.notifications.detail', {
        url: '/:id?page',
        reloadOnSearch: false,
        views: {
          'detail': {
            controller: 'NotificationsListCtrl as vm',
            templateUrl: 'notifications/list/notifications-list.html'
          }
        }
      }).state('student.messages', {
        url: '/messages',
        abstract: true,
        ncyBreadcrumb: {skip: true},
        views: {
          'active': {
            template: '<div ui-view="page"><div/>'
          }
        }
      }).state('student.messages.list', {
        url: '',
        views: {
          'page': {
            controller: 'MessagesListCtrl as vm',
            templateUrl: 'messages/list/messages-list.html'
          }
        }
      }).state('student.messages.new', {
        url: '/new',
        views: {
          'page': {
            controller: 'MessagesCreateCtrl as vm',
            templateUrl: 'messages/create/messages-create.html'
          }
        }
      }).state('student.messages.detail', {
        url: '/:id',
        views: {
          'page': {
            controller: 'MessagesDetailCtrl as vm',
            templateUrl: 'messages/detail/messages-detail.html'
          }
        }
      }).state('student.webexSessions', {
        url: '/webexsessions',
        abstract: true,
        ncyBreadcrumb: {skip: true},
        views: {
          'active': {
            template: '<div ui-view="page"><div/>'
          }
        }
      }).state('student.webexSessions.list', {
        url: '',
        views: {
          'page': {
            controller: 'WebexSessionsListCtrl as vm',
            templateUrl: 'webex-sessions/list/webex-sessions-list.html'
          }
        }
      }).state('student.webexSessions.new', {
        url: '/new',
        views: {
          'page': {
            controller: 'WebexSessionsCreateCtrl as vm',
            templateUrl: 'webex-sessions/create/webex-sessions-create.html'
          }
        }
      }).state('student.webexSessions.detail', {
        url: '/:id?page',
        reloadOnSearch: false,
        views: {
          'page': {
            controller: 'WebexSessionsListCtrl as vm',
            templateUrl: 'webex-sessions/list/webex-sessions-list.html'
          }
        }
      }).state('student.info', {
        url: '/info',
        views: {
          'active': {
            templateUrl: 'error/error-student-info.html'
          }
        }
      }).state('student.profile', {
        url: '/profile',
        abstract: true,
        views: {
          'active': {
            template: '<div ui-view="profile"></div>'
          }
        }
      }).state('student.profile.show', {
        url: '',
        views: {
          'profile': {
            controller: 'ProfileDetailsCtrl as vm',
            templateUrl: 'auth/profile/detail/profile-detail.html'
          }
        }
      }).state('student.profile.edit', {
        url: '/edit',
        views: {
          'profile': {
            controller: 'ProfileEditCtrl as vm',
            templateUrl: 'auth/profile/edit/profile-edit.html'
          }
        }
      });
  }
}());
