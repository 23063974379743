(function() {
  'use strict';

  angular
    .module('tcm.common')
    .filter('capitalize', CapitalizeFilter);

  function CapitalizeFilter() {
    return function(input, format) {
      if (!input) {
        return input;
      }
      format = format || 'first';
      if (format === 'first') {
        // Capitalize the first letter of a sentence
        return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
      } else if (format === 'all') {
        // Capitalize the first letter of each word from a sentence
        return input.toLowerCase().replace(/(^|[^a-z0-9-])([a-z])/g, function(m, m1, m2) {
          return m1 + m2.toUpperCase();
        });
      } else {
        var words = input.split(' ');
        var result = [];
        words.forEach(function(word) {
          if (word.length === 2 && format === 'team') {
            // Uppercase team abbreviations like FC, CD, SD
            result.push(word.toUpperCase());
          } else {
            result.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
          }
        });
        return result.join(' ');
      }
    };
  }
}());
