(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('NotificationsService', NotificationsService);

  function NotificationsService(SpinnerService, TableFeaturesService, $q,
    $rootScope, Notification, $location, UserService) {
    var service = this, tableFeatures, tableFeaturesForPreview, cachedPromise, spinner;

    spinner = new SpinnerService();

    tableFeatures = new TableFeaturesService({
      search: false,
      sorting: false,
      pagination: {
        scrollTop: false
      },
      loadData: function(queryParams) {
        return Notification.getList(queryParams);
      }
    });

    tableFeaturesForPreview = new TableFeaturesService({
      pagination: false,
      sorting: false,
      loadData: function(queryParams) {
        queryParams.only_unread = true;
        queryParams.limit = 3;
        return Notification.getList(queryParams);
      }
    });

    service.paginationData = tableFeatures.paginationParams;

    service.getUserNotifications = function(pageNumber) {
      return $q(function(resolve) {
        if (!cachedPromise) {
          if (pageNumber) {
            tableFeatures.paginationParams.currentPage = pageNumber;
          }
          tableFeatures.getPageData().then(function(response) {
            cachedPromise = response;
            resolve(cachedPromise);
          });
        } else {
          //FIXME: spinner
          spinner.activateController([]);
          resolve(cachedPromise);
        }
      });
    };

    service.getUnreadNotificationsCount = function() {
      return Notification.one('unread_count').get();
    };

    service.getUserNotificationsForPreview = function() {
      return tableFeaturesForPreview.getPageData();
    };

    service.getNewUserNotifications = function() {
      service.invalidateCache();
      $location.search('page', null);
      tableFeatures.paginationParams.scrollTop = true;
      return service.getUserNotifications();
    };

    service.updateNotification = function(notification) {
      var index;
      if (cachedPromise) {
        index = _.findIndex(cachedPromise[0].data, {id: notification.id});
        cachedPromise[0].data[index].read_at = notification.read_at;
      } else {
        service.getUserNotifications().then(function(response) {
          index = _.findIndex(response[0].data, {id: notification.id});
          response[0].data[index].read_at = notification.read_at;
        });
      }
    };

    service.deleteNotification = function(notification) {
      var index = _.findIndex(cachedPromise[0].data, {id: notification.id});
      cachedPromise[0].data.splice(index, 1);
    };

    service.resetNotifications = function() {
      service.invalidateCache();
      service.paginationData.currentPage = 0;
    };

    service.invalidateCache = function() {
      cachedPromise = null;
    };

    service.canRead = function() {
      return UserService.canAccess(['READ'], ['notifications']);
    };

    $rootScope.$on('cache:invalidate', function() {
      service.invalidateCache();
    });
  }
}());
