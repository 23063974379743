(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoInstitutionCourses', eoInstitutionCourses);

  function eoInstitutionCourses($state, $stateParams, TableFeaturesService,
    Institution, $uibModal
  ) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-institution-courses/eo-institution-courses.html',
      scope: {},
      controller: eoInstitutionCoursesCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoInstitutionCoursesCtrl() {

      var vm = this, coursesTable, contextId, institutionId;

      contextId = parseInt($stateParams.contextId, 10);
      institutionId = parseInt($stateParams.id, 10);

      coursesTable  = new TableFeaturesService({
        search: false,
        pagination: {perPage: 10},
        sorting: {column: 'name'},
        loadData: function(queryParams) {
          return Institution.one(institutionId).getList('curriculums', queryParams);
        }
      });

      vm.coursesTable = {
        paginationData: coursesTable.paginationParams,
        sortingData: coursesTable.sortingParams
      };

      vm.loadInstitutionCourses = function() {
        coursesTable.getPageData().then(function(response) {
          vm.courses = response[0].data;
        });
      };

      vm.sortCourses = function() {
        coursesTable.getNewData().then(function(response) {
          vm.courses = response[0].data;
        });
      };

      vm.addCourse = function() {
        if (contextId !== institutionId) {
          $uibModal.open({
            templateUrl: 'dashboard/admin/institutions/modals/add-course-dialog.html',
            controller: function($scope) {
              $scope.ok = function() {
                $scope.$close();
                $state.go('admin.courses.new', {contextId: institutionId});
              };

            }
          });
        } else {
          $state.go('admin.courses.new', {contextId: institutionId});
        }
      };

      vm.viewCourse = function(courseId) {
        if (contextId !== institutionId) {
          $uibModal.open({
            templateUrl: 'dashboard/admin/institutions/modals/view-course-dialog.html',
            controller: function($scope) {
              $scope.ok = function() {
                $scope.$close();
                $state.go('admin.courses.show', {contextId: institutionId, id: courseId});
              };

            }
          });
        } else {
          $state.go('admin.courses.show', {id: courseId});
        }
      };

      vm.loadInstitutionCourses();
    }
  }
})();
