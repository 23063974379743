(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoItemSlider', eoItemSlider);

  function eoItemSlider() {
    return {
      require: '^eoItemsSlider',
      scope: {
        active: '=?'
      },
      compile: function(tElem) {
        var itemBody = getFirstChild(tElem, 'eo-item-slider-body');

        if (itemBody.length === 0) {
          var contents = tElem.contents().detach();
          itemBody = angular.element('<eo-item-slider-body></eo-item-slider-body>');

          itemBody.append(contents);
        }

        if (itemBody.html()) {
          tElem.append(itemBody);
        }

        return link;
      }
    };

    function link(scope, element, attrs, ctrl) {
      if (!ctrl) { return; }

      var index = ctrl.getItemElementIndex(element),
        itemBody = getFirstChild(element, 'eo-item-slider-body').remove(),
        itemElm = ctrl.insertItemElement({
          scope: scope,
          parent: scope.$parent,
          element: element,
          template: itemBody.html()
        }, index);

      scope.$watch('active', function(active) {
        if (active) {
          ctrl.select(itemElm.getIndex());
        }
      });
    }

    function getFirstChild(element, tagName) {
      var children = element[0].children;
      for (var i = 0; i < children.length; i++) {
        var child = children[i];
        if (child.tagName === tagName.toUpperCase()) {
          return angular.element(child);
        }
      }

      return angular.element();
    }
  }

}());
