(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoNotificationPreview', eoNotificationPreview);

  function eoNotificationPreview($state, NotificationsService) {
    return {
      templateUrl: 'components/eo-notification-preview/eo-notification-preview.html',
      scope: {
        notification: '='
      },
      controller: eoNotificationPreviewCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoNotificationPreviewCtrl($element, $attrs) {
      var vm = this, notificationClass, icon = $element.find('.glyphicon');

      if (vm.notification && vm.notification.event) {
        defineNotificationType(vm.notification.event);
      }

      function defineNotificationType(notificationType) {
        switch (notificationType) {
          case 'mastered':
            icon.addClass('glyphicon-ok');
            notificationClass = 'notification-mastered';
            break;
          case 'failed':
            icon.addClass('glyphicon-remove');
            notificationClass = 'notification-failed';
            break;
          case 'at_risk':
            icon.addClass('glyphicon-exclamation-sign');
            notificationClass = 'notification-at-risk';
            break;
          case 'inactive_students':
            icon.addClass('glyphicon-info-sign');
            notificationClass = 'notification-inactive-students';
            break;
          case 'awaiting_grading':
            icon.addClass('glyphicon-info-sign');
            notificationClass = 'notification-awaiting-grading';
            break;
          case 'new_score_comment':
            icon.addClass('glyphicon-info-sign');
            notificationClass = 'notification-new-score-comment';
            break;
          //message classes
          case 'all_instructors':
            icon.addClass('glyphicon-envelope');
            notificationClass = 'message-general';
            break;
          case 'all_students':
            icon.addClass('glyphicon-envelope');
            notificationClass = 'message-prior-middle';
            break;
          case 'general_message':
            icon.addClass('glyphicon-bullhorn');
            notificationClass = 'message-prior-high';
            break;
          case 'specific_users':
            icon.addClass('glyphicon-flag');
            notificationClass = 'message-prior-low';
            break;
          case 'webex_meeting':
            icon.addClass('glyphicon-headphones');
            notificationClass = 'message-prior-low';
            break;
          case 'webex_meeting_ready':
            icon.addClass('glyphicon-headphones');
            notificationClass = 'message-general';
            break;
          case 'success':
            icon.addClass('glyphicon-ok');
            notificationClass = 'notification-mastered';
            break;
        }
      }

      vm.getNotificationCircleClass = function() {
        return notificationClass;
      };

      vm.readNotification = function() {
        NotificationsService.invalidateCache();

        if ($state.includes('student.**')) {
          $state.go('student.' + $attrs.forComponent + '.detail', {id: vm.notification.id, page: vm.notification.page}, {reload: true});
        } else if ($state.includes('instructor.**')) {
          $state.go('instructor.' + $attrs.forComponent + '.detail', {id: vm.notification.id, page: vm.notification.page}, {reload: true});
        } else if ($state.includes('successcoach.**')) {
          $state.go('successcoach.' + $attrs.forComponent + '.detail', {id: vm.notification.id, page: vm.notification.page}, {reload: true});
        }
      };
    }
  }
}());
