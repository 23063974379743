(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('SortingService', SortingService);

  function SortingService() {
    var Sorting = function(options) {
      options = options || {};

      if (!options.column) {
        throw new Error('SortingService: Sorting column is undefined');
      }

      this.data = {
        column: options.column,
        direction: options.direction || 'asc'
      };
    };

    Sorting.prototype.addParams = function(queryParams) {
      if (queryParams) {
        queryParams['q[s]'] = this.data.column + ' ' + this.data.direction;
      }
    };

    return Sorting;
  }
}());
