(function() {
  'use strict';

  angular
    .module('tcm.auth')
    .config(authRoutesConfig);

  function authRoutesConfig($stateProvider) {

    $stateProvider.state('signin', {
      url: '/signin',
      views: {
        'main': {
          controller: 'SigninCtrl',
          templateUrl: 'auth/signin/signin.html'
        }
      }
    });
  }
}());
