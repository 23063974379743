(function() {
  'use strict';

  angular
    .module('tcm.dashboard.student', [
      'tcm.core',
      'tcm.common',
      'tcm.components',
      'tcm.dashboard.student.cohorts',
      'tcm.dashboard.student.programs'
    ]);
}());
