angular.module('tcm.dashboard', [
  'tcm.core',
  'tcm.common',
  'tcm.dashboard.admin',
  'tcm.dashboard.instructor',
  'tcm.dashboard.student',
  'tcm.dashboard.successcoach',
  'tcm.error'
])
.config(function config($stateProvider) {
  'use strict';

  $stateProvider.state('dashboard', {
    url: '/dashboard',
    views: {
      'main': {
        template: ''
      }
    },
    resolve: {
      auth: function(SecurityManagerService, $state, DashboardRouting, localStorageService) {
        SecurityManagerService.isGranted('Root').then(function(granted) {
          if (granted) {
            $state.go('admin.dashboard');
          } else {
            SecurityManagerService.authorize(['isSuccessCoach']).then(function(hasPermission) {
              if (hasPermission) {
                redirectUser('successcoach');
              } else {
                SecurityManagerService.authorize(['isInstructor']).then(function(hasPermission) {
                  if (hasPermission) {
                    redirectUser('instructor');
                  } else {
                    SecurityManagerService.authorize(['isStudent']).then(function(hasPermission) {
                      if (hasPermission) {
                        DashboardRouting.execStudentDashboardRouting();
                      } else {
                        SecurityManagerService.authorize(['isAdmin']).then(function(hasPermission) {
                          if (hasPermission) {
                            $state.go('admin.dashboard');
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });

        function redirectUser(role) {
          if (!role) {
            return;
          }

          if ($state.includes('successcoach.**')) {
            role = 'successcoach';
          } else if ($state.includes('instructor.**')) {
            role = 'instructor';
          } else if (localStorageService.get('role') !== null) {
            role = localStorageService.get('role');
          }

          $state.go(role + '.cohorts.objectives.studentlist', { userId: 'all',id: 'all', state: 'all', dashboard: 'dashboard'}, {reload: true});
        }
      }
    }
  });
});
