(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('Profile', ProfileResource);

  function ProfileResource(ApiRestangular) {
    return ApiRestangular.withConfig(function(RestangularConfigurer) {
      RestangularConfigurer.setFullResponse(true);
    }).service('profile');
  }
}());
