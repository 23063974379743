(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.rubrics')
    .controller('AdminRubricsListCtrl', AdminRubricsListCtrl);

  function AdminRubricsListCtrl(TableFeaturesService, $stateParams, Institution, $location) {
    var vm = this, tableFeatures, contextId;

    contextId = parseInt($stateParams.contextId, 10);

    tableFeatures = new TableFeaturesService({
      sorting: {column: 'title'},
      search: {
        'title_cont': $stateParams.title_cont
      },
      loadData: function(queryParams) {
        return Institution.one(contextId).getList('rubrics', queryParams);
      }
    });

    vm.table = {
      searchData: tableFeatures.searchParams,
      sortingData: tableFeatures.sortingParams,
      paginationData: tableFeatures.paginationParams
    };
    vm.search = function() {
      tableFeatures.resetEmptySearchData();
      $location.search(vm.table.searchData);
      tableFeatures.getNewData().then(function(response) {
        vm.rubrics = response[0].data;
        if (vm.rubrics.length === 0) {
          vm.noRubricsExist = true;
          vm.rubricsExist = false;
        }else {
          vm.noRubricsExist = false;
          vm.rubricsExist = false;
        }
      });
    };

    vm.loadRubricsList = function() {
      tableFeatures.getPageData().then(function(response) {
        vm.rubricSize = response[0].data.length;
        if ($stateParams.title_cont !== undefined) {
          vm.rubrics = response[0].data;
        }else {
          toggleMessage(vm.rubricSize);
        }
      });
    };

    vm.sort = function() {
      tableFeatures.getNewData().then(function(response) {
        vm.rubrics = response[0].data;
      });
    };
    vm.disableClearOption = function() {
      var disable = true;
      _.each(vm.table.searchData, function(n, key) {
        if (vm.table.searchData[key] !== void 0 && vm.table.searchData[key].length > 0) {
          disable = false;
        }
      });
      return disable;
    };
    vm.reset = function() {
      tableFeatures.resetSearch();
      vm.search();
    };
    function toggleMessage(rubricSize) {
      if (rubricSize !== 0) {
        vm.rubricsExist = true;
        vm.noRubricsExist = false;
      }else {
        vm.noRubricsExist = true;
        vm.rubricsExist = false;
      }
    }
    vm.loadRubricsList();
  }
}());
