(function() {
  'use strict';

  angular
    .module('tcm.dashboard.instructor.cohorts')
    .config(cohortsRoutesConfig);

  function cohortsRoutesConfig($stateProvider) {

    $stateProvider.state('instructor.cohorts', {
      url: '/cohorts/:id?:dashboard',
      abstract: true,
      views: {
        'active': {
          controller: 'InstructorCohortsCtrl as vm',
          template: '<div ui-view="section"><div/>'
        }
      },
      ncyBreadcrumb: {
        label: 'Dashboard'
      }
    }).state('instructor.cohorts.grade', {
      url: '/curricula/:curriculumId',
      abstract: true,
      views: {
        'section': {
          template: '<div ncy-breadcrumb></div><div ui-view="page"><div/>'
        }
      },
      ncyBreadcrumb: {
        label: '{{vm.cohortName || vm.courseName}}'
      }
    }).state('instructor.cohorts.grade.show', {
      url: '/grade',
      views: {
        'page': {
          template: '<eo-course-manage-objectives></eo-course-manage-objectives>'
        }
      },
      ncyBreadcrumb: {
        label: 'Gradable Activities',
        parent: 'instructor.cohorts.objectives.studentlist'
      }
    }).state('instructor.cohorts.structure', {
      url: '/curricula/:curriculumId',
      abstract: true,
      views: {
        'section': {
          template: '<div ncy-breadcrumb></div><div ui-view="page"><div/>'
        }
      },
      ncyBreadcrumb: {
        label: '{{vm.cohortName || vm.courseName}}'
      }
    })
    .state('instructor.cohorts.structure.show', {
      url: '/structure',
      views: {
        'page': {
          template: '{{vm.cohortName}}<eo-course-structure-tree></eo-course-structure-tree>'
        }
      },
      ncyBreadcrumb: {
        label: 'Structure',
        parent: 'instructor.cohorts.objectives.studentlist'
      }
    }).state('instructor.cohorts.structure.activity', {
      url: '/activity/:activityId?:objectiveId',
      views: {
        'section@instructor.cohorts': {
          template: '<eo-course-structure-tree-activity></eo-course-structure-tree-activity>'
        }
      },
      ncyBreadcrumb: {
        label: 'Structure',
        parent: 'instructor.cohorts.objectives.studentlist'
      }
    });
  }
}());
