(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoItemSliderTemplate', eoItemSliderTemplate);

  function eoItemSliderTemplate($compile) {
    return {
      restrict: 'A',
      require: '^eoItemsSlider',
      scope: {
        template: '=eoItemSliderTemplate',
        compileScope: '=eoScope'
      },
      link: function(scope, element, attrs, ctrl) {
        if (!ctrl) { return; }

        element.html(scope.template);
        $compile(element.contents())(scope.compileScope);
      }
    };
  }
}());
