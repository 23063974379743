(function() {
  'use strict';

  angular
    .module('tcm.dashboard.instructor.cohorts.objectives')
    .controller('InstructorObjectivesToGradeActivityCtrl', InstructorObjectivesToGradeActivityCtrl);

  function InstructorObjectivesToGradeActivityCtrl($scope, $stateParams,
    $location, User, SpinnerService, TableFeaturesService, StudentActivity) {

    var userId, activityId, tableFeature, cohortId, spinner;

    userId = parseInt($stateParams.userId, 10);
    activityId = parseInt($stateParams.activityId, 10);
    cohortId = ($stateParams.id !== 'all') ?  parseInt($stateParams.id, 10) : null;

    spinner = new SpinnerService();

    tableFeature = new TableFeaturesService({
      spinner: false,
      search: {
        'state_in][': ['mastered', 'awaiting_instructor_grading']
      },
      sorting: {
        column: $location.search().sort || 'started_at',
        direction: $location.search().direction || 'asc'
      },
      loadData: function(queryParams) {
        return StudentActivity.one(activityId).get(queryParams);
      }
    });

    if (cohortId !== null) {
      tableFeature.searchParams.cohort_id_eq = cohortId;
    }

    $scope.loadActivityForGrading = tableFeature.getPageData.bind(tableFeature);

    function loadStudentProfile() {
      return User.one(userId).get().then(function(response) {
        $scope.user = response.data;
      });
    }

    spinner.activateController([
      loadStudentProfile()
    ]);
  }
}());
