(function() {
  'use strict';

  angular
    .module('tcm.dashboard.student.programs')
    .config(programsRoutesConfig);

  function programsRoutesConfig($stateProvider) {
    $stateProvider
      .state('student.programs', {
        url: '/programs/:id',
        abstract: true,
        ncyBreadcrumb: {skip: true},
        views: {
          'active': {
            controller: 'StudentProgramsCtrl as vm',
            template: '<div ui-view="section"><div/>'
          }
        }
      })
      .state('student.programs.competencies', {
        url: '/competencies',
        abstract: true,
        views: {
          'section': {
            templateUrl: 'dashboard/student/programs/student-programs.html'
          }
        },
        ncyBreadcrumb: {skip: true}
      })
      .state('student.programs.competencies.list', {
        url: '/:state',
        views: {
          'component': {
            template: '<eo-competencies-list></eo-competencies-list>'
          }
        },
        ncyBreadcrumb: {
          label: '{{vm.program.name}}',
          parent: 'student.programs.competencies'
        }
      })
      .state('student.programs.report', {
        url: '/report',
        views: {
          'section': {
            controller: 'CompetenciesReportCtrl as vm',
            templateUrl: 'reports/competencies/competencies.html'
          }
        },
        ncyBreadcrumb: {
          label: 'Report',
          parent: 'student.programs.competencies.list'
        }
      });
  }
}());
