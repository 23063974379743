(function() {
  'use strict';

  angular
    .module('tcm.dashboard.instructor.cohorts.objectives')
    .controller('InstructorObjectivesActivityCtrl', InstructorObjectivesActivityCtrl);

  function InstructorObjectivesActivityCtrl($scope, $location, UserActivities,
    TableFeaturesService, $stateParams, SpinnerService, User) {

    var userId, activityId, tableFeature, cohortId, spinner;

    userId = parseInt($stateParams.userId, 10);
    activityId = parseInt($stateParams.activityId, 10);
    cohortId = ($stateParams.id === 'all') ? parseInt($location.search().cohort_id, 10) : parseInt($stateParams.id, 10);

    spinner = new SpinnerService();

    tableFeature = new TableFeaturesService({
      spinner: false,
      search: {
        learning_activity_description_cont: $stateParams.search,
        weight: $stateParams.weight
      },
      sorting: {
        column: $location.search().gb_sort || 'status_priority',
        direction: $location.search().gb_direction || 'asc'
      },
      pagination: {
        perPage: 10,
        currentPage: parseInt($location.search().page, 10)
      },
      loadData: function(queryParams) {
        return UserActivities.getActivityByUser(userId, cohortId, activityId, queryParams);
      }
    });

    $scope.loadActivityForGrading = tableFeature.getPageData.bind(tableFeature);

    function loadStudentProfile() {
      return User.one(userId).get().then(function(response) {
        $scope.user = response.data;
      });
    }

    spinner.activateController([
      loadStudentProfile()
    ]);
  }
}());
