(function() {
  'use strict';

  angular
    .module('tcm.core')
    .config(idleProviderConfig);

  function idleProviderConfig(IdleProvider, EnvConfig) {
    if (EnvConfig.build_mode === 'prod') {
      // 3.5 hours
      IdleProvider.idle(12600);
    } else {
      // 10 minutes
      IdleProvider.idle(600);
    }

    IdleProvider.timeout(90);
    IdleProvider.keepalive(false);
  }
}());
