(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.institutions')
    .controller('AdminInstitutionsSettingsCtrl', AdminInstitutionsSettingsCtrl);

  function AdminInstitutionsSettingsCtrl($stateParams, Institution,
    SpinnerService, $uibModal, ValidationService) {

    var vm = this, spinner, institutionId;

    institutionId = parseInt($stateParams.id, 10);
    spinner = new SpinnerService();

    function loadInstitutionSettings() {
      return Institution.one(institutionId).get().then(function(response) {
        vm.allSettings = response.data;
        vm.institution = vm.allSettings; // breadcrumb label
        setNotificationSettings(vm.allSettings);
        setCohortSettings(vm.allSettings);
      });
    }

    vm.saveSettings = function(model, modalScope) {
      Institution.one(institutionId).one('update_settings').patch(model).then(function() {
        vm.flash = 'Settings have been successfully saved';
        modalScope.$close();
        loadInstitutionSettings();
      });
    };

    function setNotificationSettings(settings) {
      vm.notificationSettings = [
        {
          name: 'Days of student\'s inactivity',
          description: 'Days of student\'s inactivity after which instructors will get notifications. Set \'0\' value in order to disable notifications.',
          value: settings.inactivity_days
        },
        {
          name: 'Days of instructor\'s inactivity',
          description: 'Days of instructor\'s inactivity after which instructors will get notifications to grade a student\'s activity.',
          value: settings.instructor_inactivity_days
        }
      ];
    }

    vm.editNotificationSettings = function(model) {
      vm.flash = '';
      $uibModal.open({
        templateUrl: 'dashboard/admin/institutions/settings/edit-settings.html',
        controllerAs: 'modal',
        controller: function editModalCtrl($scope) {
          var modal = this;
          modal.model = model;
          modal.model.title = 'Edit Notification Settings';

          modal.fields = [
            {
              key: 'inactivity_days',
              type: 'input',
              templateOptions: {
                type: 'number',
                label: 'Days of student inactivity',
                required: true
              },
              validators: {
                positiveNumber: ValidationService.validatePositiveNumber(),
                integer: ValidationService.validateInteger()
              }
            },
            {
              key: 'instructor_inactivity_days',
              type: 'input',
              templateOptions: {
                type: 'number',
                label: 'Days of instructor inactivity',
                required: true
              },
              validators: {
                positiveNumber: ValidationService.validatePositiveNumber(),
                integer: ValidationService.validateInteger()
              }
            }
          ];

          modal.save = function() {
            if (modal.editSettingsForm.$valid) {
              vm.saveSettings(modal.model, $scope);
            }
          };
        }
      });
    };

    function setCohortSettings(settings) {
      vm.cohortSettings = [
        {
          name: 'Amount of days when expired cohorts are visible on Instructor dashboard',
          description: 'Amount of days when expired cohorts are visible on Instructor dashboard. Set \'0\' value in order to always show expired cohorts.',
          value: settings.days_to_display_expired_cohorts
        },
        {
          name: 'Display grade on Student Dashboard',
          description: 'Display grade on Student Dashboard. This setting can be overwritten on the cohort level',
          value: settings.display_grades ? 'yes' : 'no'
        }
      ];
    }

    vm.editCohortSettings = function(model) {
      vm.flash = '';
      $uibModal.open({
        templateUrl: 'dashboard/admin/institutions/settings/edit-settings.html',
        controllerAs: 'modal',
        controller: function editModalCtrl($scope) {
          var modal = this;
          modal.model = model;
          modal.model.title = 'Edit Cohort Settings';

          modal.fields = [
            {
              key: 'days_to_display_expired_cohorts',
              type: 'input',
              templateOptions: {
                type: 'number',
                label: 'Amount of days to show expired cohorts',
                required: true
              },
              validators: {
                positiveNumber: ValidationService.validatePositiveNumber(),
                integer: ValidationService.validateInteger()
              }
            },
            {
              key: 'display_grades',
              type: 'checkbox',
              templateOptions: {
                label: 'Display grade on Student Dashboard'
              },
              /* @ngInject */
              controller: function($scope, $uibModal) {
                var originalDisplayGrades = modal.model.display_grades;

                $scope.$watch('model.display_grades', function(updateValue) {
                  if (updateValue !== originalDisplayGrades) {
                    $uibModal.open({
                      templateUrl: 'dashboard/admin/institutions/modals/update-existing-cohorts-dialog.html',
                      controllerAs: 'vm',
                      controller: function($scope) {
                        var vm = this;

                        vm.updateCohorts = function() {
                          modal.model.update_cohorts = true;
                          $scope.$close();
                        };

                        vm.cancel = function() {
                          delete modal.model.update_cohorts;
                          $scope.$close();
                        };
                      }
                    });
                  }
                });
              }
            }
          ];

          modal.save = function() {
            if (modal.editSettingsForm.$valid) {
              vm.saveSettings(modal.model, $scope);
            }
          };
        }
      });
    };

    spinner.activateController([
      loadInstitutionSettings()
    ]);
  }
}());
