(function() {
  'use strict';

  angular.module('tcm.dashboard.successcoach.cohorts')
  .controller('SuccessCoachCohortsCtrl', SuccessCoachCohortsCtrl);

  function SuccessCoachCohortsCtrl(localStorageService, $rootScope, $stateParams, SpinnerService, UserService, User) {

    var vm = this, spinner, cohortId, studentId;

    spinner = new SpinnerService();
    cohortId =  $stateParams.id === 'all' ? 'all' : parseInt($stateParams.id, 10);
    studentId = $stateParams.userId;
    vm.courseId = 'all';

    function loadInstitution() {
      $rootScope.$broadcast('roleChanged', {role: 'successcoach'});
      localStorageService.set('role', 'successcoach');
    }

    function loadStudentProfile() {
      if (cohortId !== 'all' && studentId !== 'all') {
        return User.one(studentId).get().then(function(response) {
          vm.user = response.data;
        });
      }
    }

    spinner.activateController([

      loadStudentProfile(),
      loadInstitution()
    ]);
  }
}());
