(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.objectives')
    .controller('AdminObjectivesDetailCtrl', AdminObjectivesDetailCtrl);

  function AdminObjectivesDetailCtrl($stateParams, SpinnerService, Objective) {

    var vm = this, spinner, objectiveId;

    spinner = new  SpinnerService();
    objectiveId = parseInt($stateParams.id, 10);

    function loadObjective() {
      return Objective.one(objectiveId).get().then(function(response) {
        vm.objective = response.data;
      });
    }
    function loadCourses() {
      return Objective.one(objectiveId).getList('courses').then(function(response) {
        vm.courses = response.data;
      });
    }
    function loadCompetencies() {
      return Objective.one(objectiveId).getList('competencies').then(function(response) {
        vm.competencies = response.data;
      });
    }

    spinner.activateController([
      loadObjective(),
      loadCompetencies(),
      loadCourses()
    ]);
  }
}());
