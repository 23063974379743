(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.courses')
    .controller('AdminCoursesCreateCtrl', AdminCoursesCreateCtrl);

  function AdminCoursesCreateCtrl($state, $stateParams, Institution,
    SpinnerService, ValidationService, UserService) {

    var vm = this, spinner, contextId, courseId;

    spinner = new SpinnerService();
    contextId = parseInt($stateParams.contextId, 10);
    courseId = parseInt($stateParams.id, 10);

    vm.model = {};

    vm.fields =  [
      {
        key: 'name',
        type: 'input',
        templateOptions: {
          required: true,
          maxlength: 100,
          type: 'text',
          label: 'Title'
        }
      },
      {
        key: 'code',
        type: 'input',
        templateOptions: {
          required: true,
          maxlength: 10,
          type: 'text',
          label: 'Course Code'
        },
        validators: {
          alphaNumericHyphens: ValidationService.validateAlphaNumericUnderscore()
        },
        expressionProperties: {
          hide: function() {
            return UserService.canAccess(['UPDATE'], ['curriculums']).then(function(hasPermission) {
              return !hasPermission;
            });
          }
        }
      },
      {
        key: 'credit_hours',
        type: 'input',
        defaultValue: 0,
        templateOptions: {
          type: 'number',
          label: 'Credit Hours',
          max: 2147483647
        },
        validators: {
          positiveNumber: ValidationService.validatePositiveNumber(),
          integer: ValidationService.validateInteger()
        }
      },
      {
        key: 'expected_duration',
        type: 'input',
        defaultValue: 192,
        templateOptions: {
          type: 'number',
          label: 'Expected Completion Time (Hours)',
          max: 2147483647
        },
        validators: {
          positiveNumber: ValidationService.validatePositiveNumber(),
          integer: ValidationService.validateInteger()
        }
      },
      {
        key: 'enforce_order',
        type: 'checkbox',
        templateOptions: {
          label: 'Enforce order of sub-courses?'
        }
      },
      {
        template: '<span class="help-block"> When checked, requires students to complete the sub-courses in order.</span>'
      },
      {
        key: 'description',
        type: 'textarea',
        templateOptions: {
          min: 0,
          label: 'Description'
        }
      }
    ];

    vm.save = function() {
      if (vm.createEditForm.$valid) {
        if (vm.model && vm.model.id) {
          vm.model.patch().then(function(response) {
            $state.go('admin.courses.show', {id: response.data.id});
          });
        } else {
          Institution.one(contextId).post('curriculums', vm.model).then(function(response) {
            $state.go('admin.courses.show', {id: response.data.id});
          });
        }
      }
    };

    function loadCourseToEdit() {
      vm.isEditMode = false;
      if (courseId) {
        vm.isEditMode = true;
        spinner.activateController([
          Institution.one(contextId).one('curriculums', courseId).get().then(function(response) {
            vm.model = response.data;
            vm.course = vm.model; // breadcrumb label
            vm.model.credit_hours = vm.model.credit_hours || 0;
          })
        ]);
      } else {
        spinner.activateController([]);
      }
    }

    loadCourseToEdit();
  }
}());
