(function() {
  'use strict';

  angular
    .module('tcm.dashboard.successcoach.cohorts')
    .controller('SuccesscoachCourseCtrl', SuccesscoachCourseCtrl);

  function SuccesscoachCourseCtrl($stateParams, User, Institution) {
    var  vm = this, studentId, institutionId, courseId;

    studentId = parseInt($stateParams.userId, 10);
    courseId = parseInt($stateParams.curriculumId, 10);

    function loadCourse() {
      return User.one(studentId).get().then(function(response) {
        institutionId = response.data.institution_id;
        vm.user = response.data;
        return Institution.one(institutionId).one('curriculums', courseId).get().then(function(response) {
          vm.course = response.data;
          vm.courseName = vm.course.name;
        });
      });
    }

    loadCourse();

  }
}());
