(function() {
  'use strict';

  angular
    .module('tcm.dashboard.student.programs')
    .controller('StudentProgramsCtrl', StudentProgramsCtrl);

  function StudentProgramsCtrl($stateParams, UserPrograms, SpinnerService) {
    var vm = this, spinner, programId;

    spinner = new SpinnerService();
    programId = parseInt($stateParams.id, 10);

    function getProgram() {
      return UserPrograms.getProgram(programId).then(function(program) {
        vm.program = program;
      });
    }

    function getChartsUrls() {
      return UserPrograms.getChartsUrls(programId).then(function(chartUrls) {
        if (chartUrls !== void 0) {
          vm.barUrl = chartUrls.barUrl;
          vm.donutUrl = chartUrls.donutUrl;
        }
      });
    }

    spinner.activateController([
      getProgram(),
      getChartsUrls()
    ]);
  }
}());
