(function() {
  'use strict';

  angular
    .module('tcm.core', [
      'ngSanitize',
      'ngAnimate',
      'ngMessages',
      'ngAria',
      'angularMoment',
      'ui.router',
      'ui.sortable',
      'ui.bootstrap',
      'ui.select',
      'ui.date',
      'ncy-angular-breadcrumb',
      'LocalStorageModule',
      'ngFileUpload',
      'ngIdle',
      'gettext',
      'highcharts-ng',
      'restangular',
      'zxcvbn',
      'ngTagsInput',
      'formly',
      'formlyBootstrap',
      'textAngular',
      'markdown',
      'tcm.EnvConfig'
    ]);
}());
