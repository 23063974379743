(function() {
  'use strict';

  angular
    .module('tcm.common')
    .filter('replace', ReplaceFilter);

  function ReplaceFilter() {
    return function(input, by) {
      if (!input) {
        return input;
      }
      by = by || '_';
      return input.split(by).join(' ');
    };
  }

}());
