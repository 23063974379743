(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.rubrics')
    .controller('AdminRubricsCreateCtrl', AdminRubricsCreateCtrl);

  function AdminRubricsCreateCtrl(Institution, SpinnerService, $stateParams, $state) {
    var vm = this, rubricId, contextId, spinner;

    rubricId = parseInt($stateParams.id, 10);
    contextId = parseInt($stateParams.contextId, 10);
    spinner = new SpinnerService();
    vm.model = { criteria: [] };

    vm.fields = [
      {
        key: 'title',
        type: 'input',
        templateOptions: {
          required: true,
          type: 'text',
          label: 'Title',
          maxlength: 255
        }
      },
      {
        key: 'description',
        type: 'textarea',
        templateOptions: {
          label: 'Description',
          maxlength: 255
        }
      }
    ];

    vm.save = function() {
      vm.createRubricForm.$setSubmitted();
      if (vm.createRubricForm.$valid && vm.isRubricsTableValid) {
        if (vm.model && vm.model.id) {
          vm.model.patch().then(function(response) {
            $state.go('admin.rubrics.show', {id: response.data.id});
          });
        } else {
          Institution.one(contextId).post('rubrics', vm.model).then(function(response) {
            $state.go('admin.rubrics.show', {id: response.data.id});
          });
        }
      }
    };

    function loadRubricToEdit() {
      vm.isEditMode = false;
      if (rubricId) {
        vm.isEditMode = true;
        spinner.activateController([
          Institution.one(contextId).one('rubrics', rubricId).get().then(function(response) {
            vm.model = response.data;
            vm.rubric = vm.model; // breadcrumb label
            vm.model.delete_criteria_ids = [];
            vm.model.delete_rules_ids = [];
          }),
          Institution.one(contextId).one('rubrics').one('default_rules').get().then(function(response) {
            vm.defaultRules = response.data.rules;
          })
        ]);
      } else {
        return Institution.one(contextId).one('rubrics').one('default_rules').get().then(function(response) {
          vm.model.criteria.push({title: 'New Criteria', rules: response.data.rules});
          vm.defaultRules = response.data.rules;
        });
      }
    }

    spinner.activateController([
      loadRubricToEdit()
    ]);
  }
}());
