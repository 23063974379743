(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoCompetencyDonut', eoCompetencyDonut);

  function eoCompetencyDonut($http, $timeout, gettext, gettextCatalog) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-competency-donut/eo-competency-donut.html',
      scope: {
        url: '=',
        viewType: '='
      },
      controller: eoCompetencyDonutCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoCompetencyDonutCtrl($scope) {
      var vm = this, middleText, typeData;

      typeData = {
        'mastered': {
          readable: gettext('Mastered'),
          sortValue: 20,
          color: '#2e8b57'
        },
        'in_process': {
          readable: gettext('In Progress'),
          sortValue: 15,
          color: '#cccc00'
        },
        'queued': {
          readable: gettext('Not Started'),
          sortValue: 10,
          color: '#afb4c2'
        },
        'not_mastered': {
          readable: gettext('Not Mastered'),
          sortValue: 5,
          color: '#cd5c5c'
        },
        'not_available': {
          readable: gettext('Not Enrolled'),
          sortValue: 1,
          color: '#d0d2d9'
        }
      };

      var drawMiddleText = function(chart) {
        if (middleText) {
          middleText.destroy();
          middleText = null;
        }

        var rend = chart.renderer;
        var pie = chart.series[0];

        if (!pie) {
          return;
        }

        var left = chart.plotLeft + pie.center[0];
        var top = chart.plotTop + pie.center[1] - 10;

        // var total = _.reduce(pie.data, function(sum, data) {
        //   return sum + data.y;
        // }, 0);
        // var mastered = _.find(pie.data, {type: 'mastered'}).y;

        // percentage display
        //var percent = Math.round((mastered / total) * 100);
        //middleText = rend.text(percent + '%<br>Mastered', left,  top)

        middleText = rend.text('LOs<br>Mastered', left,  top)
          .attr({
            'text-anchor': 'middle'
          })
          .css({
            fontSize: '16px',
            color: 'gray'
          })
          .add();

        rend
          .label('Learning Objectives (LO) Progress', 0, -5)
          .css({
            fontSize: '12px',
            color: 'gray'
          })
          .add();
      };

      vm.chartConfig = {
        options: {
          chart: {
            type: 'pie',
            events: {
              redraw: function() {
                if (middleText) {
                  drawMiddleText(this);
                }
              },
              addSeries: function() {
                // delay this because the series hasn't been added yet, so we need to
                // wait so we can look it up *and* get the pie's coordinates
                _.delay(_.partial(drawMiddleText, this));
              }
            },
            height: 275,
            style: {
              fontFamily: '"Source Sans Pro", "Helvetica Neue", Helvetica, sans-serif'
            }
          },
          plotOptions: {
            pie: {
              enableMouseTracking: false,
              innerSize: 80,
              dataLabels: {
                enabled: false
              },
              showInLegend: true,
              startAngle: 45
            },
            series: {
              events: {
                legendItemClick: function() {
                  return false;
                }
              }
            }
          },
          legend: {
            itemWidth: 115
          }
        },
        series: [],
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        loading: false,
        func: function(chart) {
          var originalWidth = chart.chartWidth,
            originalHeight = chart.chartHeight;

          $scope.$on('donut:print', function(event, printWidth, printHeight) {
            chart.setSize(printWidth, printHeight, false);
            $timeout(function() {
              chart.setSize(originalWidth, originalHeight, false);
            }, 150);
          });
        }
      };

      $scope.$watch('vm.viewType', function(newVal) {
        if (!newVal) {
          return;
        }

        if (newVal === 'programs') {
          vm.chartConfig.title.text = gettextCatalog.getString('Competency Progress');
        } else if (newVal === 'cohorts') {
          vm.chartConfig.title.text = gettextCatalog.getString('Learning Objectives Progress');
        }
      });

      $scope.$watch('vm.url', function(newVal) {
        if (!newVal) {
          return;
        }

        vm.chartConfig.series = [];
        vm.chartConfig.loading = true;

        $http.get(newVal).then(function(result) {
          if (!result.data.length) {
            return;
          }

          var translatedData = _(result.data)
            .map(function(item) {
              return {
                name: item.y + ' ' + gettextCatalog.getString(typeData[item.name].readable),
                y: item.y,
                type: item.name,
                color: typeData[item.name].color,
                sortValue: typeData[item.name].sortValue
              };
            })
            .sortBy('sortValue')
            .value();

          vm.chartConfig.series = [{
            data: translatedData
          }];

        })
          .finally(function() {
            vm.chartConfig.loading = false;
          });
      });
    }
  }
}());
