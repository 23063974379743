(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('UserObjectives', UserObjectives);

  function UserObjectives(User, UserService, SortingService,
    PaginationService, $q, $rootScope) {

    var pagination, sorting, objectivesPromise;

    pagination = new PaginationService({perPage: 100});
    sorting = new SortingService({column: 'position'});

    $rootScope.$on('cache:invalidate', function() {
      objectivesPromise = null;
    });

    $rootScope.$on('user-objectives-cache:invalidate', function() {
      objectivesPromise = null;
    });

    function getObjectivesByCohort(cohortId, queryString) {
      queryString = queryString || {};

      if (_.isEmpty(queryString)) {
        pagination.addParams(queryString);
        sorting.addParams(queryString);
      }

      return UserService.loadProfile().then(function(profile) {
        return User.one(profile.id).one('cohorts', cohortId).getList('objectives', queryString);
      });
    }

    // TODO: Avoid caching with better architectural design
    // See StudentObjectiveActivitiesCtrl
    function getAllObjectivesByCohort(cohortId, queryString) {
      queryString = queryString || {};

      if (_.isEmpty(queryString)) {
        pagination.addParams(queryString);
        sorting.addParams(queryString);
      }

      if (!objectivesPromise) {
        objectivesPromise = $q.defer();
        UserService.loadProfile().then(function(profile) {
          User.one(profile.id).one('cohorts', cohortId).getList('objectives', queryString).then(function(response) {
            objectivesPromise.resolve(response);
          });
        });
      }

      return objectivesPromise.promise;
    }

    function getObjective(id) {
      return UserService.loadProfile().then(function(profile) {
        return User.one(profile.id).one('objectives', id).get();
      });
    }

    return {
      getObjectivesByCohort: getObjectivesByCohort,
      getAllObjectivesByCohort: getAllObjectivesByCohort,
      getObjective: getObjective
    };
  }
}());
