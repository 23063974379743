(function() {
  'use strict';

  angular
    .module('tcm.common')
    .directive('eoLtiActivityInstructions', eoLtiActivityInstructions);

  function eoLtiActivityInstructions() {
    return {
      bindToController: true,
      controllerAs: 'vm',
      controller: eoLtiActivityInstructionsCtrl,
      scope: {
        activity: '=',
        launchLti: '&?'
      },
      restrict: 'E',
      templateUrl: 'components/eo-lti-activity-instructions/eo-lti-activity-instructions.html'
    };

    function eoLtiActivityInstructionsCtrl() {}
  }
})();
