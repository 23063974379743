(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.competencies')
    .controller('AdminCompetenciesListCtrl', AdminCompetenciesListCtrl);

  function AdminCompetenciesListCtrl($stateParams, $location,
    TableFeaturesService, SpinnerService, SortingService, Institution) {

    var vm = this, spinner, sorting, locationQueryParams, contextId;

    contextId = parseInt($stateParams.contextId, 10);
    spinner = new SpinnerService();
    sorting = new SortingService({column: 'name'});
    locationQueryParams = {};
    vm.library = {};

    var tableFeatures = new TableFeaturesService({
      sorting: {column: 'name'},
      search: {
        'name_cont': $stateParams.name_cont,
        'description_cont': $stateParams.description_cont,
        'published_true': $stateParams.published_true,
        'published_false': $stateParams.published_false
      },
      loadData: function(queryParams) {
        queryParams.all = true;
        return Institution.one(contextId).one('competencies', vm.library.selected).getList('children', queryParams);
      }
    });

    vm.table = {
      searchData: tableFeatures.searchParams,
      sortingData: tableFeatures.sortingParams,
      paginationData: tableFeatures.paginationParams
    };

    function getCompetencyRoots() {
      var queryParams = {};
      sorting.addParams(queryParams);

      return Institution.one(contextId).one('competencies').one('roots').get(queryParams).then(function(response) {
        vm.competencyLibraries = response.data;

        if (vm.competencyLibraries.length) {
          vm.library.selected = parseInt($stateParams.library_id, 10) || vm.competencyLibraries[0].id;
          if ($stateParams.published_true) {
            vm.published = 'true';
          } else if ($stateParams.published_false) {
            vm.published = 'false';
          } else {
            vm.published = '';
          }

          vm.loadPage();
        }
      });
    }

    vm.search = function() {
      tableFeatures.resetEmptySearchData();
      checkStatus();

      locationQueryParams = vm.table.searchData;
      if (vm.library.selected !== vm.competencyLibraries[0].id) {
        locationQueryParams.library_id = vm.library.selected;
      }

      $location.search(locationQueryParams);

      tableFeatures.getNewData().then(function(response) {
        vm.competencies = _.filter(response[0].data, 'parent_id');
      });
    };

    vm.loadPage = function() {
      tableFeatures.getPageData().then(function(response) {
        vm.competencies = _.filter(response[0].data, 'parent_id');
      });
    };

    vm.reset = function() {
      resetLibraryFilter();
      tableFeatures.resetSearch();
      vm.search();
    };

    vm.disableClearOption = function() {
      var disable = true;

      _.each(vm.table.searchData, function(n, key) {
        if (vm.table.searchData[key] !== void 0 && vm.table.searchData[key].length > 0) {
          disable = false;
        }
      });

      return disable;
    };

    function checkStatus() {
      delete vm.table.searchData.published_true;
      delete vm.table.searchData.published_false;
      if (vm.published !== '') {
        vm.table.searchData['published_' + vm.published] = '1';
      }
    }

    function resetLibraryFilter() {
      $location.search('library_id', null);
      vm.library.selected = vm.competencyLibraries[0].id;
      vm.published = '';
    }

    spinner.activateController([
      getCompetencyRoots()
    ]);
  }
}());
