(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoNotificationControls', eoNotificationControls);

  function eoNotificationControls($document, $q, NotificationsService, Notification) {
    return {
      replace: true,
      transclude: true,
      templateUrl: 'components/eo-notification-controls/eo-notification-controls.html',
      scope: { },
      link: eoNotificationControlsLinkFn,
      controller: eoNotificationControlsCtrl,
      controllerAs: 'controls',
      bindToController: true
    };

    function eoNotificationControlsLinkFn(scope, element) {
      scope.toggleDropdown = function($event) {
        if ($event) {
          $event.stopPropagation();
        }
        element.find('.dropdown-container').slideToggle(200);
      };

      // prevent click on checkbox to trigger button click
      element.find('input').on('click', function(e) {
        e.stopPropagation();
      });
    }

    function eoNotificationControlsCtrl($scope, $element) {
      /*jshint validthis: true */

      var controls = this, notificationElements = [], selectedNotificationElements = [],
        selectedNotificationIds = [], selectedUnreadNotificationIds = [];

      // keep an array of all notification elements for select all/unselect all functionality
      controls.setNotificationElement = function(notificationElement) {
        notificationElements.push(notificationElement);
      };

      controls.unsetNotificationElement = function(notificationElement) {
        var index = _.indexOf(notificationElements, notificationElement);
        notificationElements.splice(index, 1);
      };

      controls.selectNotification = function(notificationElement) {
        selectedNotificationElements.push(notificationElement);
        controls.selectedAll = true;
      };

      controls.unselectNotification = function(notificationElement) {
        var index = _.indexOf(selectedNotificationElements, notificationElement);
        selectedNotificationElements.splice(index, 1);
        if (selectedNotificationElements.length === 0) { controls.selectedAll = false; }
      };

      controls.updateSelectedState = function() {
        notificationElements.forEach(function(notificationElement) {
          notificationElement.isolateScope().selected = controls.selectedAll;
        });
      };

      controls.selectAll = function() {
        notificationElements.forEach(function(notificationElement) {
          notificationElement.isolateScope().selected = true;
        });
        controls.selectedAll = true;
        hideDropdown();
      };

      controls.unselectAll = function() {
        notificationElements.forEach(function(notificationElement) {
          notificationElement.isolateScope().selected = false;
        });
        controls.selectedAll = false;
        selectedNotificationIds.length = 0;
        selectedUnreadNotificationIds.length = 0;
        hideDropdown();
      };

      controls.markRead = function() {
        filterNotificationsForReadState(true).then(function() {
          if (selectedNotificationIds.length) {
            Notification.one('mark_read').patch({ids: selectedNotificationIds}).then(function() {
              $scope.$emit('eo-notification:mark_read', selectedNotificationIds.length);
              controls.unselectAll();
              NotificationsService.invalidateCache();
            });
          } else {
            controls.unselectAll();
          }
        });
      };

      controls.markUnread = function() {
        filterNotificationsForReadState(false).then(function() {
          if (selectedNotificationIds.length) {
            Notification.one('mark_unread').patch({ids: selectedNotificationIds}).then(function() {
              $scope.$emit('eo-notification:mark_unread', selectedNotificationIds.length);
              controls.unselectAll();
              NotificationsService.invalidateCache();
            });
          } else {
            controls.unselectAll();
          }
        });
      };

      controls.deleteSelected = function() {
        filterNotificationsForDelete().then(function() {
          if (selectedNotificationIds.length) {
            Notification.one().customDELETE('', {'notification_ids[]': selectedNotificationIds}).then(function() {
              $scope.$emit('UserNotificationsCtrl:reload');
              $scope.$emit('eo-notification:mark_read', selectedUnreadNotificationIds.length);
              controls.unselectAll();
              NotificationsService.invalidateCache();
            });
          } else {
            controls.unselectAll();
          }
        });
      };

      // do not store notification ids which already have desired "read" state
      function filterNotificationsForReadState(readState) {
        return $q(function(resolve) {
          selectedNotificationElements.forEach(function(notificationElement, index) {
            if (notificationElement.isolateScope().isRead !== readState) {
              notificationElement.isolateScope().isRead = readState;
              selectedNotificationIds.push(notificationElement.isolateScope().notification.id);
            }
            if (index === selectedNotificationElements.length - 1) { resolve(); }
          });
        });
      }

      // get all selected notification ids and notification ids which are unread to update
      // eo-notifications-badge counter
      function filterNotificationsForDelete() {
        return $q(function(resolve) {
          selectedNotificationElements.forEach(function(notificationElement, index) {
            // filter unread notifications which were selected for deletion
            if (!notificationElement.isolateScope().isRead) {
              selectedUnreadNotificationIds.push(notificationElement.isolateScope().notification.id);
            }
            selectedNotificationIds.push(notificationElement.isolateScope().notification.id);

            if (index === selectedNotificationElements.length - 1) {
              resolve();
            }
          });
        });
      }

      function hideDropdown() {
        $element.find('.dropdown-container').slideUp(200);
      }

      $document.on('click', function() { hideDropdown(); });
    }
  }
}());
