(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.competencies')
    .config(competenciesRoutesConfig);

  function competenciesRoutesConfig($stateProvider) {

    $stateProvider.state('admin.competencies', {
      url: '/competencies?name_cont&description_cont&published_true&published_false&library_id',
      abstract: true,
      template: '<div ui-view="active"></div>'
    }).state('admin.competencies.list', {
      url: '',
      reloadOnSearch: false,
      views: {
        'active': {
          controller: 'AdminCompetenciesListCtrl as vm',
          templateUrl: 'dashboard/admin/competencies/list/competencies-list.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Competencies',
        parent: 'admin.dashboard'
      }
    }).state('admin.competencies.show', {
      url: '/:id',
      views: {
        'active': {
          controller: 'AdminCompetenciesDetailCtrl as vm',
          templateUrl: 'dashboard/admin/competencies/detail/competencies-detail.html'
        }
      },
      ncyBreadcrumb: {
        label: '{{vm.competency.name}}',
        parent: 'admin.competencies.list'
      }
    }).state('admin.competencies.manage', {
      url: '/:id/manage',
      views: {
        'active': {
          controller: 'AdminCompetenciesManageObjectivesCtrl as vm',
          templateUrl: 'dashboard/admin/competencies/manage/competencies-manage-objectives.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Manage',
        parent: 'admin.competencies.show'
      }
    });
  }
}());
