(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.libraries')
    .config(librariesRoutesConfig);

  function librariesRoutesConfig($stateProvider) {

    $stateProvider.state('admin.libraries', {
      url: '/libraries?name_cont',
      abstract: true,
      template: '<div ui-view="active"></div>'
    }).state('admin.libraries.list', {
      url: '',
      views: {
        'active': {
          controller: 'AdminLibrariesListCtrl as vm',
          templateUrl: 'dashboard/admin/libraries/list/libraries-list.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Libraries',
        parent: 'admin.dashboard'
      }
    });
  }
}());
