(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoNotificationBadge', eoNotificationBadge);

  function eoNotificationBadge($rootScope, $state, $interval,
    NotificationsService) {
    return {
      replace: true,
      templateUrl: 'components/eo-notification-badge/eo-notification-badge.html',
      scope: { },
      controller: eoNotificationBadgeCtrl,
      controllerAs: 'badge',
      bindToController: true
    };

    function eoNotificationBadgeCtrl($element) {
      var fetchTimer, badge = this, counter;

      // fetch new unread notifications each minute
      fetchTimer = $interval(getUnreadNotificationsCount, 60000);

      function getUnreadNotificationsCount() {
        return NotificationsService.canRead().then(function(hasPermission) {
          if (!hasPermission) {
            return false;
          }

          return NotificationsService.getUnreadNotificationsCount().then(function(response) {
            badge.unreadNotificationsCount = response.data;
            // fetch notification for preview on init or we get new notification count
            if (counter !== badge.unreadNotificationsCount) {
              NotificationsService.invalidateCache();
              getUserNotificationsForPreview();
            }

            counter =  badge.unreadNotificationsCount;
            return counter;
          });
        });
      }

      function getUserNotificationsForPreview() {
        return NotificationsService.canRead().then(function(hasPermission) {
          if (!hasPermission) {
            return false;
          }

          return NotificationsService.getUserNotificationsForPreview().then(function(response) {
            badge.unreadNotifications = response[0].data;
            return badge.unreadNotifications;
          });
        });
      }

      badge.redirectToViewNotifications = function() {
        if ($state.includes('student.**')) {
          NotificationsService.resetNotifications();
          $state.go('student.notifications', {}, {reload: true});
        } else if ($state.includes('instructor.**')) {
          NotificationsService.resetNotifications();
          $state.go('instructor.notifications', {}, {reload: true});
        } else if ($state.includes('successcoach.**')) {
          NotificationsService.resetNotifications();
          $state.go('successcoach.notifications', {}, {reload: true});
        }
      };

      $rootScope.$on('eo-notification:mark_read', function(event, readCount) {
        badge.unreadNotificationsCount -= readCount;
        counter = badge.unreadNotificationsCount;
        if (badge.unreadNotificationsCount === 0) {
          badge.unreadNotifications = null;
        } else {
          getUserNotificationsForPreview();
        }
      });

      $rootScope.$on('eo-notification:mark_unread', function(event, unreadCount) {
        badge.unreadNotificationsCount += unreadCount;
        counter = badge.unreadNotificationsCount;
        getUserNotificationsForPreview();
      });

      $rootScope.$on('badge-counter:refresh', function() {
        getUnreadNotificationsCount();
      });

      $element.on('$destroy', function() {
        $interval.cancel(fetchTimer);
      });

      getUnreadNotificationsCount();
    }
  }
}());
