(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.competencies')
    .controller('AdminCompetenciesDetailCtrl', AdminCompetenciesDetailCtrl);

  function AdminCompetenciesDetailCtrl($stateParams, SpinnerService,
    Institution, SearchService) {

    var vm = this, spinner, search, contextId, competencyId;

    contextId = parseInt($stateParams.contextId, 10);
    competencyId = parseInt($stateParams.id, 10);
    spinner  = new SpinnerService();
    search = new SearchService();
    vm.competency = {};
    vm.tags = [];

    function loadCompetency() {
      return Institution.one(contextId).one('competencies', competencyId).get().then(function(response) {
        vm.competency = response.data;
        loadParentCompetency(vm.competency);

        // TODO: Backend should send tags in a structure {text:'tag'} to avoid such operations
        vm.tags = _.map(vm.competency.tags, function(tag) { return {text: tag}; });
      });
    }

    function loadParentCompetency(competency) {
      return Institution.one(contextId).one('competencies', competency.parent_id).get().then(function(response) {
        vm.competency.parentCompetency = response.data;
      });
    }

    function loadCompetencyChildren() {
      return Institution.one(contextId).one('competencies', competencyId).getList('children').then(function(response) {
        vm.competency.children = response.data;
      });
    }

    vm.sendCompetencyTags = function() {
      var tags = _.map(vm.tags, function(tag) { return tag.text; });
      spinner.activateController([
        Institution.one(contextId).one('competencies', competencyId).one('tags').customPUT({
          tags: tags
        })
      ]);
    };

    vm.loadCompetencyTags = function(query) {
      var queryParams = {};
      search.data.text_cont = query;
      search.addParams(queryParams);
      return Institution.one(contextId).one('competencies').one('tags').get(queryParams);
    };

    spinner.activateController([
      loadCompetency(),
      loadCompetencyChildren()
    ]);
  }
}());
