(function() {
  'use strict';

  angular
    .module('tcm.common')
    .directive('eoPublishToggle', eoPublishToggle);

  function eoPublishToggle() {
    return {
      restrict: 'E',
      templateUrl: 'common/directives/eo-publish-toggle/eo-publish-toggle.html',
      scope: {
        obj: '=',
        flashMessage: '=?',
        resource: '@',
        section: '@?'
      },
      controller: eoPublishToggleCtrl
    };

    function eoPublishToggleCtrl($scope, $element, $attrs, $uibModal, $stateParams, ApiRestangular, UserService, Institution) {
      var resources = $attrs.resource.split('|');
      UserService.canAccess(['PUBLISH'], resources).then(function(hasPermission) {
        if (!hasPermission) {
          var buttons = $element.find('button');
          angular.element(buttons[0]).attr('disabled', 'disabled');
          angular.element(buttons[1]).attr('disabled', 'disabled');
        }
      });

      $scope.service = ApiRestangular.service($scope.resource);

      $scope.$watch('obj.published', function(newValue, oldValue) {
        $scope.oldValue = oldValue;
        return newValue;
      });

      $scope.toggleState = function() {
        if ($scope.obj.published) {
          var modalInstance = $uibModal.open({
            templateUrl: 'common/directives/eo-publish-toggle/eo-publish-toggle-pop.html',
            controller: function($scope) {
              $scope.data = {};

              $scope.ok = function() {
                $scope.$close();
              };
            }
          });

          modalInstance.result.then(function() {
            if ($attrs.context === 'false') {
              $scope.service.one($scope.obj.id).one('publish').patch().catch(rollbackStateChange);
            } else {
              Institution.one($stateParams.contextId).one($scope.resource, $scope.obj.id).one('publish').patch().catch(rollbackStateChange);
            }
          }, function() {
            $scope.obj.published = $scope.oldValue;
          });

        } else {
          if ($attrs.context === 'false') {
            $scope.service.one($scope.obj.id).one('draft').patch().catch(rollbackStateChange);
          } else {
            Institution.one($stateParams.contextId).one($scope.resource, $scope.obj.id).one('draft').patch().catch(rollbackStateChange);
          }
        }
        return $scope.obj.published;
      };

      function rollbackStateChange() {
        $scope.flashMessage = 'There was a problem in changing state of the ' + $scope.section + '. Please check your ' + $scope.section + ' is set up correctly and you have permission to make the required changes.';
        $scope.obj.published = $scope.oldValue;
      }
    }
  }
}());
