(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.users')
    .controller('AdminUsersCreateCtrl', AdminUsersCreateCtrl);

  function AdminUsersCreateCtrl($state, $stateParams, $q,
    User, SpinnerService, ValidationService) {

    var vm = this, spinner, userId;

    spinner = new SpinnerService();
    userId = parseInt($stateParams.userId, 10);

    vm.model = {};
    vm.contextId = parseInt($stateParams.contextId, 10);

    vm.fields = [
      {
        key: 'first_name',
        type: 'input',
        templateOptions: {
          required: true,
          minlength: 2,
          maxlength: 50,
          type: 'text',
          label: 'First Name'
        },
        validators: {
          lettersHyphens: ValidationService.validateLettersHyphens()
        }
      },
      {
        key: 'last_name',
        type: 'input',
        templateOptions: {
          required: true,
          minlength: 2,
          maxlength: 50,
          type: 'text',
          label: 'Last Name'
        },
        validators: {
          lettersHyphens: ValidationService.validateLettersHyphens()
        }
      },
      {
        key: 'email',
        type: 'input',
        templateOptions: {
          required: true,
          type: 'email',
          label: 'Email'
        },
        validators: {
          emailAddress: ValidationService.validateEmail()
        }
      },
      {
        key: 'institution_id',
        type: 'ui-select',
        templateOptions: {
          required: true,
          label: 'Institution',
          options: [],
          valueProp: 'id',
          labelProp: 'institution_name',
          disabled: true
        },
        hideExpression: 'model.id',
        /* @ngInject */
        controller: function($scope, Institution, UserService, TableFeaturesService) {
          var tableFeatures = new TableFeaturesService({
            sorting: {column: 'name'},
            search: {
              id_eq: vm.contextId
            },
            loadData: function(queryParams) {
              return Institution.getList(queryParams);
            }
          });

          tableFeatures.getPageData().then(function(response) {
            $scope.to.options = response[0].data;
            vm.model.institution_id = response[0].data[0].id;
          });
        }
      },
      {
        key: 'address1',
        type: 'input',
        templateOptions: {
          maxlength: 100,
          type: 'text',
          label: 'Address Line 1'
        },
        validators: {
          address: ValidationService.validateAddress()
        }
      },
      {
        key: 'address2',
        type: 'input',
        templateOptions: {
          maxlength: 100,
          type: 'text',
          label: 'Address Line 2'
        },
        validators: {
          address: ValidationService.validateAddress()
        }
      },
      {
        className: 'row',
        fieldGroup: [
          {
            className: 'col-xs-6',
            key: 'city',
            type: 'input',
            templateOptions: {
              maxlength: 50,
              type: 'text',
              label: 'City'
            },
            validators: {
              address: ValidationService.validateAddress()
            }
          },
          {
            className: 'col-xs-6',
            key: 'zip_postal_code',
            type: 'input',
            templateOptions: {
              type: 'text',
              label: 'Zip Code'
            },
            validators: {
              zip: ValidationService.validateZip()
            }
          }
        ]
      },
      {
        className: 'row',
        fieldGroup: [
          {
            className: 'col-xs-6',
            key: 'state_province',
            type: 'ui-select',
            templateOptions: {
              label: 'State',
              options: []
            },
            /* @ngInject */
            controller: function($scope, State) {
              State.getList().then(function(response) {
                $scope.to.options = response.data;
              });
            }
          },
          {
            className: 'col-xs-6',
            key: 'phone',
            type: 'input',
            templateOptions: {
              type: 'text',
              label: 'Phone Number'
            },
            validators: {
              phone: ValidationService.validatePhoneNumber()
            }
          }
        ]
      },
      {
        className: 'row',
        fieldGroup: [
          {
            className: 'col-xs-6',
            key: 'webex_credentials.username',
            type: 'input',
            templateOptions: {
              type: 'text',
              label: 'WebEx User Name'
            },
            hideExpression: function() {
              return webexFeature();
            },
            expressionProperties: {
              'templateOptions.required': function() {
                resetWebex();
                return vm.model.webex_credentials;
              }
            }
          },
          {
            className: 'col-xs-6',
            key: 'webex_credentials.default_meeting_password',
            type: 'input',
            templateOptions: {
              type: 'text',
              label: 'Default Meeting Password'
            },
            hideExpression: function() {
              return webexFeature();
            },
            expressionProperties: {
              'templateOptions.required': function() {
                resetWebex();
                return vm.model.webex_credentials;
              }
            }
          }
        ]
      },
      {
        className: 'row',
        fieldGroup: [
          {
            className: 'col-xs-6',
            key: 'webex_credentials.password',
            type: 'input',
            templateOptions: {
              type: 'password',
              label: 'WebEx Password'
            },
            hideExpression: function() {
              return webexFeature();
            },
            expressionProperties: {
              'templateOptions.required': function() {
                resetWebex();
                return vm.model.webex_credentials;
              }
            },
            validators: {
              noSpaces: ValidationService.validateNoSpaces()
            }
          }
        ]
      },
      {
        type: 'multiCheckbox',
        key: 'roles',
        templateOptions: {
          required: true,
          label: 'Roles',
          options: [],
          valueProp: 'id',
          labelProp: 'title'
        },
        /* @ngInject */
        controller: function($scope, AssignableRole) {
          AssignableRole.getList().then(function(response) {
            $scope.to.options = response.data;
          });
        }
      }
    ];

    function resetWebex() {
      if (vm.model.webex_credentials && !vm.model.webex_credentials.id && !vm.model.webex_credentials.password && !vm.model.webex_credentials.default_meeting_password && !vm.model.webex_credentials.username) {
        delete vm.model.webex_credentials;
      }
    }

    function webexFeature() {
      if (vm.model.webex) {
        var flag = false;

        if (vm.model.assignments) {
          for (var x = 0; x < vm.model.assignments.length; x++) {
          //Checking on the role type Instructor where webex is enabled
            if (vm.model.assignments[x].role_name === 'Instructor' || vm.model.assignments[x].role_name === 'Success Coach') {
              flag = false;
              break;
            } else {
              flag = true;
            }
          }
        }

        return flag;
      } else {
        return true;
      }
    }

    vm.save = function() {
      if (vm.createEditForm.$valid) {
        if (vm.model && vm.model.id) {

          formatChosenRoles().then(function(formattedRoles) {
            vm.model.assignments = formattedRoles;
            vm.model.patch(vm.model).then(function(response) {
              $state.go('admin.users.show', {userId: response.data.id});
            });
          });
        } else {
          formatChosenRoles().then(function(formattedRoles) {
            vm.model.assignments = formattedRoles;
            User.post(vm.model).then(function(response) {
              $state.go('admin.users.show', {userId: response.data.id});
            });
          });
        }
      }
    };

    // multiCheckbox uses pure array of ids but we have array of objects with 'role_id' key
    // This method makes mapping from pure array to a required format
    // Note: we use 'model.roles' in order to prevent checkbox get unselected on form submit
    // but from backend we actually get 'model.assignments'
    function formatChosenRoles() {
      return $q(function(resolve) {
        resolve(vm.model.roles.map(function(id) { return { role_id: id }; }));
      });
    }

    function formatUserRoles() {
      return $q(function(resolve) {
        resolve(vm.model.assignments.map(function(role) { return role.role_id; }));
      });
    }

    function loadUserToEdit() {
      vm.isEditMode = false;
      if (userId) {
        vm.isEditMode = true;
        spinner.activateController([
          User.one(userId).get().then(function(response) {
            vm.model = response.data;
            vm.user = response.data; //breadcrumb data
            formatUserRoles().then(function(formattedRoles) {
              vm.model.roles = formattedRoles;
            });
          })
        ]);
      }
    }

    loadUserToEdit();
  }
}());
