(function() {
  'use strict';

  angular
    .module('tcm.dashboard.successcoach.cohorts.objectives')
    .controller('SuccessCoachCoursesListController', SuccessCoachCoursesListController);

  function SuccessCoachCoursesListController(DashboardRouting, UserService, $uibModal, $scope, $stateParams, TableFeaturesService, User) {
    var  tableFeature, cohortId, userId;

    $scope.userId = $stateParams.userId;
    userId = $scope.userId;
    cohortId = $stateParams.id === 'all' ? 'all' : parseInt($stateParams.id, 10);

    tableFeature = new TableFeaturesService({
      sorting: {column: 'started_on', direction: 'desc'},
      pagination: {perPage: 100},
      search: false,
      loadData: function(queryParams) {
        queryParams.json_type = 'with_stats';
        return User.one($scope.userId).getList('cohorts', queryParams);
      }
    });

    $scope.table = {
      paginationData: tableFeature.paginationParams,
      sortingData: tableFeature.sortingParams
    };

    $scope.getTabData = function() {
      tableFeature.getPageData().then(function(response) {
        $scope.data = response[0].data;

        for (var x = 0; x < $scope.data.length;x++) {
          $scope.data[x].totalPoints = $scope.data[x].mastery_count  + $scope.data[x].in_progress_count + $scope.data[x].available_count;
        }
        if ($scope.data.length > 0) {
          $scope.userName = $scope.data[0].user_first_name + ' ' + $scope.data[0].user_last_name;
        } else {
          $scope.userName = '';
        }
      });
    };

    $scope.masqueradeAsStudent = function($event, student) {
      $event.stopPropagation();

      $uibModal.open({
        templateUrl: 'components/eo-student-list/modals/masquerade-as-student-modal.html',
        controllerAs: 'modal',
        controller: function($scope) {
          var modal = this;

          modal.studentFullName = student.user_first_name + ' ' + student.user_last_name;

          modal.submit = function() {
            UserService.signInAsStudent(student.user_id, student.cohort_id, userId, 'successcoach').then(function() {
              $scope.$close();
              $scope.$emit('badge-counter:refresh');
              DashboardRouting.execStudentDashboardRouting();
            });
          };
        }
      });
    };
    $scope.getUserData = function() {
      User.one($scope.userId).get().then(function(response) {
        $scope.user = response.data;
      });

    };

    $scope.getTabData();
    $scope.getUserData();
  }
}());
