(function() {
  'use strict';

  angular
    .module('tcm.dashboard.successcoach.cohorts.objectives')
    .config(objectivesRoutesConfig);

  function objectivesRoutesConfig($stateProvider) {

    $stateProvider.state('successcoach.cohorts.objectives', {
      url: '',
      abstract: true,
      views: {
        'section': {
          templateUrl: 'dashboard/success-coach/dashboard-success-coach.html'
        }
      },
      ncyBreadcrumb: {skip: true}
    }).state('successcoach.cohorts.objectives.studentlist', {
      url: '?:dashboard',
      views: {
        'component': {
          templateUrl: 'dashboard/success-coach/cohorts/success-coach-cohorts.html'
        }
      },
      ncyBreadcrumb: {
        label: 'All Students',
        parent: 'successcoach.cohorts'
      }
    }).state('successcoach.cohorts.objectives.courses', {
      url: '/:userId/courses',
      views: {
        'component': {
          controller: 'SuccessCoachCoursesListController',
          templateUrl: 'dashboard/success-coach/cohorts/success-coach-courses-list.html'
        }
      },
      ncyBreadcrumb: {
        label: '{{vm.user.first_name || user.user_first_name || user.first_name }} {{vm.user.last_name || user.user_last_name || user.last_name}}',
        parent: 'successcoach.cohorts.objectives.studentlist'
      }
    }).state('successcoach.cohorts.objectives.progress', {
      url: '/:userId/gradebook/:cohortId?page&sort&direction&gb_page&gb_sort&gb_direction&search&weight',
      abstract: true,
      views: {
        'component': {
          template: '<div ui-view="page"><div/>'
        }
      }
    }).state('successcoach.cohorts.objectives.progress.details', {
      url: '',
      views: {
        'page': {
          controller: 'CoachUsersProgressCtrl',
          templateUrl: 'dashboard/success-coach/users/progress/coach-users-progress.html'
        }
      },
      ncyBreadcrumb: {
        label: '{{cohort.curriculum_name}}',
        parent: 'successcoach.cohorts.objectives.courses'
      }
    }).state('successcoach.cohorts.objectives.progress.activity', {
      url: '/activity/:activityId',
      views: {
        'page': {
          controller: 'CoachUsersProgressActivityCtrl',
          templateUrl: 'dashboard/success-coach/users/progress/activity/coach-progress-activity.html'
        }
      },
      ncyBreadcrumb: {
        label: '{{activity.description}}',
        parent: 'successcoach.cohorts.objectives.progress.details'
      }
    });
  }
}());
