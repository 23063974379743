(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoAvailableCourses', eoAvailableCourses);

  function eoAvailableCourses($rootScope, $timeout, $stateParams,
    Institution, TableFeaturesService
  ) {
    return {
      templateUrl: 'components/eo-available-courses/eo-available-courses.html',
      scope: {
        managePermission: '=?'
      },
      controller: eoAvailableCoursesCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoAvailableCoursesCtrl($scope) {
      var vm = this, contextId, courseId, tableFeatures;

      contextId = parseInt($stateParams.contextId, 10);
      courseId = parseInt($stateParams.id, 10);
      vm.isFilterCollapsed = true;
      vm.checkedCourses = [];

      $timeout(function() {
        $scope.$emit('eoAvailableCourses:handshake');
      }, 0);

      $rootScope.$on('eoAssignedCourses:handshake', function() {
        vm.manageMode = true;
      });

      $rootScope.$on('eoAvailableCourses:reload', function() {
        vm.loadAvailableCourses();
      });

      tableFeatures = new TableFeaturesService({
        sorting: {column: 'position'},
        pagination: {
          perPage: 10,
          scrollTop: false
        },
        search: {published_not_eq: true},
        loadData: function(queryParams) {
          queryParams.id = courseId;
          return Institution.one(contextId).getList('curriculums', queryParams);
        }
      });

      vm.table = {
        searchData: tableFeatures.searchParams,
        paginationData: tableFeatures.paginationParams
      };

      vm.loadAvailableCourses = function() {
        vm.checkedCourses.length = 0;
        tableFeatures.getPageData().then(function(response) {
          vm.availableCourses = response[0].data;
        });
      };

      vm.search = function() {
        tableFeatures.getNewData().then(function(response) {
          vm.availableCourses = response[0].data;
        });
      };

      vm.checkCourse = function(course) {
        if (course.checked) {
          vm.checkedCourses.push(course.id);
        } else {
          _.pull(vm.checkedCourses, course.id);
        }
      };

      vm.setCheckedState = function(state) {
        vm.checkedCourses.length = 0;
        _.each(vm.availableCourses, function(course) {
          course.checked = state;
          if (state) {
            vm.checkedCourses.push(course.id);
          } else {
            vm.checkedCourses.length = 0;
          }
        });
      };

      vm.addSelectedCourses = function() {
        Institution.one(contextId).one('curriculums', courseId).post('children', {'children_ids': vm.checkedCourses}).then(function() {
          vm.loadAvailableCourses();
          vm.checkedCourses.length = 0;
          $scope.$emit('eoAssignedCourses:reload');
        });
      };

      vm.loadAvailableCourses();
    }
  }

}());
