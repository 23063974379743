(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoNotification', eoNotification);

  function eoNotification($state, $stateParams,  Notification, NotificationsService) {
    return {
      replace: true,
      require: '^eoNotificationControls',
      templateUrl: 'components/eo-notification/eo-notification.html',
      scope: {
        notification: '='
      },
      link: eoNotificationLinkFn
    };

    function eoNotificationLinkFn(scope, element, attrs, controlsCtrl) {
      var notificationClass, notificationId, icon = element.find('.notification-icon .glyphicon');

      if (scope.notification && scope.notification.event) {
        defineNotificationType(scope.notification.event);
      }

      notificationId = parseInt($stateParams.id, 10);
      scope.isRead = scope.notification.read_at ? true : false;
      scope.isActive = scope.notification.id === notificationId;

      controlsCtrl.setNotificationElement(element);

      function defineNotificationType(notificationType) {
        switch (notificationType) {
          case 'mastered':
            icon.addClass('glyphicon-ok');
            notificationClass = 'notification-mastered';
            break;
          case 'failed':
            icon.addClass('glyphicon-remove');
            notificationClass = 'notification-failed';
            break;
          case 'at_risk':
            icon.addClass('glyphicon-exclamation-sign');
            notificationClass = 'notification-at-risk';
            break;
          case 'inactive_students':
            icon.addClass('glyphicon-info-sign');
            notificationClass = 'notification-inactive-students';
            break;
          case 'awaiting_grading':
            icon.addClass('glyphicon-info-sign');
            notificationClass = 'notification-awaiting-grading';
            break;
          case 'new_score_comment':
            icon.addClass('glyphicon-info-sign');
            notificationClass = 'notification-new-score-comment';
            break;
          case 'success':
            icon.addClass('glyphicon-ok');
            notificationClass = 'notification-mastered';
            break;
        }
      }

      function markAsRead() {
        if (scope.isActive && !scope.isRead) {
          scope.isRead = true;
          Notification.one('mark_read').patch({ids: [notificationId]}).then(function(response) {
            scope.$emit('eo-notification:mark_read', 1);
            NotificationsService.updateNotification(response.data[0]);
          });
        }
      }

      scope.removeNotification = function() {
        if (scope.isActive) {
          Notification.one().customDELETE('', {'notification_ids[]': notificationId}).then(function() {
            NotificationsService.deleteNotification(scope.notification);
          });
        }
      };

      scope.getNotificationCircleClass = function() {
        return notificationClass;
      };

      scope.$watch('selected', function(selectValue) {
        if (selectValue) {
          controlsCtrl.selectNotification(element);
        } else {
          controlsCtrl.unselectNotification(element);
        }
      });

      scope.toggleActiveState = function() {
        if ($state.includes('student.**')) {
          if (scope.isActive) {
            $state.go('student.notifications', {}, {reload: true});
          } else {
            $state.go('student.notifications.detail', {id: scope.notification.id}, {reload: true});
          }
        } else if ($state.includes('instructor.**')) {
          if (scope.isActive) {
            $state.go('instructor.notifications', {}, {reload: true});
          } else {
            $state.go('instructor.notifications.detail', {id: scope.notification.id}, {reload: true});
          }
        } else if ($state.includes('successcoach.**')) {
          if (scope.isActive) {
            $state.go('successcoach.notifications', {}, {reload: true});
          } else {
            $state.go('successcoach.notifications.detail', {id: scope.notification.id}, {reload: true});
          }
        }
      };

      element.on('$destroy', function() {
        controlsCtrl.unsetNotificationElement(element);
      });

      markAsRead();
    }
  }
}());
