(function() {
  'use strict';

  angular
    .module('ng2Web')
    .controller('AppCtrl', AppCtrl);

  function AppCtrl($rootScope, $state, $scope, Idle, $uibModal, $location,
    $uibModalStack, UserService, gettextCatalog, amMoment, EnvConfig,
    localStorageService, SecurityManagerService, SessionStorageService) {

    //Functionlity to hide the elements in header on activityLaunch
    $scope.isActivityLaunched = false;
    $scope.establishDate = new Date().getFullYear();
    $scope.$on('activityLaunched', function() {
      $scope.isActivityLaunched = true;
    });

    var writeUsername = function(firstName, lastName) {
        $scope.userName = firstName + ' ' + lastName;
      };

    var setup = function() {
      return UserService.loadProfile().then(function(profile) {
        if (!profile) {
          return;
        }

        //set institution context
        $scope.currentInstitutionContext = profile.institution_id;

        // set language:
        gettextCatalog.setCurrentLanguage(profile.language_preference);
        amMoment.changeLocale(profile.language_preference);
        writeUsername(profile.first_name, profile.last_name);

        // Provide user's institution white label settings
        $scope.institutionWhiteLabel = profile.institution_white_label;

        // Show/hide webex icon
        $scope.institutionHasWebex = profile.webex;

        return profile;
      });
    };

    var onLogin = function() {
      $scope.loggedIn = true;
      $scope.startIdle();
      $scope.showHelp = true;
      return setup();
    };

    $rootScope.dev_mode = (EnvConfig.build_mode === 'dev');

    // High-contrast setting: if truthy, on; else, off.
    $scope.highContrast = localStorageService.get('highContrast');

    $scope.signOut = function() {
      if (UserService.isMasqueradeMode()) {
        var cohortId, userId, masqueradeRole;

        cohortId = localStorageService.get('sourceCohort') || 'all';
        userId = localStorageService.get('sourceUser');

        UserService.signOutAsStudent().then(function() {
          //refresh notifications, sessions and messages counters
          $scope.$emit('badge-counter:refresh');
          SecurityManagerService.authorize(['isSuccessCoach']).then(function(hasPermission) {
            if (hasPermission) {
              masqueradeRole = localStorageService.get('masqueradeRole');
              localStorageService.remove('masqueradeRole');

              if (masqueradeRole === 'successcoach') {
                $state.go('successcoach.cohorts.objectives.courses', {userId: userId, id: 'all', state: 'all'});
              } else {
                $state.go('instructor.cohorts.objectives.studentlist', {id: cohortId, state: 'all'});
              }
            } else {
              SecurityManagerService.authorize(['isInstructor']).then(function(hasPermission) {
                if (hasPermission) {
                  $state.go('instructor.cohorts.objectives.studentlist', {id: cohortId, state: 'all'});
                }
              });
            }
          });
        });
      } else {
        UserService.signout()
          .then(function() {
            // Set white label branding styles to default
            $scope.$broadcast('eo-white-label-branding:default');
            SessionStorageService.clearAll();
            localStorageService.remove('role');
            $scope.$emit('cache:invalidate');
            $scope.loggedIn = false;
            $scope.showHelp = false;
            $state.go('signin');
          });
      }
    };

    $scope.signOutFromMasqueradeMode = function() {
      UserService.signOutAsStudent().then(function() {
        UserService.signout().then(function() {
          $scope.$broadcast('eo-white-label-branding:default');
          SessionStorageService.clearAll();
          $scope.$emit('cache:invalidate');
          $scope.loggedIn = false;
          $state.go('signin');
        });
      });
    };

    $scope.toggleContrast = function() {
      var newVal = ($scope.highContrast) ? null : 'on' ;
      if (localStorageService.set('highContrast', newVal)) {
        $scope.highContrast = newVal;
      }
    };

    /* Idle user timeout */

    function closeModals() {
      if ($scope.warning) {
        $scope.warning.close();
        $scope.warning = null;
      }
    }

    $scope.$on('IdleStart', function() {
      closeModals();
      if ($scope.loggedIn) {
        $scope.warning = $uibModal.open({
          templateUrl: 'core/templates/ng-idle/ng-idle-warning-dialog.html',
          windowClass: 'modal-danger'
        });
      }
    });

    $scope.$on('IdleEnd', function() {
      closeModals();
    });

    $scope.$on('IdleTimeout', function() {
      closeModals();
      $uibModalStack.dismissAll();
      if (UserService.isMasqueradeMode()) {
        $scope.signOutFromMasqueradeMode();
      } else {
        $scope.signOut();
      }
    });

    $scope.startIdle = function() {
      closeModals();
      Idle.watch();
      $scope.started = true;
    };

    $scope.stopIdle = function() {
      closeModals();
      Idle.unwatch();
      $scope.started = false;
    };

    if (UserService.signedIn()) {
      onLogin();
    }

    $scope.$on('login', function() {
      onLogin().then(function() {
        SecurityManagerService.authorize(['isStudent']).then(function() {
          // shows help page on the first login
          if ($state.is('signin') || $state.is('change_password')) {
            $state.go('dashboard');
          }

          // Remove the refresh token from the URL which launching from LTI
          $location.search(_.omit($location.search(), 'refresh_token'));
        });
      });
    });

    $scope.redirectToUserProfile = function() {
      if ($state.includes('admin.**')) {
        $state.go('admin.profile.show');
      } else if ($state.includes('student.**')) {
        $state.go('student.profile.show');
      } else if ($state.includes('instructor.**')) {
        $state.go('instructor.profile.show');
      } else if ($state.includes('successcoach.**')) {
        $state.go('successcoach.profile.show');
      } else {
        $state.go('dashboard');
      }
    };

    $scope.$on('update-user-name', function(e, profile) {
      writeUsername(profile.first_name, profile.last_name);
    });

    $scope.isMasqueradeMode = function() {
      return UserService.isMasqueradeMode();
    };

    // Logging in through LTI using refresh_token
    if ($location.search().refresh_token) {
      // Load user profile so that initiate access_token request
      UserService.loadProfile().then(function() {
        $scope.$emit('login');
      });
    }
    $rootScope.$on('auth:unauthorized', function() {
      $scope.loggedIn = false;
    });
  }
}());
