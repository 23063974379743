(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.programs')
    .controller('AdminProgramsListCtrl', AdminProgramsListCtrl);

  function AdminProgramsListCtrl($scope, $stateParams, $location,
    TableFeaturesService, Institution) {

    var tableFeature = new TableFeaturesService({
      sorting: {column: 'name'},
      search: {
        'name_cont': $stateParams.name_cont
      },
      loadData: function(queryParams) {
        return Institution.one($stateParams.contextId).getList('programs', queryParams);
      }
    });

    $scope.table = {
      searchData: tableFeature.searchParams,
      sortingData: tableFeature.sortingParams,
      paginationData: tableFeature.paginationParams
    };

    $scope.search = function() {
      tableFeature.resetEmptySearchData();
      $location.search($scope.table.searchData);
      tableFeature.getNewData().then(function(response) {
        $scope.programs = response[0].data;
      });
    };

    $scope.loadPage = function() {
      tableFeature.getPageData().then(function(response) {
        $scope.programs = response[0].data;
      });
    };

    $scope.reset = function() {
      tableFeature.resetSearch();
      $scope.search();
    };

    $scope.disableClearOption = function() {
      var disable = true;

      _.each($scope.table.searchData, function(n, key) {
        if ($scope.table.searchData[key] !== void 0 && $scope.table.searchData[key].length > 0) {
          disable = false;
        }
      });

      return disable;
    };

    $scope.loadPage();
  }
}());
