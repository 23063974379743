(function() {
  'use strict';

  /**
   * ViewState is a value service which holds current and previous state of ui-router.
   * This service was created because when $state is required in a 'run' phase it causes
   * breaks of unit tests with unknown reason. This service is filled with values on a run phase (see app.js).
   */

  angular
    .module('tcm.common')
    .value('ViewState', {
      current: {},
      previous: {}
    });
}());
