(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.activities')
    .controller('AdminActivitiesCreateCtrl', AdminActivitiesCreateCtrl);

  function AdminActivitiesCreateCtrl($state, $stateParams, Activity,
    SpinnerService, ValidationService, Institution, LtiProvider) {

    var vm = this, spinner, activityId, contextId, contentTypes, activityTypes, scoreOptions;

    spinner = new SpinnerService();
    activityId = parseInt($stateParams.id, 10);
    contextId = parseInt($stateParams.contextId, 10);

    vm.model = {};

    contentTypes = [
      {value: 'DiscussionActivity', label: 'Discussion Activity'},
      {value: 'AuthenticItem', label: 'Authentic Item'},
      {value: 'LtiProvider', label: 'Lti Provider'},
      {value: 'CdpItem', label: 'Cdp Item'}
    ];

    activityTypes = [
      {value: 'assessment', label: 'Assessment'},
      {value: 'web_content', label: 'Web content'},
      {value: 'assignment', label: 'Assignment'},
      {value: 'blti', label: 'BLTI'}
    ];

    scoreOptions = [
      {value: 'highest', label: 'Highest'},
      {value: 'lowest', label: 'Lowest'},
      {value: 'average', label: 'Average of all attempts'},
      {value: 'latest', label: 'Last Attempt'}
    ];

    vm.fields = [
      {
        key: 'owner_institution_id',
        type: 'ui-select',
        className: 'col-md-6',
        templateOptions: {
          required: true,
          type: 'text',
          options: [],
          label: 'Institution',
          placeholder: 'Select an institution',
          valueProp: 'id',
          labelProp: 'institution_name',
          disabled: true
        },
        /* @ngInject */
        controller: function($scope, TableFeaturesService) {
          var tableFeatures = new TableFeaturesService({
            pagination: {perPage: 100},
            sorting: {column: 'name'},
            search: {id_eq: contextId},
            loadData: function(queryParams) {
              return Institution.getList(queryParams);
            }
          });

          tableFeatures.getPageData().then(function(response) {
            $scope.to.options = response[0].data;
            vm.model.owner_institution_id = response[0].data[0].id;
          });
        }
      },
      {
        key: 'content_type',
        type: 'ui-select',
        className: 'col-md-6',
        templateOptions: {
          required: true,
          type: 'text',
          options: contentTypes,
          label: 'Content Type',
          placeholder: 'Select a content type',
          valueProp: 'value',
          labelProp: 'label'
        },
        expressionProperties: {
          'templateOptions.disabled': function() {
            return vm.isEditMode;
          }
        },
        validators: {
          required: function($viewValue, $modelValue) {
            return !_.isEmpty($modelValue) || vm.isEditMode;
          }
        }
      },
      {
        key: 'content_id',
        type: 'ui-select',
        className: 'col-md-offset-6 col-md-6 content-id',
        templateOptions: {
          required: true,
          type: 'text',
          options: [],
          label: 'Lti Provider',
          placeholder: 'Select a Lti Provider',
          valueProp: 'id',
          labelProp: 'name'
        },
        hideExpression: function() {
          if (vm.model.content_type !== 'LtiProvider') {
            delete vm.model.content_id;
          }

          return vm.model.content_type !== 'LtiProvider';
        },
        expressionProperties: {
          'templateOptions.disabled': function() {
            return vm.isEditMode;
          }
        },
        /* @ngInject */
        controller: function($scope, TableFeaturesService) {
          var tableFeatures = new TableFeaturesService({
            pagination: {perPage: 100},
            sorting: {column: 'name'},
            loadData: function(queryParams) {
              return LtiProvider.getList(queryParams);
            }
          });

          tableFeatures.getPageData().then(function(response) {
            $scope.to.options = response[0].data;
          });
        }
      },
      {
        key: 'activity_type',
        type: 'ui-select',
        className: 'col-md-6',
        templateOptions: {
          required: true,
          type: 'text',
          options: activityTypes,
          label: 'Activity Type',
          placeholder: 'Select an activity type',
          valueProp: 'value',
          labelProp: 'label'
        },
        expressionProperties: {
          'templateOptions.disabled': function() {
            return vm.isEditMode;
          }
        }
      },
      {
        key: 'identifier',
        type: 'input',
        className: 'col-md-6',
        templateOptions: {
          required: true,
          type: 'text',
          label: 'Prefix',
          maxlength: 15
        },
        hideExpression: function() {
          return vm.isEditMode;
        }
      },
      {
        key: 'description',
        type: 'input',
        className: 'col-md-6',
        templateOptions: {
          required: true,
          type: 'text',
          label: 'Description'
        }
      },
      {
        key: 'proposed_hours',
        type: 'input',
        className: 'col-md-6',
        defaultValue: 0,
        templateOptions: {
          type: 'number',
          label: 'Proposed Hours',
          max: 2147483647
        },
        validators: {
          positiveNumber: ValidationService.validatePositiveNumber(),
          integer: ValidationService.validateInteger()
        }
      },
      {
        key: 'mastery_score',
        type: 'input',
        className: 'col-md-6',
        templateOptions: {
          required: true,
          type: 'number',
          label: 'Mastery Score',
          max: 100
        },
        validators: {
          positiveNumber: ValidationService.validatePositiveNumber(),
          integer: ValidationService.validateInteger()
        }
      },
      {
        key: 'type_of_score',
        type: 'ui-select',
        className: 'col-md-6',
        templateOptions: {
          required: true,
          type: 'text',
          options: scoreOptions,
          label: 'Type Of Score',
          placeholder: 'Select a score type',
          valueProp: 'value',
          labelProp: 'label'
        }
      },
      {
        key: 'rubric_id',
        type: 'ui-select',
        className: 'col-md-6',
        templateOptions: {
          label: 'Rubric',
          options: [],
          placeholder: 'Select a rubric',
          valueProp: 'id',
          labelProp: 'title'
        },
        expressionProperties: {
          'templateOptions.disabled': '!model.owner_institution_id || !model.gradable'
        },
        /* @ngInject */
        controller: function($scope, TableFeaturesService) {
          var tableFeatures = new TableFeaturesService({
            pagination: {perPage: 1000},
            search: {published_eq: true},
            sorting: {column: 'name'},
            loadData: function(queryParams) {
              return Institution.one(vm.model.owner_institution_id).getList('rubrics', queryParams);
            }
          });

          $scope.$watch('model.owner_institution_id', function(institutionId) {
            if (institutionId) {
              if (vm.model.rubric_id && !vm.isEditMode) {
                delete vm.model.rubric_id;
              }

              tableFeatures.getPageData().then(function(response) {
                $scope.to.options = response[0].data;
              });
            }
          });
        }
      },
      {
        key: 'requires_instructor_grading',
        type: 'checkbox',
        className: 'col-md-2',
        templateOptions: {
          label: 'Requires Instructor Grading'
        }
      },
      {
        key: 'read_only',
        type: 'checkbox',
        className: 'col-md-2',
        templateOptions: {
          label: 'Read only Activity'
        }
      },
      {
        key: 'gradable',
        type: 'checkbox',
        className: 'col-md-2',
        templateOptions: {
          label: 'Gradable'
        }
      },
      {
        key: 'instructions',
        type: 'ta-editor',
        className: 'col-md-12',
        templateOptions: {
          label: 'Instructions'
        },
        hideExpression: function() {
          return vm.isEditMode && vm.model.content_type === 'CdpItem';
        }
      }
    ];

    vm.save = function() {
      if (vm.createLearningActivityForm.$valid) {
        vm.model.rubric_id = !vm.model.gradable? 0 : vm.model.rubric_id;

        if (vm.isEditMode) {
          vm.model.patch().then(function(response) {
            $state.go('admin.activities.show', {id: response.data.id});
          });
        } else {
          Activity.post(vm.model).then(function(response) {
            $state.go('admin.activities.show', {id: response.data.id});
          });
        }
      }
    };

    function loadLearningActivityToEdit() {
      vm.isEditMode = false;
      if (activityId) {
        vm.isEditMode = true;
        spinner.activateController([
          Activity.one(activityId).get().then(function(response) {
            vm.model = response.data;
            vm.activity = vm.model; //breadcrumb label
            vm.model.proposed_hours = vm.model.proposed_hours || 0;
          })
        ]);
      } else {
        contentTypes.splice(3, 1); //remove CdpItem on creation
        vm.model.content_type = contentTypes[0].value; // set discussion by default
        vm.model.activity_type = activityTypes[0].value;
        vm.model.mastery_score = 80;
        vm.model.type_of_score = scoreOptions[0].value;
        spinner.activateController([]);
      }
    }

    loadLearningActivityToEdit();
  }
}());
