/*globals moment:false */
(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.courses')
    .controller('AdminCoursesCohortsListCtrl', AdminCoursesCohortsListCtrl);

  function AdminCoursesCohortsListCtrl($stateParams, $location, SpinnerService, Institution,
    TableFeaturesService) {
    var vm = this, spinner, contextId, courseId, tableFeatures;

    spinner = new SpinnerService();
    contextId = parseInt($stateParams.contextId, 10);
    courseId = parseInt($stateParams.id, 10);
    vm.active_cohorts = $stateParams.active_cohorts || 'true';
    vm.isfilterCollapsed = true;
    vm.checkedCohorts = [];

    tableFeatures = new TableFeaturesService({
      sorting: {column: 'name'},
      loadData: function(queryParams) {
        queryParams.active_cohorts = vm.active_cohorts;
        return Institution.one(contextId).one('curriculums', courseId).getList('cohorts', queryParams);
      }
    });

    vm.cohortsList = {
      sortingData: tableFeatures.sortingParams,
      paginationData: tableFeatures.paginationParams
    };

    vm.loadPage = function() {
      vm.flash = '';
      return tableFeatures.getPageData().then(function(response) {
        clearCohortSelection();
        vm.cohorts = response[0].data;
      });
    };

    vm.search = function() {
      vm.flash = '';
      $location.search({active_cohorts: vm.active_cohorts});
      tableFeatures.getNewData().then(function(response) {
        vm.cohorts = response[0].data;
        clearCohortSelection();
      });
    };

    vm.checkCohort = function(cohort) {
      if (cohort.checked) {
        vm.checkedCohorts.push(cohort.id);
      } else {
        _.pull(vm.checkedCohorts, cohort.id);
      }

      vm.cohorts.checked = vm.checkedCohorts.length ? true : false;
    };

    vm.updateCohorts = function() {
      vm.flash = '';
      spinner.activateController([
        Institution.one(contextId).one('curriculums', courseId).post('update_cohorts', {
          'cohort_ids': vm.checkedCohorts
        }).then(function() {
          vm.flash = 'Changes were pushed successfully to the selected cohort(s)';
          clearCohortSelection();
        }, function() {
          clearCohortSelection();
        })
      ]);
    };

    vm.updateGrades = function() {
      vm.flash = '';
      spinner.activateController([
        Institution.one(contextId).one('curriculums', courseId).post('update_grades_percentages', {
          'cohort_ids': vm.checkedCohorts
        }).then(function() {
          vm.flash = 'Changes were pushed successfully to the selected cohort(s)';
          clearCohortSelection();
        }, function() {
          clearCohortSelection();
        })
      ]);
    };

    function clearCohortSelection() {
      if (vm.cohorts) {
        vm.cohorts.checked = false;
        vm.toggleCheckedState();
      }
    }

    vm.toggleCheckedState = function() {
      vm.checkedCohorts.length = 0;
      _.each(vm.cohorts, function(cohort) {
        cohort.checked = vm.cohorts.checked;
        if (vm.cohorts.checked && !isExpired(cohort.ends_on)) {
          vm.checkCohort(cohort);
        } else if (!vm.cohorts.checked) {
          vm.checkedCohorts.length = 0;
        }
      });
    };

    function isExpired(endDate) {
      return (endDate === void 0) ? false : moment(endDate).isBefore(new Date());
    }

    vm.isActiveCohort = function(cohort) {
      return !cohort.ends_on || (cohort.ends_on && !isExpired(cohort.ends_on));
    };

    function getCourse() {
      return Institution.one(contextId).one('curriculums', courseId).get().then(function(response) {
        vm.course = response.data;
      });
    }

    spinner.activateController([
      getCourse(),
      vm.loadPage()
    ]);
  }
}());
