(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.courses')
    .controller('AdminCoursesDetailCtrl', AdminCoursesDetailCtrl);

  function AdminCoursesDetailCtrl($stateParams, Institution, SpinnerService, $uibModal) {

    var vm = this, spinner, courseId, contextId, $uibModalInstance;

    spinner = new SpinnerService();
    contextId = parseInt($stateParams.contextId, 10);
    courseId = parseInt($stateParams.id, 10);

    vm.flash = null;
    vm.allActivitiesPublished = true;
    vm.allObjectivesPublished = true;

    vm.allItemsToggle = function(title) {
      var attr = 'all' + _.capitalize(title) + 'Published';

      if (vm[attr] === void 0) {
        return;
      }

      if (vm[attr]) {
        $uibModalInstance = $uibModal.open({
          animation: true,
          templateUrl: 'dashboard/admin/courses/detail/modal/confirm-courses-publication-modal.html',
          controllerAs: 'modal',
          controller: function CancelModalCtrl() {
            var modal = this;

            modal.title = title;

            modal.publish = function() {
              vm.togglePatch(title, attr);
              $uibModalInstance.close();
            };

            modal.cancel = function() {
              if (vm[attr] !== void 0) {
                vm[attr] = !vm[attr];
              }

              $uibModalInstance.close();
            };
          }
        });
      } else {
        vm.togglePatch(title, attr);
      }
    };

    vm.togglePatch = function(title, attr) {
      var param, modelAttr;

      modelAttr = 'all_' + title + '_published';

      if (vm[attr] === void 0 || vm.course[modelAttr] === void 0) {
        return;
      }

      param = vm[attr] ? 'publish' : 'draft';

      Institution.one(contextId).one('curriculums', courseId).one(title + '_' + param).patch().then(function() {
        vm.course[modelAttr] = vm[attr];
      }).catch(function(errorObj) {
        vm[attr] = !vm[attr];
        vm.flash = errorObj.data.error;
      });
    };

    function loadCourseDetails() {
      Institution.one(contextId).one('curriculums', courseId).get().then(function(response) {
        vm.course = response.data;
        vm.allActivitiesPublished = !!vm.course.all_activities_published;
        vm.allObjectivesPublished = !!vm.course.all_objectives_published;
      });
    }

    spinner.activateController([
      loadCourseDetails()
    ]);
  }
}());
