(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoCohortsList', eoCohortsList);

  function eoCohortsList($rootScope, $state, $stateParams, UserCohorts,
    SpinnerService, SessionStorageService, SortingService, localStorageService) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-cohorts-list/eo-cohorts-list.html',
      scope: {},
      controllerAs: 'vm',
      bindToController: true,
      controller: eoCohortsListCtrl
    };

    function eoCohortsListCtrl($attrs) {
      var vm = this, spinner, sorting, collapsedState, isMasqueradeMode;

      spinner = new SpinnerService();
      sorting = new SortingService({column: 'cohort_users.started_on', direction: 'desc'});
      vm.currentCohortId = $stateParams.id === 'all' ? 'all' : parseInt($stateParams.id, 10);
      collapsedState = SessionStorageService.get('eo-cohorts-list');
      vm.title = $attrs.title;

      vm.collapsed = {
        current: _.isNull(collapsedState) ? null : collapsedState.current,
        future: _.isNull(collapsedState) ? null : collapsedState.future,
        expired: _.isNull(collapsedState) ? null : collapsedState.expired
      };

      if (SessionStorageService.get('eo-cohorts')) {
        vm.cohorts = SessionStorageService.get('eo-cohorts');
      }

      if (localStorageService.get('isMasqueradeMode')) {
        isMasqueradeMode = localStorageService.get('isMasqueradeMode');
      }

      if (vm.collapsed.expired === null) {
        vm.collapsed.expired = true;
        SessionStorageService.set('eo-cohorts-list', JSON.stringify(vm.collapsed));
      }

      if (vm.collapsed.future === null) {
        vm.collapsed.future = true;
        SessionStorageService.set('eo-cohorts-list', JSON.stringify(vm.collapsed));
      }

      function initController() {
        if ($stateParams.dashboard === 'dashboard') {
          return;
        }

        if (isMasqueradeMode) {
          return loadCohorts().then(function(){
            SessionStorageService.set('eo-cohorts', vm.cohorts);
            isMasqueradeMode = false;
          });
        }

        if (vm.cohorts && _.isObject(vm.cohorts)) {
          return getCohortGroup(vm.currentCohortId);
        }

        return loadCohorts();
      }

      function loadCohorts() {
        var queryParams = {};

        sorting.addParams(queryParams);

        return spinner.activateController([ UserCohorts.getGroupedCohorts($attrs.role, queryParams).then(function(response) {
          vm.cohorts = response.data;
        })]);
      }

      function getCohortGroup(cohortId) {
        return _.find(vm.cohorts, function(group, name) {
          if (group.length) {
            return _.find(group, function (cohort) {
              if (cohort.id && cohort.id === cohortId) {
                return resetCollapse(name);
              }
            });
          }
        });
      }

      function resetCollapse(sectionName) {
        collapsedState = SessionStorageService.get('eo-cohorts-list');

        vm.collapsed = {
          current: _.isNull(collapsedState) ? null : collapsedState.current,
          future: _.isNull(collapsedState) ? null : collapsedState.future,
          expired: _.isNull(collapsedState) ? null : collapsedState.expired
        };

        _.forEach(vm.collapsed, function(val, key) {
          if (vm.collapsed[key] !== null || key === sectionName) {
            vm.collapsed[key] = true;
          }
        });

        vm.setCollapsedState(sectionName);
      }

      vm.isCurrentSection = function(cohortId) {
        return (vm.currentCohortId === cohortId &&
          ($state.includes('student.cohorts.**') ||
            $state.includes('instructor.cohorts.**') ||
            $state.includes('successcoach.cohorts.**')));
      };

      vm.setCollapsedState = function(sectionName) {
        vm.collapsed[sectionName] = !vm.collapsed[sectionName];
        SessionStorageService.set('eo-cohorts-list', JSON.stringify(vm.collapsed));
      };

      vm.isInstructor = function() {
        return $attrs.role === 'Instructor';
      };

      vm.redirectToViewCohort = function(cohortId) {
        if (vm.isInstructor()) {
          $state.go('instructor.cohorts.objectives.studentlist', {id: cohortId});
        } else {
          $rootScope.$broadcast('user-objectives-cache:invalidate');
          $state.go('student.cohorts.objectives.list', {id: cohortId, state: 'all'});
        }
      };

      initController();
    }
  }
}());
