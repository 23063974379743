(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('HasManyService', HasManyService);

  function HasManyService($q) {
    var hasMany;

    var HasMany = function(config) {
      hasMany  = this;
      hasMany.checkedItems = [];

      config = config || {};

      hasMany.data = {
        selected: config.selected || [],
        available: config.available || []
      };

      hasMany.onAdd = config.onAdd || angular.noop;
      hasMany.onRemove = config.onRemove || angular.noop;

      hasMany.markSelected();
    };

    HasMany.prototype.loadSelected = function(selected) {
      $q.when(selected).then(function(response) {
        return push(hasMany, hasMany.data.selected, response);
      });
    };

    HasMany.prototype.resetSelected = function(selected) {
      $q.when(selected).then(function(response) {
        hasMany.data.selected.splice(0);
        return push(hasMany, hasMany.data.selected, response);
      });
    };

    HasMany.prototype.loadAvailable = function(available) {
      $q.when(available).then(function(response) {
        return push(hasMany, hasMany.data.available, response);
      });
    };

    HasMany.prototype.resetAvailable = function(available) {
      $q.when(available).then(function(response) {
        hasMany.data.available.splice(0);
        hasMany.checkedItems.length = 0;
        return push(hasMany, hasMany.data.available, response);
      });
    };

    HasMany.prototype.add = function(obj) {
      obj.selected = true;
      hasMany.data.selected.push(obj);
      hasMany.onAdd(obj);
    };

    /**
     * @param {Boolean} noRefresh set to true if removed
     * item from selected list is required to be pushed
     * to the available list
     */
    HasMany.prototype.remove = function(obj, noRefresh) {
      var available, selected;
      obj.selected = false;

      available = _.find(hasMany.data.available, { id: obj.id });
      selected = _.findIndex(hasMany.data.selected, { id: obj.id });

      if (available) {
        available.selected = false;
        available.checked = false;
      } else if (noRefresh === void 0) {
        hasMany.data.available.push(obj);
      }

      if (selected !== -1) {
        hasMany.data.selected.splice(selected, 1);
      }

      hasMany.onRemove(obj);
    };

    HasMany.prototype.isSelected = function(obj) {
      return !!_.find(hasMany.data.selected, { id: obj.id });
    };

    HasMany.prototype.markSelected = function() {
      var selected = hasMany.data.selected;
      _.each(hasMany.data.available, function(related) {
        related.selected = !!_.find(selected, { id: related.id });
      });
    };

    function push(hasManyParam, array, collection) {
      Array.prototype.push.apply(array, collection.data || collection);
      hasManyParam.markSelected();
      return array;
    }

    HasMany.prototype.setCheckedItem = function(obj) {
      if (obj.checked === true && hasMany.checkedItems.indexOf(obj.id) === -1) {
        hasMany.checkedItems.push(obj.id);
      } else {
        _.pull(hasMany.checkedItems, obj.id);
      }
    };

    HasMany.prototype.getCheckedItems = function() {
      return hasMany.checkedItems;
    };

    HasMany.prototype.checkAllItems = function() {
      hasMany.checkedItems.length = 0;
      _.each(hasMany.data.available, function(item) {
        item.checked = true;
        if (!hasMany.isSelected(item)) {
          hasMany.checkedItems.push(item.id);
        }
      });
    };

    HasMany.prototype.addCheckedItems = function() {
      _.each(hasMany.data.available, function(item) {
        if (item.checked && !item.selected) {
          item.selected = true;
          hasMany.data.selected.push(item);
        }
      });

      hasMany.onAdd(hasMany.checkedItems.slice(0));
      hasMany.checkedItems.length = 0;
    };

    return HasMany;
  }
}());
