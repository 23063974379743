(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoComment', eoComment);

  function eoComment() {
    return {
      scope: {
        comment: '='
      },
      templateUrl: 'components/eo-comment/eo-comment.html'
    };
  }
}());
