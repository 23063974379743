(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoAvailableCourseObjectives', eoAvailableCourseObjectives);

  function eoAvailableCourseObjectives($rootScope, $timeout, $stateParams,
    Institution, Objective, TableFeaturesService
  ) {
    return {
      templateUrl: 'components/eo-available-course-objectives/eo-available-course-objectives.html',
      scope: {
        managePermission: '=?'
      },
      controller: eoAvailableCourseObjectivesCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoAvailableCourseObjectivesCtrl($scope) {
      var vm = this, tableFeatures, contextId, courseId;

      contextId = parseInt($stateParams.contextId, 10);
      courseId = parseInt($stateParams.id, 10);
      vm.checkedObjectives = [];
      vm.isFilterCollapsed = true;

      $timeout(function() {
        $scope.$emit('eoAvailableCourseObjectives:handshake');
      }, 0);

      $rootScope.$on('eoAssignedCourseObjectives:handshake', function() {
        vm.manageMode = true;
      });

      $rootScope.$on('eoAvailableCourseObjectives:reload', function() {
        vm.loadAvailableCourseObjectives();
      });

      tableFeatures = new TableFeaturesService({
        sorting: false,
        search: { published_eq: true},
        pagination: {
          perPage: 10,
          scrollTop: false
        },
        loadData: function(queryParams) {
          queryParams.curriculum_id = courseId;
          queryParams.context_id = contextId;
          return Objective.getList(queryParams);
        }
      });

      vm.table = {
        searchData: tableFeatures.searchParams,
        paginationData: tableFeatures.paginationParams
      };

      vm.loadAvailableCourseObjectives = function() {
        vm.checkedObjectives.length = 0;
        tableFeatures.getPageData().then(function(response) {
          vm.availableObjectives = response[0].data;
        });
      };

      vm.search = function() {
        tableFeatures.getNewData().then(function(response) {
          vm.availableObjectives = response[0].data;
        });
      };

      vm.checkObjective = function(objective) {
        if (objective.checked) {
          vm.checkedObjectives.push(objective.id);
        } else {
          _.pull(vm.checkedObjectives, objective.id);
        }
      };

      vm.setCheckedState = function(state) {
        vm.checkedObjectives.length = 0;
        _.each(vm.availableObjectives, function(objective) {
          objective.checked = state;
          if (state) {
            vm.checkedObjectives.push(objective.id);
          } else {
            vm.checkedObjectives.length = 0;
          }
        });
      };

      vm.addSelectedObjectives = function() {
        Institution.one(contextId).one('curriculums', courseId).post('objectives', {'lo_ids': vm.checkedObjectives}).then(function() {
          vm.loadAvailableCourseObjectives();
          vm.checkedObjectives.length = 0;
          $scope.$emit('eoAssignedCourseObjectives:reload');
        });
      };

      vm.loadAvailableCourseObjectives();
    }
  }
}());
