(function() {
  'use strict';

  angular
    .module('tcm.reports.competencies')
    .controller('CompetenciesReportCtrl', CompetenciesReportCtrl);

  // TODO:
  // See how loadActivities() on AdminCurriculumStructureCtrl patches the activities response to
  // the scope. Apply it on getLearningObjectives() to avoid dependencies on angular ui tree scope.
  function CompetenciesReportCtrl($state, $stateParams,
    $timeout, $q, $window, UserPrograms, SpinnerService,
    UserService, UserCompetencies, TableFeaturesService,
    PaginationService, SortingService, User, $scope) {
    var vm = this, spinner, sorting, tableFeature;

    vm.objectivesLoaded = false;
    vm.printObjectives = false;

    spinner = new SpinnerService();
    sorting = new SortingService({column: 'mastered_at', direction: 'desc'});

    tableFeature = new TableFeaturesService({
      sorting: {column: 'title'},
      search: {state_eq: 'mastered'},
      pagination: false,
      loadData: function(queryParams) {
        return UserService.loadProfile().then(function(profile) {
          return UserCompetencies.getUserCompetenciesGroupedByTags(parseInt($stateParams.userId, 10) || profile.id, parseInt($stateParams.id, 10), queryParams);
        });
      }
    });

    vm.date = new Date();

    vm.table = {
      searchData: tableFeature.searchParams,
      sortingData: tableFeature.sortingParams,
      paginationData: tableFeature.paginationParams
    };

    vm.loadCompetencies = function loadCompetencies(state) {
      vm.objectivesLoaded = false;
      tableFeature.setSearchQueryParamsByState(state);
      setSortingQuery(state);
      tableFeature.getPageData().then(function(response) {
        vm.tags = response[0].data;
        vm.currentView = state;

        vm.emptyProgram = !checkCompetencies(vm.tags);
      });
    };

    function setSortingQuery(state) {
      switch (state) {
        case 'in_progress':
          vm.table.sortingData.column = 'started_at';
          vm.table.sortingData.direction = 'asc';
          break;
        case 'mastered':
          vm.table.sortingData.column = 'mastered_at';
          vm.table.sortingData.direction = 'desc';
          break;
        default:
          vm.table.sortingData.column = 'description';
          vm.table.sortingData.direction = 'asc';
          break;
      }
    }

    function checkCompetencies(tags) {
      var valid = false;

      _.each(tags, function(tag) {
        if (tag.competencies && tag.competencies.length) {
          valid = true;
        }
      });

      return valid;
    }

    function loadStudent() {
      if (parseInt($stateParams.userId, 10)) {
        return User.one(parseInt($stateParams.userId, 10)).get().then(function(profile) {
          vm.user = profile.data;
        });
      } else {
        return UserService.loadProfile().then(function(profile) {
          vm.user = profile;
        });
      }
    }

    function getCompetenciesCount() {
      var pagination = new PaginationService({perPage: 1});
      var queryParams = {};
      pagination.addParams(queryParams);
      return UserService.loadProfile().then(function(profile) {
        return UserCompetencies.getUserCompetenciesReportByProgram(parseInt($stateParams.userId, 10) || profile.id, parseInt($stateParams.id, 10), queryParams).then(function(response) {
          vm.total = response.headers()['x-total'] || '';
        });
      });
    }

    function getPrograms() {
      UserPrograms.flushCache();
      return UserPrograms.getPrograms().then(function(response) {
        vm.programs = response.data;
        vm.program = _.find(vm.programs, {id: parseInt($stateParams.id, 10)});
      });
    }

    function getChartsUrls() {
      return UserPrograms.getChartsUrls(parseInt($stateParams.id, 10)).then(function(chartUrls) {
        if (chartUrls !== void 0) {
          vm.donutUrl = chartUrls.donutUrl;
        }
      });
    }

    vm.printReport = function() {
      if (vm.printObjectives) {
        vm.loadLearningObjectives().then(function() {
          doPrint();
        });
      } else {
        doPrint();
      }
    };

    function doPrint() {
      $scope.$broadcast('donut:print', 170, 275);
      $timeout(function() {
        $window.print();
      }, 0);
    }

    vm.loadProgram = function(id) {
      if ($state.includes('admin')) {
        $state.go('admin.users.competencies', {userId: $stateParams.userId, id: id });
      } else if ($state.includes('instructor')) {
        $state.go('instructor.users.competencies', {id: id });
      } else if ($state.includes('successcoach')) {
        $state.go('successcoach.users.competencies', {id: id });
      } else {
        $state.go('student.programs.report', {id: id });
      }
    };

    vm.getLearningObjectives = function(node) {
      var nodeData = node.$modelValue;

      if (!nodeData.objectives) {
        spinner.activateController([
          getCompetencyObjectives(nodeData.id).then(function(response) {
            nodeData.objectives = response.data;
            node.toggle();
          })
        ]);
      } else {
        node.toggle();
      }
    };

    vm.toggleNodeCollapseState = function(expand) {
      var el, treeScope;

      el = document.querySelector('#competencies-list');
      treeScope = angular.element(el).scope();

      if (expand) {
        treeScope.expandAll();
      } else {
        treeScope.collapseAll();
      }
    };

    vm.loadLearningObjectives = function() {
      return $q(function(resolve) {
        if (vm.objectivesLoaded) {
          vm.expandAll();
          resolve();
        } else {
          spinner.activateController(getLearningObjectives()).then(function() {
            vm.objectivesLoaded = true;
            vm.expandAll();
            resolve();
          });
        }
      });
    };

    var getRootNodesScope = function() {
      return angular.element(document.getElementById('competencies-list')).scope();
    };

    vm.collapseAll = function() {
      var scope = getRootNodesScope();
      if (scope) {
        scope.collapseAll();
      }
    };

    vm.expandAll = function() {
      var scope = getRootNodesScope();
      if (scope) {
        scope.expandAll();
      }
    };

    function getLearningObjectives() {
      var promises = [];

      _.each(vm.tags, function(tag) {
        _.each(tag.competencies, function(competency) {
          var promise;

          promise = getCompetencyObjectives(competency.id);
          promise.then(function(response) {
            competency.objectives = response.data;
          });

          promises.push(promise);
        });
      });

      return promises;
    }

    function getCompetencyObjectives(id) {
      var queryParams = {};
      sorting.data.column = (vm.currentView !== 'mastered') ? 'started_at' : 'mastered_at';
      sorting.addParams(queryParams);

      return User.one(vm.user.id).one('competencies', id).getList('objectives', queryParams);
    }

    spinner.activateController([
      vm.loadCompetencies('mastered'),
      loadStudent(),
      getPrograms(),
      getChartsUrls(),
      getCompetenciesCount()
    ]);
  }
}());
