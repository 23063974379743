(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoInstitutionContext', eoInstitutionContext);

  function eoInstitutionContext($rootScope, $state, $stateParams,
    Institution, SortingService) {
    return {
      replace: true,
      scope: {},
      templateUrl: 'components/eo-institution-context/eo-institution-context.html',
      controller: eoInstitutionContextCtrl
    };

    function eoInstitutionContextCtrl($scope) {
      var sorting = new SortingService({column: 'name'});

      // Set user's institution as default value
      $scope.currentInstitutionContext = parseInt($stateParams.contextId, 10);

      $rootScope.$on('$stateChangeSuccess', function() {
        checkDropDownState();
        $scope.currentInstitutionContext = parseInt($stateParams.contextId, 10);
      });

      $rootScope.$on('institution:add', function() {
        loadUserInstitutions();
      });

      function checkDropDownState() {
        $scope.disableContextDropDown = true;
        if ($state.includes('admin.*.list') || $state.includes('admin.dashboard') || $state.includes('admin.courses.list')) {
          $scope.disableContextDropDown = false;
          if ($state.includes('admin.institutions.*') || $state.includes('admin.courses.manage')) {
            $scope.disableContextDropDown = true;
          }
        }
      }

      function loadUserInstitutions() {
        var queryParams = {};
        sorting.addParams(queryParams);
        Institution.getList(queryParams).then(function(response) {
          $scope.institutions = response.data;
        });
      }

      $scope.switchInstitutionContext = function() {
        // $state.go does not reload current state if reloadOnSearch: false
        $state.current.reloadOnSearch = true;
        $state.go($state.current.name, {contextId: $scope.currentInstitutionContext});
        $state.current.reloadOnSearch = false;
      };

      checkDropDownState();
      loadUserInstitutions();
    }
  }
}());
