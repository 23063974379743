(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.objectives')
    .controller('AdminObjectivesListCtrl', AdminObjectivesListCtrl);

  function AdminObjectivesListCtrl($scope, $stateParams, $location,
    Objective, TableFeaturesService) {

    var tableFeatures = new TableFeaturesService({
      sorting: {column: 'description'},
      search: {
        'description_cont': $stateParams.description_cont,
        'type_cont': $stateParams.type_cont,
        'owner_institution_id_eq': $stateParams.contextId
      },
      loadData: function(queryParams) {
        return Objective.getList(queryParams);
      }
    });

    $scope.userList = {
      searchData: tableFeatures.searchParams,
      sortingData: tableFeatures.sortingParams,
      paginationData: tableFeatures.paginationParams
    };

    $scope.loadPage = function() {
      tableFeatures.getPageData().then(function(response) {
        $scope.objectives = response[0].data;
      });
    };

    $scope.search = function() {
      tableFeatures.resetEmptySearchData();
      $scope.userList.searchData.owner_institution_id_eq = $stateParams.contextId;
      $location.search($scope.userList.searchData);
      tableFeatures.getNewData().then(function(response) {
        $scope.objectives = response[0].data;
      });
    };

    $scope.reset = function() {
      tableFeatures.resetSearch();
      $scope.search();
    };

    $scope.disableClearOption = function() {
      var disable = true;

      _.each($scope.userList.searchData, function(n, key) {
        if ($scope.userList.searchData[key] !== void 0 && $scope.userList.searchData[key].length > 0) {
          disable = false;
        }
      });

      return disable;
    };

    $scope.loadPage();
  }
}());
