(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoCompetenciesList', eoCompetenciesList);

  function eoCompetenciesList($stateParams, gettext, UserCompetencies, UserService, TableFeaturesService) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-competencies-list/eo-competencies-list.html',
      scope: {},
      controller: eoCompetenciesListCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoCompetenciesListCtrl() {
      var vm = this, tableFeatures, userId, programId;

      vm.state = $stateParams.state || 'all';
      userId = parseInt($stateParams.userId, 10);
      programId = parseInt($stateParams.id, 10);

      tableFeatures = new TableFeaturesService({
        sorting: {column: 'description'},
        pagination: {
          perPage: 10,
          scrollTop: false
        },
        loadData: function(queryParams) {
          return UserService.loadProfile().then(function(profile) {
            return UserCompetencies.getUserCompetenciesReportByProgram(userId || profile.id, programId, queryParams);
          });
        }
      });

      vm.table = {
        searchData: tableFeatures.searchParams,
        sortingData: tableFeatures.sortingParams,
        paginationData: tableFeatures.paginationParams
      };

      function setActiveTab(tab) {
        vm.activeTab = {};

        if (tab === 'in-progress') {
          tab = 'in_progress';
        }

        vm.activeTab[tab] = true;
      }

      vm.getTabData = function() {
        tableFeatures.setSearchQueryParamsByState(vm.state);
        tableFeatures.getPageData().then(function(response) {
          vm.competencies = response[0].data;
          setActiveTab(vm.state);
        });
      };

      //TODO: Candidate for a custom filter
      vm.status = function(competency) {
        if (competency.user_at_risk) {
          return gettext('At Risk');
        } else {
          return (competency.user_state_readable.toLowerCase() === 'unavailable') ? 'Not Enrolled' : competency.user_state_readable;
        }
      };

      setActiveTab(vm.state);
      vm.getTabData();
    }
  }
}());
