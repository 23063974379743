(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('StudentActivity', StudentActivityResource);

  function StudentActivityResource(Restangular, EnvConfig) {
    return Restangular.withConfig(function(RestangularConfigurer) {
      RestangularConfigurer.setBaseUrl(EnvConfig.main_url + '/api/ui');
      RestangularConfigurer.setFullResponse(true);
      RestangularConfigurer.setDefaultHeaders({
        'Content-Type': 'application/json'
      });
    }).service('student_activities');
  }
}());
