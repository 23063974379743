(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('WebexSessionsService', WebexSessionsService);

  function WebexSessionsService(TableFeaturesService, UserService, Institution) {
    var service = this, tableFeatures, tableFeaturesForPreview;

    tableFeatures = new TableFeaturesService({
      search: false,
      sorting: {column: 'scheduled_date', direction: 'ASC'},
      pagination: {scrollTop: false, perPage: 15},
      loadData: function(queryParams) {
        return UserService.loadProfile().then(function(profile) {
          return Institution.one(profile.institution_id).one('webex_meetings').getList(profile.id, queryParams);
        });
      }
    });

    tableFeaturesForPreview = new TableFeaturesService({
      pagination: {scrollTop: false, perPage: 3},
      sorting: {column: 'scheduled_date', direction: 'ASC'},
      loadData: function(queryParams) {
        return UserService.loadProfile().then(function(profile) {
          return Institution.one(profile.institution_id).one('webex_meetings').getList(profile.id, queryParams);
        });
      }
    });

    service.paginationData = tableFeatures.paginationParams;

    service.getUserSessions = function(pageNumber) {
      if (pageNumber) {
        tableFeatures.paginationParams.currentPage = pageNumber;
      }

      return tableFeatures.getPageData();
    };

    service.getSessionsCount = function() {
      return UserService.loadProfile().then(function(profile) {
        return Institution.one(profile.institution_id).one('webex_meetings', profile.id).one('upcoming_count').get();
      });
    };

    service.getUnreadSessionsForPreview = function() {
      return tableFeaturesForPreview.getPageData();
    };

    service.createNewSession = function(queryParams) {
      return UserService.loadProfile().then(function(profile) {
        return Institution.one(profile.institution_id).post('webex_meetings', queryParams);
      });
    };

    service.canCreateSession = function() {
      return UserService.canAccess(['CREATE'], ['webex_meetings']).then(function(response) {
        return response  && !UserService.isMasqueradeMode();
      });
    };

    /**
     * Cancel a session on the system
     *
     * @param {number} id The webex session id
     * @returns {Promise}
     */
    service.cancelSession = function(id) {
      return UserService.loadProfile().then(function(profile) {
        return Institution.one(profile.institution_id).one('webex_meetings').customDELETE(id);
      });
    };

    /**
    * Get session url updated
    *
    * @param {number} id The webex session id
    * @return {Promise}
    **/
    service.updateUrl = function(id) {
      return UserService.loadProfile().then(function(profile) {
        return Institution.one(profile.institution_id).one('webex_meetings', id).one('update_url_host').patch();
      });
    };

    service.canRead = function() {
      return UserService.canAccess(['READ'], ['webex_meetings']);
    };
  }
}());
