(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('Message', MessageResource);

  function MessageResource(ApiRestangular) {
    return ApiRestangular.withConfig(function(RestangularConfigurer) {
      RestangularConfigurer.setFullResponse(true);
    }).service('messages');
  }
}());
