(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoInstitutionChildren', eoInstitutionChildren);

  function eoInstitutionChildren($stateParams, Institution, SpinnerService
  ) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-institution-children/eo-institution-children.html',
      scope: {},
      controller: eoInstitutionChildrenCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoInstitutionChildrenCtrl($scope) {

      var vm = this, spinner;

      spinner = new SpinnerService();
      vm.institutionId = parseInt($stateParams.id, 10);

      function loadChildrenInstitutions() {
        Institution.one(vm.institutionId).getList('children').then(function(response) {
          vm.childInstitutions = response.data;
        });
      }

      $scope.$on('eoInstitutionChildren:enabled', function(e, state) {
        _.each(vm.childInstitutions, function(institution) {
          institution.enabled = state;
        });
      });

      spinner.activateController([
        loadChildrenInstitutions()
      ]);
    }
  }
}());
