(function() {
  'use strict';

  angular
    .module('tcm.auth')
    .service('SecurityManagerService', SecurityManagerService);

  function SecurityManagerService(UserService, Grants) {
    return {
      can: function(role, resource, identifier) {
        if (!UserService.signedIn()) {
          return false;
        }

        if (resource) {
          return UserService.can(role, resource, identifier);
        } else {
          return this.isGranted(role);
        }
      },

      hasPermission: function(checkingRole) {
        return function(profileRole) {
          if (profileRole.title === 'Root' || _.includes(Grants[profileRole.title], checkingRole)) {
            return true;
          }  else {
            return false;
          }
        };
      },

      isGranted: function(role) {
        var self = this;
        return UserService.loadProfile().then(function(profile) {
          if (profile) {
            return _.some(profile.roles, self.hasPermission(role));
          }

          return false;
        });
      },

      authorize: function(roles) {
        var self = this;
        return UserService.loadProfile().then(function(profile) {
          if (profile) {
            return _.some(roles, function(role) {
              return _.some(profile.roles, self.hasPermission(role));
            });
          }

          return false;
        });
      }
    };
  }
}());
