(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoGradebook', eoGradebook);

  function eoGradebook($state, $location, User, UserService, gettext, TableFeaturesService, UserActivities) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-gradebook/eo-gradebook.html',
      scope: {
        userId: '=',
        cohortId: '='
      },
      controller: eoGradebookCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoGradebookCtrl() {
      var vm = this, tableFeature;

      vm.canViewDetails = false;
      vm.userHasPermissionToView = false;

      tableFeature = new TableFeaturesService({
        spinner: true,
        sorting: {
          column: $location.search().gb_sort || 'status_priority',
          direction: $location.search().gb_direction || 'asc'
        },
        pagination: {perPage: 15},
        loadData: function(queryParams) {
          return UserActivities.getActivitiesByUser(vm.userId, vm.cohortId, queryParams);
        }

      });

      vm.gradebook = {
        searchData: tableFeature.searchParams,
        sorting: tableFeature.sortingParams,
        pagination: tableFeature.paginationParams
      };

      vm.loadPage = function() {
        vm.gradebook.searchData.learning_activity_description_cont = $location.search().search || '';
        vm.gradebook.searchData.weight = $location.search().weight || '>';
        tableFeature.getPageData().then(function(response) {
          vm.data = response[0].data;
        });
      };

      vm.search = function() {
        var searchParams;

        if (_.isEmpty(vm.gradebook.searchData.learning_activity_description_cont)) {
          $location.search(_.omit($location.search(), 'search'));
        }

        if (_.isEmpty(vm.gradebook.searchData.weight)) {
          $location.search(_.omit($location.search(), 'weight'));
        }

        searchParams = $location.search();

        if (!_.isEmpty(vm.gradebook.searchData.learning_activity_description_cont)) {
          searchParams.search = vm.gradebook.searchData.learning_activity_description_cont;
        }

        if (!_.isEmpty(vm.gradebook.searchData.weight)) {
          searchParams.weight = vm.gradebook.searchData.weight;
        }

        $location.search(searchParams);
        $state.current.reloadOnSearch = false;

        tableFeature.getNewData().then(function(response) {
          vm.data = response[0].data;
          $state.current.reloadOnSearch = true;
        });
      };

      vm.status = function(value) {
        if (value.user_state === 'awaiting_instructor_grading') {

          return gettext('Grade it');
        } else {
          return value.user_state_readable;
        }
      };

      // Redirects to details related to current state
      vm.openDetails = function(activity) {
        var queryParams;
        if (!$state.is('successcoach.cohorts.objectives.progress.details') && !$state.is('instructor.cohorts.objectives.gradebook') && !$state.is('admin.users.progress.details') || !vm.canViewDetails) {
          return;
        }

        queryParams = {
          userId: vm.userId,
          activityId: activity.id,
          cohort_id: vm.cohortId,
          gb_sort: vm.gradebook.sorting.column,
          gb_direction: vm.gradebook.sorting.direction,
          search: vm.gradebook.searchData.learning_activity_description_cont,
          weight: vm.gradebook.searchData.weight
        };

        if ($state.is('admin.users.progress.details')) {
          $state.go('admin.users.progress.activity', queryParams);
        } else if ($state.is('successcoach.cohorts.objectives.progress.details')) {
          $state.go('successcoach.cohorts.objectives.progress.activity', queryParams);
        } else {
          $state.go('instructor.cohorts.objectives.gradebook.activity', queryParams);
        }
      };

      function getCohort() {
        User.one(vm.userId).one('cohorts', vm.cohortId).get().then(function(response) {
          vm.cohort = response.data;
        });
      }

      function userHasPermissionToView() {
        UserService.canAccess(['SCORE', 'SCORE_FEEDBACK', 'SEE_DETAILS'], ['user_activities']).then(function(response) {
          // Helper, indicates if activities details can be accessed
          vm.canViewDetails = $state.is('successcoach.cohorts.objectives.progress.details') || $state.is('instructor.cohorts.objectives.gradebook') || $state.is('admin.users.progress.details') && response;
          vm.userHasPermissionToView = response;
        });
      }

      vm.loadPage();
      getCohort();
      userHasPermissionToView();
    }
  }
}());
