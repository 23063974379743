(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('DashboardRouting', DashboardRouting);

  function DashboardRouting($state, UserCohorts, UserPrograms, localStorageService) {

    function execStudentDashboardRouting() {
      // if user is masqueraded and has a cohort stored then redirect to it as student
      if (localStorageService.get('isMasqueradeMode') && localStorageService.get('sourceCohort') && _.isNumber(localStorageService.get('sourceCohort'))) {
        return $state.go('student.cohorts.objectives.list', {id: localStorageService.get('sourceCohort'), state: 'all'});
      }

      UserCohorts.getFirstCohort(new Date(), 'Student').then(function(cohort) {
        if (cohort !== void 0) {
          $state.go('student.cohorts.objectives.list', {id: cohort.id, state: 'all'});
        } else {
          // otherwise going to the first program
          UserPrograms.getPrograms().then(function(response) {
            if (response.data.length) {
              UserPrograms.getFirstProgram().then(function(firstPrograms) {
                $state.go('student.programs.competencies.list', {id: firstPrograms.id, state: 'all'});
              });
            } else {
              // otherwise going to the info page
              $state.go('student.info');
            }
          });
        }
      });
    }

    return {
      execStudentDashboardRouting: execStudentDashboardRouting
    };
  }
}());
