/*globals Highcharts:false */
/*globals moment:false */
(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoCompetencyBurnup', eoCompetencyBurnup);

  function eoCompetencyBurnup($http, gettextCatalog, UserService, $state) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-competency-burnup/eo-competency-burnup.html',
      scope: {
        url: '='
      },
      controller: eoCompetencyBurnupCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoCompetencyBurnupCtrl($scope) {
      var vm = this;

      vm.noChartData = true;

      if ($state.includes('student.cohorts.**')) {
        vm.isStudent = true;
        UserService.loadProfile().then(function(profile) {
          vm.isFirstSession = (profile.sign_in_count === 1) ? true : false;
        });
      }

      Highcharts.dateFormats = {
        W: function(timestamp) {
          var interval_ms = timestamp - vm.chartConfig.xAxis.min;
          return 1 + Math.floor(interval_ms / 604800000);
        }
      };

      vm.chartConfig = {
        options: {
          chart: {
            style: {
              fontFamily: '"Source Sans Pro", "Helvetica Neue", Helvetica, sans-serif'
            },
            height: 275
          },
          legend: {
            enabled: false
          },
          tooltip: {
            shared: true,
            pointFormat: '',
            formatter : function() {
              return moment(this.x).format("dddd, MMM DD of YYYY");
            }
          },
          plotOptions: {
            arearange: {
              enableMouseTracking: false
            }
          }
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: gettextCatalog.getString('Weeks')
          },
          dateTimeLabelFormats: {
            day: '%e',
            week: '%e'
          },
          tickInterval: 7 * 24 * 36e5, // one week
          labels: {
            format: '{value:%W}'
          }
        },
        yAxis: {
          title: {
            text: gettextCatalog.getString('Learning Objectives')
          },
          allowDecimals: false,
          gridLineColor: '#ededee',
          min: 0
        },
        series: [],
        title: {
          text: null
        },
        credits: {
          enabled: false
        },
        loading: false
      };

      function generateExpectedGradient(data) {
        if (data.count_log.length > 1) {
          var expectedRange, betterRange, firstRange, tolerance, tolarencePercentage;

          tolarencePercentage = 15 / 100;

          tolerance = parseInt((data.ends_on - data.starts_on) * tolarencePercentage, 10);
          firstRange = [data.starts_on, 0, 0];
          expectedRange = [data.ends_on, data.num_learning_objectives, data.num_learning_objectives];
          betterRange = [data.ends_on - tolerance, data.num_learning_objectives, data.num_learning_objectives * (1  - tolarencePercentage)];

          return {
            fillColor: {
              linearGradient: { x1: 0, y1: 1, x2: 1, y2: 1 },
              stops: [
                [0, 'rgba(215, 232, 249, 0.5)'],
                [1, 'rgba(215, 232, 249, 1)']
              ]
            },
            type: 'arearange',
            name: 'Total consumption',
            data: [firstRange, betterRange, expectedRange],
            zIndex: -2,
            lineWidth: 0,
            opacity: 1
          };
        } else {
          return {
            type: 'line',
            data: [],
            zIndex: 1
          };
        }
      }

      vm.renderGraph = function(url) {
        vm.chartConfig.loading = true;

        if (!url) {
          vm.foregroundData = null;
          return;
        }

        $http.get(url).then(function(result) {
          var diffWeeks;
          vm.foregroundData = result.data;

          if (vm.foregroundData) {
            vm.chartConfig.xAxis.min = vm.foregroundData.starts_on;
            vm.chartConfig.xAxis.max = vm.foregroundData.ends_on;
            vm.chartConfig.yAxis.max = vm.foregroundData.num_learning_objectives;

            diffWeeks = moment(vm.chartConfig.xAxis.max).diff(vm.chartConfig.xAxis.min, 'weeks');
            if (diffWeeks > 12) {
              vm.chartConfig.options.chart.width = diffWeeks * 40;
            }

            // Progress
            vm.chartConfig.series.push({
              type: 'line',
              data: vm.foregroundData.count_log,
              zIndex: 1,
              marker: {
                radius: 2.8
              }
            });

            // Gradient
            vm.chartConfig.series.push(generateExpectedGradient(vm.foregroundData));

            // Today Indicator
            vm.chartConfig.series.push({
              data: [
                {x: new Date().getTime(), y: 0},
                {x: new Date().getTime(), y: vm.chartConfig.yAxis.max}
              ],
              enableMouseTracking: false,
              lineWidth: 1,
              color: '#c0d0e0',
              dashStyle: 'dash',
              marker: {enabled: false}
            });
          }

          if (vm.foregroundData && vm.foregroundData.total > 0) {
            vm.noChartData = false;
          }
        }).finally(function() {
          vm.chartConfig.loading = false;
        });
      };

      $scope.$watch('vm.url', function(newVal) {
        vm.renderGraph(newVal);
      });
    }
  }
}());
