(function() {
  'use strict';

  angular
    .module('tcm.core')
    .config(uiSelectConfigBlock);

  function uiSelectConfigBlock(uiSelectConfig) {
    uiSelectConfig.theme = 'bootstrap';
    uiSelectConfig.resetSearchInput = true;
  }
}());
