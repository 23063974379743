(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoGradeActivity', eoGradeActivity);

  function eoGradeActivity($stateParams, $location, ExternalService, $uibModal,
    SpinnerService, UserActivities, UserService, $state, $timeout, Institution, User) {

    return {
      templateUrl: 'components/eo-grade-activity/eo-grade-activity.html',
      scope: {
        student: '=',
        activity: '=?',
        loadActivity: '&'
      },
      controller: eoGradeActivityCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoGradeActivityCtrl() {
      var vm = this, spinner, activityId, cohortId, userId;

      vm.selectedRules = [];

      userId = parseInt($stateParams.userId, 10);
      activityId = parseInt($stateParams.activityId, 10);
      cohortId = ($stateParams.id === 'all') ? $location.search().cohort_id : parseInt($stateParams.id, 10) || parseInt($stateParams.cohortId, 10);

      spinner = new SpinnerService();

      vm.userCanGrade = false;

      function loadActivityForGrading() {
        return vm.loadActivity().then(function(response) {
          vm.activity = response.data;
          vm.isApprovalActivity = vm.activity.activity_type === 'assignment' || vm.activity.activity_type === 'assessment';
          vm.isCdpActivity = (vm.activity.content_type === 'CdpItem') ? true : false;

          if (vm.activity.user_attempts && vm.activity.user_attempts.length) {
            vm.currentAttempt = getCurrentAttempt(vm.activity.user_attempts);
          }  else {
            vm.currentAttempt = {};
          }

          // in to-grade case we have to use 'learning_activity_id'
          activityId = vm.activity.learning_activity_id || activityId;

          setActivityState();
          // validation of LTI prevent of autolaunching
          if (vm.activity.content_type !== 'LtiProvider') {
            vm.launchActivity();
          }

          getApprovalState();
        });
      }

      function updateActivityState() {
        for (var i = 0; i < vm.activity.user_attempts.length;i++) {
          if (vm.activity.user_attempts[i].state === 'mastered' || vm.currentAttempt.state === 'mastered') {
            vm.activity.user_state = 'mastered';
            break;
          } else {
            vm.activity.user_state = 'failed';
          }
        }
      }

      function getApprovalState() {
        if (!vm.activity.gradable) {
          vm.approvalState = vm.currentAttempt.state === 'mastered' ? 'Approved' : vm.approvalState;
          vm.approvalState = vm.currentAttempt.state === 'failed' ? 'Disapproved' : vm.approvalState;
        }
      }

      function getCurrentAttempt(attempts) {
        var submittedAttempts = [], invalidAttempts = [],
          inProgressAttempts = [];
        for (var i = 0; i < attempts.length; i++) {
          if (isAttemptSubmitted(attempts[i])) {
            submittedAttempts.push(attempts[i]);
          } else if (attempts[i].state === 'not_valid') {
            invalidAttempts.push(attempts[i]);
          } else {
            inProgressAttempts.push(attempts[i]);
          }
        }

        // when the activity comes from to grade tab, select the latest attempt on "awaiting_instructor_grading" state
        if ($stateParams.state === 'to-grade') {
          return _.find(submittedAttempts.concat(invalidAttempts, inProgressAttempts), ['state', 'awaiting_instructor_grading']);
        } else {
          return _.head(submittedAttempts.concat(invalidAttempts, inProgressAttempts));
        }
      }

      function isAttemptSubmitted(attempt) {
        var attemptStates = [
          'mastered',
          'failed',
          'awaiting_grading',
          'awaiting_instructor_grading'
        ];

        return _.includes(attemptStates, attempt.state);
      }

      vm.launchActivity = function() {
        var queryParams = {
          role: 'Instructor',
          student_id: userId
        };

        if (vm.currentAttempt.attempt_number) {
          queryParams.attempt_number = vm.currentAttempt.attempt_number;
        }

        return ExternalService.launchContentForActivity({
          id: activityId,
          content_type: vm.activity.content_type
        }, queryParams).then(function(result) {
          vm.activityContent = result;
        });
      };

      function userHasPermissionToGrade() {
        return UserService.canAccess(['SCORE'], ['user_activities']).then(function(response) {
          vm.userCanGrade = response;
        });
      }

      vm.gradeActivity = function() {
        var queryParams = {
          attempt_number: vm.currentAttempt.attempt_number || 1,
          score: vm.currentAttempt.score.value,
          instructor_override: vm.activity.content_type !== 'AuthenticItem'
        };

        // revise grade case
        if (vm.currentAttempt.score.id) {
          queryParams.score_id = vm.currentAttempt.score.id;
        }

        if (cohortId !== void 0) {
          queryParams.cohort_id = cohortId;
        }

        spinner.activateController([
          UserService.loadProfile().then(function(response) {
            queryParams.instructor_id = response.id;

            return UserActivities.sendScore(userId, activityId, queryParams).then(function(response) {
              vm.isActivityGraded = true;
              vm.activity.user_score = response.data.effective_score;
            });
          })
        ]);
      };

      vm.gradeActivityWithRubric = function() {
        var rubricComment = vm.currentAttempt.rubric_comment !== undefined ? vm.currentAttempt.rubric_comment.comment : '';
        var params = {
          rules: vm.selectedRules,
          student_id: userId,
          attempt_number: vm.currentAttempt.attempt_number || 1,
          rubric_comment: rubricComment
        };
        params.la_id = $stateParams.state === 'to-grade' ? vm.activity.learning_activity_id : vm.activity.id;
        spinner.activateController([
          UserService.loadProfile().then(function(profile) {
            var institutionId = $state.includes('admin.**') ? vm.student.institution_id : profile.institution_id;

            return Institution.one(institutionId).one('rubrics', vm.activity.rubric.id).post('scores_all', params).then(function(response) {
              if (response.data.score) {
                vm.currentAttempt.score.value = response.data.score.value;
                vm.activity.user_score = response.data.effective_score;
              } else {
                vm.noScoreMessage = 'Note: Overall score will not be calculated unless a rule has been selected for all criteria in the rubric';
              }

              vm.currentAttempt.selected_rules = response.data.selected_rules;
            });
          })
        ]);
      };

      vm.gradeActivityWithAwaitingButtons = function(fullScore) {
        vm.currentAttempt.score.value = fullScore ? 100 : 0;
        vm.currentAttempt.state = fullScore ? 'mastered' : 'failed';
        vm.activity.user_state = fullScore ? 'mastered' : vm.currentAttempt.state;
        updateActivityState();
        vm.gradeActivity();
        getApprovalState();
      };

      vm.selectAttempt = function(attempt) {
        vm.currentAttempt = attempt;

        setActivityState();
        vm.launchActivity();
        vm.selectedRules = [];
        vm.noScoreMessage = '';
      };

      function setActivityState() {
        // set a default score if an activity doesn't have one
        if (!vm.currentAttempt.score) {
          vm.currentAttempt.score = {value: 0};
        }

        vm.isActivityGraded = vm.currentAttempt.score.id !== void 0;
      }

      vm.markAttemptAsInvalid = function() {
        $uibModal.open({
          templateUrl: 'components/eo-grade-activity/modals/mark-as-invalid-modal.html',
          controllerAs: 'modal',
          controller: function editModalCtrl($scope) {
            var modal = this;
            modal.model = {};

            modal.fields = [
              {
                key: 'invalid_reason',
                type: 'ui-select',
                defaultValue: 'incomplete',
                templateOptions: {
                  label: 'Code',
                  options: [{
                    name: 'Incomplete Submission',
                    value: 'incomplete'
                  }, {
                    name: 'Disqualified Attempt',
                    value: 'disqualified'
                  }, {
                    name: 'Other',
                    value: 'other'
                  }]
                }
              },
              {
                key: 'invalid_comments',
                type: 'textarea',
                templateOptions: {
                  label: 'Optional Note (visible ONLY to administrators)'
                }
              }
            ];

            modal.submit = function() {
              if (modal.markInvalidAttemptForm.$valid) {
                var params = {
                  user_state: 'not_valid',
                  attempt_number: vm.currentAttempt.attempt_number,
                  invalid_reason: modal.model.invalid_reason,
                  invalid_comments: modal.model.invalid_comments
                };

                if (vm.isCdpActivity) {
                  // How CDP requires params attributes
                  params = {
                    StudentId: userId,
                    invalidReason: modal.model.invalid_reason,
                    invalidComments: modal.model.invalid_comments,
                    LearningActivityId: activityId,
                    LearningActivityIdentifier: vm.activity.identifier || vm.activity.learning_activity_identifier,
                    NoOfAttempt: vm.currentAttempt.attempt_number
                  };
                  UserActivities.updateCDPActivityState(userId, activityId, params).then(posUpdateActivityState);
                } else {
                  UserActivities.updateActivityState(userId, activityId, params).then(posUpdateActivityState);
                }
              }

              function posUpdateActivityState(response) {
                var index = _.findIndex(vm.activity.user_attempts, function(attempt) {
                  return attempt.attempt_number === response.data.user_attempts[0].attempt_number;
                });
                vm.activity.user_attempts[index] = response.data.user_attempts[0];
                vm.currentAttempt = response.data.user_attempts[0];
                vm.activity.user_attempt_count = response.data.user_attempt_count;
                User.one(userId).one('activities', activityId).one('effective_score').get().then(function(response) {
                  vm.activity.user_score = response.data.effective_score;
                });
                $scope.$close();
              }
            };
          }
        });
      };
      vm.deleteFeedback = function(feedbackId) {
        var queryParams = {
          'attempt_number': vm.currentAttempt.attempt_number,
          'cohort_id': cohortId
        };
        return UserActivities.deleteActivityComment(userId, activityId, feedbackId, queryParams);
      };
      vm.updateFeedback = function(feedbackId, comment) {
        var queryParams = {
          'attempt_number': vm.currentAttempt.attempt_number,
          'cohort_id': cohortId,
          'score_comment': comment
        };
        return UserActivities.updateActivityAttemptComment(userId, activityId, feedbackId, queryParams);
      };

      vm.manageCommentsForAttempt = function() {
        $uibModal.open({
          templateUrl: 'components/eo-grade-activity/modals/comments-for-attempt-modal.html',
          controllerAs: 'modal',
          windowClass: 'feedback-modal',
          backdrop: 'static',
          controller: function writeCommentForAttemptModalCtrl() {
            var modal = this;
            modal.model = {};
            modal.toggleTextEditor = false;
            modal.currentSelected = 0;
            modal.model.activity_score_comments = vm.currentAttempt.activity_score_comments;
           //on tab change show the relevant button controlls
            modal.tabChange = function(tab) {
              if (tab === 'existing') {
                modal.exisitngFeedback = true;
                modal.newFeedback = false;
              } else {
                modal.exisitngFeedback = false;
                modal.newFeedback = true;
              }
            };
            modal.currentFeedback = 0;
            modal.toggleEditor = function() {
              modal.toggleTextEditor = true;
            };
            modal.nextFeedback = function() {
              if (modal.model.activity_score_comments.length - 1 !== modal.currentFeedback) {
                modal.toggleTextEditor = false;
                modal.currentFeedback++;
              }
            };
            modal.previousFeedback = function() {
              if (modal.currentFeedback !== 0) {
                modal.toggleTextEditor = false;
                modal.currentFeedback--;
              }
            };
            modal.deleteFeedback = function() {

              $uibModal.open({
                templateUrl: 'components/eo-grade-activity/modals/confirm-comment-delete-modal.html',
                controllerAs: 'confirm',
                controller: function($scope) {
                  var confirm = this;

                  confirm.submit = function() {
                    var feedbackId = modal.model.activity_score_comments[modal.currentFeedback].id;
                    var deleteResponse = vm.deleteFeedback(feedbackId);

                    deleteResponse.then(function(response) {
                      modal.model.activity_score_comments = response.data.activity_score_comments;
                      vm.currentAttempt.activity_score_comments = response.data.activity_score_comments;
                      //modal.showComments = false;
                      if (modal.model.activity_score_comments.length === modal.currentFeedback) {
                        modal.currentFeedback = 0;
                      }
                    });
                    $scope.$close();
                  };
                }
              });
            };

            //inline feeedback update
            modal.updateFeedback = function() {
              var comment, feedbackId;

              comment = modal.model.activity_score_comments[modal.currentFeedback].body;
              feedbackId = modal.model.activity_score_comments[modal.currentFeedback].id;

              vm.updateFeedback(feedbackId, comment).then(function() {
                modal.toggleTextEditor = false ;
              });
            };

            //disbale edit on closing of feedback dialog
            modal.disableEdit = function() {
              modal.toggleTextEditor = false;
            };

            modal.submitFeedback = function() {
              if (modal.model.score_comment && modal.model.score_comment.length > 0) {

                $uibModal.open({
                  templateUrl: 'components/eo-grade-activity/modals/confirm-comment-submission-modal.html',
                  controllerAs: 'confirm',
                  controller: function($scope) {
                    var confirm = this;

                    confirm.submit = function() {

                      var queryParams = {
                        'attempt_number': vm.currentAttempt.attempt_number,
                        'cohort_id': cohortId,
                        'score_comment': modal.model.score_comment
                      };

                      UserActivities.submitActivityAttemptComment(userId, activityId, queryParams).then(function(response) {
                        modal.model.activity_score_comments = modal.model.activity_score_comments || [];

                        UserService.loadProfile().then(function(profile) {
                          modal.model.activity_score_comments.unshift({
                            id: response.data.activity_score_comments[0].id,
                            body: response.data.activity_score_comments[0].body,
                            created_at: response.data.activity_score_comments[0].created_at,
                            author: profile.first_name + ' ' + profile.last_name
                          });
                          $timeout(function() {
                            modal.showComments = true;
                          }, 0);
                        });

                        modal.model.score_comment = '';
                        modal.errorMessage = '';
                        modal.currentFeedback = 0;

                        $scope.$close();
                      });
                    };
                  }
                });
              } else {
                modal.errorMessage = 'Please write your feedback comment before submitting the form.';
              }
            };
          }
        });
      };

      vm.getReasonCode = function() {
        switch (vm.currentAttempt.invalid_reason) {
          case 'incomplete':
            return 'Incomplete Submission';
          case 'disqualified':
            return 'Disqualified Attempt';
          case 'plagiarism':
            return 'Plagiarism';
          case 'other':
            return 'Other';
        }
      };

      vm.nextActivity = function() {
        if ($state.is('instructor.cohorts.objectives.gradebook.activity') || $state.is('admin.users.progress.activity') || $state.is('successcoach.cohorts.objectives.progress.activity')) {

          var queryParams = {
            userId: vm.activity.next.user_id || userId,
            activityId: vm.activity.next.id,
            gb_sort: $location.search().gb_sort,
            gb_direction: $location.search().gb_direction,
            search: $location.search().search || '',
            weight: $location.search().weight || ''
          };

          if ($state.is('admin.users.progress.activity')) {
            $state.go('admin.users.progress.activity', queryParams);
          } else if ($state.is('successcoach.cohorts.objectives.progress.activity')) {
            $state.go('successcoach.cohorts.objectives.progress.activity', queryParams);
          } else {
            $state.go('instructor.cohorts.objectives.gradebook.activity', queryParams);
          }
        } else if ($state.is('instructor.cohorts.toGradeActivity')) {
          $state.go('instructor.cohorts.toGradeActivity',
            {
              userId: vm.activity.next.user_id || userId,
              activityId: vm.activity.next.id,
              state: $location.search().state,
              page: $location.search().page,
              sort: $location.search().sort,
              direction: $location.search().direction
            }
          );
        }
      };

      vm.previousActivity = function() {
        if ($state.is('instructor.cohorts.objectives.gradebook.activity') || $state.is('admin.users.progress.activity') || $state.is('successcoach.cohorts.objectives.progress.activity')) {
          var queryParams = {
            userId: vm.activity.previous.user_id || userId,
            activityId: vm.activity.previous.id,
            gb_sort: $location.search().gb_sort,
            gb_direction: $location.search().gb_direction,
            search: $location.search().search || '',
            weight: $location.search().weight || ''
          };

          if ($state.is('admin.users.progress.activity')) {
            $state.go('admin.users.progress.activity', queryParams);
          } else if ($state.is('successcoach.cohorts.objectives.progress.activity')) {
            $state.go('successcoach.cohorts.objectives.progress.activity', queryParams);
          } else {
            $state.go('instructor.cohorts.objectives.gradebook.activity', queryParams);
          }

        } else if ($state.is('instructor.cohorts.toGradeActivity')) {
          $state.go('instructor.cohorts.toGradeActivity', {
            userId: vm.activity.previous.user_id || userId,
            activityId: vm.activity.previous.id,
            state: $location.search().state,
            page: $location.search().page,
            sort: $location.search().sort,
            direction: $location.search().direction
          });
        }
      };

      vm.selectRule = function(rule) {
        if (vm.userCanGrade) {
          var index = _.findIndex(vm.selectedRules, function(selectedRule) {
            return selectedRule.rubric_criterium_id === rule.rubric_criterium_id;
          });

          if (index > -1) {
            vm.selectedRules.splice(index, 1);
          }

          vm.selectedRules.push({
            id: rule.id,
            points: rule.points,
            rubric_criterium_id: rule.rubric_criterium_id
          });

          vm.isRubricsTableValid = true;
        }
      };

      vm.updateRule = function(criteria) {
        var ruleIndex = _.findIndex(vm.selectedRules, function(selectedRule) {
          return selectedRule.rubric_criterium_id === criteria.id;
        });

        if (ruleIndex > -1) {
          vm.selectedRules[ruleIndex].points = criteria.rule_points;
          vm.selectedRules[ruleIndex].comment = criteria.rule_comment;
        }
      };

      spinner.activateController([
        loadActivityForGrading(),
        userHasPermissionToGrade()
      ]);
    }
  }
}());
