(function() {
  'use strict';

  angular
    .module('tcm.common')
    .directive('eoFlash', eoFlash);

  function eoFlash() {
    return {
      restrict: 'E',
      transclude: true,
      template: function(elem, attr) {
        attr.type = attr.type || 'danger';
        return '<div role="alert" class="flash alert alert-' + attr.type + '" ng-show="flash"> {{flash}} </div>';
      }
    };
  }
}());
