(function() {
  'use strict';

  angular
    .module('ng2Web', [
      'tcm.core',
      'tcm.common',
      'tcm.components',
      'tcm.EnvConfig',
      'tcm.auth',
      'tcm.notifications',
      'tcm.messages',
      'tcm.webexSessions',
      'tcm.notes',
      'tcm.error',
      'tcm.help',
      'tcm.release',
      'tcm.reports',
      'tcm.dashboard',
      'ng2-web-templates'
    ])
    .run(runBlock);

  function runBlock($rootScope, uibPaginationConfig, ViewState, formlyConfig,
    formlyValidationMessages, $window, LocationTrackingService, UserService, $stateParams) {

    // angular-formly standard types configuration
    // Overwrite standard wrappers with our custom

    var standardInput = formlyConfig.getType('input');
    standardInput.wrapper = ['validation', 'control-with-validation'];

    var standardMultiCheckbox = formlyConfig.getType('multiCheckbox');
    standardMultiCheckbox.wrapper = ['validation', 'control-with-validation'];

    var standardTextarea = formlyConfig.getType('textarea');
    standardTextarea.wrapper = ['validation', 'control-with-validation'];

    formlyConfig.extras.errorExistsAndShouldBeVisibleExpression = 'fc.$touched || form.$submitted';
    // improved regex email validation
    formlyValidationMessages.addStringMessage('emailAddress', 'This email is invalid');
    formlyValidationMessages.addStringMessage('email', 'This email is invalid');
    formlyValidationMessages.addStringMessage('required', 'This field is required');
    formlyValidationMessages.addStringMessage('number', 'This field must contain a numeric value');
    formlyValidationMessages.addStringMessage('positiveNumber', 'This field must contain a positive value');
    formlyValidationMessages.addStringMessage('integer', 'This field must contain an integer value');
    formlyValidationMessages.addStringMessage('zip', 'This field must contain a 5 or 9 digit number');
    formlyValidationMessages.addStringMessage('lettersHyphens', 'This field must contain only letters, hyphens, and \'');
    formlyValidationMessages.addStringMessage('lettersHyphensSpaces', 'This field must contain only letters, hyphens, spaces, and \'');
    formlyValidationMessages.addStringMessage('address', 'This field must contain only letters, numbers and .-\'#\u0026');
    formlyValidationMessages.addStringMessage('phone', 'This field must contain a 10 digit number');
    formlyValidationMessages.addStringMessage('alphaNumeric', 'This field must contain only letters and numbers');
    formlyValidationMessages.addStringMessage('alphaNumericHyphens', 'This field must contain only letters, numbers or _');
    formlyValidationMessages.addStringMessage('noSpaces', 'This field must not contain white spaces');
    formlyValidationMessages.addStringMessage('uniqueEoCustomer', 'This value has already been taken. It must be a unique value.');
    formlyValidationMessages.addStringMessage('password', 'Password is too simple. Try adding words, numbers, or symbols.');
    formlyValidationMessages.addStringMessage('passwordMatch', 'Passwords do not match.');
    formlyValidationMessages.addStringMessage('validTime', 'This time is invalid');

    formlyValidationMessages.addTemplateOptionValueMessage('maxlength', 'maxlength', 'This field has too long value (max is ', 'characters)', 'Too long');
    formlyValidationMessages.addTemplateOptionValueMessage('minlength', 'minlength', 'This field has too short value (min is ', 'characters)', 'Too short');
    formlyValidationMessages.addTemplateOptionValueMessage('max', 'max', 'This field must contain a value less or equal to ', '', 'Out of range');
    formlyValidationMessages.addTemplateOptionValueMessage('fileExtension', 'fileExtension', 'This field must contain file with extension equal to ', '', 'Unsupported file extension');

    // Pagination defaults
    uibPaginationConfig.maxSize = 5;
    uibPaginationConfig.previousText = '<<';
    uibPaginationConfig.nextText = '>>';

    // use mustache style template
    _.templateSettings = {
      interpolate: /\{\{(.+?)\}\}/g
    };

    function isStudentActivityState(state) {
      return state.name.indexOf('student') > -1 && (state.name.indexOf('activity') > -1 || state.name.indexOf('activityLaunch') > -1);
    }

    $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState) {
      ViewState.current = toState;
      ViewState.previous = fromState;

      if (UserService.signedIn()) {

        if (isStudentActivityState(toState)) {
          if (isStudentActivityState(fromState)) {
            // between activities
            LocationTrackingService.trackActivityTimeOnNextPrev(parseInt($stateParams.activityId, 10));
          } else {
            // on enter student activity
            LocationTrackingService.trackActivityTimeOnEnter(parseInt($stateParams.activityId, 10));
            LocationTrackingService.trackActivityListener(parseInt($stateParams.activityId, 10));
          }
        } else if (isStudentActivityState(fromState)) {
          // on exit student activity
          LocationTrackingService.trackActivityTimeOnExit();
        } else {
          // general case
          LocationTrackingService.stateChanged();
        }
      }
    });
  }
}());
