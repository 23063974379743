(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoAssignedCourses', eoAssignedCourses);

  function eoAssignedCourses($rootScope, $timeout, $stateParams,
    SpinnerService, TableFeaturesService, Institution
  ) {
    return {
      templateUrl: 'components/eo-assigned-courses/eo-assigned-courses.html',
      scope: {
        courseState: '=',
        managePermission: '=?'
      },
      controller: eoAssignedCoursesCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoAssignedCoursesCtrl($scope) {
      var vm = this, spinner, contextId, courseId, tableFeatures;

      spinner = new SpinnerService();
      contextId = parseInt($stateParams.contextId, 10);
      courseId = parseInt($stateParams.id, 10);
      vm.checkedCourses = [];

      $timeout(function() {
        $scope.$emit('eoAssignedCourses:handshake');
      }, 0);

      $rootScope.$on('eoAvailableCourses:handshake', function() {
        vm.manageMode = true;
      });

      $rootScope.$on('eoAssignedCourses:reload', function() {
        vm.loadAssignedCourses();
      });

      tableFeatures = new TableFeaturesService({
        sorting: {column: 'position'},
        pagination: {
          perPage: 10,
          scrollTop: false
        },
        loadData: function(queryString) {
          return Institution.one(contextId).one('curriculums', courseId).getList('children', queryString);
        }
      });

      vm.table = {
        paginationData: tableFeatures.paginationParams
      };

      vm.removeSelectedCourses = function() {
        Institution.one(contextId).one('curriculums', courseId).customDELETE('children', {'children_ids[]': vm.checkedCourses}).then(function() {
          vm.loadAssignedCourses();
          vm.checkedCourses.length = 0;
          $scope.$emit('eoAvailableCourses:reload');
        });
      };

      vm.moveCourse = function(course, direction, $event) {
        var position, delta;

        $event.stopPropagation();
        $event.preventDefault();

        delta = (direction === 'up') ?  -1 : 1;
        position = course.position + delta;

        if (position >= 0 && position <= vm.table.paginationData.totalItems) {
          spinner.activateController([
            Institution.one(contextId).one('curriculums', courseId).one('children', course.id).put({
              position: position
            }).then(function() {
              vm.loadAssignedCourses();
            })
          ]);
        }
      };

      vm.checkCourse = function(course) {
        if (course.checked) {
          vm.checkedCourses.push(course.id);
        } else {
          _.pull(vm.checkedCourses, course.id);
        }
      };

      vm.setCheckedState = function(state) {
        vm.checkedCourses.length = 0;
        _.each(vm.assignedCourses, function(course) {
          course.checked = state;
          if (state) {
            vm.checkedCourses.push(course.id);
          } else {
            vm.checkedCourses.length = 0;
          }
        });
      };

      vm.loadAssignedCourses = function() {
        vm.checkedCourses.length = 0;
        tableFeatures.getPageData().then(function(response) {
          vm.assignedCourses = response[0].data;
        });
      };

      vm.loadAssignedCourses();
    }
  }

}());
