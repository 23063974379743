(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoLearningActivityCard', eoLearningActivityCard)
    .service('launchModalService', launchModalService);

  function launchModalService() {
    var modalRef;

    this.setModalRef = function(modal) {
      modalRef = modal;
    };

    this.getModalRef = function() {
      return modalRef || null;
    };

    this.updateModalActivity = function(activity, next, previous) {
      if (modalRef) {
        modalRef.activity = activity;
        modalRef.nextActivity = next;
        modalRef.previousActivity = previous;
      }
    };

    this.removeModalRef = function() {
      modalRef = null;
    };
  }

  function eoLearningActivityCard(UserActivities, $uibModal, $stateParams,
    ExternalService, $state, UserService, launchModalService) {
    return {
      restrict: 'E',
      require: ['^eoLearningActivityCardsList', 'eoLearningActivityCard'],
      templateUrl: 'components/eo-learning-activity-card/eo-learning-activity-card.html',
      scope: {
        activity: '=',
        next: '@?',
        previous: '@?',
        search: '=?'
      },
      controller: eoLearningActivityCardCtrl,
      link: eoLearningActivityCardLinkFn,
      controllerAs: 'card',
      bindToController: true
    };

    function eoLearningActivityCardLinkFn(scope, element, attrs, ctrls) {
      var cardsListCtrl = ctrls[0], cardCtrl = ctrls[1];
      cardCtrl.cardsListCtrl = cardsListCtrl;

      cardsListCtrl.setCardElement(element);

      element.on('$destroy', function() {
        cardsListCtrl.unsetCardElement(element);
      });
    }

    function eoLearningActivityCardCtrl($scope, $element, UserActivities) {
      var card = this, masterdText, state, objectiveId, cohortId, unbindActivityIdChangeWatcher;

      objectiveId = parseInt($stateParams.objectiveId, 10);
      cohortId = parseInt($stateParams.id, 10);
      state = $stateParams.state;

      // watch activity id change in the url to prevent reloading the page
      // and making useless requests to get list of all activities again
      unbindActivityIdChangeWatcher = $scope.$watch(function() {
        return $state.params.activityId;
      }, function(activityId) {
        if (card.activity.id === parseInt(activityId, 10)) {
          // TO-DO: research where these lines are not used anymore
          if ($state.includes('**.activityLaunch')) {
            if (launchModalService.getModalRef()) {
              // launch activity in the same opened modal window
              launchModalService.updateModalActivity(card.activity, card.next, card.previous);
              if (card.activity.content_type !== 'LtiProvider') {
                launchModalService.getModalRef().currentAttempt = undefined;

                //make sure to select last attempt
                if (card.activity.user_attempts && card.activity.user_attempts.length) {
                  launchModalService.getModalRef().currentAttempt = card.activity.user_attempts[0];
                }

                launchModalService.getModalRef().launchActivity(card.activity);
              }
            } else {
              card.launchActivity();
            }
          } else if ($state.includes('**.activityFeedback')) {
            card.viewAttemptComments();
          }
        }
      });

      function redirectToActivitiesListState() {
        if ($state.includes('**.cohorts.activities.**')) {
          $state.go('student.cohorts.activities');
        } else if ($state.includes('**.cohorts.all.**')) {
          $state.go('student.cohorts.all.activities');
        }
      }

      card.getStateClass = function(state, mode) {
        var states = {
          'available': 'not-started',
          'in_progress': 'in-progress',
          'mastered': 'mastered',
          'awaiting_instructor_grading': 'awaiting-instructor',
          'awaiting_grading': 'awaiting',
          'failed': 'not-mastered',
          'default': 'not-started'
        };

        state = states[state] || states.default;

        if (mode === 'darker') {
          state += ' darker';
        }

        return state;
      };

      card.getStateForDisplay = function(activity) {
        if (activity.activity_type === 'web_content') {
          masterdText = 'Mastered';

        } else {
          masterdText = 'Approved';
        }
        var states = {
          'available': 'Not started',
          'in_progress': 'In progress',
          'mastered': activity.gradable ? 'Mastered' : masterdText,
          'awaiting_instructor_grading': activity.gradable ? 'Awaiting Instructor Grading' : 'Awaiting Instructor Approval',
          'awaiting_grading': 'Awaiting',
          'failed': activity.gradable ? 'Not mastered' : 'Disapproved',
          'default': 'Not started'
        };

        activity.custom_state = states[activity.user_state] || states.default;

        return states[activity.user_state] || states.default;
      };

      card.viewAttemptComments = function() {
        $uibModal.open({
          templateUrl: 'components/eo-learning-activity-card/modals/instructor-comments-for-attempt-modal.html',
          controllerAs: 'modal',
          keyboard: false,
          backdrop: 'static',
          windowClass: 'view-attempt-comments-modal',
          controller: function editModalCtrl($scope) {
            var modal = this;
            UserService.loadProfile().then(function(profile) {
              var queryParams = {};
              UserActivities.getActivityByUser(profile.id, cohortId, card.activity.id, queryParams).then(function(response) {
                modal.activity = response.data;
              });
            });

            modal.close = function() {
              $scope.$close();
              redirectToActivitiesListState();
            };
          }
        });
      };

      card.redirectToState = function(state) {
        if (state === 'launch') {
          //$state.go($state.current.name + '.activityLaunch', {activityId: card.activity.id});
          card.launchActivity();
        } else if (state === 'feedback') {
          $state.go($state.current.name + '.activityFeedback', {activityId: card.activity.id});
        }
      };

      card.launchActivity = function() {
        var url, queryParams = {state: state, activityId: card.activity.id};

        if (queryParams.state === 'search') {
          queryParams.search = card.search || null;
          url = $state.href('student.cohorts.all.activitySearchLaunch', queryParams);
        } else {
          queryParams.objectiveId = objectiveId;
          url = $state.href('student.cohorts.activityLaunch', queryParams);
        }
        window.open(url, '_blank');
      };

      $element.on('$destroy', function() {
        unbindActivityIdChangeWatcher();
      });
    }
  }
}());
