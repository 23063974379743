(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoMessage', eoMessage);

  function eoMessage($state) {
    return {
      replace: true,
      require: '^eoMessageControls',
      templateUrl: 'components/eo-message/eo-message.html',
      scope: {
        message: '='
      },
      link: eoMessageLinkFn
    };

    function eoMessageLinkFn(scope, element, attrs, controlsCtrl) {
      var messageClass, messageId, icon = element.find('.notification-icon .glyphicon');

      messageId = scope.message.id;
      scope.message.author = scope.message.sent_by.first_name + ' ' + scope.message.sent_by.last_name;
      scope.isRead = scope.message.read_at ? true : false;

      if (scope.message && scope.message.event) {
        defineMessageType(scope.message.event);
      }

      controlsCtrl.setMessageElement(element);

      scope.getMessageCircleClass = function() {
        return messageClass;
      };

      scope.goToMessage = function() {
        if ($state.includes('student.**')) {
          $state.go('student.messages.detail', {id: scope.message.id});
        } else if ($state.includes('instructor.**')) {
          $state.go('instructor.messages.detail', {id: scope.message.id});
        } else if ($state.includes('successcoach.**')) {
          $state.go('successcoach.messages.detail', {id: scope.message.id});
        }
      };

      function defineMessageType(messageType) {
        switch (messageType) {
          case 'all_instructors':
            icon.addClass('glyphicon-envelope');
            messageClass = 'message-general';
            break;
          case 'all_students':
            icon.addClass('glyphicon-envelope');
            messageClass = 'message-prior-middle';
            break;
          case 'general_message':
            icon.addClass('glyphicon-bullhorn');
            messageClass = 'message-prior-high';
            break;
          case 'specific_users':
            icon.addClass('glyphicon-flag');
            messageClass = 'message-prior-low';
            break;
        }
      }

      scope.$watch('selected', function(selectValue) {
        if (selectValue) {
          controlsCtrl.selectMessage(element);
        } else {
          controlsCtrl.unselectMessage(element);
        }
      });

      element.on('$destroy', function() {
        controlsCtrl.unsetMessageElement(element);
      });
    }
  }
}());
