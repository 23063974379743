/*globals moment:false */
(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.cohorts')
    .controller('AdminCohortsDetailCtrl', AdminCohortsDetailCtrl);

  function AdminCohortsDetailCtrl($stateParams, $uibModal, SearchService,
    SortingService, User, Institution, SpinnerService, UserCohorts,
    TableFeaturesService) {

    var vm = this, tableFeatures, search, sorting, spinner,
      contextId, cohortId;

    spinner = new SpinnerService();

    search = new SearchService({published_eq: true});
    sorting = new SortingService({column: 'name'});

    contextId = parseInt($stateParams.contextId, 10);
    cohortId = parseInt($stateParams.id, 10);

    tableFeatures = new TableFeaturesService({
      sorting: {column: 'user_name_i'},
      loadData: function(queryParams) {
        return Institution.one(contextId).one('cohorts', cohortId).getList('enrollments', queryParams);
      }
    });

    vm.table = {
      searchData: tableFeatures.searchParams,
      sortingData: tableFeatures.sortingParams,
      paginationData: tableFeatures.paginationParams
    };

    vm.isFilterCollapsed = true;

    vm.startDate = {dateFormat: 'yy-mm-dd'};
    vm.endDate = {dateFormat: 'yy-mm-dd'};

    vm.search = function() {
      tableFeatures.getNewData().then(function(response) {
        vm.enrollments = response[0].data;
      });
    };

    vm.showConfirmDisenrollModal = function(enrollment) {
      delete vm.flash;
      $uibModal.open({
        templateUrl: 'dashboard/admin/cohorts/detail/confirmation.html',
        controllerAs: 'modal',
        controller: function($scope) {
          var modal = this;

          modal.fullName = enrollment.user.first_name + ' ' + enrollment.user.last_name;

          modal.ok = function() {
            Institution.one(contextId).one('cohorts', cohortId).one('enrollments', enrollment.user.id).remove().then(function() {
              vm.search();
            });

            $scope.$dismiss();
          };
        }
      });
    };

    vm.export = function(cohort) {
      var queryParams = {cohort_id: cohort.id, from: cohort.starts_on, to: cohort.ends_on};
      spinner.activateController([
        Institution.one(contextId).one('reports').one('student_progress.csv').get(queryParams).then(function(response) {
          if (typeof Blob === 'function') {
            var reports_csv = new Blob([response.data], {type: 'text/csv;charset=UTF-8'});
            saveAs(reports_csv, vm.cohort.name + '_reports.csv');
          }
        })
      ]);
    };

    vm.updateEnrollmentDate = function(enrollment) {
      var params = {
        started_on: enrollment.started_on
      };

      if (enrollment.ends_on) {
        params.ends_on = enrollment.ends_on;
      }

      spinner.activateController([
        User.one(enrollment.user.id).one('cohorts', enrollment.cohort_id).patch(params)
      ]);
    };

    vm.showLTIModal = function(cohort) {
      delete vm.flash;
      $uibModal.open({
        templateUrl: 'dashboard/admin/cohorts/detail/lti_params.html',
        controllerAs: 'modal',
        controller: function($scope, gettext) {
          var modal = this, query;

          query = {'q[owner_institution_id_eq]': cohort.institution_id};
          modal.cohortId = cohort.id;
          modal.courseId = cohort.curriculum_id;
          modal.program = {};
          modal.programPlaceholder = gettext('Select a program...');

          search.addParams(query);
          sorting.addParams(query);

          Institution.one(contextId).getList('programs', query).then(function(response) {
            modal.programs = response.data;
          });
        }
      });
    };

    vm.updateDRC = function(enrollment) {
      spinner.activateController([
        Institution
          .one(contextId).one('cohorts', enrollment.cohort_id).one('enrollments/' + enrollment.user.id + '/' + enrollment.role)
          .patch({drc_accommodation: enrollment.drc_accommodation})
      ]);
    };

    vm.loadEnrollments = function() {
      tableFeatures.getPageData().then(function(response) {
        vm.enrollments = response[0].data;
      });
    };

    vm.loadCohortDetails = function() {
      spinner.activateController([
        Institution.one(contextId).one('cohorts', cohortId).get().then(function(response) {
          vm.cohort = response.data;
          vm.startDate.minDate = response.data.starts_on;
          vm.startDate.maxDate = response.data.ends_on;

          vm.endDate.minDate = vm.startDate.maxDate;
          loadCourse(vm.cohort.curriculum_id);
          if (vm.cohort.ends_on === null || vm.cohort.ends_on === undefined || moment().isBefore(vm.cohort.ends_on, 'day')) {
            vm.enableEnroll = true;
          }
        })
      ]);
    };

    vm.isNotOngoingCohort = function(cohortEndDate) {
      return moment().isAfter(cohortEndDate, 'day');
    };

    vm.resetStudent = function(enrollment) {
      delete vm.flash;
      $uibModal.open({
        templateUrl: 'dashboard/admin/cohorts/modals/confirm-reset-user-modal.html',
        controllerAs: 'modal',
        controller: function($scope) {
          var modal = this;

          modal.reset = function() {
            spinner.activateController([
              UserCohorts.resetCohortUser(enrollment.user.institution_id, enrollment.cohort_id, enrollment.user.id).then(function() {
                vm.flash = {type: 'success'};
                $scope.$dismiss();
              }).catch(function(response) {
                vm.flash = {type: 'error'};
                vm.flash.message = response.data ? response.data.error : response.errors;
                $scope.$dismiss();
              })
            ]);
          };

          modal.close = function() {
            $scope.$dismiss();
          };
        }
      });
    };

    function loadCourse(courseId) {
      spinner.activateController([
        Institution.one($stateParams.contextId).one('curriculums', courseId).get().then(function(response) {
          vm.course = response.data;
        })
      ]);
    }

    vm.loadCohortDetails();
    vm.loadEnrollments();
  }
}());
