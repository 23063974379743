(function() {
  'use strict';

  angular
    .module('tcm.core')
    .config(textAngularProviderConfig);

  function textAngularProviderConfig($provide) {
    $provide.decorator('taOptions', ['$delegate', function(taOptions) {
      // $delegate is the taOptions we are decorating
      // here we override the default toolbars and classes specified in taOptions.
      taOptions.forceTextAngularSanitize = true; // set false to allow the textAngular-sanitize provider to be replaced
      taOptions.toolbar = [
        ['h1', 'h2', 'h3', 'p'],
        ['bold', 'italics', 'underline', 'ul', 'ol', 'redo', 'undo', 'clear'],
        ['insertLink']
      ];
      return taOptions;
    }]);
  }
}());
