(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('Student', StudentResource);

  function StudentResource(ApiRestangular) {
    return ApiRestangular.withConfig(function(RestangularConfigurer) {
      RestangularConfigurer.setFullResponse(true);
      RestangularConfigurer.setDefaultHeaders({'Content-Type': 'application/json'});
    }).service('students');
  }
}());
