(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.cohorts')
    .config(cohortRoutesConfig);

  function cohortRoutesConfig($stateProvider) {

    $stateProvider.state('admin.cohorts', {
      url: '/cohorts?name_cont&starts_on_eq&starts_on_gt&starts_on_lt&ends_on_eq&ends_on_gt&ends_on_lt',
      abstract: true,
      template: '<div ui-view="active"></div>'
    }).state('admin.cohorts.list', {
      url: '',
      reloadOnSearch: false,
      views: {
        'active': {
          controller: 'AdminCohortsListCtrl as vm',
          templateUrl: 'dashboard/admin/cohorts/list/cohorts-list.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Cohorts',
        parent: 'admin.dashboard'
      }
    }).state('admin.cohorts.new', {
      url: '/new?courseId',
      views: {
        'active': {
          controller: 'AdminCohortsCreateCtrl as vm',
          templateUrl: 'dashboard/admin/cohorts/create/cohorts-create.html'
        }
      },
      ncyBreadcrumb: {
        label: 'New',
        parent: 'admin.cohorts.list'
      }
    }).state('admin.cohorts.edit', {
      url: '/:id/edit',
      views: {
        'active': {
          controller: 'AdminCohortsCreateCtrl as vm',
          templateUrl: 'dashboard/admin/cohorts/create/cohorts-create.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Edit',
        parent: 'admin.cohorts.show'
      }
    }).state('admin.cohorts.grade', {
      url: '/:id/grade',
      views: {
        'active': {
          controller: 'AdminCoursesManageObjectivesCtrl as vm',
          templateUrl: 'dashboard/admin/courses/manage-objectives/courses-manage-activities.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Gradable Activities',
        parent: 'admin.cohorts.show'
      }
    }).state('admin.cohorts.show', {
      url: '/:id?error',
      views: {
        'active': {
          controller: 'AdminCohortsDetailCtrl as vm',
          templateUrl: 'dashboard/admin/cohorts/detail/cohorts-detail.html'
        }
      },
      ncyBreadcrumb: {
        label: '{{vm.cohort.name}}',
        parent: 'admin.cohorts.list'
      }
    }).state('admin.cohorts.enroll', {
      url: '/:id/enroll',
      views: {
        'active': {
          controller: 'AdminUsersListCtrl as vm',
          templateUrl: 'dashboard/admin/users/list/users-list.html'
        }
      },
      ncyBreadcrumb: {
        label: 'enroll',
        parent: 'admin.cohorts.show'
      }
    }).state('admin.cohorts.activity', {
      url: '/:activityId/activityLaunch',
      views: {
        'active@admin.cohorts': {
          template: '<eo-course-structure-tree-activity></eo-course-structure-tree-activity>'
        }
      },
      ncyBreadcrumb: {
        label: 'Structure',
        parent: 'admin.chorts.list'
      }
    });
  }
}());
