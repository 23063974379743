(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoCourseStructureTree', eoCourseStructureTree);

  function eoCourseStructureTree($state, $stateParams, Objective, SortingService,
     ExternalService, UserService, SpinnerService, Institution, $uibModal) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-course-structure-tree/eo-course-structure-tree.html',
      scope: {},
      controller: eoCourseStructureTreeCtrl,
      controllerAs: 'vm'
    };

    function eoCourseStructureTreeCtrl($element) {
      var vm = this, sorting, spinner, institutionId, courseId;
      vm.contentLaunched = false;
      vm.objectives = [];
      vm.activities = [];
      vm.flash = {};

      sorting = new SortingService({ column: 'position' });
      spinner = new SpinnerService();

      vm.loadNestedModules = function(module, isExpanded) {
        var queryString = {};
        if (isExpanded) {
          if (!module.nestedModules) {
            sorting.addParams(queryString);
            spinner.activateController([
              Institution.one(institutionId).one('curriculums', module.id).getList('children', queryString).then(function(response) {
                module.nestedModules = response.data;
              })
            ]);
          }
        }
      };

      vm.loadObjectives = function(moduleId, isExpanded) {
        if (isExpanded) {
          if (!vm.objectives[moduleId]) {
            spinner.activateController([
              Institution.one(institutionId).one('curriculums', moduleId).getList('objectives').then(function(response) {
                vm.objectives[moduleId] = response.data;
              })
            ]);
          }
        }
      };

      vm.loadActivities = function(objectiveId, isExpanded) {
        if (isExpanded) {
          if (!vm.activities[objectiveId]) {
            spinner.activateController([
              Objective.one(objectiveId).getList('activities').then(function(response) {
                vm.activities[objectiveId] = response.data;
              })
            ]);
          }
        }
      };

      vm.manageModule = function(moduleId, collapsed) {
        if ($state.includes('admin.**')) {
          $state.go('admin.courses.show', {id: moduleId});
        } else {
          vm.loadObjectives(moduleId, collapsed);
        }
      };

      vm.manageObjective = function(objectiveId, collapsed) {
        if ($state.includes('admin.**')) {
          $state.go('admin.objectives.manage', {id: objectiveId});
        } else {
          vm.loadActivities(objectiveId, collapsed);
        }
      };

      vm.enableLink = function(pageName) {
        return pageName === vm.pageName;
      };

      vm.deleteActivity = function(activity, objective) {
        $uibModal.open({
          templateUrl: 'components/eo-course-structure-tree/modals/confirm-remove-activity-modal.html',
          controllerAs: 'modal',
          controller: function editModalCtrl($scope, Objective) {
            var modal = this;

            vm.flash = {};

            modal.activity = activity;

            modal.cancel = function() {
              $scope.$close();
            };

            modal.removeActivity = function(fromCourse) {
              if (fromCourse === true) {
                _.each(vm.courseObjectives, function(objective) {
                 _.each(objective.activities, function(objectiveActivity) {
                   if (activity.id === objectiveActivity.id) {
                     removeActivityFromLO(activity.id, objective.id);
                   }
                 });
                });

                modal.cancel();
              } else {
                removeActivityFromLO(activity.id, objective.id).then(function() {
                  modal.cancel();
                });
              }
            };

            function removeActivityFromLO(activityId, objectiveId) {
              var activityElement = $element.find('li[data-objective-id="' + objectiveId + '"] li[data-activity-id="' + activityId + '"]');
              if (!activityId || !objectiveId) {
                return;
              }

              return Objective.one(objectiveId).customDELETE('activities', {'la_ids[]': [activityId]}).then(function() {
                activityElement.addClass('hide-activity');
                vm.flash.success = true;
                vm.flash.message = activity.description;
              }).catch(function(response) {
                var errors = '';
                vm.flash.error = true;
                vm.flash.danger = true;

                if (response.data && response.data.error) {
                  errors = response.data.error;
                }

                if (response.data.errors) {
                  _.each(response.data.errors, function(error, key) {
                    if (error.length && error[0] && error[0].message) {
                      errors += key + ' ' + error[0].message;
                    }
                  });
                }

                vm.flash.message = errors;
              });
            }
          }
        });
      };

      vm.openActivity = function(activity, cObjective) {
        var url, role = vm.pageName;

        if (role === 'Success Coach') {
          role = 'successcoach';
        }

        url = $state.href(role + '.cohorts.structure.activity', {activityId:activity.id, objectiveId:cObjective.id});

        if (role === 'admin') {
          url = $state.href(role + '.courses.activity', {activityId: activity.id, objectiveId: cObjective.id, curriculumId: vm.course.id}); 
        }

        return window.open(url, 'blank');
      };

      function loadCourse() {
        return Institution.one(institutionId).one('curriculums', courseId).get().then(function(response) {
          vm.course = response.data;
          vm.courseName = vm.course.name;
        });
      }

      function loadModules() {
        var queryString = {};
        sorting.addParams(queryString);
        return Institution.one(institutionId).one('curriculums', courseId).getList('children', queryString).then(function(response) {
          vm.modules = response.data;
        });
      }

      function loadCourseObjectives() {
        return Institution.one(institutionId).one('curriculums', courseId).getList('objectives').then(function(response) {
          vm.courseObjectives = response.data;
        });
      }

      function loadCourseStructure() {
        spinner.activateController([
          loadCourse(),
          loadModules(),
          loadCourseObjectives()
        ]);
      }

      function loadPage() {
        if (!$state.includes('admin.**')) {
          if ($state.includes('instructor.**')) {
            vm.pageName = 'instructor';
          } else {
            vm.pageName = 'Success Coach';
          }

          courseId = parseInt($stateParams.curriculumId, 10);
          UserService.loadProfile().then(function(profile) {
            institutionId = profile.institution_id;
            loadCourseStructure();
          });
        } else {
          vm.pageName = 'admin';
          institutionId = parseInt($stateParams.contextId, 10);
          courseId = parseInt($stateParams.id, 10);
          loadCourseStructure();
        }
      }

      loadPage();
    }
  }
}());
