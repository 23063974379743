(function() {
  'use strict';

  angular
    .module('tcm.auth')
    .controller('ProfileEditCtrl', ProfileEditCtrl);

  function ProfileEditCtrl($scope, UserService, SpinnerService,
    ValidationService, $state) {

    var vm = this, spinner;

    spinner = new SpinnerService();
    vm.model = {};
    vm.model.webex_credentials = {};

    vm.fields = [
      {
        key: 'first_name',
        type: 'input',
        templateOptions: {
          required: true,
          minlength: 2,
          maxlength: 50,
          type: 'text',
          label: 'First Name'
        },
        validators: {
          lettersHyphens: ValidationService.validateLettersHyphens()
        }
      },
      {
        key: 'last_name',
        type: 'input',
        templateOptions: {
          required: true,
          minlength: 2,
          maxlength: 50,
          type: 'text',
          label: 'Last Name'
        },
        validators: {
          lettersHyphens: ValidationService.validateLettersHyphens()
        }
      },
      {
        key: 'email',
        type: 'input',
        templateOptions: {
          required: true,
          type: 'email',
          label: 'Email'
        },
        validators: {
          emailAddress: ValidationService.validateEmail()
        }
      },
      {
        key: 'address1',
        type: 'input',
        templateOptions: {
          maxlength: 100,
          type: 'text',
          label: 'Address Line 1'
        },
        validators: {
          address: ValidationService.validateAddress()
        }
      },
      {
        key: 'address2',
        type: 'input',
        templateOptions: {
          maxlength: 100,
          type: 'text',
          label: 'Address Line 2'
        },
        validators: {
          address: ValidationService.validateAddress()
        }
      },
      {
        className: 'row',
        fieldGroup: [
          {
            className: 'col-xs-6',
            key: 'city',
            type: 'input',
            templateOptions: {
              maxlength: 50,
              type: 'text',
              label: 'City'
            },
            validators: {
              address: ValidationService.validateAddress()
            }
          },
          {
            className: 'col-xs-6',
            key: 'zip_postal_code',
            type: 'input',
            templateOptions: {
              type: 'text',
              label: 'Zip Code'
            },
            validators: {
              zip: ValidationService.validateZip()
            }
          }
        ]
      },
      {
        className: 'row',
        fieldGroup: [
          {
            className: 'col-xs-6',
            key: 'state_province',
            type: 'ui-select',
            templateOptions: {
              label: 'State',
              options: []
            },
            /* @ngInject */
            controller: function($scope, State) {
              State.getList().then(function(response) {
                $scope.to.options = response.data;
              });
            }
          },
          {
            className: 'col-xs-6',
            key: 'phone',
            type: 'input',
            templateOptions: {
              type: 'text',
              label: 'Phone Number'
            },
            validators: {
              phone: ValidationService.validatePhoneNumber()
            }
          }
        ]
      },
      {
        className: 'row',
        fieldGroup: [
          {
            className: 'col-xs-6',
            key: 'webex_credentials.username',
            type: 'input',
            templateOptions: {
              type: 'text',
              label: 'WebEx User Name'
            },
            hideExpression: function() {
              return webexFeature();
            },
            expressionProperties: {
              'templateOptions.required': function() {
                resetWebex();
                return vm.model.webex_credentials ;
              }
            }
          },
          {
            className: 'col-xs-6',
            key: 'webex_credentials.default_meeting_password',
            type: 'input',
            templateOptions: {
              type: 'text',
              label: 'Default Meeting Password'
            },
            hideExpression: function() {
              return webexFeature();
            },
            expressionProperties: {
              'templateOptions.required': function() {
                resetWebex();
                return vm.model.webex_credentials;
              }
            }
          }
        ]
      },
      {
        className: 'row',
        fieldGroup: [
          {
            className: 'col-xs-6',
            key: 'webex_credentials.password',
            type: 'input',
            templateOptions: {
              type: 'password',
              label: 'WebEx Password'
            },
            hideExpression: function() {
              return webexFeature();
            },
            expressionProperties: {
              'templateOptions.required': function() {
                resetWebex();
                return vm.model.webex_credentials;
              }
            },
            validators: {
              noSpaces: ValidationService.validateNoSpaces()
            }
          }
        ]
      }
    ];

    function resetWebex() {
      if (vm.model.webex_credentials && !vm.model.webex_credentials.id && !vm.model.webex_credentials.password && !vm.model.webex_credentials.default_meeting_password && !vm.model.webex_credentials.username) {
        delete vm.model.webex_credentials;
      }
    }

    function webexFeature() {
      if (vm.model.webex) {
        var flag = false;
        if (vm.model.roles) {
          for (var x = 0; x < vm.model.roles.length; x++) {
          //Checking on the role type Instructor where webex is enabled
            if (vm.model.roles[x].title === 'Instructor' || vm.model.roles[x].title === 'Success Coach') {
              flag = false;
              break;
            } else {
              flag = true;
            }
          }
        }

        return flag;
      } else {
        return true;
      }
    }

    vm.passwordFields = [
      {
        key: 'password',
        type: 'zxcvbn',
        modelOptions: {
          allowInvalid: true
        },
        templateOptions: {
          required: true,
          label: 'Password',
          maxlength: '32',
          minlength: '8',
          type: 'password',
          onKeyup: function(viewValue, modelValue, scope) {
            if (!viewValue) {
              scope.fullData = {};
            }
          }
        },
        validators: {
          password: ValidationService.validatePassword()
        }
      },
      {
        key: 'password_confirmation',
        type: 'input',
        templateOptions: {
          required: true,
          label: 'Confirm Password',
          type: 'password'
        },
        validators: {
          passwordMatch: ValidationService.validatePasswordMatch()
        }
      }
    ];

    function loadProfile() {
      return UserService.loadProfile().then(function(profile) {
        vm.model = profile;
      });
    }

    vm.save = function() {
      if (vm.editProfileForm.$valid) {

        UserService.saveProfile(vm.model).then(function(profile) {
          $scope.$emit('update-user-name', profile);

          if ($state.is('student.profile.edit')) {
            $state.go('student.profile.show');
          } else if ($state.is('instructor.profile.edit')) {
            $state.go('instructor.profile.show');
          } else if ($state.is('admin.profile.edit')) {
            $state.go('admin.profile.show');
          } else if ($state.is('successcoach.profile.edit')) {
            $state.go('successcoach.profile.show');
          }
        });
      }
    };

    vm.resetPassword = function() {
      if (vm.changePasswordForm.$valid) {
        UserService.saveProfile(vm.model).then(function() {
          vm.flash = 'Your password has been successfully changed';
          delete vm.model.password;
          delete vm.model.password_confirmation;

          // manually reset validation on password reset
          if (vm.passwordFields[0].formControl && vm.passwordFields[1].formControl) {
            vm.passwordFields[0].formControl.$setUntouched();
            vm.passwordFields[1].formControl.$setUntouched();
            vm.changePasswordForm.$setPristine();
          }
        });
      }
    };

    spinner.activateController([
      loadProfile()
    ]);
  }
}());
