(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('UserCompetencies', UserCompetencies);

  function UserCompetencies(User, SortingService, PaginationService) {
    var pagination, sorting;

    pagination = new PaginationService({perPage: 100});
    sorting = new SortingService({column: 'position'});

    function getUserCompetenciesReportByProgram(userId, programId, queryString) {
      queryString = queryString || {};

      if (_.isEmpty(queryString)) {
        pagination.addParams(queryString);
        sorting.addParams(queryString);
      }

      return User.one(userId).one('programs', programId).getList('competencies', queryString);
    }

    function getUserCompetenciesGroupedByTags(userId, programId, queryString) {
      return User.one(userId).one('programs', programId).one('competencies', 'tagged').get(queryString);
    }

    return {
      getUserCompetenciesReportByProgram: getUserCompetenciesReportByProgram,
      getUserCompetenciesGroupedByTags: getUserCompetenciesGroupedByTags
    };
  }
}());
