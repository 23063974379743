(function() {
  'use strict';

  angular
    .module('tcm.common', [
      'tcm.core',
      'tcm.auth',
      'tcm.EnvConfig',
      'restangular.api'
    ]);
}());
