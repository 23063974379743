(function() {
  'use strict';

  angular
    .module('tcm.auth.password')
    .config(passwordRoutesConfig);

  function passwordRoutesConfig($stateProvider) {
    $stateProvider
      .state('change_password', {
        url: '/change_password',
        views: {
          'main': {
            controller: 'PasswordChangeCtrl as vm',
            templateUrl: 'auth/password/change/password-change.html'
          }
        }
      })
      .state('forgot_password', {
        url: '/forgot_password',
        views: {
          'main': {
            controller: 'PasswordForgotCtrl',
            templateUrl: 'auth/password/forgot/password-forgot.html'
          }
        }
      });
  }
}());
