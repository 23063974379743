(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.courses')
    .controller('AdminCoursesStructureCtrl', AdminCoursesStructureCtrl);

  function AdminCoursesStructureCtrl($stateParams, Institution) {
    var vm = this, institutionId, courseId;

    institutionId = parseInt($stateParams.contextId, 10);
    courseId = parseInt($stateParams.id, 10);

    function loadCourse() {
      return Institution.one(institutionId).one('curriculums', courseId).get().then(function(response) {
        vm.course = response.data;
      });
    }

    loadCourse();
  }
}());
