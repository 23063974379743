(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.libraries')
    .controller('AdminLibrariesListCtrl', AdminLibrariesListCtrl);

  function AdminLibrariesListCtrl(Institution, TableFeaturesService, $location,
    $stateParams, SortingService, SpinnerService, $uibModal, Upload) {

    var vm = this, sorting, spinner, tableFeatures, contextId;

    contextId = parseInt($stateParams.contextId, 10);
    sorting = new SortingService({column: 'name'});
    spinner = new SpinnerService();
    vm.competencies = [];

    tableFeatures = new TableFeaturesService({
      sorting: false,
      pagination: {perPage: 5},
      search: {
        'name_cont': $stateParams.name_cont
      },
      loadData: function(queryParams) {
        return Institution.one(contextId).getList('libraries', queryParams);
      }
    });

    vm.table = {
      searchData: tableFeatures.searchParams,
      paginationData: tableFeatures.paginationParams
    };

    vm.loadLibrariesList = function() {
      tableFeatures.getPageData().then(function(response) {
        vm.libraries = response[0].data;
        updateCompetencyLists();
      });
    };

    vm.search = function() {
      tableFeatures.resetEmptySearchData();
      $location.search(vm.table.searchData);

      tableFeatures.getNewData().then(function(response) {
        vm.libraries = response[0].data;
        updateCompetencyLists();
      });
    };

    function updateCompetencyLists() {
      var ids = _.map(vm.libraries, 'id');
      getRootCompetencies(vm.libraries).then(function(competencies) {
        _.each(competencies, function(competency, index) {
          vm.competencies[ids[index]] = competency.data;
        });
      });
    }

    function getRootCompetencies(libraries) {
      var queryString = {};
      sorting.addParams(queryString);
      var rootIds = _.map(libraries, 'root_id');
      return spinner.activateController(_.map(rootIds, function(root_id) {
        return Institution.one(contextId).one('competencies', root_id).getList('children', queryString);
      }));
    }

    vm.getChildren = function(competency, isExpanded) {
      if (isExpanded) {
        if (!competency.children) {
          spinner.activateController([
            Institution.one(contextId).one('competencies', competency.id).getList('children').then(function(response) {
              competency.children = response.data;
            })
          ]);
        }
      }
    };

    vm.expandCollapseTree = function(expand, libraryId) {
      var element, treeScope;

      element = document.querySelector('#library-tree-' + libraryId);
      treeScope = angular.element(element).scope();
      if (expand) {
        treeScope.expandAll();
      } else {
        treeScope.collapseAll();
      }
    };

    vm.createLibrary = function() {
      var modalInstance = $uibModal.open({
        templateUrl: 'dashboard/admin/libraries/list/create-update-library.html',
        controllerAs: 'modal',
        controller: function uploadModalCtrl($scope) {
          var modal = this, fileObj;
          modal.model = {};

          modal.fields = [
            {
              key: 'name',
              type: 'input',
              templateOptions: {
                required: true,
                label: 'Name'
              }
            },
            {
              key: 'description',
              type: 'textarea',
              templateOptions: {
                label: 'Description'
              }
            },
            {
              key: 'file',
              type: 'file-upload',
              templateOptions: {
                required: true,
                label: 'CSV File',
                fileExtension: '.csv'
              },
              expressionProperties: {
                'templateOptions.fileOptions.fileExtension': '".csv"'
              },
              /* @ngInject */
              controller: function($scope) {
                $scope.$watch('file', function(newValue) {
                  var file;

                  if (newValue) {
                    // FIXME. Creating a copy of the file object after processing it to avoid exceptions caused due
                    // to illegal types. Using $.extend since its the only one that seemed to extend the
                    // native File object as expected
                    // For formly validation
                    file = $.extend({}, newValue);
                    file.lastModifiedDate = file.lastModifiedDate.toString();
                    modal.model.file = file;

                    // File object to upload
                    fileObj = newValue;
                  }
                });
              }
            }
          ];

          modal.save = function() {
            modal.flash = '';
            if (modal.uploadLibraryForm.$valid) {
              Upload.upload({
                url: Institution.one(contextId).one('libraries').one('csv').getRestangularUrl(),
                fields: {
                  name: modal.model.name,
                  description: modal.model.description
                },
                file: fileObj
              }).success($scope.$close)
                .error(function(error) { modal.flash = error.error; });
            }
          };
        }
      });

      modalInstance.result.then(function(result) {
        vm.libraries.push(result);
        updateCompetencyLists();
      });
    };

    vm.updateLibrary = function(library) {
      var modalInstance = $uibModal.open({
        templateUrl: 'dashboard/admin/libraries/list/create-update-library.html',
        controllerAs: 'modal',
        controller: function($scope) {
          var modal = this, fileObj;
          modal.model = library;

          modal.fields = [
            {
              key: 'file',
              type: 'file-upload',
              templateOptions: {
                required: true,
                label: 'CSV File',
                fileExtension: '.csv'
              },
              expressionProperties: {
                'templateOptions.fileOptions.fileExtension': '".csv"'
              },
              /* @ngInject */
              controller: function($scope) {
                $scope.$watch('file', function(newValue) {
                  var file;

                  if (newValue) {
                    // FIXME. Creating a copy of the file object after processing it to avoid exceptions caused due
                    // to illegal types. Using $.extend since its the only one that seemed to extend the
                    // native File object as expected
                    // For formly validation
                    file = $.extend({}, newValue);
                    file.lastModifiedDate = file.lastModifiedDate.toString();
                    modal.model.file = file;

                    // File object to upload
                    fileObj = newValue;
                  }
                });
              }
            }
          ];

          modal.save = function() {
            if (modal.uploadLibraryForm.$valid) {
              Upload.upload({
                url: Institution.one(contextId).one('libraries', library.id).one('csv').getRestangularUrl(),
                file: fileObj,
                method: 'PUT'
              })
                .success($scope.$close)
                .error(function(error) { modal.flash = error.error; });
            }
          };
        }
      });

      modalInstance.result.then(function() {
        updateCompetencyLists();
      });
    };

    vm.export = function(libraryId) {
      spinner.activateController([
        Institution.one(contextId).one('libraries', libraryId).one('csv').get().then(function(response) {
          // PhantomJS 1.9.x does not support Blob type for unit testing
          if (typeof Blob === 'function') {
            var library_csv = new Blob([response.data], {type: 'text/csv;charset=UTF-8'});
            saveAs(library_csv, 'library_' + libraryId + '.csv');
          }
        })
      ]);
    };

    vm.publish = function(library) {
      spinner.activateController([
        Institution.one(contextId).one('libraries', library.id).one('publish').patch().then(function(lib) {
          library.published = lib.data.published;
        })
      ]);
    };

    vm.unpublish = function(library) {
      spinner.activateController([
        Institution.one(contextId).one('libraries', library.id).one('draft').patch().then(function(lib) {
          library.published = lib.data.published;
        })
      ]);
    };

    vm.loadLibrariesList();
  }
}());
