(function() {
  'use strict';

  angular
    .module('tcm.dashboard.instructor.cohorts.objectives')
    .config(objectivesRoutesConfig);

  function objectivesRoutesConfig($stateProvider) {

    $stateProvider.state('instructor.cohorts.objectives', {
      url: '/objectives',
      abstract: true,
      views: {
        'section': {
          templateUrl: 'dashboard/instructor/dashboard-instructor.html'
        }
      },
      ncyBreadcrumb: {skip: true}
    }).state('instructor.cohorts.objectives.studentlist', {
      url: '/:state',
      views: {
        'component': {
          template: '<eo-student-list is-grade-percentage="vm.isGradePercentage" cohort-name="vm.cohortName" course-name="vm.courseName" course-id="vm.courseId" institution-id="vm.institutionId"></eo-student-list>'
        }
      },
      ncyBreadcrumb: {
        label: '{{vm.cohortName || vm.courseName}}',
        parent: 'instructor.cohorts'
      }
    })
    .state('instructor.cohorts.objectives.studentlist.search', {
      url: '/search?user_first_name_cont&user_last_name_cont',
      views: {
        'component@instructor.cohorts.objectives': {
          template: '<eo-student-list is-grade-percentage="vm.isGradePercentage" cohort-name="vm.cohortName" course-name="vm.courseName" course-id="vm.courseId" institution-id="vm.institutionId"></eo-student-list>'
        }
      },
      ncyBreadcrumb: {label: 'Search'}
    }).state('instructor.cohorts.objectives.gradebook', {
      url: '/:state/users/:userId/activities?page&cohort_id&cohort_user_id&sort&direction&gb_page&gb_sort&gb_direction&search&weight',
      views: {
        'component': {
          controller: 'InstructorObjectivesGradebookCtrl',
          templateUrl: 'dashboard/instructor/cohorts/objectives/gradebook/objectives-gradebook.html'
        }
      },
      ncyBreadcrumb: {
        label: '{{user.first_name}} {{user.last_name}}',
        parent: 'instructor.cohorts.objectives.studentlist'
      }
    }).state('instructor.cohorts.objectives.gradebook.activity', {
      url: '/:activityId',
      views: {
        'section@instructor.cohorts': {
          controller: 'InstructorObjectivesActivityCtrl',
          templateUrl: 'dashboard/instructor/cohorts/objectives/activity/objectives-activity.html'
        }
      },
      ncyBreadcrumb: {
        label: '{{activity.description}}'
      }
    }).state('instructor.cohorts.toGradeActivity', {
      url: '/users/:userId/activities/:activityId?state&page&cohort_id&sort&direction&gb_sort&gb_direction&search',
      views: {
        'section@instructor.cohorts': {
          controller: 'InstructorObjectivesToGradeActivityCtrl',
          templateUrl: 'dashboard/instructor/cohorts/objectives/activity/objectives-activity.html'
        }
      },
      ncyBreadcrumb: {
        label: '{{user.first_name}} {{user.last_name}}',
        parent: 'instructor.cohorts.objectives.studentlist'
      }
    });
  }
}());
