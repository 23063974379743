(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('ExternalService', ExternalService);

  function ExternalService($http, $q, $log, Idle, Activity, SpinnerService) {
    var launch_url;

    function handleContent(data, opts, isPreview) {
      var action = isPreview === true ? 'preview' : 'content';
      resetLearningActivityContent();

      switch (data.content_type) {
        case 'LtiProvider':
          return handleActivityForLtiProvider(data, opts, action);
        case 'CdpItem':
          return handleActivityForCdpItem(data, opts, action);
        case 'AuthenticItem':
          return handleActivityForAuthenticItem(data, opts, action);
        default:
          $log.warn(data.content_type + ':  Not supported');
          return $q.when(false);
      }
    }

    // Content Handlers:

    function handleActivityForAuthenticItem(data, opts, action) {
      var deferred = $q.defer();
      var learning_activity_id = data.id;
      var params = buildParameters(data, opts);

      Activity.one(learning_activity_id).one(action).get(params).then(function(response) {
        var description = response.data.description;
        createLearningActivityContentRaw(description);
        deferred.resolve(false);
      });

      return deferred.promise;
    }

    function handleActivityForCdpItem(data, opts, action) {
      var deferred = $q.defer();
      var learning_activity_id = data.id;
      var params = buildParameters(data, opts);

      Activity.one(learning_activity_id).one(action).get(params).then(function(response) {
        var launch_data = response.data.launch_data;
        launch_url = response.data.launch_url;

        $http({
          method: 'POST',
          url: launch_url,
          data: launch_data,
          transformRequest: function(obj) {
            var str = [];
            for (var p in obj) {
              str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
            }
            return str.join('&');
          },
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
          .then(function(response) {
            var contentIFrame = getLearningActivityContentFrame();
            try {
              contentIFrame.document.open();
              contentIFrame.document.write(response.data);
              contentIFrame.document.close();
            } catch (e) {
              $log.warn('No iframe ' + e);
            }
            launch_data.contentLaunched = true;
            deferred.resolve(launch_data);
          }, function(response, status) {
            $log.warn('getContentForActivity failure (status ' + status + '):');
            $log.warn(response.data || 'Request failed');
            launch_data.contentLaunched  = false;
            deferred.resolve(launch_data);
          });
      });

      return deferred.promise;
    }

    function handleActivityForLtiProvider(data, opts, action) {
      var deferred = $q.defer();
      var learning_activity_id = data.id;
      var params = buildParameters(data, opts);

      Activity.one(learning_activity_id).one(action).get(params).then(function(response) {
        var launch_url = response.data.launch_url;
        var launch_data = response.data.launch_data;
        var htmlForm = null;

        if (launch_data.launch_presentation_document_target === 'window') {
          // Launch content in a new browser tab
          htmlForm = buildLtiProviderLaunchForm(document, learning_activity_id, launch_url, '_blank');
          buildLtiProviderLaunchFormFields(document, htmlForm, launch_data);
          htmlForm.submit();
          // Special case: saying spinner to hide because of pop-up window
          var spinner = new SpinnerService();
          spinner.activateController([]);
        } else {
          // IFrame the content
          var contentIFrame = getLearningActivityContentFrame();
          try {
            contentIFrame.document.open();
            contentIFrame.document.write('<html><head></head><body></body></html>');

            htmlForm = buildLtiProviderLaunchForm(contentIFrame.document, learning_activity_id, launch_url, '_self');
            buildLtiProviderLaunchFormFields(contentIFrame.document, htmlForm, launch_data);

            contentIFrame.document.write(htmlForm.outerHTML);
            contentIFrame.document.close();
            contentIFrame.document.forms[0].submit();
          } catch (e) {
            $log.warn('No iframe ' + e);
          }
          deferred.resolve(true);
        }
      });
      return deferred.promise;
    }

    function buildParameters(learningActivity, opts) {
      var params = opts || {};
      params.id = learningActivity.id;

      return params;
    }

    // Activity Content Management:

    function resetLearningActivityContent() {
      resetLearningActivityContentFrame();
      destroyLearningActivityContentRaw();
    }

    function getLearningActivityContentRawId() {
      return 'learningActivityContentRaw';
    }

    function getLearningActivityContentRaw() {
      return document.getElementById(getLearningActivityContentRawId());
    }

    function createLearningActivityContentRaw(content) {
      var contentIFrame = getLearningActivityContentFrame();
      try {
        var parent = contentIFrame.frameElement.parentNode;
        var element = document.createElement('div');
        element.setAttribute('id', getLearningActivityContentRawId());
        element.innerHTML = content || '';
        parent.parentNode.appendChild(element);
      } catch (e) {
        $log.warn('No iframe ' + e);
      }
    }

    function destroyLearningActivityContentRaw() {
      var element = getLearningActivityContentRaw();
      return element === null ? null : element.parentNode.removeChild(element);
    }

    // IFrame Management:
    function resetLearningActivityContentFrame() {
      var iframe = document.getElementById(getLearningActivityContentFrameId());
      try {
        var iframeContainer = iframe.parentNode;
        iframeContainer.removeChild(iframe);
        iframe = document.createElement('iframe');
        iframe.id = getLearningActivityContentFrameId();
        iframe.name = getLearningActivityContentFrameId();
        iframeContainer.appendChild(iframe);
        iframe.src = 'about:blank';

        // event list copied from ng-idle
        var events = 'mousemove keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll';
        angular.element(iframe.contentWindow).on(events, function() {
          Idle.interrupt();
        });

      } catch (e) {
        $log.warn('No iframe ' + e);
      }
    }

    function getLearningActivityContentFrameId() {
      return 'learningActivityContentFrame';
    }

    function getLearningActivityContentFrame() {
      var iframe = document.getElementById(getLearningActivityContentFrameId());

      if (!iframe) {
        return null;
      }

      iframe = (iframe.contentWindow) ? iframe.contentWindow : (iframe.contentDocument.document) ? iframe.contentDocument.document : iframe.contentDocument;

      return iframe;
    }

    // Content Launch Form Management:

    function buildLtiProviderLaunchForm(parentDocument, id, launch_url, target) {
      var elementId = 'ltiLaunchForm_' + id;
      var htmlForm = parentDocument.getElementById(elementId);

      if (htmlForm !== null) {
        htmlForm.parentNode.removeChild(htmlForm);
      }

      htmlForm = parentDocument.createElement('form');
      htmlForm.setAttribute('id', elementId);
      htmlForm.setAttribute('name', elementId);
      htmlForm.setAttribute('action', launch_url);
      htmlForm.setAttribute('method', 'post');
      htmlForm.setAttribute('encType', 'application/x-www-form-urlencoded');
      htmlForm.setAttribute('target', target);

      parentDocument.body.appendChild(htmlForm);
      return htmlForm;
    }

    function buildLtiProviderLaunchFormFields(parentDocument, form, launch_data) {
      for (var launch_parameter in launch_data) {
        var field = parentDocument.createElement('input');
        field.setAttribute('type', 'hidden');
        field.setAttribute('name', launch_parameter);
        field.setAttribute('value',  launch_data[launch_parameter]);
        form.appendChild(field);
      }
    }

    // External Service Public Methods:

    /**
     * Launch Content is intended for students when they are working on an activity, or when an
     * instructor is launching an activity on behalf of a student to review or score it.
     *
     * If the user's cohort role is student, these querystring parameters are required:
     *   id (the learning activity id), cohort_id, learning_objective_id
     * If the user's cohort role is instructor, these querystring parameters are required:
     *   id (the learning activity id), cohort_id, learning_objective_id, student_id
     * For all other roles, the user should not be launching content, they should be previewing it!
     */
    this.launchContentForActivity = function(data, opts) {
      return handleContent(data, opts, false);
    };

    /**
     * Preview Content is intended for admins, root, instructors when viewing curriculum content, etc.
     * The intention is that the user interested in the activity content, not student responses or performing scoring.
     */
    this.previewContentForActivity = function(data, opts) {
      return handleContent(data, opts, true);
    };

    this.getLaunchUrl = function() {
      return launch_url;
    };
  }
}());
