(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.objectives')
    .config(objectivesRoutesConfig);

  function objectivesRoutesConfig($stateProvider) {

    $stateProvider.state('admin.objectives', {
      url: '/objectives?description_cont&type_cont',
      abstract: true,
      template: '<div ui-view="active"></div>'
    }).state('admin.objectives.list', {
      url: '',
      views: {
        'active': {
          controller: 'AdminObjectivesListCtrl',
          templateUrl: 'dashboard/admin/objectives/list/objectives-list.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Objectives',
        parent: 'admin.dashboard'
      }
    }).state('admin.objectives.new', {
      url: '/new',
      views: {
        'active': {
          controller: 'AdminObjectivesCreateCtrl as vm',
          templateUrl: 'dashboard/admin/objectives/create/objectives-create.html'
        }
      },
      ncyBreadcrumb: {
        label: 'New',
        parent: 'admin.objectives.list'
      }
    }).state('admin.objectives.edit', {
      url: '/:id/edit',
      views: {
        'active': {
          controller: 'AdminObjectivesCreateCtrl as vm',
          templateUrl: 'dashboard/admin/objectives/create/objectives-create.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Edit',
        parent: 'admin.objectives.show'
      }
    }).state('admin.objectives.show', {
      url: '/:id',
      views: {
        'active': {
          controller: 'AdminObjectivesDetailCtrl as vm',
          templateUrl: 'dashboard/admin/objectives/detail/objectives-detail.html'
        }
      },
      ncyBreadcrumb: {
        label: '{{vm.objective.description}}',
        parent: 'admin.objectives.list'
      }
    }).state('admin.objectives.manage', {
      url: '/:id/manage',
      views: {
        'active': {
          controller: 'AdminObjectivesManageActivitiesCtrl as vm',
          templateUrl: 'dashboard/admin/objectives/manage/objectives-manage-activities.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Manage',
        parent: 'admin.objectives.show'
      }
    });
  }
}());
