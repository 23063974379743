(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoAssignedCompetencies', eoAssignedCompetencies);

  function eoAssignedCompetencies($rootScope, $timeout, $stateParams,
    Institution, TableFeaturesService
  ) {
    return {
      templateUrl: 'components/eo-assigned-competencies/eo-assigned-competencies.html',
      scope: {
        programState: '=',
        managePermission: '=?'
      },
      controller: eoAssignedCompetenciesCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoAssignedCompetenciesCtrl($scope) {
      var vm = this, tableFeatures, contextId, programId;

      contextId = parseInt($stateParams.contextId, 10);
      programId = parseInt($stateParams.id, 10);
      vm.checkedCompetencies = [];

      $timeout(function() {
        $scope.$emit('eoAssignedCompetencies:handshake');
      }, 0);

      $rootScope.$on('eoAvailableCompetencies:handshake', function() {
        vm.manageMode = true;
      });

      $rootScope.$on('eoAssignedCompetencies:reload', function() {
        vm.loadAssignedCompetencies();
      });

      tableFeatures = new TableFeaturesService({
        sorting: false,
        search: false,
        pagination: {
          perPage: 10,
          scrollTop: false
        },
        loadData: function(queryParams) {
          return Institution.one(contextId).one('programs', programId).getList('competencies', queryParams);
        }
      });

      vm.table = {
        paginationData: tableFeatures.paginationParams
      };

      vm.loadAssignedCompetencies = function() {
        vm.checkedCompetencies.length = 0;
        tableFeatures.getPageData().then(function(response) {
          vm.assignedCompetencies = response[0].data;
        });
      };

      vm.checkCompetency = function(competency) {
        if (competency.checked) {
          vm.checkedCompetencies.push(competency.id);
        } else {
          _.pull(vm.checkedCompetencies, competency.id);
        }
      };

      vm.setCheckedState = function(state) {
        vm.checkedCompetencies.length = 0;
        _.each(vm.assignedCompetencies, function(competency) {
          competency.checked = state;
          if (state) {
            vm.checkedCompetencies.push(competency.id);
          } else {
            vm.checkedCompetencies.length = 0;
          }
        });
      };

      vm.removeSelectedCompetencies = function() {
        Institution.one(contextId).one('programs', programId).customDELETE('competencies', {'competency_ids[]': vm.checkedCompetencies}).then(function() {
          vm.loadAssignedCompetencies();
          vm.checkedCompetencies.length = 0;
          $scope.$emit('eoAvailableCompetencies:reload');
        });
      };

      vm.loadAssignedCompetencies();
    }
  }
}());
