(function() {
  'use strict';

  angular
    .module('tcm.release')
    .config(releaseRoutesConfig);

  function releaseRoutesConfig($stateProvider) {

    $stateProvider.state('release', {
      url: '/release',
      views: {
        'main': {
          controller: 'ReleaseCtrl as vm',
          templateUrl: 'release/release.html'
        }
      }
    });
  }
}());
