(function() {
  'use strict';

  angular
    .module('tcm.common')
    .directive('eoScrollTop', eoScrollTop);

  function eoScrollTop() {
    /**
     *  eo-eoScrollTop directive scrolls page to the top.
     *  When a page list is really long user has to scroll to the top
     *  each time when he/she switches pages.
     *  This directive aims to solve that and improve UX.
     *
     *  It is considered that this directive is used only on 'body' tag.
     */
    return {
      link: function(scope, element) {
        scope.$on('paginationService:scrollTop', function() {
          element.animate({scrollTop: 0}, 500);
        });
      }
    };
  }
}());
