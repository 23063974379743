(function() {
  'use strict';

  angular
    .module('tcm.help', [
      'tcm.core',
      'com.2fdevs.videogular',
      'com.2fdevs.videogular.plugins.controls',
      'com.2fdevs.videogular.plugins.overlayplay',
      'com.2fdevs.videogular.plugins.poster'
    ]);
}());
