(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.institutions')
    .controller('AdminInstitutionsDetailCtrl', AdminInstitutionsDetailCtrl);

  function AdminInstitutionsDetailCtrl($scope, $stateParams, Institution,
    SpinnerService, $uibModal) {

    var vm = this, spinner, institutionId;

    spinner = new SpinnerService();
    institutionId = parseInt($stateParams.id, 10);

    function loadInstitutionDetails() {
      return Institution.one(institutionId).get().then(function(response) {
        vm.institution = response.data;
        if (vm.institution.parent_id) {
          loadParentInstitution(vm.institution.parent_id);
        }
      });
    }

    function loadParentInstitution(parentId) {
      return Institution.one(parentId).get().then(function(response) {
        vm.institution.parent = response.data;
      });
    }

    vm.disableInstitution = function() {
      vm.institution.enabled = false;
      vm.institution.patch(vm.institution).then(function() {
        $scope.$broadcast('eoInstitutionChildren:enabled', false);
      });
    };

    vm.enableInstitution = function() {
      var modal = $uibModal.open({
        templateUrl: 'dashboard/admin/institutions/detail/update-descendants.html',
        controller: function($scope) {
          $scope.submit = function(yesno) {
            $scope.$close(yesno);
          };
        }
      });

      modal.result.then(function(update) {
        vm.institution.update_descendants = !!update;
        vm.institution.enabled = true;
        vm.institution.patch(vm.institution).then(function() {
          if (update) {
            $scope.$broadcast('eoInstitutionChildren:enabled', true);
          }
        });
      });
    };

    spinner.activateController([
      loadInstitutionDetails()
    ]);
  }
}());
