(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoLearningActivityTable', eoLearningActivityTable);

  function eoLearningActivityTable($state, $stateParams) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-learning-activity-table/eo-learning-activity-table.html',
      scope: {
        activities: '=',
        sortingColumn: '=',
        search: '=?',
        filter: '=?'
      },
      controller: eoLearningActivityTableCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoLearningActivityTableCtrl() {
      var vm = this, objectiveId, cohortId, state, sorting, masterdText;

      objectiveId = parseInt($stateParams.objectiveId, 10);
      cohortId = parseInt($stateParams.id, 10);
      state = $stateParams.state;
      sorting = vm.sortingColumn;

      vm.sortedColumn = {};

      vm.sortByColumn = function(column) {
        vm.sortedColumn.reversed = vm.sortedColumn.name === column ? !vm.sortedColumn.reversed : false;
        vm.sortedColumn.name = column;
      };

      vm.launchActivity = function(activity) {
        var url, queryParams = {state: state, activityId: activity.id};

        if (activity.read_only) {
          return;
        }

        if (state === 'search') {
          queryParams.search = vm.search || null;
          url = $state.href('student.cohorts.all.activitySearchLaunch', queryParams);
        } else {
          queryParams.objectiveId = objectiveId;
          url = $state.href('student.cohorts.activityLaunch', queryParams);
        }
        window.open(url, '_blank');
      };

      vm.getActivityState = function(activity) {
        if (activity.activity_type === 'web_content') {
          masterdText = 'Mastered';

        } else {
          masterdText = 'Approved';
        }
        var states = {
          'available': 'Not started',
          'in_progress': 'In progress',
          'mastered': activity.gradable ? 'Mastered' : masterdText,
          'awaiting_instructor_grading': activity.gradable ? 'Awaiting Instructor Grading' : 'Awaiting Instructor Approval',
          'awaiting_grading': 'Awaiting',
          'failed': activity.gradable ? 'Not mastered' : 'Disapproved',
          'default': 'Not started'
        };

        activity.custom_state = states[activity.user_state] || states.default;

        return states[activity.user_state] || states.default;
      };
    }
  }
}());
