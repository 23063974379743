(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.rubrics')
    .controller('AdminRubricsDetailCtrl', AdminRubricsDetailCtrl);

  function AdminRubricsDetailCtrl($stateParams, Institution, SpinnerService) {
    var vm = this, rubricId, contextId, spinner;

    rubricId = parseInt($stateParams.id, 10);
    contextId = parseInt($stateParams.contextId, 10);
    spinner = new SpinnerService();

    function loadRubricDetails() {
      return Institution.one(contextId).one('rubrics', rubricId).get().then(function(response) {
        vm.rubric = response.data;
      });
    }

    spinner.activateController([
      loadRubricDetails()
    ]);
  }
}());
