(function() {
  'use strict';

  angular
    .module('tcm.dashboard.student.cohorts')
    .config(cohortsRoutesConfig);

  function cohortsRoutesConfig($stateProvider) {
    $stateProvider
      .state('student.cohorts', {
        url: '/cohorts/:id',
        abstract: true,
        ncyBreadcrumb: {skip: true},
        views: {
          'active': {
            controller: 'StudentCohortsCtrl as vm',
            template: '<div ui-view="section"><div/>'
          }
        }
      })
      .state('student.cohorts.objectives', {
        url: '/objectives',
        abstract: true,
        views: {
          'section': {
            templateUrl: 'dashboard/student/cohorts/student-cohorts.html'
          }
        },
        ncyBreadcrumb: {skip: true}
      })
      .state('student.cohorts.objectives.list', {
        url: '/:state',
        views: {
          'component': {
            template: '<eo-objectives-list></eo-objectives-list>'
          }
        },
        ncyBreadcrumb: {
          label: '{{vm.cohort.curriculum_name}}',
          parent: 'student.cohorts.objectives'
        }
      })
      .state('student.cohorts.activities', {
        url: '/objectives/:state/:objectiveId/activities?:displayCards',
        reloadOnSearch: false,
        ncyBreadcrumb: {
          label: '{{currentObjective.description}}',
          parent: 'student.cohorts.objectives.list'
        },
        views: {
          'section': {
            controller: 'StudentObjectiveActivitiesCtrl',
            templateUrl: 'dashboard/student/cohorts/objective-activities/objective-activities.html'
          }
        }
      })
      .state('student.cohorts.activities.activityLaunch', {
        url: '/:activityId/launch',
        ncyBreadcrumb: {skip: true},
        reloadOnSearch: false,
        views: {
          'section': {
            templateUrl: 'dashboard/student/cohorts/objective-activities/objective-activities.html'
          }
        }
      })
      .state('student.cohorts.activities.activityFeedback', {
        url: '/:activityId/feedback',
        ncyBreadcrumb: {skip: true},
        reloadOnSearch: false,
        views: {
          'section': {
            templateUrl: 'dashboard/student/cohorts/objective-activities/objective-activities.html'
          }
        }
      })
      .state('student.cohorts.activityLaunch', {
        url: '/objectives/:state/:objectiveId/activities/:activityId',
        ncyBreadcrumb: {skip: true},
        views: {
          'section': {
            template:'<eo-student-activities-launch><eo-student-activities-launch/>'
          }
        }
      })
      .state('student.cohorts.all', {
        url: '/all',
        abstract: true,
        ncyBreadcrumb: {skip: true},
        views: {
          'section': {
            template: '<div ui-view="all"><div/>'
          }
        }
      })
      .state('student.cohorts.all.activities', {
        url: '/activities/:state?:search',
        ncyBreadcrumb: {
          label: 'Learning Activities',
          parent: 'student.cohorts.objectives.list({state: "all"})'
        },
        views: {
          'all': {
            templateUrl: 'dashboard/student/cohorts/search-activities/search-activities.html',
            controller: 'StudentSearchActivitiesCtrl as vm'
          }
        }
      })
      .state('student.cohorts.all.activities.activityLaunch', {
        url: '/:activityId/launch',
        ncyBreadcrumb: {skip: true},
        views: {
          'all': {
            templateUrl: 'dashboard/student/cohorts/search-activities/search-activities.html',
            controller: 'StudentSearchActivitiesCtrl as vm'
          }
        }
      })
      .state('student.cohorts.all.activities.activityFeedback', {
        url: '/:activityId/feedback',
        ncyBreadcrumb: {skip: true},
        views: {
          'all': {
            templateUrl: 'dashboard/student/cohorts/search-activities/search-activities.html',
            controller: 'StudentSearchActivitiesCtrl as vm'
          }
        }
      })
      .state('student.cohorts.all.activitySearchLaunch', {
        url: '/objectives/:state/activities/:activityId?:search',
        ncyBreadcrumb: {skip: true},
        views: {
          'all': {
            template: '<eo-student-activities-launch><eo-student-activities-launch/>'
          }
        }
      });
  }
}());
