(function() {
  'use strict';

  angular
    .module('tcm.dashboard.student.cohorts')
    .controller('StudentObjectiveActivitiesCtrl', StudentObjectiveActivitiesCtrl);

  function StudentObjectiveActivitiesCtrl(
    $scope,
    $rootScope,
    $stateParams,
    $state,
    User,
    UserService,
    PaginationService,
    SpinnerService,
    UserObjectives,
    TableFeaturesService,
    localStorageService
  ) {
    var pagination, spinner, tableFeatures;

    $scope.cohortId = $stateParams.id;
    $scope.objectiveId = $stateParams.objectiveId;
    $scope.state = $stateParams.state;
    $scope.sortingColumn = $stateParams.sort || 'position';
    $scope.sortingDirection = $stateParams.direction;
    $scope.displayCards = localStorageService.get('displayCards') !== null ? JSON.parse(localStorageService.get('displayCards')) : true;
    $scope.isfilterCollapsed = true;

    pagination = new PaginationService({ perPage: 1 });
    $scope.paginationData = pagination.data;

    spinner = new SpinnerService();

    tableFeatures = new TableFeaturesService({
      sorting: {column: $scope.sortingColumn, direction: $scope.sortingDirection},
      pagination: {perPage: 100},
      loadData: function(queryParams) {
        return UserObjectives.getAllObjectivesByCohort($scope.cohortId, queryParams);
      }
    });

    $scope.listData = {
      searchData: tableFeatures.searchParams,
      tableFeaturesPagination: tableFeatures.paginationParams
    };

    $scope.loadPage = function() {
      var queryString = {};
      pagination.addParams(queryString);
      if (queryString.page) {
        setCurrentObjective();
        $state.go('student.cohorts.activities', {state: $stateParams.state, objectiveId: $scope.objectives[queryString.page - 1].id, displayCards: $scope.displayCards}, {reload: false});
      } else {
        fetchData();
      }

      // broadcast scroll event as we don't use pagination.parse() method
      $rootScope.$broadcast('paginationService:scrollTop');
    };

    $scope.changeDisplay = function(visible) {
      $scope.displayCards = visible;
      localStorageService.set('displayCards', visible);
    };

    $scope.linkIsExternal = function(activity) {
      return (activity.content_attributes && (activity.content_attributes.launch_presentation_document_target === 'window'));
    };

    $scope.getActivities = function() {
      UserService.loadProfile().then(function(profile) {
        spinner.activateController([User.one(profile.id).one('objectives', $scope.currentObjective.id).getList('activities')])
        .then(function(response) {
          $scope.activities = response[0].data;
        });
      });
    };

    $scope.resetFilter = function() {
      delete $scope.filter;
    };

    function fetchData() {
      tableFeatures.setSearchQueryParamsByState($stateParams.state, 'user_objective_state_eq');
      tableFeatures.getPageData()
        .then(setPaginationData)
        .then(setCurrentObjectivePosition)
        .then(function(response) {
          $scope.objectives = response[0].data;
        })
        .then(setCurrentObjective)
        .then($scope.getActivities);
    }

    function setPaginationData(response) {
      $scope.paginationData.totalItems = $scope.listData.tableFeaturesPagination.totalItems;
      $scope.paginationData.totalPages = $scope.listData.tableFeaturesPagination.totalItems;
      $scope.paginationData.perPage = 1;
      return response;
    }

    function setCurrentObjectivePosition(response) {
      var objectiveIndex = _.findIndex(response[0].data, {id: parseInt($scope.objectiveId, 10)});
      pagination.data.currentPage = objectiveIndex + 1;
      return response;
    }

    function setCurrentObjective() {
      var index = $scope.paginationData.currentPage;
      $scope.currentObjective = $scope.objectives[index - 1];
    }

    $scope.$on('activities-refresh:objective', function() {
      $scope.getActivities();
    });

    $scope.loadPage();
  }
}());
