(function() {
  'use strict';

  angular
    .module('tcm.dashboard.instructor.cohorts.objectives')
    .controller('InstructorObjectivesGradebookCtrl', InstructorObjectivesGradebookCtrl);

  function InstructorObjectivesGradebookCtrl($scope, $stateParams, $location,
    Student, SearchService, EnvConfig, SortingService, PaginationService,
    User) {

    $scope.userId = parseInt($stateParams.userId, 10);

    function setCohortId() {
      if ($stateParams.id === 'all') {
        $scope.cohortId = $location.search().cohort_id;
      } else {
        $scope.cohortId = parseInt($stateParams.id, 10);
      }
    }

    function setChartsUrls(institutionId) {
      var burnupTemplate = _.template(EnvConfig.main_url + '/api/v1/institutions/' + institutionId + '/cohorts/{{cohortId}}/enrollments/{{id}}/objective_burnup');
      $scope.burnupUrl = burnupTemplate({
        cohortId: $scope.cohortId,
        id: $scope.userId
      });
    }

    function loadStudentPrograms() {
      User.one($scope.userId).getList('programs').then(function(response) {
        $scope.programs = response.data;
      });
    }

    function getQueryString() {
      var state, searchParameters, search, queryString, sorting, pagination;
      queryString = {};
      searchParameters = {};
      sorting = new SortingService({
        column: $location.search().sort || 'user_name',
        direction: $location.search().direction || 'asc'
      });
      sorting.addParams(queryString);

      state = $stateParams.state || 'all';

      if ($stateParams.id !== 'all') {
        searchParameters = {
          cohort_id_eq: $scope.cohortId
        };
      }
      if (state === 'at-risk') {
        searchParameters.at_risk_true = 1;
      }

      search = new SearchService(searchParameters);
      search.addParams(queryString);

      // Limit list of students on gradebook_list (graph) up to 1000 -- Agreed due to DE2366
      pagination = new PaginationService({perPage: 1000, currentPage: $location.search().page || 1});
      pagination.addParams(queryString);

      return queryString;
    }

    function loadStudentData() {
      var cohortUserId = $location.search().cohort_user_id;
      Student.one(cohortUserId).get(getQueryString()).then(function(response) {
        $scope.student = response.data;
      });
    }

    function getStudentAsUser() {
      User.one($scope.userId).get().then(function(response) {
        $scope.user = response.data;
        setChartsUrls(response.data.institution_id);
      });
    }

    setCohortId();
    getStudentAsUser();
    loadStudentPrograms();
    loadStudentData();
  }
}());
