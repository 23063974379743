(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoAssignedObjectives', eoAssignedObjectives);

  function eoAssignedObjectives($rootScope, $timeout, $stateParams,
    Institution, TableFeaturesService
  ) {
    return {
      templateUrl: 'components/eo-assigned-objectives/eo-assigned-objectives.html',
      scope: {
        competencyState: '=',
        managePermission: '=?'
      },
      controller: eoAssignedObjectivesCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoAssignedObjectivesCtrl($scope) {
      var vm = this, tableFeatures, contextId, competencyId;

      contextId = parseInt($stateParams.contextId, 10);
      competencyId = parseInt($stateParams.id, 10);
      vm.checkedObjectives = [];

      $timeout(function() {
        $scope.$emit('eoAssignedObjectives:handshake');
      }, 0);

      $rootScope.$on('eoAvailableObjectives:handshake', function() {
        vm.manageMode = true;
      });

      $rootScope.$on('eoAssignedObjectives:reload', function() {
        vm.loadAssignedObjectives();
      });

      tableFeatures = new TableFeaturesService({
        sorting: false,
        search: false,
        pagination: {
          perPage: 10,
          scrollTop: false
        },
        loadData: function(queryParams) {
          return Institution.one(contextId).one('competencies', competencyId).getList('objectives', queryParams);
        }
      });

      vm.table = {
        paginationData: tableFeatures.paginationParams
      };

      vm.updateWeights = function() {
        var weights = {
          weights: _.chain(vm.assignedObjectives).map(function(objective) {
            return {
              id: objective.id,
              weight: (objective.weight !== null) ? objective.weight : 1
            };
          }).value()
        };
        Institution.one(contextId).one('competencies', competencyId).one('objectives/weights').customPUT(weights);
      };

      vm.loadAssignedObjectives = function() {
        vm.checkedObjectives.length = 0;
        tableFeatures.getPageData().then(function(response) {
          vm.assignedObjectives = response[0].data;
        });
      };

      vm.checkObjective = function(objective) {
        if (objective.checked) {
          vm.checkedObjectives.push(objective.id);
        } else {
          _.pull(vm.checkedObjectives, objective.id);
        }
      };

      vm.setCheckedState = function(state) {
        vm.checkedObjectives.length = 0;
        _.each(vm.assignedObjectives, function(objective) {
          objective.checked = state;
          if (state) {
            vm.checkedObjectives.push(objective.id);
          } else {
            vm.checkedObjectives.length = 0;
          }
        });
      };

      vm.removeSelectedObjectives = function() {
        Institution.one(contextId).one('competencies', competencyId).customDELETE('objectives', {'lo_ids[]': vm.checkedObjectives}).then(function() {
          vm.loadAssignedObjectives();
          vm.checkedObjectives.length = 0;
          $scope.$emit('eoAvailableObjectives:reload');
        });
      };

      vm.loadAssignedObjectives();
    }
  }
}());
