(function() {
  'use strict';

  angular
    .module('tcm.dashboard.instructor')
    .config(instructorRoutesConfig);

  function instructorRoutesConfig($stateProvider) {

    $stateProvider.state('instructor', {
      url: '/instructor',
      abstract: true,
      ncyBreadcrumb: {skip: true},
      views: {
        'main': {
          template: '<div ui-view="active"><div/>'
        }
      },
      resolve: {
        auth: function(SecurityManagerService, $state) {
          SecurityManagerService.authorize(['isInstructor']).then(function(hasPermission) {
            if (!hasPermission) {
              $state.go('dashboard');
            }
          });
        }
      }
    }).state('instructor.users', {
      abstract: true,
      url: '/users/:userId',
      views: {
        'active': {
          template: '<div ui-view="page"><div>'
        }
      },
      ncyBreadcrumb: {
        label: '{{vm.user.first_name}} {{vm.user.last_name}}',
        parent: 'instructor.cohorts'
      }
    })
    .state('instructor.users.competencies', {
      url: '/report/:id',
      views: {
        'page': {
          controller: 'CompetenciesReportCtrl as vm',
          templateUrl: 'reports/competencies/competencies.html'
        }
      },
      ncyBreadcrumb: {
        label: '{{vm.program.name}}',
        parent: 'instructor.users'
      }
    }).state('instructor.users.notes', {
      url: '/notes',
      abstract: true,
      views: {
        'page': {
          template: '<div ui-view="active"><div/>'
        }
      },
      ncyBreadcrumb: {skip: true}
    }).state('instructor.users.notes.list', {
      url: '',
      views: {
        'active': {
          controller: 'NotesListCtrl as vm',
          templateUrl: 'notes/list/notes-list.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Notes',
        parent: 'instructor.users'
      }
    }).state('instructor.users.notes.new', {
      url: '/new',
      views: {
        'active': {
          controller: 'NotesCreateCtrl as vm',
          templateUrl: 'notes/create/notes-create.html'
        }
      },
      ncyBreadcrumb: {
        label: 'New',
        parent: 'instructor.users.notes.list'
      }
    }).state('instructor.users.notes.edit', {
      url: '/:noteId/edit',
      views: {
        'active': {
          controller: 'NotesCreateCtrl as vm',
          templateUrl: 'notes/create/notes-create.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Edit',
        parent: 'instructor.users.notes.list'
      }
    }).state('instructor.notifications', {
      url: '/notifications',
      views: {
        'active': {
          controller: 'NotificationsListCtrl as vm',
          templateUrl: 'notifications/list/notifications-list.html'
        }
      }
    }).state('instructor.notifications.detail', {
      url: '/:id?page',
      reloadOnSearch: false,
      views: {
        'active': {
          controller: 'NotificationsListCtrl as vm',
          templateUrl: 'notifications/list/notifications-list.html'
        }
      }
    }).state('instructor.messages', {
      url: '/messages',
      abstract: true,
      ncyBreadcrumb: {skip: true},
      views: {
        'active': {
          template: '<div ui-view="page"><div/>'
        }
      }
    }).state('instructor.messages.list', {
      url: '',
      views: {
        'page': {
          controller: 'MessagesListCtrl as vm',
          templateUrl: 'messages/list/messages-list.html'
        }
      }
    }).state('instructor.messages.new', {
      url: '/new',
      views: {
        'page': {
          controller: 'MessagesCreateCtrl as vm',
          templateUrl: 'messages/create/messages-create.html'
        }
      }
    }).state('instructor.messages.detail', {
      url: '/:id',
      views: {
        'page': {
          controller: 'MessagesDetailCtrl as vm',
          templateUrl: 'messages/detail/messages-detail.html'
        }
      }
    }).state('instructor.webexSessions', {
      url: '/webexsessions',
      abstract: true,
      ncyBreadcrumb: {skip: true},
      views: {
        'active': {
          template: '<div ui-view="page"><div/>'
        }
      }
    }).state('instructor.webexSessions.list', {
      url: '',
      views: {
        'page': {
          controller: 'WebexSessionsListCtrl as vm',
          templateUrl: 'webex-sessions/list/webex-sessions-list.html'
        }
      }
    }).state('instructor.webexSessions.new', {
      url: '/new',
      views: {
        'page': {
          controller: 'WebexSessionsCreateCtrl as vm',
          templateUrl: 'webex-sessions/create/webex-sessions-create.html'
        }
      }
    }).state('instructor.webexSessions.detail', {
      url: '/:id?page',
      reloadOnSearch: false,
      views: {
        'page': {
          controller: 'WebexSessionsListCtrl as vm',
          templateUrl: 'webex-sessions/list/webex-sessions-list.html'
        }
      }
    }).state('instructor.profile', {
      url: '/profile',
      abstract: true,
      views: {
        'active': {
          template: '<div ui-view="profile"></div>'
        }
      }
    }).state('instructor.profile.show', {
      url: '',
      views: {
        'profile': {
          controller: 'ProfileDetailsCtrl as vm',
          templateUrl: 'auth/profile/detail/profile-detail.html'
        }
      }
    }).state('instructor.profile.edit', {
      url: '/edit',
      views: {
        'profile': {
          controller: 'ProfileEditCtrl as vm',
          templateUrl: 'auth/profile/edit/profile-edit.html'
        }
      }
    });
  }
}());
