(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoSearchActivities', eoSearchActivities);

  function eoSearchActivities($rootScope, $state, $stateParams, StudentSearchActivities) {
    return {
      templateUrl: 'components/eo-search-activities/eo-search-activities.html',
      scope: {},
      controller: eoSearchActivitiesCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoSearchActivitiesCtrl() {
      var vm = this;

      vm.hideSearch = $state.current.name.indexOf('student.cohorts') === -1;

      if ($stateParams.search) {
        vm.searchQuery = $stateParams.search;
      }

      $rootScope.$on('$stateChangeSuccess', function(event, toState) {
        // Hide search box if current view is not in the 'cohorts' section
        vm.hideSearch = toState.name.indexOf('student.cohorts') === -1;
      });

      delete StudentSearchActivities.searchData.learning_activity_description_cont;
      vm.searchData = StudentSearchActivities.searchData;

      vm.search = function() {
        // Update search query only by calling this method
        vm.searchData.learning_activity_description_cont = vm.searchQuery;

        StudentSearchActivities.getActivities({}, 'search').then(function(response) {
          if (response.data.length === 0 || response.data.length > 1) {
            $state.go('student.cohorts.all.activities', {id: parseInt($stateParams.id, 10), state: 'search', search: vm.searchQuery}, {reload: true});
          } else {
            $state.go('student.cohorts.all.activities', {id: parseInt($stateParams.id, 10), activityId: response.data[0].id, state: 'search', search: vm.searchQuery}, {reload: true});
          }
        });
      };
    }
  }
}());
