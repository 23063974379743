(function() {
  'use strict';

  angular
    .module('tcm.core')
    .config(breadcrumbProviderConfig);

  function breadcrumbProviderConfig($breadcrumbProvider) {
    $breadcrumbProvider.setOptions({
      includeAbstract: true,
      templateUrl: 'core/templates/angular-breadcrumbs/angular-breadcrumbs-custom.html'
    });
  }
}());
