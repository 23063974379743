(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoMessageBadge', eoMessageBadge);

  function eoMessageBadge($rootScope, $state, $interval, MessagesService) {
    return {
      replace: true,
      templateUrl: 'components/eo-message-badge/eo-message-badge.html',
      scope: { },
      controller: eoMessagesBadgeCtrl,
      controllerAs: 'badge',
      bindToController: true
    };

    function eoMessagesBadgeCtrl($element) {
      var fetchTimer, badge = this, counter;

      // fetch new unread messages each minute
      fetchTimer = $interval(getUnreadMessagesCount, 60000);

      badge.redirectToViewMessages = function() {
        MessagesService.resetPagination();
        if ($state.includes('student.**')) {
          $state.go('student.messages.list', {}, {reload: true});
        } else if ($state.includes('instructor.**')) {
          $state.go('instructor.messages.list', {}, {reload: true});
        } else if ($state.includes('successcoach.**')) {
          $state.go('successcoach.messages.list', {}, {reload: true});
        }
      };

      badge.composeNewMessage = function() {
        if ($state.includes('instructor.**')) {
          $state.go('instructor.messages.new');
        }

        if ($state.includes('student.**')) {
          $state.go('student.messages.new');
        }

        if ($state.includes('successcoach.**')) {
          $state.go('successcoach.messages.new');
        }
      };

      badge.canCompose = MessagesService.canCompose;

      function getUnreadMessagesCount() {
        return MessagesService.canRead().then(function(hasPermission) {
          if (!hasPermission) {
            return false;
          }

          return MessagesService.getUnreadMessagesCount().then(function(response) {

            badge.unreadMessagesCount = response.data;
            // fetch message for preview on init or we get new Message count
            if (counter !== badge.unreadMessagesCount) {
              getUserMessagesForPreview();
            }

            counter =  badge.unreadMessagesCount;

            return counter;
          });
        });
      }

      function getUserMessagesForPreview() {
        return MessagesService.canRead().then(function(hasPermission) {
          if (!hasPermission) {
            return false;
          }

          return MessagesService.getUnreadMessagesForPreview().then(function(response) {
            badge.unreadMessages = response[0].data;
            return badge.unreadMessages;
          });
        });
      }

      $rootScope.$on('eo-message:mark_read', function(event, readCount) {
        badge.unreadMessagesCount -= readCount;
        counter = badge.unreadMessagesCount;
        if (badge.unreadMessagesCount === 0) {
          badge.unreadMessages = null;
        } else {
          getUserMessagesForPreview();
        }
      });

      $rootScope.$on('eo-message:mark_unread', function(event, unreadCount) {
        badge.unreadMessagesCount += unreadCount;
        counter = badge.unreadMessagesCount;
        getUserMessagesForPreview();
      });

      $rootScope.$on('badge-counter:refresh', function() {
        getUnreadMessagesCount();
      });

      $element.on('$destroy', function() {
        $interval.cancel(fetchTimer);
      });

      getUnreadMessagesCount();
    }
  }
}());
