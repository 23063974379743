(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('UserPrograms', UserPrograms);

  function UserPrograms($rootScope, $stateParams, $q, User, UserService,
    PaginationService, EnvConfig) {
    var pagination, programsPromise;

    pagination = new PaginationService({ perPage: 100 });

    $rootScope.$on('cache:invalidate', function() {
      programsPromise = null;
    });

    function getPrograms() {
      var queryString = {};
      if (programsPromise === void 0 || _.isNull(programsPromise)) {
        programsPromise = $q.defer();
        pagination.addParams(queryString);
        UserService.loadProfile().then(function(profile) {
          User.one(parseInt($stateParams.userId, 10) || profile.id).getList('programs', queryString).then(function(response) {
            programsPromise.resolve(response);
          });
        });
      }
      return programsPromise.promise;
    }

    function getFirstProgram() {
      return getPrograms().then(function(response) {
        return _.head(response.data);
      });
    }

    function getProgram(id) {
      var indexes, index;
      return getPrograms().then(function(response) {
        indexes = _.map(response.data, 'id');
        index = _.indexOf(indexes, id);
        return response.data[index];
      });
    }

    function getUrlTemplate() {
      var userId = parseInt($stateParams.userId, 10);
      if (userId) {
        return User.one(userId).get().then(function(profile) {
          return _.template(EnvConfig.main_url + '/api/v1/institutions/' + profile.data.institution_id + '/{{type}}/{{id}}/enrollments/' + userId + '/{{graph}}');
        });
      } else {
        return UserService.loadProfile().then(function(profile) {
          return _.template(EnvConfig.main_url + '/api/v1/institutions/' + profile.institution_id + '/{{type}}/{{id}}/enrollments/' + profile.id + '/{{graph}}');
        });
      }
    }

    function getChartsUrls(programId) {
      return getUrlTemplate().then(function(template) {
        return getProgram(programId).then(function(program) {
          if (program === void 0) { return {}; }
          return {
            barUrl: template({
              type: 'programs',
              id: program.id,
              graph: 'competency_bars'
            }),
            donutUrl: template({
              type: 'programs',
              id: program.id,
              graph: 'competency_donut'
            })
          };
        });
      });
    }

    function flushCache() {
      programsPromise = null;
    }

    return {
      getPrograms: getPrograms,
      getFirstProgram: getFirstProgram,
      getProgram: getProgram,
      getChartsUrls: getChartsUrls,
      flushCache: flushCache
    };
  }
}());
