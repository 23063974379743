(function() {
  'use strict';

  angular.module('tcm.dashboard.successcoach.cohorts').
  config(cohortsRoutesConfig);

  function cohortsRoutesConfig($stateProvider) {
    $stateProvider.state('successcoach.cohorts', {
      url: '/students?last_name_cont&first_name_cont&username_cont&email_cont',
      abstract: true,
      views: {
        'active': {
          controller: 'SuccessCoachCohortsCtrl as vm',
          template: '<div ui-view="section"><div/>'
        }
      },
      ncyBreadcrumb: {
        label: 'Dashboard'
      }
    }).state('successcoach.cohorts.structure', {
      url: '/:userId/curricula/:curriculumId',
      abstract: true,
      views: {
        'section': {
          template: '<div ncy-breadcrumb></div><div ui-view="page"><div/>'
        }
      },
      ncyBreadcrumb: {skip: true}
    })
    .state('successcoach.cohorts.structure.show', {
      url: '/structure',
      views: {
        'page': {
          template: '{{vm.course.name}}<eo-course-structure-tree></eo-course-structure-tree>',
          controller: 'SuccesscoachCourseCtrl as vm'
        }
      },
      ncyBreadcrumb: {
        label: '{{vm.courseName}}',
        parent: 'successcoach.cohorts.objectives.courses'
      }
    }).state('successcoach.cohorts.structure.activity', {
      url: '/activity/:activityId/activityLaunch?:objectiveId',
      views: {
        'section@successcoach.cohorts': {
          template: '<eo-course-structure-tree-activity></eo-course-structure-tree-activity>'
        }
      },
      ncyBreadcrumb: {
        label: 'Structure',
        parent: 'successcoach.cohorts.objectives.courses'
      }
    });
  }
}());
