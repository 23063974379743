(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin')
    .controller('AdminDashboardCtrl', AdminDashboardCtrl);

  function AdminDashboardCtrl($scope, UserService, CurrentLoggedinUser, SpinnerService) {
    var spinner = new SpinnerService();
    function loadProfile() {
      return UserService.loadProfile().then(function(profile) {
        if (profile) {
          $scope.userInstitutionId = profile.institution_id;
        }
      });
    }

    function loadSystemReport() {
      return UserService.canAccess(['READ'], ['report_current_logged_in']).then(function(hasPermission) {
        if (hasPermission) {
          $scope.hasReportPermission = hasPermission;
          return CurrentLoggedinUser.one().get().then(function(response) {
            $scope.userStatistics = response.data.metadata;
          });
        }
      });
    }

    spinner.activateController([
      loadProfile(),
      loadSystemReport()
    ]);
  }
}());
