(function() {
  'use strict';

  angular
    .module('tcm.common')
    .filter('unCamel', UnCamelFilter);

  function UnCamelFilter() {
    return function(input) {
      input = input || '';
      return input.replace(/(?!^\w)([A-Z]{1})/g, ' $1');
    };
  }
}());
