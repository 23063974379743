(function() {
  'use strict';

  angular
    .module('tcm.help')
    .controller('HelpCtrl', HelpCtrl);

  function HelpCtrl($scope, $sce, $log, UserService, EO_DEFAULT_INFO) {
    var noop = angular.noop;

    var play = function() {
      $scope.API.play();
      $scope.handlePlay = noop;
    };

    var playPause = function() {
      $scope.API.playPause();
      $scope.handlePlay = noop;
    };

    $scope.showVideo = false;
    $scope.API = {};

    $scope.config = {
      sources: [{
        src: $sce.trustAsResourceUrl('https://tcm.educate-online.com/assets/tcm-tutorial.mp4'),
        type: 'video/mp4'
      }, {
        src: $sce.trustAsResourceUrl('https://tcm.educate-online.com/assets/tcm-tutorial.ogg'),
        type: 'video/ogg'
      }],
      tracks: [{
        src: '/assets/tracks/en/tcm-tutorial.6a268c4f.vtt',
        kind: 'subtitles',
        srclang: 'en',
        label: 'English',
        default: ''
      }],
      plugins: {
        poster: '/assets/images/meteor_learning_poster.f76a3a88.png'
      }
    };

    $scope.handlePlayerReady = function(API) {
      $scope.API = API;
    };

    $scope.handlePlay = play;

    $scope.handleVideoStateChange = function(state) {
      if (state === 'play') {
        $scope.handlePlay = noop;
      } else if (state === 'stop') {
        $scope.handlePlay = play;
      } else if (state === 'pause') {
        $scope.handlePlay = playPause;
      } else {
        // warn on undocumented state
        $log.warn('Didn\'t recognize state ' + state);
      }
    };

    $scope.handleVideoComplete = function() {
      $scope.handlePlay = play;
    };

    function loadProfile() {
      $scope.email = EO_DEFAULT_INFO.email;
      $scope.phone = EO_DEFAULT_INFO.phone;

      if (!UserService.signedIn()) {
        return false;
      }

      return UserService.loadProfile().then(function(profile) {
        if (profile) {
          $scope.email = profile.help_desk_email || EO_DEFAULT_INFO.email;
          $scope.phone = profile.help_desk_phone_number || EO_DEFAULT_INFO.phone;
        }
      });
    }

    loadProfile();
  }
}());
