(function() {
  'use strict';

  angular
    .module('tcm.common')
    .directive('eoSliderFilter', eoSliderFilter);

  function eoSliderFilter() {
    return {
      restrict: 'E',
      templateUrl: 'common/directives/eo-slider-filter/eo-slider-filter.html',
      scope: {
        'filter': '='
      },
      controller: eoSliderFilterCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoSliderFilterCtrl() {
      var vm = this;

      vm.collapse = function() {
        vm.filterExpanded = !vm.filterExpanded;
        delete vm.filter;
      };
    }
  }
}());
