(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.rubrics')
    .config(rubricsRoutesConfig);

  function rubricsRoutesConfig($stateProvider) {

    $stateProvider.state('admin.rubrics', {
      url: '/rubrics?title_cont',
      abstract: true,
      template: '<div ui-view="active"></div>'
    }).state('admin.rubrics.list', {
      url: '',
      reloadOnSearch: false,
      views: {
        'active': {
          controller: 'AdminRubricsListCtrl as vm',
          templateUrl: 'dashboard/admin/rubrics/list/rubrics-list.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Rubrics',
        parent: 'admin.dashboard'
      }
    }).state('admin.rubrics.new', {
      url: '/new',
      views: {
        'active': {
          controller: 'AdminRubricsCreateCtrl as vm',
          templateUrl: 'dashboard/admin/rubrics/create/rubrics-create.html'
        }
      },
      ncyBreadcrumb: {
        label: 'New',
        parent: 'admin.rubrics.list'
      }
    }).state('admin.rubrics.edit', {
      url: '/:id/edit',
      views: {
        'active': {
          controller: 'AdminRubricsCreateCtrl as vm',
          templateUrl: 'dashboard/admin/rubrics/create/rubrics-create.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Edit',
        parent: 'admin.rubrics.show'
      }
    }).state('admin.rubrics.show', {
      url: '/:id',
      views: {
        'active': {
          controller: 'AdminRubricsDetailCtrl as vm',
          templateUrl: 'dashboard/admin/rubrics/detail/rubrics-detail.html'
        }
      },
      ncyBreadcrumb: {
        label: '{{vm.rubric.title}}',
        parent: 'admin.rubrics.list'
      }
    });
  }
}());
