(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.activities')
    .controller('AdminActivitiesListCtrl', AdminActivitiesListCtrl);

  function AdminActivitiesListCtrl($stateParams, $location, Activity,
    TableFeaturesService) {

    var vm = this, tableFeatures;

    vm.isfilterCollapsed = true;

    tableFeatures = new TableFeaturesService({
      sorting: {column: 'identifier'},
      search: {
        'identifier_cont': $stateParams.identifier_cont,
        'description_cont': $stateParams.description_cont,
        'published_eq': $stateParams.published_eq,
        'owner_institution_id_eq': $stateParams.contextId,
        'activity_type_eq': $stateParams.activity_type_eq
      },
      loadData: function(queryParams) {
        return Activity.getList(queryParams);
      }
    });

    vm.table = {
      searchData: tableFeatures.searchParams,
      sortingData: tableFeatures.sortingParams,
      paginationData: tableFeatures.paginationParams
    };

    vm.search = function() {
      tableFeatures.resetEmptySearchData();
      vm.table.searchData.owner_institution_id_eq = $stateParams.contextId;
      $location.search(vm.table.searchData);
      tableFeatures.getNewData().then(function(response) {
        vm.activities = response[0].data;
      });
    };

    vm.loadActivitiesList = function() {
      tableFeatures.getPageData().then(function(response) {
        vm.activities = response[0].data;
      });
    };

    vm.reset = function() {
      tableFeatures.resetSearch();
      vm.search();
    };

    vm.disableClearOption = function() {
      var disable = true;
      _.each(vm.table.searchData, function(n, key) {
        if (vm.table.searchData[key] !== void 0 && vm.table.searchData[key].length > 0) {
          disable = false;
        }
      });

      return disable;
    };

    vm.loadActivitiesList();
  }
}());
