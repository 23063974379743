(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoAssignedActivities', eoAssignedActivities);

  function eoAssignedActivities($rootScope, $timeout, $stateParams,
    Objective, TableFeaturesService, SpinnerService
  ) {
    return {
      templateUrl: 'components/eo-assigned-activities/eo-assigned-activities' +
      '.html',
      scope: {
        objectiveState: '=',
        flashMessage: '=?',
        managePermission: '=?'
      },
      controller: eoAssignedActivitiesCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoAssignedActivitiesCtrl($scope) {
      var vm = this, tableFeatures, objectiveId, spinner;

      objectiveId = parseInt($stateParams.id, 10);
      vm.checkedActivities = [];

      spinner = new SpinnerService();

      $timeout(function() {
        $scope.$emit('eoAssignedActivities:handshake');
      }, 0);

      $rootScope.$on('eoAvailableActivities:handshake', function() {
        vm.manageMode = true;
      });

      $rootScope.$on('eoAssignedActivities:reload', function() {
        vm.loadAssignedActivities();
      });

      tableFeatures = new TableFeaturesService({
        sorting: false,
        search: false,
        pagination: {
          perPage: 10,
          scrollTop: false
        },
        loadData: function(queryParams) {
          return Objective.one(objectiveId).getList('activities', queryParams);
        }
      });

      vm.table = {
        paginationData: tableFeatures.paginationParams
      };

      vm.updateWeights = function() {
        var weights = {
          activity_weights: _.chain(vm.assignedActivities).map(function(activity) {
            return {
              id: activity.id,
              weight: (activity.weight !== null) ? activity.weight : 0
            };
          }).value()
        };
        Objective.one(objectiveId).one('activities/weights').customPUT(weights);
      };

      vm.loadAssignedActivities = function() {
        vm.checkedActivities.length = 0;
        tableFeatures.getPageData().then(function(response) {
          vm.assignedActivities = response[0].data;
        });
      };

      vm.moveActivity = function(activity, direction, $event) {
        var position, delta;

        $event.stopPropagation();

        delta = (direction === 'up') ?  -1 : 1;
        position = activity.position + delta;

        if (position >= 0 && position <= vm.table.paginationData.totalItems) {
          spinner.activateController([
            Objective.one(objectiveId).one('activities', activity.id).put({
              position: position,
              weight: activity.weight
            }).then(function() {
              vm.flashMessage = '';
              vm.loadAssignedActivities();
            }, function() {
              vm.flashMessage = 'Updating activity\'s position has failed';
            })
          ]);
        }
      };

      vm.checkActivity = function(activity) {
        if (activity.checked) {
          vm.checkedActivities.push(activity.id);
        } else {
          _.pull(vm.checkedActivities, activity.id);
        }
      };

      vm.setCheckedState = function(state) {
        vm.checkedActivities.length = 0;
        _.each(vm.assignedActivities, function(activity) {
          activity.checked = state;
          if (state) {
            vm.checkedActivities.push(activity.id);
          } else {
            vm.checkedActivities.length = 0;
          }
        });
      };

      vm.removeSelectedObjectives = function() {
        Objective.one(objectiveId).customDELETE('activities', {'la_ids[]': vm.checkedActivities}).then(function() {
          vm.loadAssignedActivities();
          vm.checkedActivities.length = 0;
          $scope.$emit('eoAvailableActivities:reload');
        });
      };

      vm.loadAssignedActivities();
    }
  }
}());
