/*globals moment:false */
(function() {
  'use strict';

  angular
    .module('tcm.common')
    .directive('eoMarkActivityCompleted', eoMarkActivityCompleted);

  function eoMarkActivityCompleted($stateParams, SpinnerService,
    UserService, UserActivities, User) {
    return {
      bindToController: true,
      controllerAs: 'vm',
      controller: eoMarkActivityCompletedCtrl,
      scope: {
        activity: '='
      },
      restrict: 'E',
      templateUrl: 'common/directives/eo-mark-activity-completed/eo-mark-activity-completed.html'
    };

    function eoMarkActivityCompletedCtrl() {
      var vm  = this, spinner, cohortId;
      spinner = new SpinnerService();
      cohortId = parseInt($stateParams.id, 10);

      vm.isCohortExpired = true;

      vm.markCompleted = function() {
        spinner.activateController([
          UserService.loadProfile().then(function(response) {
            var userId = response.id;

            return UserActivities.updateActivityState(userId, vm.activity.id, {
              user_state: 'work_submitted'
            }).then(function(response) {
              vm.activity.user_completed_at = response.data.user_completed_at;
            });
          })
        ]);
      };

      vm.isUserInstructor = function() {
        return UserService.isMasqueradeMode();
      };

      function isExpired(endDate) {
        return (endDate === void 0) ? false : moment(endDate).isBefore(new Date().toISOString().slice(0, 10));
      }

      function loadCohort() {
        spinner.activateController([
          UserService.loadProfile().then(function(response) {
            User.one(response.id).one('cohorts', cohortId).get().then(function(response) {
              vm.cohort = response.data;
              vm.isCohortExpired = isExpired(vm.cohort.user_ends_on);
            });
          })
        ]);
      }

      loadCohort();
    }
  }
}());
