(function() {
  'use strict';

  angular
    .module('tcm.messages')
    .controller('MessagesListCtrl', MessagesListCtrl);

  function MessagesListCtrl($scope, MessagesService) {
    var vm = this, messagesPage;

    vm.messagesPagination = MessagesService.paginationData;

    //TO-DO: Create this functionality as general case
    vm.getUserMessages = function() {
      MessagesService.getUserMessages().then(function(response) {
        vm.messages = response[0].data;
        messagesPage = parseInt(vm.messagesPagination.currentPage, 10);
        if (!vm.messages[0] && messagesPage > 1) {
          messagesPage--;
          MessagesService.paginationData.currentPage = messagesPage;
          vm.getUserMessages();
        }
      });
    };

    $scope.$on('MessagesListCtrl:reload', function() {
      vm.getUserMessages();
    });

    vm.getUserMessages();
  }
}());
