(function() {
  'use strict';

  angular
    .module('tcm.common')
    .directive('eoFlatPagination', eoFlatPagination);

  function eoFlatPagination() {
    return {
      restrict: 'E',
      require: ['eo-flat-pagination', 'ngModel'],
      scope: {
        totalItems: '=',
        perPage: '='
      },
      templateUrl: 'common/directives/eo-flat-pagination/eo-flat-pagination.html',
      link: function(scope, element, attrs, ctrls) {
        var paginationCtrl = ctrls[0], ngModelCtrl = ctrls[1];
        paginationCtrl.init(ngModelCtrl);
      },
      controller: function($scope) {
        var modelCtrl;

        $scope.load = function() {
          var val = parseInt(modelCtrl.$modelValue) + 1;
          if (val > 0 && val <= $scope.totalPages) {
            modelCtrl.$setViewValue(val);
            update();
          }
        };

        this.init = function(ctrl) {
          modelCtrl = ctrl;
        };

        $scope.$watch('perPage', update);
        $scope.$watch('totalItems', update);

        function update() {
          $scope.totalPages = calculateTotalPages();
          $scope.end = calculateEnd();
          $scope.canLoadMore = modelCtrl.$modelValue < $scope.totalPages;
        }

        function calculateTotalPages() {
          var totalPages = $scope.perPage < 1 ?
           1 : Math.ceil($scope.totalItems / $scope.perPage);
          return Math.max(totalPages || 0, 1);
        }

        function calculateEnd() {
          return parseInt(modelCtrl.$modelValue) === parseInt($scope.totalPages) ?
            $scope.totalItems : $scope.perPage * modelCtrl.$modelValue;
        }
      }
    };
  }
}());
