(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoUserCohortsList', eoUserCohortsList);

  function eoUserCohortsList($uibModal, Institution) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-user-cohorts-list/eo-user-cohorts-list.html',
      scope: {
        cohorts: '=',
        user: '=',
        role: '='
      },
      controllerAs: 'vm',
      controller: eoUserCohortsList,
      bindToController: true
    };

    function eoUserCohortsList() {
      var vm = this;

      vm.unenrollFromCohort = function(cohort) {
        var cohortIndex, modalInstance = getUnenrollModal('cohort');
        cohortIndex = _.indexOf(vm.cohorts, cohort);

        modalInstance.result.then(function() {
          Institution.one(vm.user.institution_id).one('cohorts', cohort.id).one('enrollments', vm.user.id).remove().then(function() {
            vm.cohorts.splice(cohortIndex, 1);
          });
        });
      };

      function getUnenrollModal(sectionName) {
        return $uibModal.open({
          templateUrl: 'components/eo-user-cohorts-list/modals/confirm-unenroll.html',
          controller: function($scope) {
            $scope.sectionName = sectionName;
            $scope.fullName = vm.user.first_name + ' ' + vm.user.last_name;

            $scope.cancel = function() {
              $scope.$dismiss('close');
            };

            $scope.ok = function() {
              $scope.$close();
            };
          }
        });
      }
    }
  }
}());
