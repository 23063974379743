(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('ValidationService', ValidationService);

  function ValidationService(Institution, SearchService, $q) {
    function validateEmail() {
      return function($viewValue) {
        if ($viewValue === void 0 || $viewValue.length === 0) { return true; }

        var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        return re.test($viewValue);
      };
    }

    function validateLettersHyphens() {
      return function($viewValue) {
        if ($viewValue === void 0 || $viewValue.length === 0) { return true; }
        var re = /^[a-zA-Z'-]+$/;

        return re.test($viewValue);
      };
    }

    function validateLettersHyphensSpaces() {
      return function($viewValue) {
        if ($viewValue === void 0 || $viewValue.length === 0) { return true; }
        var re = /^[a-zA-Z' -]+$/;

        return re.test($viewValue);
      };
    }

    function validateAlphaNumeric() {
      return function($viewValue) {
        if ($viewValue === void 0 || $viewValue.length === 0) { return true; }

        var re = /^[a-z0-9 ]+$/i;
        return re.test($viewValue);
      };
    }

    function validateNoSpaces() {
      return function($viewValue) {
        if ($viewValue === void 0 || $viewValue.length === 0) { return true; }

        var re = /^\S*$/;
        return re.test($viewValue);
      };
    }

    function validateAlphaNumericUnderscore() {
      return function($viewValue) {
        if ($viewValue === void 0 || $viewValue.length === 0) { return true; }

        var re = /^[a-zA-Z0-9_]+$/i;
        return re.test($viewValue);
      };
    }

    function validateAddress() {
      return function($viewValue) {
        if ($viewValue === void 0 || $viewValue.length === 0) { return true; }

        var re = /^[a-zA-z0-9\s\-#&'.]+$/;
        return re.test($viewValue);
      };
    }

    function validateZip() {
      return function($viewValue) {
        if ($viewValue === void 0 || $viewValue.length === 0) { return true; }

        var re = /^\d{5}([\-]?\d{4})?$/;
        return re.test($viewValue);
      };
    }

    function validatePhoneNumber() {
      return function($viewValue) {
        if ($viewValue === void 0 || $viewValue.length === 0) { return true; }

        var re = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
        return re.test($viewValue);
      };
    }

    function validateUniqueEoCustomer(institutionId) {
      var search = new SearchService();
      return function($viewValue) {
        var queryParams = {};
        search.data.eocustomer_eq = $viewValue.toUpperCase();
        search.addParams(queryParams);
        return $q(function(resolve, reject) {
          return Institution.getList(queryParams).then(function(response) {
            if (response.data.length === 0) { resolve(); }
            if (response.data.length === 1 && institutionId === response.data[0].id) {
              resolve();
            }
            reject();
          });
        });
      };
    }

    function validateInteger() {
      return function($viewValue) {
        if ($viewValue === void 0 || $viewValue.length === 0) { return true; }
        return Number($viewValue) === parseInt($viewValue, 10);
      };
    }

    function validatePositiveNumber() {
      return function($viewValue) {
        if ($viewValue === void 0 || $viewValue.length === 0) { return true; }
        return $viewValue >= 0;
      };
    }

    // There are problems to properly validate file extensions (e.g. Windows has its own file type naming)
    // This validation was deferred for use
    function validateFileExtension(extension, alternateExt) {
      return function($viewValue) {
        if ($viewValue) {
          return $viewValue.type.indexOf(extension) !== -1 || $viewValue.type.indexOf(alternateExt) !== -1;
        }
        return true;
      };
    }

    function validatePassword() {
      return function($viewValue, $viewModel, scope) {
        if ($viewValue === void 0 || $viewValue.length === 0) { return true; }
        return (scope.fullData.score !== void 0 && scope.fullData.score > 0);
      };
    }

    function validatePasswordMatch() {
      return function($viewValue, $viewModel, scope) {
        if ($viewValue === void 0 || $viewValue.length === 0) {
          return true;
        }
        return $viewValue === scope.model.password;
      };
    }

    return {
      validateEmail: validateEmail,
      validateAddress: validateAddress,
      validateZip: validateZip,
      validatePhoneNumber: validatePhoneNumber,
      validateLettersHyphens: validateLettersHyphens,
      validateLettersHyphensSpaces: validateLettersHyphensSpaces,
      validateAlphaNumeric: validateAlphaNumeric,
      validateNoSpaces: validateNoSpaces,
      validateUniqueEoCustomer: validateUniqueEoCustomer,
      validateInteger: validateInteger,
      validatePositiveNumber: validatePositiveNumber,
      validateFileExtension: validateFileExtension,
      validateAlphaNumericUnderscore: validateAlphaNumericUnderscore,
      validatePassword: validatePassword,
      validatePasswordMatch: validatePasswordMatch
    };
  }
}());
