(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.objectives')
    .controller('AdminObjectivesCreateCtrl', AdminObjectivesCreateCtrl);

  function AdminObjectivesCreateCtrl($stateParams, $state, Objective,
    SpinnerService, ValidationService) {

    var vm = this, spinner, objectiveId;

    spinner = new SpinnerService();
    objectiveId = parseInt($stateParams.id, 10);

    vm.model = {};

    vm.fields = [
      {
        key: 'description',
        type: 'textarea',
        templateOptions: {
          required: true,
          label: 'Description'
        }
      },
      {
        key: 'type',
        type: 'ui-select',
        defaultValue: 'LearningObjective',
        templateOptions: {
          label: 'Type',
          options: [{
            name: 'Assessment Objective',
            value: 'AssessmentObjective'
          }, {
            name: 'Learning Objective',
            value: 'LearningObjective'
          }]
        },
        hideExpression: function() {
          return !Number.isNaN(objectiveId);
        }
      },
      {
        key: 'mastery_score',
        type: 'input',
        templateOptions: {
          required: true,
          type: 'number',
          label: 'Mastery Score',
          max: 100
        },
        validators: {
          positiveNumber: ValidationService.validatePositiveNumber(),
          integer: ValidationService.validateInteger()
        }
      }
    ];

    vm.save = function() {
      vm.model.owner_institution_id = $state.params.contextId;

      if (vm.createEditForm.$valid) {
        if (vm.model && vm.model.id) {
          vm.model.patch().then(function(response) {
            $state.go('admin.objectives.show', {id: response.data.id});
          });
        } else {
          Objective.post(vm.model).then(function(response) {
            $state.go('admin.objectives.show', {id: response.data.id});
          });
        }
      }
    };

    function loadObjectiveToEdit() {
      vm.isEditMode = false;
      if (objectiveId) {
        vm.isEditMode = true;
        Objective.one(objectiveId).get().then(function(response) {
          vm.model = response.data;
          vm.objective = vm.model; //breadcrumb label
        });
      } else {
        vm.model.mastery_score = 80;
      }
    }

    spinner.activateController([
      loadObjectiveToEdit()
    ]);
  }
}());
