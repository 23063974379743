(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoRubricTable', eoRubricTable);

  function eoRubricTable() {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-rubric-table/eo-rubric-table.html',
      scope: {
        rubric: '='
      },
      controller: eoRubricTableCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoRubricTableCtrl() {}
  }
}());
