/*globals moment:false */
(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.users')
    .controller('AdminUsersDetailCtrl', AdminUsersDetailCtrl);

  function AdminUsersDetailCtrl($stateParams, $uibModal, $state, User,
    Institution, SpinnerService, PaginationService, SearchService,
    ValidationService) {

    var vm = this, spinner, pagination, userId;

    spinner = new SpinnerService();
    pagination = new PaginationService({ perPage: 100 });
    userId = parseInt($stateParams.userId, 10);

    function getUserDetails() {
      return User.one(userId).get().then(function(response) {
        vm.user = response.data;
      });
    }

    function getUserProgramsEnrollment() {
      var queryString = {};
      queryString.all_visible = true;
      pagination.addParams(queryString);
      return User.one(userId).getList('programs', queryString).then(function(response) {
        vm.programs = response.data;
      });
    }

    function getUserCohortsEnrollment() {
      var queryString = {};
      pagination.addParams(queryString);
      return User.one(userId).getList('cohorts', queryString).then(function(response) {
        vm.cohorts = response.data;
      });
    }

    function getUnenrollModal(sectionName) {
      return $uibModal.open({
        templateUrl: 'dashboard/admin/users/modals/confirm-unenroll.html',
        controller: function($scope) {
          $scope.sectionName = sectionName;
          $scope.fullName = vm.user.first_name + ' ' + vm.user.last_name;

          $scope.cancel = function() {
            $scope.$dismiss('close');
          };

          $scope.ok = function() {
            $scope.$close();
          };
        }
      });
    }

    vm.isUserStudent = function() {
      if (vm.user) {
        return _.some(vm.user.assignments, function(assignment) {
          return assignment.role_name === 'Student';
        });
      }
    };

    vm.enrollToProgram = function() {
      var modalInstance = $uibModal.open({
        templateUrl: 'dashboard/admin/users/modals/enroll-program.html',
        resolve: {
          programs: function() {
            var search, queryString;

            search = new SearchService({ published_eq: true});
            queryString = {};
            search.addParams(queryString);

            return spinner.activateController([
              Institution.one(vm.user.institution_id).getList('programs', queryString).then(function(response) {
                return _.reject(response.data, function(program) {
                  return _.findIndex(vm.programs, {id: parseInt(program.id, 10)}) !== -1;
                });
              })
            ]);
          }
        },
        controllerAs: 'modal',
        controller: function($scope, programs) {
          var modal = this;

          modal.programs = programs[0];
          modal.model = {};
          modal.fields = [
            {
              key: 'id',
              type: 'ui-select',
              templateOptions: {
                required: true,
                label: 'Programs',
                options: modal.programs,
                placeholder: 'Select a program',
                valueProp: 'id',
                labelProp: 'name'
              },
              expressionProperties: {
                'templateOptions.disabled': 'vm.programs.length > 0'
              }
            },
            {
              key: 'start_date',
              type: 'ui-date',
              templateOptions: {
                required: true,
                label: 'Select enrollment date'
              },
              expressionProperties: {
                'templateOptions.dateOptions.dateFormat': '"yy-mm-dd"',
                'templateOptions.dateOptions.minDate': function() {
                  return new Date().toISOString().slice(0, 10);
                }
              },
              hideExpression: '!model.id'
            }
          ];

          modal.cancel = function() {
            $scope.$dismiss('close');
          };

          modal.save = function() {
            if (modal.enrollInProgramForm.$valid) {
              $scope.$close(modal);
            }
          };
        }
      });

      modalInstance.result.then(function(modal) {
        spinner.activateController([
          User.one(userId).one('programs', modal.model.id).put({enrollment_date: modal.model.start_date}).then(function() {
            var program = _.find(modal.programs, {id: modal.model.id});
            program.start_date = modal.model.start_date;
            vm.programs.push(program);
          })
        ]);
      });
    };

    vm.unenrollFromProgram = function(program) {
      var programIndex, modalInstance = getUnenrollModal('program');
      programIndex = _.indexOf(vm.programs, program);

      modalInstance.result.then(function() {
        User.one(userId).one('programs', program.id).remove().then(function() {
          vm.programs.splice(programIndex, 1);
        });
      });
    };

    vm.enrollToCohort = function() {
      var modalInstance = $uibModal.open({
        templateUrl: 'dashboard/admin/users/modals/enroll-cohort.html',
        resolve: {
          cohorts: function() {
            var search, queryString;

            queryString = {};
            search = new SearchService({
              published_eq: true,
              ends_on_gteq: new Date().toISOString().slice(0, 10)
            });
            search.addParams(queryString);

            return spinner.activateController([
              Institution.one(vm.user.institution_id).getList('cohorts', queryString).then(function(response) {
                return _.reject(response.data, function(cohort) {
                  return _.findIndex(vm.cohorts, {id: parseInt(cohort.id, 10)}) !== -1;
                });
              })
            ]);
          }
        },
        controllerAs: 'vm',
        controller: function($scope, cohorts) {
          var vm = this;

          vm.cohorts = cohorts[0]; // get first element of array returned by spinner service

          vm.model = {};
          vm.fields = [
            {
              key: 'id',
              type: 'ui-select',
              templateOptions: {
                required: true,
                label: 'Cohorts',
                options: vm.cohorts,
                placeholder: 'Select a cohort',
                valueProp: 'id',
                labelProp: 'name',
                onChange: function($viewValue) {
                  delete vm.model.user_started_on;

                  if ($viewValue !== void 0) {
                    var cohort = _.find(vm.cohorts, {id: $viewValue});
                    vm.model.starts_on = cohort.starts_on;
                    vm.model.ends_on = cohort.ends_on;
                    vm.model.name = cohort.name;
                    vm.model.rolling = cohort.rolling;
                    // rolling cohort case
                    if (cohort.rolling) {
                      vm.model.user_started_on = isFutureCohort(vm.model.starts_on) ? vm.model.starts_on : new Date().toISOString().slice(0, 10);
                    // standard cohort case
                    } else {
                      vm.model.user_started_on = vm.model.starts_on;
                    }
                  }
                }
              },
              expressionProperties: {
                'templateOptions.disabled': 'vm.cohorts.length > 0'
              }
            },
            {
              key: 'user_role',
              type: 'radio',
              defaultValue: 'Student',
              templateOptions: {
                label: 'Select role within the cohort',
                options: [
                  {name: 'Student', value: 'Student'},
                  {name: 'Instructor', value: 'Instructor'}
                ]
              },
              expressionProperties: {
                hide: '!model.id'
              }
            },
            {
              key: 'drc_accommodation',
              type: 'ui-select',
              defaultValue: '100',
              templateOptions: {
                label: 'DRC',
                options: [
                  {id: 0, value: 0},
                  {id: 100, value: 100},
                  {id: 150, value: 150},
                  {id: 200, value: 200}
                ],
                placeholder: 'Select a value',
                valueProp: 'id',
                labelProp: 'value'
              },
              hideExpression: '!model.id || model.user_role === "Instructor"'
            },
            {
              key: 'user_started_on',
              type: 'ui-date',
              templateOptions: {
                required: true,
                label: 'Select enrollment date'
              },
              expressionProperties: {
                'templateOptions.disabled': function() {
                  return !vm.model.rolling;
                },
                'templateOptions.dateOptions.dateFormat': '"yy-mm-dd"',
                'templateOptions.dateOptions.minDate': function($viewValue, $modelValue, $scope) {
                  if (vm.model.rolling) {
                    return isFutureCohort($scope.model.starts_on) ? $scope.model.starts_on : new Date().toISOString().slice(0, 10);
                  } else {
                    return $scope.model.starts_on;
                  }
                },
                'templateOptions.dateOptions.maxDate': 'model.ends_on'
              },
              hideExpression: '!model.id'
            }
          ];

          vm.cancel = function() {
            $scope.$dismiss('close');
          };

          vm.save = function() {
            if (vm.enrollInCohortForm.$valid) {
              $scope.$close(vm.model);
            }
          };
        }
      });

      modalInstance.result.then(function(cohort) {
        var queryString = {
          started_on: cohort.user_started_on
        };

        if (cohort.user_role === 'Student') {
          queryString.drc_accommodation = cohort.drc_accommodation;
        }

        spinner.activateController([
          Institution.one(vm.user.institution_id).one('cohorts', cohort.id).one('enrollments', userId).one(cohort.user_role).put(queryString).then(function() {
            vm.cohorts.push(cohort);
          })
        ]);
      });
    };

    vm.resetUser = function() {
      $uibModal.open({
        templateUrl: 'dashboard/admin/users/modals/confirm-user-reset.html',
        controllerAs: 'modal',
        controller: function($scope) {
          var modal  = this;

          modal.model = {};

          modal.fields = [
            {
              key: 'password',
              type: 'zxcvbn',
              modelOptions: {
                allowInvalid: true
              },
              templateOptions: {
                placeholder: 'Password',
                maxlength: '32',
                minlength: '8',
                required: true,
                type: 'password',
                onKeyup: function(viewValue, modelValue, scope) {
                  if (!viewValue) {
                    scope.fullData = {};
                  }
                }
              },
              validators: {
                password: ValidationService.validatePassword()
              }
            },
            {
              key: 'password_confirmation',
              type: 'input',
              templateOptions: {
                placeholder: 'Confirm Password',
                required: true,
                type: 'password'
              },
              validators: {
                passwordMatch: ValidationService.validatePasswordMatch()
              }
            }
          ];

          modal.cancel = function() {
            $scope.$dismiss('close');
          };

          modal.save = function() {
            if (modal.resetUserForm.$valid) {
              spinner.activateController([
                User.one(userId).one('clone_user').customPOST({
                  password: modal.model.password
                }).then(function(response) {
                  $state.go('admin.users.show', {userId: response.data.id});
                  $scope.$close();
                }, function(response) {
                  modal.flash = response.data.error;
                })
              ]);
            }
          };
        }
      });
    };

    function isFutureCohort(startsOn) {
      return startsOn !== new Date().toISOString().slice(0, 10) && moment(startsOn).isAfter(new Date(), 'day');
    }

    spinner.activateController([
      getUserDetails(),
      getUserProgramsEnrollment(),
      getUserCohortsEnrollment()
    ]);
  }
}());
