(function() {
  'use strict';

  angular
    .module('tcm.common')
    .service('SpinnerService', SpinnerService);

  function SpinnerService($rootScope, $q) {
    var Spinner = function() {};

    Spinner.prototype.activateController = function(promises) {
      $rootScope.$broadcast('eo_spinner_loading');

      return $q.all(promises).finally(function(data) {
        $rootScope.$broadcast('eo_spinner_loaded');
        return data;
      });
    };

    return Spinner;
  }
}());
