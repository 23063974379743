(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoTodoList', eoTodoList);

  function eoTodoList(UserTodoList) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-todo-list/eo-todo-list.html',
      scope: {
        cohortId: '='
      },
      replace: true,
      controller: eoTodoListCtrl,
      controllerAs: 'vm',
      bindToController: true
    };

    function eoTodoListCtrl() {
      var vm = this;

      UserTodoList.getActivitiesByCohortId(vm.cohortId).then(function(response) {
        vm.activities = response.data;
      });
    }
  }

}());
