(function() {
  'use strict';

  angular
    .module('tcm.common')
    .directive('eoShowWithRoles', eoShowWithRoles);

  function eoShowWithRoles(UserService) {
    /**
     * eoShowWithRoles directive removes element as ng-if does when user does not
     * have a role(s) provided with directive. The directive support "and" and "or" cases as
     * well as single role and multiple which should be divided by pipe ("|"). In the case
     * when it is necessary to check if user has any except provided role(s) (negation case)
     * the roles should be divided by caret ("^").
     *
     * ## Usage
     *
     * <div eo-show-with-roles or-roles="Student">
     *   <div>Content</div>
     * </div>
     *
     * The div would be removed if a user does not have a Student role
     *
     * ## Usage ("or" case)
     *
     * <div eo-show-with-roles or-roles="Student|Instructor">
     *   <div>Content</div>
     * </div>
     *
     * The div would be removed if a user does not have either Student or Instructor roles
     *
     * ## Usage ("and" case)
     *
     * <div eo-show-with-roles and-roles="Student|Instructor">
     *   <div>Content</div>
     * </div>
     *
     * The div would be removed if a user does not have Student and Instructor roles
     *
     * ## Usage (negation case)
     *
     * <div eo-show-with-roles or-roles="^Student|^Instructor">
     *   <div>Content</div>
     * </div>
     *
     * The div would not be removed if a user has any roles except either Student or
     * Instructor roles
     *
     */
    return {
      transclude: 'element',
      scope: true,
      link: function(scope, element, attrs, ctrl, transclude) {
        var orRoles, andRoles, profileRolesArr = [];

        if (attrs.orRoles) {
          orRoles = _.map(attrs.orRoles.split('|'), toLowerCase);
          UserService.loadProfile().then(function(profile) {
            if (hasNegation(orRoles)) {
              orRoles = _.map(orRoles, removeCaret);
              if (!hasAnyRole(profile.roles, orRoles)) {
                renderElement();
              }
            } else {
              if (hasAnyRole(profile.roles, orRoles)) {
                renderElement();
              }
            }
          });
        }

        if (attrs.andRoles) {
          andRoles = _.map(attrs.andRoles.split('|'), toLowerCase);
          UserService.loadProfile().then(function(profile) {
            if (hasNegation(andRoles)) {
              andRoles = _.map(andRoles, removeCaret);
              if (!hasEachRole(profile.roles, andRoles)) {
                renderElement();
              }
            } else {
              if (hasEachRole(profile.roles, andRoles)) {
                renderElement();
              }
            }
          });
        }

        function hasNegation(roles) {
          return _.some(roles, function(role) {
            return role.charAt(0) === '^';
          });
        }

        function hasAnyRole(profilesRoles, roles) {
          return _.some(profilesRoles, function(profileRole) {
            return _.includes(roles, profileRole.title.toLowerCase());
          });
        }

        function hasEachRole(profilesRoles, roles) {
          _.each(profilesRoles, function(item) {
            profileRolesArr.push(item.title.toLowerCase());
          });
          return _.every(roles, function(role) {
            return _.includes(profileRolesArr, role);
          });
        }

        function renderElement() {
          transclude(scope, function(clone) {
            element.after(clone);
          });
        }

        function toLowerCase(element) {
          return element.toLowerCase();
        }

        function removeCaret(element) {
          return element.substr(1);
        }
      }
    };
  }
}());
