(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.programs')
    .controller('AdminProgramsManageCompetenciesCtrl', AdminProgramsManageCompetenciesCtrl);

  function AdminProgramsManageCompetenciesCtrl($stateParams, Institution,
    SpinnerService, UserService) {

    var vm = this, spinner, contextId, programId;

    spinner = new SpinnerService();
    contextId = parseInt($stateParams.contextId);
    programId = parseInt($stateParams.id);

    function loadProgram() {
      return Institution.one(contextId).one('programs', programId).get().then(function(response) {
        vm.program = response.data;
      });
    }

    function checkManagePermission() {
      return UserService.canAccess(['MANAGE_COMPETENCIES'], ['programs']).then(function(hasPermission) {
        vm.managePermission = hasPermission;
      });
    }

    spinner.activateController([
      loadProgram(),
      checkManagePermission()
    ]);
  }
}());
