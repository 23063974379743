(function() {
  'use strict';

  angular
    .module('tcm.messages')
    .controller('MessagesDetailCtrl', MessagesDetailCtrl);

  function MessagesDetailCtrl($scope, MessagesService, UserCohorts, $state,
    $stateParams, SpinnerService) {
    var vm = this, spinner, messageId = parseInt($stateParams.id, 10);

    vm.showReply = false;

    vm.model = {};

    vm.fields = [
      {
        key: 'body',
        type: 'ta-editor',
        templateOptions: {
          required: true,
          label: 'Body'
        }
      }
    ];

    spinner = new SpinnerService();

    vm.submitReply = function() {

      if (vm.replyMessageForm.$valid) {
        vm.model.user_ids = [vm.message.sent_by.id];
        vm.model.subject = vm.message.subject;

        if (vm.model.subject && !vm.model.subject.startsWith('RE:')) {
          vm.model.subject = 'RE: ' + vm.model.subject;
        }

        if (vm.message.subject_id) {
          vm.model.cohort_id = vm.message.subject_id;
        }

        MessagesService.createMessage(vm.model).then(function() {
          vm.goToMessagesList();
        });
      }
    };

    vm.goToMessagesList = function() {
      if ($state.includes('student.**')) {
        $state.go('student.messages.list');
      } else if ($state.includes('instructor.**')) {
        $state.go('instructor.messages.list');
      } else if ($state.includes('successcoach.**')) {
        $state.go('successcoach.messages.list');
      }
    };

    function getMessage() {
      return MessagesService.getMessage(messageId).then(function(response) {
        vm.message = response.data;
        vm.message.author = vm.message.sent_by.first_name + ' ' + vm.message.sent_by.last_name;
        if (!vm.message.read_at) {
          MessagesService.markRead([vm.message.id]).then(function() {
            vm.message.read_at = true;
            $scope.$emit('eo-message:mark_read', 1);
          });
        }
      });
    }

    spinner.activateController([
      getMessage()
    ]);

  }
}());
