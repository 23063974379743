(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoAdminDashboardPanel', eoAdminDashboardPanel);

  function eoAdminDashboardPanel() {
    return {
      replace: true,
      restrict: 'E',
      templateUrl: 'components/eo-admin-dashboard-panel/eo-admin-dashboard-panel.html'
    };
  }
}());
