(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin', [
      'tcm.core',
      'tcm.common',
      'tcm.notes',
      'tcm.dashboard.admin.activities',
      'tcm.dashboard.admin.cohorts',
      'tcm.dashboard.admin.competencies',
      'tcm.dashboard.admin.courses',
      'tcm.dashboard.admin.institutions',
      'tcm.dashboard.admin.libraries',
      'tcm.dashboard.admin.objectives',
      'tcm.dashboard.admin.programs',
      'tcm.dashboard.admin.rubrics',
      'tcm.dashboard.admin.users',
      'tcm.dashboard.admin.reports'
    ]);
}());
