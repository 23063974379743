(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.institutions')
    .controller('AdminInstitutionsListCtrl', AdminInstitutionsListCtrl);

  function AdminInstitutionsListCtrl($scope, $stateParams, $location,
    Institution, TableFeaturesService) {

    var tableFeatures = new TableFeaturesService({
      sorting: {column: 'name'},
      search: {
        'name_cont': $stateParams.name_cont
      },
      loadData: function(queryParams) {
        return Institution.getList(queryParams);
      }
    });

    $scope.institutionsList = {
      searchData: tableFeatures.searchParams,
      sortingData: tableFeatures.sortingParams,
      paginationData: tableFeatures.paginationParams
    };

    $scope.loadPage = function() {
      tableFeatures.getPageData().then(loadInstitutions);
    };

    $scope.search = function() {
      tableFeatures.resetEmptySearchData();
      $location.search($scope.institutionsList.searchData);
      tableFeatures.getNewData().then(loadInstitutions);
    };

    $scope.reset = function() {
      tableFeatures.resetSearch();
      $scope.search();
    };

    $scope.disableClearOption = function() {
      var disable = true;

      _.each($scope.institutionsList.searchData, function(n, key) {
        if ($scope.institutionsList.searchData[key] !== void 0 && $scope.institutionsList.searchData[key].length > 0) {
          disable = false;
        }
      });

      return disable;
    };

    function loadInstitutions(institutions) {
      var data = [];
      angular.forEach(institutions[0].data, function(institution) {
        var parent;
        institution.parent = {};
        if (institution.parent_id && institution.parent_id !== 1) {
          parent = _.find(institutions[0].data, { id: institution.parent_id });
          if (!parent) {
            Institution.one(institution.parent_id).get().then(function(child) {
              angular.copy(child.data, institution.parent);
            });
          } else {
            angular.copy(parent, institution.parent);
          }
        }
        data.push(institution);
      });
      $scope.institutions = institutions[0].data;
    }

    $scope.loadPage();
  }
}());
