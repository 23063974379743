(function() {
  'use strict';

  angular
  .module('tcm.common')
  .filter('htmlToPlaintext', htmlToPlaintext);

  function htmlToPlaintext() {
    return function(text) {
      return text ? String(text).replace(/<[^>]+>/gm, ' ').replace(/\s+/g, ' ').trim() : '';
    };
  }
}());
