(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.users')
    .controller('CoachUsersProgressCtrl', CoachUsersProgressCtrl);

  function CoachUsersProgressCtrl($state, $scope, $stateParams, $location,
    EnvConfig, SpinnerService, User, Institution, SortingService) {

    var spinner, sorting, courseIndex;

    spinner = new  SpinnerService();
    sorting = new SortingService({
      column: $location.search().sort || 'user_name_i',
      direction: $location.search().direction || 'asc'
    });

    $scope.userId = parseInt($stateParams.userId, 10);
    $scope.cohortId = parseInt($stateParams.cohortId, 10);

    $scope.isUserProgress = $stateParams.state === 'users';
    $scope.isSuccessCoach = $state.includes('successcoach.**');
    $scope.isAdmin = $state.includes('admin.**');
    function getStudentAsUser() {
      var queryParams = {};
      sorting.addParams(queryParams);

      return User.one($scope.userId).get().then(function(response) {
        $scope.user = response.data;
        return Institution.one(response.data.institution_id).one('cohorts', $scope.cohortId).one('enrollments', $scope.userId).get(queryParams).then(function(response) {
          $scope.user = response.data;
          getCohort($scope.user.user_institution_id);
          setChartsUrls($scope.user.user_institution_id);
        });
      });
    }

    function setChartsUrls(institutionId) {
      var burnupTemplate = _.template(EnvConfig.main_url + '/api/v1/institutions/' + institutionId + '/cohorts/{{cohortId}}/enrollments/{{id}}/objective_burnup');
      $scope.burnupUrl = burnupTemplate({
        cohortId: $scope.cohortId,
        id: $scope.userId
      });
    }

    function loadStudentPrograms() {
      return User.one($scope.userId).getList('programs').then(function(response) {
        $scope.programs = response.data;
      });
    }

    function getCohort() {
      var queryParams = {'role': 'Student'};
      return User.one($scope.userId).one('cohorts', $scope.cohortId).get(queryParams).then(function(response) {
        $scope.cohort = response.data;
      });
    }

    function loadAllCourses() {
      var queryParams = {'per_page': 100, 'q[s]': 'started_on desc', 'json_type': 'with_stats'};
      return User.one($scope.userId).getList('cohorts', queryParams).then(function(response) {
        var courses = response.data;
        _.each(courses, function(item, index) {

          if (_.isEqual(item.cohort_id, $scope.cohortId)) {
            courseIndex = index;
            return;
          }
        });

        if (courses.length === 1) {
          $scope.hasNextCourse = false;
          $scope.hasPreviousCourse = false;

        } else {
          if (courseIndex === 0) {
              //for first course
            $scope.hasPreviousCourse = false;
            $scope.hasNextCourse = true;
            $scope.nextCourseId = courses[courseIndex + 1].cohort_id;

            } else if (courses.length - 1 === courseIndex) {
              //for last course
              $scope.hasPreviousCourse = true;
              $scope.hasNextCourse = false;
              $scope.previousCourseId = courses[courseIndex - 1].cohort_id;

            } else {
              $scope.hasPreviousCourse = true;
              $scope.hasNextCourse = true;
              $scope.nextCourseId = courses[courseIndex + 1].cohort_id;
              $scope.previousCourseId = courses[courseIndex - 1].cohort_id;
            }
        }
      });
    }

    spinner.activateController([
      getStudentAsUser(),
      loadStudentPrograms(),
      loadAllCourses()
    ]);
  }
}());
