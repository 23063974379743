(function() {
  'use strict';

  angular
    .module('tcm.webexSessions')
    .controller('WebexSessionsListCtrl', WebexSessionsListCtrl);

  function WebexSessionsListCtrl($rootScope, $scope, WebexSessionsService, $location, $uibModal) {
    var vm = this,
      sessionPage = parseInt($location.search().page, 10),
      $uibModalInstance;

    vm.sessionsPagination = WebexSessionsService.paginationData;

    vm.sessionsPagination.currentPage = 1;

    vm.messageActions = {};

    vm.getUserSessions = function() {
      WebexSessionsService.getUserSessions(sessionPage).then(function(response) {
        vm.sessions = response[0].data;
      });
    };

    $rootScope.$on('eo-webex-session:cancel-error', function(event, message) {
      vm.flash = message;

      window.scrollTo(0, 0);
    });

    $rootScope.$on('eo-webex-session:delete', function(event, session, response) {
      var position = -1;

      $scope.$emit('badge-counter:refresh');

      vm.sessions.some(function(element, index) {
        if (element.id === session.id) {
          position = index;

          return true;
        }
      });

      if (-1 !== position) {
        vm.sessions.splice(position, 1);

        $uibModalInstance = $uibModal.open({
          animation: $scope.animationsEnabled,
          templateUrl: 'webex-sessions/list/modals/cancel-session-modal.html',
          controllerAs: 'modal',
          controller: function CancelModalCtrl() {
            var modal = this;

            modal.message = 'We found some errors while performing the operation.';

            if (204 === response.status) {
              modal.message = 'The session was cancelled sucessfully.';
            }

            modal.cancel = function () {
              $uibModalInstance.close();
            };
          }
        });
      }
    });

    $scope.$emit('badge-counter:refresh');
    vm.getUserSessions();
  }
}());
