(function() {
  'use strict';

  angular
    .module('tcm.common')
    .directive('eoSortableManager', eoSortableManager)
    .directive('eoSortableColumn', eoSortableColumn);

  function eoSortableManager() {
    return {
      scope: {
        updateQueryParams: '&eoSortableManager',
        sortableData: '='
      },
      controller: eoSortableManagerCtrl
    };

    function eoSortableManagerCtrl($scope) {
      var prevColumnElement;

      this.register = function(columnName, columnElement) {
        // set default direction icon
        if (columnName === $scope.sortableData.column) {
          columnElement.applyClass($scope.sortableData.direction);
          prevColumnElement = columnElement;
        }
      };

      this.sortingBy = function(column, currentColumn) {
        var prevColumn = $scope.sortableData.column;
        if (prevColumn === column) {
          $scope.sortableData.direction = ($scope.sortableData.direction === 'asc') ? 'desc' : 'asc';
          currentColumn.applyClass($scope.sortableData.direction);
        } else {
          $scope.sortableData.column = column;
          $scope.sortableData.direction = 'asc';
          if (prevColumnElement) {
            prevColumnElement.resetClass();
          }
          currentColumn.applyClass($scope.sortableData.direction);
        }
        prevColumnElement = currentColumn;
        $scope.updateQueryParams();
      };
    }
  }

  function eoSortableColumn() {
    return {
      require: ['^eoSortableManager', 'eoSortableColumn'],
      scope: {
        sortingColumn: '@eoSortableColumn'
      },
      link: function(scope, el, attrs, SortableCtrl) {

        SortableCtrl[0].register(scope.sortingColumn, SortableCtrl[1]);

        el.on('click', function() {
          SortableCtrl[0].sortingBy(scope.sortingColumn, SortableCtrl[1]);
        });
      },
      controller: eoSortableColumnCtrl
    };

    function eoSortableColumnCtrl($scope, $element) {
      this.applyClass = function(direction) {
        this.resetClass();
        if (direction === 'asc') {
          $element.addClass('eo-sortable-asc');
        } else {
          $element.addClass('eo-sortable-desc');
        }
      };
      this.resetClass = function() {
        $element.removeClass('eo-sortable-asc eo-sortable-desc');
      };
    }
  }
}());
