(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.courses')
    .config(coursesRoutesConfig);

  function coursesRoutesConfig($stateProvider) {

    $stateProvider.state('admin.courses', {
      url: '/course?name_cont&code_cont',
      abstract: true,
      template: '<div ui-view="active"></div>'
    }).state('admin.courses.list', {
      url: '',
      reloadOnSearch: false,
      views: {
        'active': {
          controller: 'AdminCoursesListCtrl',
          templateUrl: 'dashboard/admin/courses/list/courses-list.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Courses',
        parent: 'admin.dashboard'
      }
    }).state('admin.courses.new', {
      url: '/new',
      views: {
        'active': {
          controller: 'AdminCoursesCreateCtrl as vm',
          templateUrl: 'dashboard/admin/courses/create/courses-create.html'
        }
      },
      ncyBreadcrumb: {
        label: 'New',
        parent: 'admin.courses.list'
      }
    }).state('admin.courses.edit', {
      url: '/:id/edit',
      views: {
        'active': {
          controller: 'AdminCoursesCreateCtrl as vm',
          templateUrl: 'dashboard/admin/courses/create/courses-create.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Edit',
        parent: 'admin.courses.show'
      }
    }).state('admin.courses.show', {
      url: '/:id',
      views: {
        'active': {
          controller: 'AdminCoursesDetailCtrl as vm',
          templateUrl: 'dashboard/admin/courses/detail/courses-detail.html'
        }
      },
      ncyBreadcrumb: {
        label: '{{vm.course.name}}',
        parent: 'admin.courses.list'
      }
    }).state('admin.courses.grade', {
      url: '/:id/grade',
      views: {
        'active': {
          controller: 'AdminCoursesManageObjectivesCtrl as vm',
          templateUrl: 'dashboard/admin/courses/manage-objectives/courses-manage-activities.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Gradable Activities',
        parent: 'admin.courses.show'
      }
    }).state('admin.courses.manage', {
      url: '/:id/manage',
      views: {
        'active': {
          controller: 'AdminCoursesManageObjectivesCtrl as vm',
          templateUrl: 'dashboard/admin/courses/manage-objectives/courses-manage-objectives.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Manage',
        parent: 'admin.courses.show'
      }
    }).state('admin.courses.children', {
      url: '/:id/children',
      views: {
        'active': {
          controller: 'AdminCoursesManageCoursesCtrl as vm',
          templateUrl: 'dashboard/admin/courses/manage-courses/courses-manage-courses.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Children',
        parent: 'admin.courses.show'
      }
    }).state('admin.courses.structure', {
      url: '/:id/structure',
      views: {
        'active': {
          templateUrl: 'dashboard/admin/courses/structure/courses-structure.html',
          controller: 'AdminCoursesStructureCtrl as vm'
        }
      },
      ncyBreadcrumb: {
        label: 'Structure',
        parent: 'admin.courses.show'
      }
    }).state('admin.courses.activity', {
      url: '/:curriculumId/activity/:activityId?:objectiveId',
      views: {
        'active': {
          template: '<eo-course-structure-tree-activity></eo-course-structure-tree-activity>'
        }
      },
      ncyBreadcrumb: {
        label: 'Structure',
        parent: 'admin.chorts.list'
      }
    }).state('admin.courses.cohorts', {
      url: '/:id/cohorts?active_cohorts',
      reloadOnSearch: false,
      views: {
        'active': {
          controller: 'AdminCoursesCohortsListCtrl as vm',
          templateUrl: 'dashboard/admin/courses/cohorts/courses-cohorts-list.html'
        }
      },
      ncyBreadcrumb: {
        label: 'Cohorts',
        parent: 'admin.courses.show'
      }
    });
  }
}());
