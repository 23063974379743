(function() {
  'use strict';

  angular
    .module('tcm.dashboard.instructor', [
      'tcm.core',
      'tcm.common',
      'tcm.notifications',
      'tcm.notes',
      'tcm.dashboard.instructor.cohorts'
    ]);
}());
