(function() {
  'use strict';

  angular
    .module('tcm.components')
    .directive('eoStudentProgramsList', eoStudentProgramsList);

  function eoStudentProgramsList($state, $stateParams, UserPrograms, SpinnerService) {
    return {
      restrict: 'E',
      templateUrl: 'components/eo-student-programs-list/eo-student-programs-list.html',
      scope: {},
      controllerAs: 'vm',
      bindToController: true,
      controller: eoStudentProgramsListCtrl
    };

    function eoStudentProgramsListCtrl() {
      var vm = this, spinner;

      spinner = new SpinnerService();
      vm.currentProgramId = parseInt($stateParams.id, 10);

      vm.isCurrentSection = function(programId) {
        return (vm.currentProgramId === programId && $state.includes('student.programs.**'));
      };

      function getStudentPrograms() {
        return UserPrograms.getPrograms().then(function(response) {
          vm.programs = response.data;
        });
      }

      spinner.activateController([
        getStudentPrograms()
      ]);
    }
  }
}());
