(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.programs')
    .controller('AdminProgramsCreateCtrl', AdminProgramsCreateCtrl);

  function AdminProgramsCreateCtrl($stateParams, $state, Institution,
    SpinnerService) {

    var vm = this, spinner, contextId, programId;

    spinner = new SpinnerService();
    contextId = parseInt($stateParams.contextId, 10);
    programId = parseInt($stateParams.id, 10);

    vm.fields = [
      {
        key: 'name',
        type: 'input',
        templateOptions: {
          required: true,
          minlength: 2,
          maxlength: 50,
          type: 'text',
          label: 'Name'
        }
      },
      {
        key: 'description',
        type: 'textarea',
        templateOptions: {
          maxlength: 500,
          label: 'Description'
        }
      },
      {
        key: 'visibility',
        type: 'checkbox',
        templateOptions: {
          label: 'Visibility'
        }
      }
    ];

    vm.save = function() {
      if (vm.createEditForm.$valid) {
        if (vm.model && vm.model.id) {
          vm.model.patch().then(function(response) {
            $state.go('admin.programs.show', {id: response.data.id});
          });
        } else {
          Institution.one(contextId).post('programs', vm.model).then(function(response) {
            $state.go('admin.programs.show', {id: response.data.id});
          });
        }
      }
    };

    function loadProgramToEdit() {
      vm.isEditMode = false;
      // set visilibity true as default on creating
      vm.model = {};
      vm.model.visibility = true;

      if (programId) {
        vm.isEditMode = true;
        Institution.one(contextId).one('programs', programId).get().then(function(response) {
          vm.model = response.data;
          vm.program = vm.model; // breadcrumb label
        });
      }
    }

    spinner.activateController([
      loadProgramToEdit()
    ]);
  }
}());
