/*globals moment:false */
(function() {
  'use strict';

  angular
    .module('tcm.dashboard.admin.users')
    .controller('AdminUsersCohortsCtrl', AdminUsersCohortsCtrl);

  function AdminUsersCohortsCtrl($stateParams, $uibModal, $state, User,
    Institution, SpinnerService, PaginationService, SearchService) {

    var vm = this, spinner, pagination, userId;

    vm.isStudent = true;

    spinner = new SpinnerService();
    pagination = new PaginationService({ perPage: 100});
    userId = parseInt($stateParams.userId, 10);

    vm.toggleProfile = function() {
      if (!vm.cohortsAsStudent || !vm.cohortsAsInstructor) {
        return;
      }

      if (vm.isStudent) {
        vm.cohorts = vm.cohortsAsStudent;   
      } else {
        vm.cohorts = vm.cohortsAsInstructor;
      }
    };

    vm.enrollToCohort = function() {
      var modalInstance = $uibModal.open({
        templateUrl: 'dashboard/admin/users/modals/enroll-cohort.html',
        resolve: {
          cohorts: function() {
            var search, queryString;

            queryString = {};

            search = new SearchService({
              published_eq: true,
              ends_on_gteq: new Date().toISOString().slice(0, 10)
            });
            search.addParams(queryString);

            return spinner.activateController([
              Institution.one(vm.user.institution_id).getList('cohorts', queryString).then(function(response) { 
                response.data = removeFromList(response.data, vm.cohortsAsStudent);
                response.data = removeFromList(response.data, vm.cohortsAsInstructor);

                return response.data;
              })
            ]);
          }
        },
        controllerAs: 'vm',
        controller: function($scope, cohorts) {
          var vm = this;

          vm.cohorts = cohorts[0]; // get first element of array returned by spinner service

          vm.model = {};
          vm.fields = [
            {
              key: 'id',
              type: 'ui-select',
              templateOptions: {
                required: true,
                label: 'Cohorts',
                options: vm.cohorts,
                placeholder: 'Select a cohort',
                valueProp: 'id',
                labelProp: 'name',
                onChange: function($viewValue) {
                  delete vm.model.user_started_on;

                  if ($viewValue !== void 0) {
                    var cohort = _.find(vm.cohorts, {id: $viewValue});
                    vm.model.starts_on = cohort.starts_on;
                    vm.model.ends_on = cohort.ends_on;
                    vm.model.name = cohort.name;
                    vm.model.rolling = cohort.rolling;
                    // rolling cohort case
                    if (cohort.rolling) {
                      vm.model.user_started_on = isFutureCohort(vm.model.starts_on) ? vm.model.starts_on : new Date().toISOString().slice(0, 10);
                    // standard cohort case
                    } else {
                      vm.model.user_started_on = vm.model.starts_on;
                    }
                  }
                }
              },
              expressionProperties: {
                'templateOptions.disabled': 'vm.cohorts.length > 0'
              }
            },
            {
              key: 'user_role',
              type: 'radio',
              defaultValue: 'Student',
              templateOptions: {
                label: 'Select role within the cohort',
                options: [
                  {name: 'Student', value: 'Student'},
                  {name: 'Instructor', value: 'Instructor'}
                ]
              },
              hideExpression: '!model.id'
            },
            {
              key: 'drc_accommodation',
              type: 'ui-select',
              defaultValue: '100',
              templateOptions: {
                label: 'DRC',
                options: [
                  {id: 0, value: 0},
                  {id: 100, value: 100},
                  {id: 150, value: 150},
                  {id: 200, value: 200}
                ],
                placeholder: 'Select a value',
                valueProp: 'id',
                labelProp: 'value'
              },
              hideExpression: '!model.id || model.user_role === "Instructor"'
            },
            {
              key: 'user_started_on',
              type: 'ui-date',
              templateOptions: {
                required: true,
                label: 'Select enrollment date'
              },
              expressionProperties: {
                'templateOptions.disabled': function() {
                  return !vm.model.rolling;
                },
                'templateOptions.dateOptions.dateFormat': '"yy-mm-dd"',
                'templateOptions.dateOptions.minDate': function($viewValue, $modelValue, $scope) {
                  if (vm.model.rolling) {
                    return isFutureCohort($scope.model.starts_on) ? $scope.model.starts_on : new Date().toISOString().slice(0, 10);
                  } else {
                    return $scope.model.starts_on;
                  }
                },
                'templateOptions.dateOptions.maxDate': 'model.ends_on'
              },
              hideExpression: '!model.id'
            }
          ];

          vm.cancel = function() {
            $scope.$dismiss('close');
          };

          vm.save = function() {
            if (vm.enrollInCohortForm.$valid) {
              $scope.$close(vm.model);
            }
          };
        }
      });

      modalInstance.result.then(function(cohort) {
        var queryString = {
          started_on: cohort.user_started_on
        };

        if (cohort.user_role === 'Student') {
          queryString.drc_accommodation = cohort.drc_accommodation;
        }

        spinner.activateController([
          Institution.one(vm.user.institution_id).one('cohorts', cohort.id).one('enrollments', userId).one(cohort.user_role).put(queryString).then(function() {
            getUserCohortsEnrollment();
          })
        ]);
      });
    };

    function getUserDetails() {
      return User.one(userId).get().then(function(response) {
        vm.user = response.data;

        vm.isStudent = _.some(vm.user.assignments, function(assignment) {
          return assignment.role_name === 'Student';
        });
      });
    }

    function getUserCohortsEnrollment() {
      getUserCohorts('Student').then(function(response) {
        vm.cohortsAsStudent = response[0].data;
        vm.toggleProfile();
      });
      getUserCohorts('Instructor').then(function(response) {
        vm.cohortsAsInstructor = response[0].data;
        vm.toggleProfile();
      });
    }

    function getUserCohorts(role) {
      var queryString = {};

      queryString.user_role = role;
      queryString.avoid_expired_extension = true;
      pagination.addParams(queryString);

      return spinner.activateController([User.one(userId).one('cohorts').one('grouped').get(queryString)]); 
    }

    function isFutureCohort(startsOn) {
      return startsOn !== new Date().toISOString().slice(0, 10) && moment(startsOn).isAfter(new Date(), 'day');
    }

    function removeFromList(cohorts, toRemove) {
      _.forEach(['expired', 'future', 'current'], function(state) {
        _.find(toRemove[state], function(cohortToExclude) {
          cohorts = _.reject(cohorts, function(cohort) {
            return cohort.id === cohortToExclude.id;
          });
        }); 
      });

      return cohorts;
    }

    spinner.activateController([
      getUserDetails(),
      getUserCohortsEnrollment()
    ]);
  }
}());
